/* eslint-disable react/jsx-no-bind */

import React, { useState, useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

import * as Icon from 'react-feather';

import { format, isToday, parseISO } from 'date-fns';

import DateFnsUtils from '@date-io/date-fns';

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

const AppFilter = ({ page_filters, current: { active_user, date, logged_in_user } }) => {
  const [open, setOpen] = useState(false);

  const [selectedDate, setSelectedDate] = useState(parseISO(date));

  const closeDialog = () => {
    if (open) {
      setOpen(false);
    }
  };

  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;

      return;
    }

    const path = `/filter/date_scope?date=${selectedDate}`;

    window.location.assign(path);
  }, [selectedDate]);

  function selectToday() {
    setSelectedDate(new Date());
  }

  function handlePropertyScopeSelect(event) {
    const scope = event.target.value;

    const path = `/filter/property_scope?property_scope=${scope}`;

    window.location.assign(path);
  }

  function handlePropertyFilterScopeSelect(event) {
    const scope = event.target.value;

    const path = `/filter/property_filter_scope?property_filter_scope=${scope}`;

    window.location.assign(path);
  }

  function resetPropertyFilterScopeSelect() {
    const event = {
      target: {
        value: 'current',
      },
    };

    handlePropertyFilterScopeSelect(event);
  }

  function handleActiveUserSelect(event) {
    const user_id = event.target.value;

    changeActiveUser(user_id);
  }

  function resetActiveUser() {
    const user_id = logged_in_user.id;

    changeActiveUser(user_id);
  }

  function changeActiveUser(user_id) {
    const path = `/filter/active_user?active_user_id=${user_id}`;

    window.location.assign(path);
  }

  function handleInvestmentPortfolioSelect(event) {
    const investment_portfolio_id = event.target.value;

    changeInvestmentPortfolio(investment_portfolio_id);
  }

  function resetInvestmentPortfolio() {
    const investment_portfolio_id = 'all';

    changeInvestmentPortfolio(investment_portfolio_id);
  }

  function changeInvestmentPortfolio(investment_portfolio_scope) {
    const path = `/filter/investment_portfolio_scope?investment_portfolio_scope=${investment_portfolio_scope}`;

    window.location.assign(path);
  }

  function handleRvpSelect(event) {
    const rvp_id = event.target.value;

    changeRvp(rvp_id);
  }

  function resetRvp() {
    const rvp_id = 'all';

    changeRvp(rvp_id);
  }

  function changeRvp(rvp_scope) {
    const path = `/filter/rvp_scope?rvp_scope=${rvp_scope}`;

    window.location.assign(path);
  }

  function handleRmSelect(event) {
    const rm_id = event.target.value;

    changeRm(rm_id);
  }

  function resetRm() {
    const rm_id = 'all';

    changeRm(rm_id);
  }

  function changeRm(rm_scope) {
    const path = `/filter/rm_scope?rm_scope=${rm_scope}`;

    window.location.assign(path);
  }

  function handleSMSelect(event) {
    const sm_id = event.target.value;
    changeSm(sm_id);
  };

  function resetSM() {
    const sm_id = 'all';
    changeSm(sm_id);
  };

  function changeSm(sm_scope) {
    const path = `/filter/sm_scope?sm_scope=${sm_scope}`;

    window.location.assign(path);
  };

  function handlePropertyTypeSelect(event) {
    const property_type_id = event.target.value;

    changePropertyType(property_type_id);
  }

  function resetPropertyType() {
    const property_type_id = 'all';

    changePropertyType(property_type_id);
  }

  function changePropertyType(property_type_scope) {
    const path = `/filter/property_type_scope?property_type_scope=${property_type_scope}`;

    window.location.assign(path);
  }

  function renderFilters() {
    return (
      <div className="flex flex-col p-4">
        <div className="px-32 text-center text-3xl">Filters</div>	
        {page_filters.date.is_permitted && renderDateInput()}

        {page_filters.property_scope.is_permitted && renderPropertyScopeSelect()}

        {page_filters.property_filter_scope.is_permitted && renderPropertyFilterScopeSelect()}

        {page_filters.investment_portfolio_scope.is_permitted && renderInvestmentPortfolioSelect()}

        {page_filters.rvp_scope.is_permitted && renderRvpSelect()}

        {page_filters.rm_scope.is_permitted && renderRmSelect()}

        {page_filters.sm_scope.is_permitted && renderSMSelect()}

        {page_filters.property_type_scope.is_permitted && renderPropertyTypeSelect()}

        {page_filters.active_user.is_permitted && renderActiveUserSelect()}
      </div>
    );
  }

  function renderDateInput() {
    const dateInput = () => (
      <div className="w-full mt-4">
        <label htmlFor="grid-city" className="block uppercase tracking-wide text-sm font-bold mb-2">
          Date
        </label>
        <div className="flex items-center">
          <div className="w-full">
            <input
              onClick={() => setOpen(true)}
              value={format(selectedDate, 'M/d/yyyy')}
              readOnly
              className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-city"
              type="text"
            />
          </div>
          {!isToday(selectedDate) && (
            <div className="pl-3">
              <Icon.XCircle
                size={24}
                // eslint-disable-next-line react/jsx-no-bind

                onClick={selectToday}
                className="cursor-pointer text-red-700"
              />
            </div>
          )}
        </div>
      </div>
    );

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          variant="dialog"
          value={selectedDate}
          onChange={setSelectedDate}
          minDate={parseISO(page_filters.date.min)}
          allowKeyboardControl
          animateYearScrolling
          disableFuture
          open={open}
          TextFieldComponent={dateInput}
          onClose={closeDialog}
        />
      </MuiPickersUtilsProvider>
    );
  }

  function renderPropertyScopeSelect() {
    return (
      <div className="w-full mt-4">
        <label
          className="block uppercase tracking-wide text-sm font-bold mb-2"
          htmlFor="grid-state"
        >
          My Assigned Properties
        </label>
        <div className="relative">
          <select
            onChange={handlePropertyScopeSelect}
            defaultValue={page_filters.property_scope.value}
            className="block appearance-none w-full bg-white border border-gray-200 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-gray-500"
            id="grid-state"
          >
            <option value="all">All</option>
            <option value="assigned">Assigned</option>
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
      </div>
    );
  }

  function renderPropertyFilterScopeSelect() {
    return (
      <div className="w-full mt-4">
        <label
          className="block uppercase tracking-wide text-sm font-bold mb-2"
          htmlFor="grid-state"
        >
          Current / Same Store
        </label>
        <div className="flex items-center">
          <div className="w-full relative">
            <select
              onChange={handlePropertyFilterScopeSelect}
              defaultValue={page_filters.property_filter_scope.value}
              className="block appearance-none w-full bg-white border border-gray-200 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-gray-500"
              id="grid-state"
            >
              {page_filters.property_filter_scope.options.map(u => (
                <option key={u.value} value={u.value}>
                  {u.label}
                </option>
              ))}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
          {page_filters.property_filter_scope.value !== 'current' && (
            <div className="pl-3">
              <Icon.XCircle
                size={24}
                onClick={resetPropertyFilterScopeSelect}
                className="cursor-pointer text-red-700"
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  function renderActiveUserSelect() {
    return (
      <div className="w-full mt-4">
        <label
          className="block uppercase tracking-wide text-sm font-bold mb-2"
          htmlFor="grid-state"
        >
          Active User
        </label>
        <div className="flex items-center">
          <div className="w-full relative">
            <select
              onChange={handleActiveUserSelect}
              defaultValue={active_user.id}
              className="block appearance-none w-full bg-white border border-gray-200 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-gray-500"
              id="grid-state"
            >
              {page_filters.active_user.options.map(u => (
                <option key={u.value} value={u.value}>
                  {u.label}
                </option>
              ))}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
          {active_user.id !== logged_in_user.id && (
            <div className="pl-3">
              <Icon.XCircle
                size={24}
                onClick={resetActiveUser}
                className="cursor-pointer text-red-700"
              />
            </div>
          )}
        </div>
        <div className="text-sm mt-1">
          <div className="text-left">
            Logged in as{' '}
            <span className="font-bold">
              {logged_in_user.first_name} {logged_in_user.last_name}
            </span>
          </div>
        </div>
      </div>
    );
  }

  function renderInvestmentPortfolioSelect() {
    return (
      <div className="w-full mt-4">
        <label
          className="block uppercase tracking-wide text-sm font-bold mb-2"
          htmlFor="grid-state"
        >
          Investment Portfolio
        </label>
        <div className="flex items-center">
          <div className="w-full relative">
            <select
              onChange={handleInvestmentPortfolioSelect}
              defaultValue={page_filters.investment_portfolio_scope.value}
              className="block appearance-none w-full bg-white border border-gray-200 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-gray-500"
              id="grid-state"
            >
              {page_filters.investment_portfolio_scope.options.map(u => (
                <option key={u.value} value={u.value}>
                  {u.label}
                </option>
              ))}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
          {page_filters.investment_portfolio_scope.value !== 'all' && (
            <div className="pl-3">
              <Icon.XCircle
                size={24}
                onClick={resetInvestmentPortfolio}
                className="cursor-pointer text-red-700"
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  function renderRvpSelect() {
    return (
      <div className="w-full mt-4">
        <label
          className="block uppercase tracking-wide text-sm font-bold mb-2"
          htmlFor="grid-state"
        >
          RVP
        </label>
        <div className="flex items-center">
          <div className="w-full relative">
            <select
              onChange={handleRvpSelect}
              defaultValue={page_filters.rvp_scope.value}
              className="block appearance-none w-full bg-white border border-gray-200 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-gray-500"
              id="grid-state"
            >
              {page_filters.rvp_scope.options.map(u => (
                <option key={u.value} value={u.value}>
                  {u.label}
                </option>
              ))}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
          {page_filters.rvp_scope.value !== 'all' && (
            <div className="pl-3">
              <Icon.XCircle size={24} onClick={resetRvp} className="cursor-pointer text-red-700" />
            </div>
          )}
        </div>
      </div>
    );
  }

  function renderRmSelect() {
    return (
      <div className="w-full mt-4">
        <label
          className="block uppercase tracking-wide text-sm font-bold mb-2"
          htmlFor="grid-state"
        >
          RM
        </label>
        <div className="flex items-center">
          <div className="w-full relative">
            <select
              onChange={handleRmSelect}
              defaultValue={page_filters.rm_scope.value}
              className="block appearance-none w-full bg-white border border-gray-200 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-gray-500"
              id="grid-state"
            >
              {page_filters.rm_scope.options.map(u => (
                <option key={u.value} value={u.value}>
                  {u.label}
                </option>
              ))}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
          {page_filters.rm_scope.value !== 'all' && (
            <div className="pl-3">
              <Icon.XCircle size={24} onClick={resetRm} className="cursor-pointer text-red-700" />
            </div>
          )}
        </div>
      </div>
    );
  }

  function renderSMSelect() {
    return (
      <div className="w-full mt-4">
        <label
          className="block uppercase tracking-wide text-sm font-bold mb-2"
          htmlFor="grid-state"
        >
          SM
        </label>
        <div className="flex items-center">
          <div className="w-full relative">
            <select
              onChange={handleSMSelect}
              defaultValue={page_filters.sm_scope.value}
              className="block appearance-none w-full bg-white border border-gray-200 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-gray-500"
              id="grid-state"
            >
              {page_filters.sm_scope.options.map(u => (
                <option key={u.value} value={u.value}>
                  {u.label}
                </option>
              ))}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
              </svg>
            </div>
          </div>
          {page_filters.sm_scope.value !== 'all' && (
            <div className="pl-3">
              <Icon.XCircle size={24} onClick={resetSM} className="cursor-pointer text-red-700" />
            </div>
          )}
        </div>
      </div>
    );
  }

  function renderPropertyTypeSelect() {
    return (
      <div className="w-full mt-4">
        <label
          className="block uppercase tracking-wide text-sm font-bold mb-2"
          htmlFor="grid-state"
        >
          Property Type
        </label>
        <div className="flex items-center">
          <div className="w-full relative">
            <select
              onChange={handlePropertyTypeSelect}
              defaultValue={page_filters.property_type_scope.value}
              className="block appearance-none w-full bg-white border border-gray-200 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-gray-500"
              id="grid-state"
            >
              {page_filters.property_type_scope.options.map(u => (
                <option key={u.value} value={u.value}>
                  {u.label}
                </option>
              ))}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
          {page_filters.property_type_scope.value !== 'all' && (
            <div className="pl-3">
              <Icon.XCircle
                size={24}
                onClick={resetPropertyType}
                className="cursor-pointer text-red-700"
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  return <div>{renderFilters()}</div>;
};

AppFilter.propTypes = {
  page_filters: PropTypes.shape({
    account_scope: PropTypes.shape({
      is_permitted: PropTypes.bool.isRequired,

      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,

          value: PropTypes.number,
        })
      ),

      value: PropTypes.number.isRequired,
    }),

    active_user: PropTypes.shape({
      is_permitted: PropTypes.bool.isRequired,

      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,

          value: PropTypes.string,
        })
      ),
    }).isRequired,

    investment_portfolio_scope: PropTypes.shape({
      is_permitted: PropTypes.bool.isRequired,

      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,

          value: PropTypes.string,
        })
      ),

      value: PropTypes.number.isRequired,
    }).isRequired,

    rvp_scope: PropTypes.shape({
      is_permitted: PropTypes.bool.isRequired,

      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,

          value: PropTypes.string,
        })
      ),

      value: PropTypes.number.isRequired,
    }).isRequired,

    rm_scope: PropTypes.shape({
      is_permitted: PropTypes.bool.isRequired,

      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,

          value: PropTypes.string,
        })
      ),

      value: PropTypes.number.isRequired,
    }).isRequired,

    property_type_scope: PropTypes.shape({
      is_permitted: PropTypes.bool.isRequired,

      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,

          value: PropTypes.string,
        })
      ),

      value: PropTypes.string.isRequired,
    }).isRequired,

    date: PropTypes.shape({
      is_permitted: PropTypes.bool.isRequired,

      min: PropTypes.string.isRequired,
    }).isRequired,

    property_scope: PropTypes.shape({
      is_permitted: PropTypes.bool.isRequired,

      value: PropTypes.string.isRequired,
    }).isRequired,

    property_filter_scope: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,

          value: PropTypes.string,
        })
      ),

      is_permitted: PropTypes.bool.isRequired,

      value: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,

  current: PropTypes.shape({
    date: PropTypes.string.isRequired,

    active_user: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,

    logged_in_user: PropTypes.shape({
      id: PropTypes.number.isRequired,

      first_name: PropTypes.string.isRequired,

      last_name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default AppFilter;