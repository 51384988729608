import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const UniversityDate = ({ title, start, end, university, propertyName }) => {
  return (
    <>
      <Typography variant="h6">
        <p className="mt-4 mb-1">{title}</p>
      </Typography>
      <Typography component="span" variant="subtitle1">
        <p className="text-gray-600">
          <span className="font-semibold">Start:</span> {start}
        </p>
      </Typography>
      <Typography component="span" variant="subtitle1">
        <p className="text-gray-600 mb-2">
          <span className="font-semibold">End:</span> {end}
        </p>
      </Typography>
      <Typography component="span" variant="subtitle1">
        <p className="text-gray-600">
          <span className="font-semibold">University:</span> {university}
        </p>
      </Typography>
      <Typography component="span" variant="subtitle1">
        <p className="text-gray-600">
          <span className="font-semibold">Property:</span> {propertyName}
        </p>
      </Typography>
    </>
  );
};

UniversityDate.propTypes = {
  title: PropTypes.string.isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  isAllDay: PropTypes.bool,
};

export default UniversityDate;
