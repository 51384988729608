import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Photo from './Photo';

const PhotoGrid = ({ photos, toggleLike }) => {
  return (
    <Box sx={{ flexGrow: 1, paddingTop: '24px' }}>
      <Grid container spacing={2}>
        {photos.map(photo => (
          <Photo key={photo.id} photo={photo} toggleLike={toggleLike} />
        ))}
      </Grid>
    </Box>
  );
};
PhotoGrid.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  toggleLike: PropTypes.func.isRequired,
};
export default PhotoGrid;
