import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { get as objectGet } from '@utils/lib/object';

const styles = {
  inputBase: `appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`,
  labelBase: 'block text-gray-700 text-sm font-bold mb-2',
  errorBase: 'text-red-600 p-1 my-1',
  statusBase: 'text-red-600 p-1 my-2',
  disabledBase: 'bg-disabled font-light',
};

const FormikInputBasic = ({
  field,
  form: { errors, touched, status },
  inputClassName,
  isdisabled,
  key,
  label,
  labelClassName,
  minimumValue,
  maximumValue,
  required,
  type,
  ...props
}) => (
  <div key={key}>
    <label htmlFor={field.name} className={classNames(styles.labelBase, labelClassName)}>
      {label}
    </label>

    {minimumValue && (
      <input
        className={classNames(
          styles.inputBase,
          { [`${styles.disabledBase}`]: isdisabled },
          inputClassName
        )}
        disabled={isdisabled}
        min={minimumValue}
        max={maximumValue}
        required={required}
        type={type}
        {...field}
        {...props}
      />
    )}

    {!minimumValue && (
      <input
        className={classNames(
          styles.inputBase,
          { [`${styles.disabledBase}`]: isdisabled },
          inputClassName
        )}
        disabled={isdisabled}
        required={required}
        type={type}
        {...field}
        {...props}
      />
    )}

    {objectGet(touched, field.name) && objectGet(status, field.name) && (
      <div className={classNames(styles.statusBase)}>{objectGet(status, field.name)}</div>
    )}

    {objectGet(touched, field.name) && objectGet(errors, field.name) && (
      <div className={classNames(styles.errorBase)}>{objectGet(errors, field.name)}</div>
    )}
  </div>
);

FormikInputBasic.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  form: PropTypes.shape({
    status: PropTypes.shape(),
    touched: PropTypes.shape(),
    errors: PropTypes.shape(),
  }).isRequired,
  inputClassName: PropTypes.string,
  isdisabled: PropTypes.bool,
  key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  minimumValue: PropTypes.string,
  maximumValue: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
};

FormikInputBasic.defaultProps = {
  isdisabled: false,
  inputClassName: '',
  key: null,
  label: null,
  labelClassName: '',
  minimumValue: null,
  maximumValue: null,
  required: false,
  type: 'text',
};

export default FormikInputBasic;
