let Highcharts = require('highcharts');
import addExporting from "highcharts/modules/exporting";
import themeSelector from "../../themes/theme_selector";
addExporting(Highcharts);

const moment = require('moment');
import columnChart from "../../common/column";

document.addEventListener("reputation-column-chart-loaded", function(e) {
  if ($('#js-reputation-column-chart').length){

      const {data} = e.detail;

      let options = {
        chart: {
          renderTo: $("#js-reputation-column-chart")[0]
        },
        title: {
          text: null
        },
        xAxis: {
          categories: data.xAxis
        },
        series: data.series
      };

      options = Highcharts.merge(options, themeSelector('light'));

      const chart = columnChart(options);
  }
})
