/* eslint react/prop-types: 0 */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import humps from 'humps';
import { Grid, TextField, IconButton, Tooltip, CircularProgress } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import ExclamationIcon from '@material-ui/icons/Error';
import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import DeleteIcon from '@material-ui/icons/Cancel';
import SelectAllIcon from '@material-ui/icons/DoneAll';
import Autocomplete from '@material-ui/lab/Autocomplete';
import operationOptionsMapping from './operationOptionsMapping';

const Filter = ({ filter, dimension, deleteFilter, updateFilter }) => {
  const operationOptions = operationOptionsMapping[dimension.type];
  const [operation, setOperation] = useState();
  const [valueOptions, setValueOptions] = useState([]);
  const [isLoadingValueOptions, setIsLoadingValueOptions] = useState(false);
  const [values, setValues] = useState([]);

  const isValid = () => dimension && operation && values.length > 0;

  const updateValueIndex = (index, value) => {
    setValues(Object.assign([...values], { [index]: value }));
  };

  const filterParams = () => ({
    ...filter,
    operation: operation.value,
    values: values.map(v => v.value),
  });

  const selectAll = () => {
    setValues(valueOptions);
  };

  useEffect(() => {
    setValues([]);
    setValueOptions([]);
    if (operation && operation.valueInputType === 'select') {
      fetchValueOptions();
    }
  }, [operation]);

  useEffect(() => {
    isValid() && updateFilter(filterParams());
  }, [operation, values]);

  const fetchValueOptions = () => {
    setIsLoadingValueOptions(true);
    const queryParams = {
      type: 'dataset',
      name: dimension.datasetName,
      dimensions: [dimension],
    };
    axios
      .get('/run_query.json', {
        params: humps.decamelizeKeys({ queryParams }),
      })
      .then(response => {
        setIsLoadingValueOptions(false);
        const results = humps.camelizeKeys(response.data.data);
        const options = results.map(r => {
          return { value: r[dimension.id] };
        });
        setValueOptions(options);
      })
      .catch(() => {
        setIsLoadingValueOptions(false);
      });
  };

  const renderValueSelect = () => (
    <Autocomplete
      multiple
      disableCloseOnSelect
      options={valueOptions}
      getOptionLabel={option => option.value}
      value={values}
      onChange={(event, selections) => {
        setValues(selections);
      }}
      renderInput={params => (
        <TextField
          {...params}
          variant="standard"
          label="Value"
          fullWidth
          disabled={!operation && isLoadingValueOptions}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoadingValueOptions ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );

  const renderDatepicker = ({ index = 0, label = 'Date' }) => (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        label={label}
        placeholder="MM/DD/YYYY"
        value={values[index]}
        onChange={momentDate => {
          const date = momentDate.format('YYYY-MM-DD');
          updateValueIndex(index, date);
        }}
        autoOk
        labelFunc={date => date.format('M/D/YYYY')}
        fullWidth
      />
    </MuiPickersUtilsProvider>
  );

  const renderValueDateSelect = () => renderDatepicker({});

  const renderValueDateRange = () => (
    <Grid container spacing={2}>
      <Grid item xs>
        {renderDatepicker({ index: 0, label: 'Start Date' })}
      </Grid>
      <Grid item xs>
        {renderDatepicker({ index: 1, label: 'End Date' })}
      </Grid>
    </Grid>
  );

  const renderValueTextInput = () => (
    <TextField
      label="Value"
      value={values[0] || ''}
      fullWidth
      onChange={event => setValues([event.target.value])}
    />
  );

  const renderValueNumberInput = ({ index = 0, label = 'Value' }) => (
    <TextField
      label={label}
      value={values[index] || ''}
      fullWidth
      number
      onChange={event => updateValueIndex(index, event.target.value)}
    />
  );

  const renderValueNumberRange = () => (
    <Grid container spacing={2}>
      <Grid item xs>
        {renderValueNumberInput({ index: 0, label: 'Start' })}
      </Grid>
      <Grid item xs>
        {renderValueNumberInput({ index: 1, label: 'End' })}
      </Grid>
    </Grid>
  );

  const InitialDisabledField = () => (
    <TextField label="Value" placeholder="Select an operation" fullWidth disabled />
  );

  const renderValueField = () => {
    if (!operation) {
      return <InitialDisabledField />;
    }
    switch (operation.valueInputType) {
      case 'select':
        return renderValueSelect();
      case 'dateSelect':
        return renderValueDateSelect();
      case 'dateRange':
        return renderValueDateRange();
      case 'text':
        return renderValueTextInput();
      case 'number':
        return renderValueNumberInput({});
      case 'numberRange':
        return renderValueNumberRange({});
      default:
        return renderValueTextInput();
    }
  };

  const renderOperationSelect = () => (
    <Autocomplete
      options={operationOptions}
      onChange={(event, selection) => {
        setOperation(selection);
      }}
      getOptionLabel={option => option.label}
      renderInput={params => (
        <TextField {...params} variant="standard" label="Operation" fullWidth />
      )}
    />
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs>
          <TextField label="Dimension" value={dimension.label} fullWidth disabled />
        </Grid>
        <Grid item xs>
          {renderOperationSelect()}
        </Grid>
        <Grid item xs={6}>
          {renderValueField()}
        </Grid>
        <Grid item xs>
          {operation && operation.valueInputType === 'select' && (
            <IconButton onClick={() => selectAll()} aria-label="delete">
              <SelectAllIcon />
            </IconButton>
          )}
          {isValid() ? (
            <Tooltip title="Filter is valid">
              <CheckIcon />
            </Tooltip>
          ) : (
            <Tooltip title="Invalid Filter">
              <ExclamationIcon />
            </Tooltip>
          )}
          <IconButton onClick={() => deleteFilter(filter.id)} aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
};

// Filter.propTypes = {
//   source: PropTypes.shape({}),
//   filter: PropTypes.shape({
//     id: PropTypes.number,
//     dimension: PropTypes.shape({}),
//     value: PropTypes.shape({}),
//   }).isRequired,
//   dispatch: PropTypes.func.isRequired,
// };

export default Filter;
