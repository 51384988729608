import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "button", "chart" ]

  initialize(){
    const on_load_button = this.findButtonWithAttribute("data-render-on-load", "true");
    this.toggleButtons(on_load_button);

    if(on_load_button.getAttribute("data-parent-id") != null){
      this.toggleChildren(this.findButtonWithAttribute("data-id", on_load_button.getAttribute("data-parent-id")));
    }

    this.renderChartByButton(on_load_button);
  }

  renderNew(event){
    const current = this.findButtonWithAttribute("data-render-loaded-currently", "true");
    current.setAttribute("data-render-loaded-currently", "false");
    current.firstChild.classList.remove('active');

    event.currentTarget.setAttribute("data-render-loaded-currently", "true");
    this.toggleButtons(event.currentTarget);

    if(event.currentTarget.getAttribute("data-parent-id") != null){
      this.toggleChildren(this.findButtonWithAttribute("data-id", event.currentTarget.getAttribute("data-parent-id")));
    }

    this.renderChartByButton(event.currentTarget);
  }

  showChildren(event){
    this.toggleChildren(event.currentTarget);
    const default_button = this.buttonTargets.find(btn => btn.getAttribute("data-default-load") == "true" &&
                                                          btn.getAttribute("data-parent-id") == event.currentTarget.getAttribute("data-id"));

    this.toggleButtons(default_button);
    this.toggleChildren(this.findButtonWithAttribute("data-id", default_button.getAttribute("data-parent-id")));
    this.renderChartByButton(default_button);
  }

  toggleChildren(button){
    const id = button.getAttribute("data-id");

    this.buttonTargets.forEach(btn => {
      if (btn.getAttribute("data-parent-id") === id){
        btn.classList.remove('d-none')
      }

      if(btn.getAttribute("data-parent-id") != null && btn.getAttribute("data-parent-id") !== id){
        btn.classList.add('d-none')
      }
    })
  }

  toggleButtons(buttonToBeActivated){
    this.unactivateButtons();
    this.activateLoadedButtons(buttonToBeActivated);
  }

  unactivateButtons(){
    this.buttonTargets.forEach(btn => btn.firstChild.classList.remove('active') );
  }

  activateLoadedButtons(button){
    button.firstChild.classList.add('active');

    const parent_id = button.getAttribute("data-parent-id")
    if(parent_id != null){
      const parent_button = this.buttonTargets.find(btn => btn.getAttribute("data-id") === parent_id );
      parent_button.firstChild.classList.add('active');
    }
  }

  findButtonWithAttribute(attr, value){
    return this.buttonTargets.find(btn => btn.getAttribute(attr) === value )
  }

  renderChartByButton(button){
    const url = button.getAttribute("data-render-chart-url");
    const chartEvent = button.getAttribute("data-render-chart-event");

    if(url){
      this.chartTarget.appendChild(this.renderLoader());
      fetch(url, {credentials: 'same-origin'})
        .then(response => response.json())
        .then(jsonResponse => {
          const event = new CustomEvent(chartEvent, {detail: {data: jsonResponse} });

          if(jsonResponse.html_content){
            this.chartTarget.innerHTML = jsonResponse.html_content;
          } else {
            this.chartTarget.removeChild(this.chartTarget.lastChild);
          }

          document.dispatchEvent(event);

          this.buttonTargets.forEach(btn => btn.setAttribute("data-render-loaded-currently", "true"));

          button.setAttribute("data-render-loaded-currently", "true");
        })
        .catch(error => this.chartTarget.innerHTML = 'An error has occurred.' )
    } else {
      this.chartTarget.innerHTML = 'An error has occurred.';
    }
  }

  renderLoader(){
    return new DOMParser().parseFromString(`
      <div class="p-3" style="position: absolute; top: 10%; left: 0; right:0">
        <div class="preloader-wrapper small active d-block mx-auto">
          <div class="spinner-layer spinner-yellow-only">
            <div class="circle-clipper left">
              <div class="circle"></div>
            </div>
            <div class="gap-patch">
              <div class="circle"></div>
            </div>
            <div class="circle-clipper right">
              <div class="circle"></div>
            </div>
          </div>
        </div>
      </div>
    `, 'text/html').body.firstChild;
  }

}
