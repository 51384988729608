require( 'datatables.net-dt' )

$(document).ready(function() {

  /** INITIALIZER FOR SORT ONLY ****************************/
  $("table.sort-only").DataTable({
    "searching": false,
    "paging": false,
    "aaSorting": [],
    "retrieve": true,
  });

  $("table.scrollable").DataTable({
    "scrollY": "40vh",
    "scrollX": "100%",
    "scrollCollapse": true,
    "aaSorting": [],
    "paging": false,
  })

  /** INCENTIVES TRACKER TABLE ****************************/
  const $incentivesTable = $("#incentives-tracker-table").DataTable({
    "paging": false, // no pagination
    "aaSorting": [], // disable initial sorting
    "columns": [
      null,
      { "searchable": false },
      { "searchable": false },
      { "searchable": false },
      { "searchable": false },
      { "searchable": false },
      { "type": "date" } ,
      { "type": "date" },
      { "searchable": false },
      { "type": "date" },
      { "type": "date" }
    ]
  });
  // Prevent rows from looking weirdly striped on redraw
  $incentivesTable.on('draw', function(e) {
    $('.row-gray').removeClass('row-gray')
    $('.odd').addClass('row-gray')
  });
  $('#incentives-search').on('keyup', function() {
    $incentivesTable.search(this.value).draw();
  });
});
