import classNames from 'classnames';
import { Field, FieldArray } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import * as Icon from 'react-feather';

import FormikInputBasic from '../shared/formik_inputs/FormikInputBasic';
import FormikInputNoEdit from '../shared/formik_inputs/FormikInputNoEdit';
import FormikInputCheckbox from '../shared/formik_inputs/FormikInputCheckbox';
import Button from '../shared/Button';

const PropertySurveyUnitMixRateFields = ({ values, initialValues, unitMixes, setFieldValue }) => {
  const findUnitMix = id => {
    return unitMixes.find(mix => mix.id === id);
  };

  const handleResetField = index => {
    setFieldValue(`unit_mix_rates[${index}].rate`, initialValues.unit_mix_rates[index].rate);
    setFieldValue(`unit_mix_rates[${index}].is_verified`, false);
  };

  const handleConfirmAll = () => {
    values.unit_mix_rates.forEach((_, index) =>
      setFieldValue(`unit_mix_rates[${index}].is_verified`, true)
    );
  };

  return (
    <React.Fragment>
      <h3 className="text-2xl font-light mb-6">Unit Mix Rates</h3>
      <div className="lg:border border-gray-200">
        <FieldArray
          name="unit_mix_rates"
          render={() => (
            <div>
              {values.unit_mix_rates.map((unit_mix_rate, index) => {
                const unitMix = findUnitMix(unit_mix_rate.unit_mix_id);
                const screenReaderOnly = index !== 0 ? 'lg:sr-only' : '';
                return (
                  <div
                    key={unitMix.id}
                    className={classNames(
                      'flex flex-wrap mt-4 lg:mt-2 py-3 lg:pb-0 lg:pt-1 sm:border lg:border-0 border-gray-200',
                      {
                        'lg:bg-gray-100': index % 2 != 0,
                      }
                    )}
                  >
                    <div className="w-2/4 lg:w-4/12 b px-3 mb-2">
                      <FormikInputNoEdit
                        label="Type"
                        labelClassName={screenReaderOnly}
                        value={[unitMix.unit_type, unitMix.unit_style]
                          .filter(el => !!el)
                          .join(' - ')}
                      />
                    </div>
                    <div className="w-1/4 lg:w-2/12 px-3 mb-2">
                      <FormikInputNoEdit
                        label="Bed x Bath"
                        labelClassName={screenReaderOnly}
                        value={`${unitMix.beds_unit}x${unitMix.baths_unit}`}
                      />
                    </div>
                    <div className="w-1/4 lg:w-2/12 px-3 mb-2">
                      <FormikInputNoEdit
                        label="Beds Total"
                        labelClassName={screenReaderOnly}
                        value={unitMix.beds}
                      />
                    </div>
                    <div className="w-4/6 lg:w-2/12 px-3">
                      <Field
                        label="Market Rate"
                        type="number"
                        labelClassName={screenReaderOnly}
                        name={`unit_mix_rates.${index}.rate`}
                        value={values.unit_mix_rates[index].rate}
                        component={FormikInputBasic}
                      />
                    </div>
                    <div className="w-1/6 lg:w-1/12 px-3">
                      <Field
                        label="Confirm"
                        name={`unit_mix_rates.${index}.is_verified`}
                        value={values.unit_mix_rates[index].is_verified}
                        component={FormikInputCheckbox}
                        checkboxClassName="mt-2 ml-2"
                        labelClassName={screenReaderOnly}
                      />
                    </div>
                    <div className="w-1/6 lg:w-1/12 px-3">
                      <FormikInputNoEdit
                        label="Reset"
                        labelClassName={screenReaderOnly}
                        value={
                          <div
                            role="button"
                            tabIndex={0}
                            className="cursor-pointer inline-block"
                            onClick={() => handleResetField(index)}
                            onKeyDown={event => {
                              const isEnterOrSpace = event.keyCode === 32 || event.keyCode === 13;
                              if (isEnterOrSpace) {
                                handleResetField(index);
                              }
                            }}
                          >
                            <Icon.RefreshCcw
                              className="text-gray-600 hover:text-gray-700"
                              size={16}
                            />
                          </div>
                        }
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        />

        <div className="flex justify-around lg:justify-start sm:border lg:border-0 lg:border-t mt-4 sm:pt-4 py-2 lg:py-0 border-gray-200">
          <div className="hidden lg:block lg:w-6/12 px-3 font-medium">
            <FormikInputNoEdit label="Totals" labelClassName="sr-only" value="Totals" />
          </div>

          <div className="w-1/6 lg:w-2/12 px-3 font-medium">
            <FormikInputNoEdit
              label="Beds Total"
              labelClassName="lg:sr-only"
              value={unitMixes.reduce((acc, unit) => acc + unit.beds, 0)}
            />
          </div>

          <div className="w-1/6 lg:w-2/12 px-3 font-medium">
            <FormikInputNoEdit
              label="Bed Avg"
              labelClassName="lg:sr-only"
              inputClassName="pl-3"
              value={
                '$' +
                Math.round(
                  values.unit_mix_rates.reduce((acc, unit) => acc + unit.rate, 0) /
                    values.unit_mix_rates.length
                )
              }
            />
          </div>

          <div className="w-2/6 lg:w-2/12 px-3 font-medium pt-2">
            <Button type="button" variant="primary" size="sm" onClick={handleConfirmAll} block>
              <span className="">Confirm All</span>
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

PropertySurveyUnitMixRateFields.propTypes = {
  values: PropTypes.shape({
    unit_mix_rates: PropTypes.arrayOf(
      PropTypes.shape({
        rate: PropTypes.number.isRequired,
        is_verified: PropTypes.bool.isRequired,
      })
    ),
  }).isRequired,
  initialValues: PropTypes.shape({
    unit_mix_rates: PropTypes.arrayOf(
      PropTypes.shape({
        is_verified: PropTypes.bool.isRequired,
        rate: PropTypes.number.isRequired,
      })
    ),
  }).isRequired,
  unitMixes: PropTypes.arrayOf(
    PropTypes.shape({
      beds: PropTypes.number.isRequired,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    })
  ).isRequired,

  setFieldValue: PropTypes.func.isRequired,
};

export default PropertySurveyUnitMixRateFields;
