import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import placeholderImage from './placeholder-image.jpg';

export default function OpenAndDownloadImageCard( { file } ) {
  console.log(file.thumbnail);
  return (
    <Card sx={{ maxWidth: 360}}>
      <CardMedia
        component="img"
        alt="green iguana"
        height="140"
        image={file.thumbnail ? file.thumbnail : placeholderImage}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {file.title}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" href={file.url_open}>Open</Button>
        <Button size="small" href={file.url_download}>Download</Button>
      </CardActions>
    </Card>
  );
}
