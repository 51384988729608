import columnChart from "../common/column";

document.addEventListener('DOMContentLoaded', function () {
  const Highcharts = require("highcharts");
  // Load module after Highcharts is loaded
  require("highcharts/modules/exporting")(Highcharts);

  if ($("#js-leasable-student-stacked").length) {
    const domChart = $("#js-leasable-student-stacked");
    const subjectData = $("#js-leasable-student-stacked").data("graph-subject");
    const allData = $("#js-leasable-student-stacked").data("graph-all");

    const categories = allData.seasons.map(d => d.season[0]);
    const categoriesTitles = categories.flatMap(d => ["Subject", "Average"]);

    const options = {
      chart: {
        renderTo: domChart[0]
      },
      title: {
        text: null
      },
      xAxis: [
        {
          categories: categoriesTitles,
          max: categoriesTitles.length - 1,
          min: 0,
          plotBands: [
            {
              from: categoriesTitles.length - 2.5,
              to: categoriesTitles.length,
              color: "#F7F7F7"
            }
          ]
        },
        {
          categories,
          lineWidth: 0,
          minorGridLineWidth: 0,
          lineColor: "transparent",
          minorTickLength: 0,
          tickLength: 0,
          labels: {
            y: 3
          }
        }
      ],
      yAxis: [
        {
          title: {
            text: null
          },
          labels: {
            format: "{value}%"
          }
        },
        {
          visible: false
        }
      ],
      plotOptions: {
        column: {
          stacking: "percent",
          events: {
            legendItemClick() {
              return false;
            }
          }
        }
      },
      tooltip: {
        useHTML: true,
        formatter() {
          let formatString = `<div style='min-width: 300px;'><table class="highcharts-table">`;
          let count = 0;

          formatString += `
            <tr>
              <th style="width: 160px"><small>${this.points[0].x}</small></td>
              <th style="width: 70px" class="text-center"><small><b>Subject</b></small></th>
              <th style="width: 70px" class="text-center"><small><b>Average</b></small></th>
            </tr>
          `;

          while (count < this.points.length) {
            formatString += `
              <tr>
                <td style="width: 160px"><b><span style="color:${
                  this.points[count].color
                }">\u25CF</span> ${this.points[count].series.name}</b></td>
                <td style="width: 70px" class="text-center">${this.points[
                  count
                ].percentage.toFixed(0)}%</td>
                <td style="width: 70px" class="text-center">${this.points[
                  count + 1
                ].percentage.toFixed(0)}%</td>
              </tr>
            `;
            count += 2;
          }

          formatString += `</table></div>`;
          return formatString;
        }
      },
      legend: {
        reversed: true
      },
      series: [
        {
          name: "Leasable Students",
          data: subjectData.seasons.map(d => d.leasable_student_total),
          color: "gray",
          stack: "single",
          yAxis: 1,
          xAxis: 1,
          pointPlacement: 0.1
        },
        {
          name: "Leasable Students",
          color: "gray",
          data: allData.seasons.map(d => d.leasable_student_total),
          stack: "avg",
          yAxis: 0,
          showInLegend: false,
          xAxis: 1,
          pointPlacement: 0.1
        },
        {
          name: "Total POSH Supply",
          data: subjectData.seasons.map(d => d.supply_posh),
          color: "#FFCE00",
          stack: "single",
          yAxis: 1,
          xAxis: 1,
          pointPlacement: 0.1
        },
        {
          name: "Total POSH Supply",
          data: allData.seasons.map(d => d.supply_posh),
          color: "#FFCE00",
          stack: "avg",
          yAxis: 0,
          showInLegend: false,
          xAxis: 1,
          pointPlacement: 0.1
        },
        {
          name: "# of students in POSH",
          data: subjectData.seasons.map(d => d.students_in_posh),
          color: "#181D30",
          stack: "single",
          yAxis: 1,
          xAxis: 1,
          pointPlacement: 0.1
        },
        {
          name: "# of students in POSH",
          color: "#181D30",
          data: allData.seasons.map(d => d.students_in_posh),
          stack: "avg",
          yAxis: 0,
          showInLegend: false,
          xAxis: 1,
          pointPlacement: 0.1
        },
        {
          data: [],
          showInLegend: false,
          xAxis: 0
        }
      ]
    };

    const chart = columnChart(options);
  }
})