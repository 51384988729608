import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import Grid from '@mui/material/Grid';

const SortablePhoto = SortableElement(({ photo }) => (
  <Grid item xs={12} sm={6} md={4} lg={3} sx={{ backgroundColor: '#f7fafc', position: 'relative' }}>
    <img
      style={{
        width: '100%',
        height: '45vh',
        objectFit: 'cover',
        borderRadius: '5px',
        boxShadow: 1,
      }}
      {...photo}
      alt="img"
      src={photo.url}
      loading="lazy"
    />
  </Grid>
));

export default SortablePhoto;
