import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["dateRange", "dateOptions", "markets", "properties", "description"];

  initialize() {
    this.report = this.data.get('report')
    this.toggleInputs()
  }

  handleDateChange(event) {
    const value = $(event.target).val()
    this.hideDateFields();
    this.clearHiddenInputs();
    if (value == 'Date Range') {
      $(this.dateRangeTarget).show();
    }
  }

  handleReportChange(event) {
    this.report = event.target.value
  }

  toggleInputs() {
    this.resetDateTypeSelection()
    this.clearHiddenInputs()
    if (this.report === "Incentives") {
      this.toggleIncentivesFields()
    } else if (this.report === "Unit Mixes") {
      this.toggleUnitMixesFields()
    } else if (this.report === "Performance By Day") {
      this.togglePerformanceByDayFields()
    } else if (this.report === "Direct/Indirect Competitor Unit Mixes") {
      this.toggleDirectIndirectMixesFields()
    } else if (this.report === "Weekly Submissions") {
      this.toggleWeeklySubmissionsFields()
    }
  }

  resetDateTypeSelection() {
    $(this.dateOptionsTarget).find('select').val('All')
  }

  hideDateFields() {
    $(this.dateRangeTarget).hide()
  }

  toggleIncentivesFields() {
    $(this.propertiesTarget).show()
    $(this.marketsTarget).hide()
    $(this.dateOptionsTarget).hide()
    this.hideDateFields()
  }

  toggleUnitMixesFields() {
    $(this.propertiesTarget).hide()
    $(this.marketsTarget).show()
    $(this.dateOptionsTarget).hide()
    this.hideDateFields()
  }

  togglePerformanceByDayFields() {
    $(this.propertiesTarget).show()
    $(this.marketsTarget).hide()
    $(this.dateOptionsTarget).show()
    this.hideDateFields()
  }

  clearHiddenInputs() {
    let elements = $(this.dateRangeTarget).find('input[type="text"]')
    $.each(elements, function(i,element) {
      $(element).val('')
    })
  }

  toggleDirectIndirectMixesFields() {
    $(this.marketsTarget).show()
    $(this.propertiesTarget).hide()
    $(this.dateOptionsTarget).hide()
    this.hideDateFields()
  }

  toggleWeeklySubmissionsFields() {
    $(this.propertiesTarget).hide()
    $(this.marketsTarget).show()
    $(this.dateOptionsTarget).show()
    this.hideDateFields()
  }

  updateDescription() {
    $(this.descriptionTarget).html(this.description)
  }

  get report() {
    return this.data.get('report')
  }

  set report(value) {
    this.data.set('report', value)
    this.updateDescription()
    this.toggleInputs()
  }

  get description() {
    return this.descriptions[this.report]
  }

  get descriptions() {
    return JSON.parse(this.data.get("descriptions"))
  }
}
