import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function InfoButton({ title, paragraph }) {
  const [open, setOpen] = useState(false);

  const toggleDrawer = open => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setOpen(open);
  };

  let paragraphArray = paragraph.split('/n/').map(str => str.replace('/n/', ''))

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '12px' }}>
      <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
        <InfoIcon />
      </IconButton>
      <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>

        {paragraphArray.map((paragraph, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Typography variant="body1" gutterBottom key={index}>
            {paragraph}
          </Typography>
        ))}
      </Drawer>
    </Box>
  );
};

InfoButton.propTypes = {
  title: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
};

export default InfoButton;