let Highcharts = require('highcharts');

import columnrangeChart from "../common/columnrange";
import themeSelector from "../themes/theme_selector";
import {capitalize} from "@utils/lib/string";

class LeadEngine{
  // Parameters referring to html class or id.
  constructor(leadEngineID, leadChart, leadBreakdown = null){
    this.state = {
      leadEngineID,
      main: leadChart,
      breakdown: leadBreakdown,
      segment: "",
      theme: null,
      mainData: {},
      breakdownData: {},
      properties: [],
      date: null
    }
    this._loadData();
  }

  createCharts(){
    if(this.state.main){ this.state.main.createChart(this._chartObject("main")); }
    if(this.state.breakdown){ this.state.breakdown.createChart(this._chartObject("breakdown")); }
  }

  updateBreakdown(){
    if(this.state.breakdown){ this.state.breakdown.updateChart(this._chartObject("breakdown")); }
  }

  _getSegmentedData(aSegment, date){
    const _this = this;
    const segment = aSegment ? aSegment : this.state.segment;
    const {properties} = this.state;
    $.get({
      url: "/charts/prelease_lead/breakdown.json",
      data: { segment_by: segment, properties: properties.map(p => p.id), date: date },
      beforeSend: function(data){
        _this.state.main.chart().showLoading();
        _this.state.breakdown.chart().showLoading();
      },
      success: function(data){
        const jEngineSelector = $(_this.state.leadEngineID);
        jEngineSelector.data("lead-breakdown-chart", data.breakdown_chart);
        jEngineSelector.data("segment", data.segment);

        _this._loadData();
        _this.updateBreakdown();

        _this.state.main.chart().hideLoading();
        _this.state.breakdown.chart().hideLoading();
      }
    })
  }

  _chartObject(chart){
    const data = this.state[chart + "Data"];
    const {theme, segment} =  this.state;
    return {
      ...data,
      theme,
      segment,
      engine: this
    }
  }

  _loadData(){
    const jEngineSelector = $(this.state.leadEngineID);
    const mainData = jEngineSelector.data("lead-chart");
    const breakdownData = jEngineSelector.data("lead-breakdown-chart");
    const theme = jEngineSelector.data("theme");
    const segment = jEngineSelector.data("segment");
    const date = jEngineSelector.data("date");
    this.state = { ...this.state, mainData, breakdownData, theme, segment, properties: mainData.properties, date }
  }

}

export default LeadEngine;
