import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import Box from '@material-ui/core/Box';

const styles = {
  formControlLabel: {
    fontSize: 13,
    textTransform: 'uppercase',
  },
};

const CheckboxInstagramPost = withStyles({
  root: {
    color: '#52B3CA', // Turquoise color
    '&$checked': {
      color: '#52B3CA',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const CheckboxTour = withStyles({
  root: {
    color: '#754ef4', // MediumPurple color
    '&$checked': {
      color: '#754ef4',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const CheckboxWorkSchedule = withStyles({
  root: {
    color: '#B6BCC1', // Gainsboro color
    '&$checked': {
      color: '#B6BCC1',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const CheckboxEndOfSeason = withStyles({
  root: {
    color: '#D22D1F', // FireBrick color
    '&$checked': {
      color: '#D22D1F',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const CheckboxVIP = withStyles({
  root: {
    color: '#ED85A9', // LightPink color
    '&$checked': {
      color: '#ED85A9',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const CheckboxRenewal = withStyles({
  root: {
    color: '#4BA5EC', // DodgerBlue color
    '&$checked': {
      color: '#4BA5EC',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const CheckboxLeadGeneration = withStyles({
  root: {
    color: '#66C978', // MediumSeaGreen color
    '&$checked': {
      color: '#66C978',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const CheckboxBrandAwareness = withStyles({
  root: {
    color: '#F4DA49', // Gold color
    '&$checked': {
      color: '#F4DA49',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const CheckboxTravelEvent = withStyles({
  root: {
    color: '#8A2BE2', // BlueViolet color
    '&$checked': {
      color: '#8A2BE2',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);
//

const CheckboxResidentEvent = withStyles({
  root: {
    color: '#EB5181', // MediumPurple color
    '&$checked': {
      color: '#eB5181',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

// const CheckboxEvent = withStyles({
//   root: {
//     color: '#8A2BE2', // BlueViolet color
//     '&$checked': {
//       color: '#8A2BE2',
//     },
//   },
//   checked: {},
// })(props => <Checkbox color="default" {...props} />);

const CheckboxUniversityDate = withStyles({
  root: {
    color: '#B7BCC2', // LightSalmon color
    '&$checked': {
      color: '#B7BCC2',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const EventFilter = props => {
  const { filters: filters, handleCheckboxChange: handleCheckboxChange } = props;

  // const [operationsChecked, setOperationsChecked] = useState(false);
  const [marketingChecked, setMarketingChecked] = useState(true);
  const [communityChecked, setCommunityChecked] = useState(false);


  const handleMarketingSwitchChange = event => {
    setMarketingChecked(event.target.checked);
    //
  };

  const handleCommunitySwitchChange = event => {
    setCommunityChecked(event.target.checked);
  };
  return (
    <>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <FormControlLabel
          control={
            <Switch
              checked={communityChecked}
              onChange={handleCommunitySwitchChange}
              name="communityChecked"
              color="primary"
            />
          }
        />
        <Typography variant="h6" gutterBottom style={{ marginRight: '16px' }}>
          Community:
        </Typography>
        {communityChecked && (
          <FormGroup row>
            <FormControlLabel
              control={
                <CheckboxResidentEvent
                  checked={filters.checkedResidentEvent}
                  onChange={handleCheckboxChange}
                  name="checkedResidentEvent"
                />
              }
              label={<Typography style={styles.formControlLabel}>Resident Event</Typography>}
            />
            <FormControlLabel
              control={
                <CheckboxTour
                  checked={filters.checkedTour}
                  onChange={handleCheckboxChange}
                  name="checkedTour"
                />
              }
              label={<Typography style={styles.formControlLabel}>Tours</Typography>}
            />
            <FormControlLabel
              control={
                <CheckboxInstagramPost
                  checked={filters.checkedInstagramPost}
                  onChange={handleCheckboxChange}
                  name="checkedInstagramPost"
                />
              }
              label={<Typography style={styles.formControlLabel}>SOCi Scheduled Post</Typography>}
            />
            <FormControlLabel
              control={
                <CheckboxWorkSchedule
                  checked={filters.checkedWorkSchedule}
                  onChange={handleCheckboxChange}
                  name="checkedWorkSchedule"
                />
              }
              label={<Typography style={styles.formControlLabel}>Office Scheduling</Typography>}
            />
            <FormControlLabel
              control={
                <CheckboxTravelEvent
                  checked={filters.checkedTravelEvent}
                  onChange={handleCheckboxChange}
                  name="checkedTravelEvent"
                />
              }
              label={<Typography style={styles.formControlLabel}>Travel Event</Typography>}
            />
          </FormGroup>
        )}
      </Box>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <FormControlLabel
          control={
            <Switch
              checked={marketingChecked}
              onChange={handleMarketingSwitchChange}
              name="marketingChecked"
              color="primary"
            />
          }
        />
        <Typography variant="h6" gutterBottom style={{ marginRight: '16px' }}>
          Marketing:
        </Typography>
        {marketingChecked && (
          <FormGroup row>
            <FormControlLabel
              control={
                <CheckboxEndOfSeason
                  checked={filters.checkedEndOfSeason}
                  onChange={handleCheckboxChange}
                  name="checkedEndOfSeason"
                />
              }
              label={<Typography style={styles.formControlLabel}>End of Season</Typography>}
            />
            <FormControlLabel
              control={
                <CheckboxVIP
                  checked={filters.checkedVIP}
                  onChange={handleCheckboxChange}
                  name="checkedVIP"
                />
              }
              label={<Typography style={styles.formControlLabel}>VIP</Typography>}
            />
            <FormControlLabel
              control={
                <CheckboxRenewal
                  checked={filters.checkedRenewal}
                  onChange={handleCheckboxChange}
                  name="checkedRenewal"
                />
              }
              label={<Typography style={styles.formControlLabel}>Renewal</Typography>}
            />
            <FormControlLabel
              control={
                <CheckboxLeadGeneration
                  checked={filters.checkedLeadGeneration}
                  onChange={handleCheckboxChange}
                  name="checkedLeadGeneration"
                />
              }
              label={<Typography style={styles.formControlLabel}>Lead Generation</Typography>}
            />
            <FormControlLabel
              control={
                <CheckboxBrandAwareness
                  checked={filters.checkedBrandAwareness}
                  onChange={handleCheckboxChange}
                  name="checkedBrandAwareness"
                />
              }
              label={<Typography style={styles.formControlLabel}>Brand Awareness</Typography>}
            />
            <FormControlLabel
              control={
                <CheckboxUniversityDate
                  checked={filters.checkedUniversityDate}
                  onChange={handleCheckboxChange}
                  name="checkedUniversityDate"
                />
              }
              label={<Typography style={styles.formControlLabel}>University Date</Typography>}
            />
          </FormGroup>
        )}
      </Box>
    </>
  );
};

EventFilter.propTypes = {
  filters: PropTypes.shape({
    checkedInstagramPost: PropTypes.bool.isRequired,
    checkedResidentEvent: PropTypes.bool.isRequired,
    checkedTour: PropTypes.bool.isRequired,
    checkedWorkSchedule: PropTypes.bool.isRequired,
    checkedEndOfSeason: PropTypes.bool.isRequired,
    checkedVIP: PropTypes.bool.isRequired,
    checkedRenewal: PropTypes.bool.isRequired,
    checkedLeadGeneration: PropTypes.bool.isRequired,
    checkedBrandAwareness: PropTypes.bool.isRequired,
    checkedUniversityDate: PropTypes.bool.isRequired,
    checkedTravelEvent: PropTypes.bool.isRequired,
  }).isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
};

export default EventFilter;
