import * as React from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
// import ArchitectureIcon from '@mui/icons-material/Architecture';
import PublicIcon from '@mui/icons-material/Public';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import Grid from '@mui/material/Grid';

const GeneralPropertyInfo = ({
  property: { website, email, contactNumber },
  propertyAddress: { street, city, state, postalCode },
  propertyMarket,
}) => {
  const fullAddress = `${street}, ${city}, ${state} ${postalCode}`;
  return (
    <Card>
      <CardContent>
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', flexWrap: 'wrap', paddingTop: '0px' }}
            spacing={4}
          >
            <Grid item sm={12} md={4} lg={2} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
              <LocationCityIcon />
              <Typography
                variant="body1"
                component="div"
                sx={{ display: 'inline', paddingLeft: '8px' }}
              >
                {propertyMarket}
              </Typography>
            </Grid>
            <Grid item sm={12} md={8} lg={3} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
              <LocationOnIcon />
              <Typography
                variant="body1"
                component="div"
                sx={{ display: 'inline', paddingLeft: '8px' }}
              >
                <a
                  href={`https://maps.google.com/?q=${fullAddress}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {fullAddress}
                </a>
              </Typography>
            </Grid>
            {/* <Grid>
                <ArchitectureIcon sx={{ fontSize: '1.5rem', marginRight: '8px' }} />
                <Typography variant="body1" component="div" sx={{ display: 'inline' }}>
                  {propertyType}
                </Typography>
              </Grid> */}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', flexWrap: 'wrap', paddingTop: '0px' }}
            spacing={4}
          >
            <Grid
              item
              sx={{ display: 'flex', alignContent: 'flex-start', flexWrap: 'nowrap' }}
              md={3}
              xs={12}
              lg={2}
            >
              <PublicIcon />
              <Typography variant="body1" component="div" sx={{ paddingLeft: '12px' }}>
                <a href={website} target="_blank" rel="noreferrer">
                  {website}
                </a>
              </Typography>
            </Grid>
            <Grid
              item
              sx={{ display: 'flex', alignContent: 'flex-start', flexWrap: 'nowrap' }}
              xs={12}
              md={4}
              lg={2}
            >
              <PhoneIcon />
              <Typography variant="body1" component="div" sx={{ paddingLeft: '12px' }}>
                <a href={`tel:${contactNumber}`}>{contactNumber}</a>
              </Typography>
            </Grid>
            <Grid item sx={{ display: 'flex', flexWrap: 'nowrap' }} md={4} xs={12} lg={2}>
              <EmailIcon />
              <Typography variant="body1" component="div" sx={{ paddingLeft: '12px' }}>
                <a href={`mailto: ${email}`} target="_blank" rel="noreferrer">
                  {email}
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

GeneralPropertyInfo.propTypes = {
  property: PropTypes.shape({
    website: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    contactNumber: PropTypes.string.isRequired,
    propertyType: PropTypes.string.isRequired,
  }).isRequired,
  propertyAddress: PropTypes.shape({
    street: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    postalCode: PropTypes.string.isRequired,
  }).isRequired,
  propertyMarket: PropTypes.string.isRequired,
};
export default GeneralPropertyInfo;
