import React, { Fragment, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import HotelIcon from '@mui/icons-material/Hotel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CalculateIcon from '@mui/icons-material/Calculate';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';

// State
import { StoreContext, actions, createAction } from '../reducer';

// Components
import api from '../../api';
import Floorplan from './Floorplan';
import TierDemo from './TierDemo';
import InfoPopover from './InfoPopover';

const Version = ({
  permissionToEdit: permissionToEdit,
  permissionToDestroy: permissionToDestroy,
  permissionToApproveLevel1: permissionToApproveLevel1,
  permissionToApproveLevel2: permissionToApproveLevel2,
  permissionToApproveLevel3: permissionToApproveLevel3,
}) => {
  const store = useContext(StoreContext);
  const {
    version: {
      property_id: propertyId,
      academic_year: academicYear,
      created_by: createdBy,
      request_notes: requestNotes,
      level_1_approved_by: level1ApprovedBy,
      level_2_approved_by: level2ApprovedBy,
      level_3_approved_by: level3ApprovedBy,
      level_1_notes: level1Notes,
      level_2_notes: level2Notes,
      level_3_notes: level3Notes,
      requested_by: requestedBy,
      is_new: isNew,
      approval_logs: approvalLogs,
      previous_approval_logs: previousApprovalLogs,
      description_investor_approved_rate: descriptionInvestorApprovedRate,
      description_adjusted_eos_hit_rate: descriptionAdjustedEosHitRate,
      description_previous_eos_hit_rate: descriptionPreviousEosHitRate,
    },
    property: {
      property_name: propertyName,
      property_bed_count: propertyBedCount,
      previous_eos_hit_rate: previousEosHitRate,
      property_investor_approved_rate: propertyInvestorApprovedRate,
    },
    floorplans,
  } = store.state;
  const dispatch = store.dispatch;
  const {
    total_avg_rate: totalAvgRate,
    floorplan_contracts_off: floorplanContractsOff,
    all_valid_rates: allValidRates,
  } = store.state?.property_calculations ? store.state.property_calculations : {};
  const [submitting, setSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(createdBy === null);
  const theme = useTheme();
  const mdBreakpointMatch = useMediaQuery(theme.breakpoints.up('md'));
  const isRequested = requestedBy !== null;

  useEffect(() => {
    dispatch(createAction(actions.UPDATE_CALCULATIONS));
  }, []);

  const handleApprove = async () => {
    setSubmitting(true);
    const params = { data: store.state };

    api.rateAdjustmentRequests
      .approve(params)
      .then(() => {
        window.location.assign(
          `/properties/${propertyId}/revenue/rate_adjustment_requests?report_type=rate_adjustment&status=requested`
        );
      })
      .catch(e => {
        setSubmitting(false);
        windowAlert(
          'There was an error submitting this version. Please create a help desk ticket if this continues to occur.'
        );
        throw new Error(e);
      });
  };

  const handleDeny = async () => {
    setSubmitting(true);
    const params = { data: store.state };

    api.rateAdjustmentRequests
      .deny(params)
      .then(() => {
        window.location.assign(
          `/properties/${propertyId}/revenue/rate_adjustment_requests?report_type=rate_adjustment&status=requested`
        );
      })
      .catch(() => {
        setSubmitting(false);
        windowAlert(
          'An error has occurred. Please create a help desk ticket if this continues to occur.'
        );
      });
  };

  const handleSave = async () => {
    setSubmitting(true);
    const params = {
      isEdit: !isNew,
      data: { ...store.state, tiers: Object.values(store.state.tiers) },
    };

    api.rateAdjustmentRequests
      .createOrUpdate(params)
      .then(res => {
        let versionId = res.data.version.id;
        window.location.assign(
          `/rate_adjustment_requests/${versionId}/edit?property_id=${propertyId}`
        );
      })
      .catch(() => {
        setSubmitting(false);
        windowAlert(
          'There was an error saving this version. Please create a help desk ticket if this continues to occur.'
        );
      });
  };

  const windowConfirm = () => {
    return window.confirm(
      `Are you sure you want to delete this request record?\nIf yes, click 'OK'.`
    );
  };

  const windowAlert = message => {
    return window.alert(message);
  };

  const handleDestroy = async () => {
    if (windowConfirm()) {
      setSubmitting(true);
      const params = { data: store.state };

      api.rateAdjustmentRequests
        .destroy(params)
        .then(() => {
          window.location.assign(
            `/properties/${propertyId}/revenue/rate_adjustment_requests?report_type=rate_adjustment&status=drafted`
          );
        })
        .catch(() => {
          setSubmitting(false);
          windowAlert(
            'There was an error deleting this version. Please create a help desk ticket if this continues to occur.'
          );
        });
    }
  };

  const requestNotesChangeHandler = e => {
    let payload = { value: e.target.value };
    dispatch(createAction(actions.UPDATE_VERSION_REQUEST_NOTES, payload));
  };

  const level1NotesChangeHandler = e => {
    let payload = { value: e.target.value };
    dispatch(createAction(actions.UPDATE_VERSION_LEVEL_1_NOTES, payload));
  };

  const level2NotesChangeHandler = e => {
    let payload = { value: e.target.value };
    dispatch(createAction(actions.UPDATE_VERSION_LEVEL_2_NOTES, payload));
  };

  const level3NotesChangeHandler = e => {
    let payload = { value: e.target.value };
    dispatch(createAction(actions.UPDATE_VERSION_LEVEL_3_NOTES, payload));
  };

  return (
    <>
      <Box>
        <Typography style={{ fontWeight: 300 }} className="text-purple-600" variant="h6">
          <a
            href={`/properties/${propertyId}/revenue/rate_adjustment_requests?report_type=rate_adjustment&status=drafted`}
          >
            {propertyName}
          </a>{' '}
          – {academicYear}
        </Typography>
      </Box>

      <Box mb={1} mt={0}>
        {isNew && <Typography variant="h4">New Rate Target Adjustment</Typography>}
        {!isNew && isEditing && <Typography variant="h4">Modify Rate Target Adjustment</Typography>}
        {!isNew && !isEditing && <Typography variant="h4">Rate Target Adjustment</Typography>}
      </Box>

      <Box mb={0} mt={0}>
        <TierDemo />
      </Box>

      <Box
        mb={3}
        mt={1}
        className="py-5 border-bottom border-top bg-white"
        style={{
          position: `${mdBreakpointMatch ? '-webkit-sticky' : 'static'}`,
          // eslint-disable-next-line no-dupe-keys
          position: `${mdBreakpointMatch ? 'sticky' : 'static'}`,
          top: 64,
          zIndex: 1020,
        }}
      >
        <Grid container className="grid grid-cols-auto gap-x-10 gap-y-4">
          <Grid item>
            <Typography
              style={{ fontWeight: 400, lineHeight: 1.3 }}
              variant="h4"
              className={floorplanContractsOff ? 'text-orange-700' : 'text-gray-700'}
            >
              <HotelIcon style={{ fontSize: 40 }} className="align-text-bottom" />{' '}
              {propertyBedCount}
            </Typography>
            <Typography
              style={{ fontWeight: 200, fontSize: '12px' }}
              variant="caption"
              className="text-gray-600"
            >
              <span>BED COUNT</span>
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              style={{ fontWeight: 400, lineHeight: 1.3 }}
              variant="h4"
              className="text-gray-700"
            >
              <CheckCircleIcon style={{ fontSize: 40 }} className="align-text-bottom" /> $
              {propertyInvestorApprovedRate.toFixed(2)}
            </Typography>
            <Typography
              style={{ fontWeight: 200, fontSize: '12px' }}
              variant="caption"
              className="text-gray-600"
            >
              <InfoPopover text={descriptionInvestorApprovedRate} />
              <span style={{ marginLeft: 6 }}>INVESTOR APPROVED RATE</span>
            </Typography>
          </Grid>
          {previousEosHitRate !== null && (
            <Grid item>
              <Typography
                style={{ fontWeight: 400, lineHeight: 1.3 }}
                variant="h4"
                className="text-gray-700"
              >
                <TrackChangesIcon style={{ fontSize: 40 }} className="align-text-bottom" /> $
                {previousEosHitRate?.toFixed(2)}
              </Typography>
              <Typography
                style={{ fontWeight: 200, fontSize: '12px' }}
                variant="caption"
                className="text-gray-600"
              >
                <InfoPopover text={descriptionPreviousEosHitRate} />
                <span style={{ marginLeft: 6 }}>PRIOR APPROVED EOS HIT RATE</span>
              </Typography>
            </Grid>
          )}
          <Grid item>
            <Typography
              style={{ fontWeight: 400, lineHeight: 1.3 }}
              variant="h4"
              className={
                totalAvgRate && totalAvgRate < previousEosHitRate
                  ? 'text-orange-700'
                  : 'text-teal-600'
              }
            >
              <CalculateIcon style={{ fontSize: 40 }} className="align-text-bottom" /> $
              {totalAvgRate && totalAvgRate.toFixed(2)}
            </Typography>
            <Typography
              style={{ fontWeight: 200, fontSize: '12px' }}
              variant="caption"
              className="text-gray-600"
            >
              <InfoPopover text={descriptionAdjustedEosHitRate} />
              <span style={{ marginLeft: 6 }}>ADJUSTED EOS HIT RATE</span>
            </Typography>
          </Grid>

          {isEditing && (
            <Grid item className="w-1/4">
              <TextField
                label="Request Notes"
                type="text"
                name="requestNotes"
                variant="outlined"
                multiline
                fullWidth
                required
                disabled={!isEditing}
                defaultValue={requestNotes}
                onBlur={requestNotesChangeHandler}
              />
            </Grid>
          )}

          {(isNew || isEditing) && (
            <Grid item>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                disabled={submitting || floorplanContractsOff || !allValidRates}
                onClick={() => handleSave()}
              >
                Save
              </Button>
            </Grid>
          )}

          {!isNew && !isEditing && (
            <>
              {!permissionToApproveLevel3 &&
                !permissionToApproveLevel2 &&
                permissionToApproveLevel1 &&
                !level2ApprovedBy &&
                !level3ApprovedBy && (
                  <Grid item className="w-1/4">
                    <TextField
                      label="Sales Manager Approval/Denial Notes"
                      type="text"
                      name="level1Notes"
                      variant="outlined"
                      multiline
                      fullWidth
                      defaultValue={level1Notes}
                      onBlur={level1NotesChangeHandler}
                    />
                  </Grid>
                )}

              {!permissionToApproveLevel3 && permissionToApproveLevel2 && !level3ApprovedBy && (
                <Grid item className="w-1/4">
                  <TextField
                    label="Sales Director Approval/Denial Notes"
                    type="text"
                    name="level2Notes"
                    variant="outlined"
                    multiline
                    fullWidth
                    defaultValue={level2Notes}
                    onBlur={level2NotesChangeHandler}
                  />
                </Grid>
              )}
              {permissionToApproveLevel3 && !level3ApprovedBy && (
                <Grid item className="w-1/4">
                  <TextField
                    label="Acquisitions Approval/Denial Notes"
                    type="text"
                    name="level3Notes"
                    variant="outlined"
                    multiline
                    fullWidth
                    defaultValue={level3Notes}
                    onBlur={level3NotesChangeHandler}
                  />
                </Grid>
              )}
              {isRequested &&
                ((permissionToApproveLevel1 && !level1ApprovedBy) ||
                  (permissionToApproveLevel2 && !level2ApprovedBy) ||
                  (permissionToApproveLevel3 && !level3ApprovedBy)) && (
                  <>
                    <Grid item>
                      <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        disabled={submitting || floorplanContractsOff}
                        onClick={() => handleApprove()}
                      >
                        Approve
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        size="medium"
                        color="secondary"
                        disabled={submitting || floorplanContractsOff}
                        onClick={() => handleDeny()}
                      >
                        Deny
                      </Button>
                    </Grid>
                  </>
                )}
            </>
          )}

          {!isNew && !isEditing && permissionToEdit && (
            <>
              {!isRequested && (
                <Grid item>
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    disabled={submitting || floorplanContractsOff}
                    onClick={() => handleApprove()}
                  >
                    Submit
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button
                  variant="contained"
                  size="medium"
                  disabled={submitting}
                  onClick={() => setIsEditing(true)}
                >
                  Edit
                </Button>
              </Grid>
            </>
          )}

          {!isNew && permissionToDestroy && (
            <Grid item>
              <Button
                variant="contained"
                size="medium"
                color="secondary"
                disabled={submitting}
                onClick={() => handleDestroy()}
              >
                Delete
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>

      {approvalLogs.length > 0 && (
        <Box mb={3}>
          <Typography
            variant="body2"
            style={{ fontWeight: 400, fontSize: '12px' }}
            className="text-gray-600 uppercase"
          >
            Request History
          </Typography>

          {approvalLogs.map(log => (
            <Fragment key={Math.random()}>
              <Typography
                variant="body2"
                style={{ fontWeight: 200, fontSize: '13px' }}
                className="text-purple-600"
              >
                {log}
              </Typography>
            </Fragment>
          ))}
        </Box>
      )}

      {previousApprovalLogs.length > 0 && (
        <Box mb={3}>
          <Typography
            variant="body2"
            style={{ fontWeight: 400, fontSize: '12px' }}
            className="text-gray-600 uppercase"
          >
            Previous Request History
          </Typography>
          {previousApprovalLogs.map(log => (
            <Fragment key={Math.random()}>
              <Typography
                variant="body2"
                style={{ fontWeight: 200, fontSize: '13px' }}
                className="text-gray-600"
              >
                {log}
              </Typography>
            </Fragment>
          ))}
        </Box>
      )}

      {floorplans &&
        floorplans.map(floorplan => (
          <Floorplan
            key={floorplan.floorplan_spacetype_key}
            floorplan={floorplan}
            isEditing={isEditing}
          />
        ))}
    </>
  );
};

Version.propTypes = {
  permissionToEdit: PropTypes.bool,
  permissionToApproveLevel1: PropTypes.bool,
  permissionToApproveLevel2: PropTypes.bool,
  permissionToApproveLevel3: PropTypes.bool,
  permissionToDestroy: PropTypes.bool,
};

Version.defaultProps = {
  permissionToEdit: false,
  permissionToApproveLevel1: false,
  permissionToApproveLevel2: false,
  permissionToApproveLevel3: false,
  permissionToDestroy: false,
};

export default Version;
