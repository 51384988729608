import React from 'react';
import PropTypes from 'prop-types';

const TabPanel = ({ className, children }) => {
  return <div className={className}>{children}</div>;
};

TabPanel.displayName = 'TabPanel';

TabPanel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

TabPanel.defaultProps = {
  className: '',
  children: null,
};

export default TabPanel;
