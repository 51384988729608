import { withFormik, Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import * as Yup from 'yup';

import api from '../api';
import FormikInputBasic from '../shared/formik_inputs/FormikInputBasic';
import FormikInputSelect from '../shared/formik_inputs/FormikInputSelect';
import FormikInputCheckbox from '../shared/formik_inputs/FormikInputCheckbox';
import Button from '../shared/Button';

const PropertyFeeForm = props => {
  const { values, handleSubmit, isEdit, fees, isSubmitting } = props;
  const disabled = true;

  return (
    <form onSubmit={handleSubmit}>
      <h3 className="text-2xl font-light my-6">{isEdit ? 'Update' : 'New'} Property Fee</h3>

      <div className="flex flex-wrap -mx-3">
        <div className="w-full md:w-1/2 px-3 mb-6">
          <Field
            label="Fee Type"
            value={values.propertyFee.fee_id}
            name="propertyFee.fee_id"
            options={fees.map(fee => ({ value: fee.id, label: fee.name }))}
            component={FormikInputSelect}
            isDisabled={disabled}
          />
        </div>

        <div className="flex-1 px-3 mb-6">
          <Field
            type="number"
            label="Amount"
            name="propertyFee.amount"
            value={values.propertyFee.amount}
            component={FormikInputBasic}
          />
        </div>

        <div className="flex-initial px-3 mb-6">
          <Field
            type="number"
            label="Waived?"
            value="propertyFee.is_waived"
            name="propertyFee.is_waived"
            component={FormikInputCheckbox}
          />
        </div>
      </div>

      <div className="flex -mx-3 mb-6">
        <div className="flex-1 px-3">
          <Field
            type="text"
            label="Additional Information"
            name="propertyFee.additional_info"
            value={values.propertyFee.additional_info}
            component={FormikInputBasic}
          />
        </div>
      </div>

      {status.error && <div className="text-red-600 p-1 my-1">{status.error}</div>}
      <Button type="submit" variant="primary" size="lg" block isDisabled={isSubmitting}>
        Submit
      </Button>
    </form>
  );
};

PropertyFeeForm.propTypes = {
  values: PropTypes.shape({
    propertyFee: PropTypes.shape({
      fee_id: PropTypes.number,
      amount: PropTypes.number,
      additional_info: PropTypes.string,
    }),
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  fees: PropTypes.arrayOf(PropTypes.shape({})),
  isSubmitting: PropTypes.bool.isRequired,
};

PropertyFeeForm.defaultProps = {
  isEdit: false,
  fees: [],
};

const ValidationSchema = Yup.object().shape({
  propertyFee: Yup.object().shape({
    fee_id: Yup.number().required('Please choose a valid option'),
    amount: Yup.number()
      .positive()
      .required('Please enter a positive number.'),
    is_waived: Yup.boolean(),
    additional_info: Yup.string(),
  }),
});

const EnhancedForm = withFormik({
  enableReinitialize: false,

  validationSchema: ValidationSchema,

  handleSubmit: (values, { setSubmitting, props }) => {
    setSubmitting(true);
    const { propertyId, isEdit } = props;

    const params = {
      property_id: propertyId,
      form_class: 'PropertyFee',
      form_object: values.propertyFee,
      isEdit: isEdit,
    };

    api.crudItems
      .updateOrCreate(params)
      .then(() => {
        props.onUpdate();
      })
      .catch(error => {
        props.setFormErrors(error.response.data.errors);
        setSubmitting(false);
      });
  },

  mapPropsToValues: ({ propertyFee }) => {
    return {
      propertyFee: {
        ...propertyFee,
        amount: propertyFee.amount || 0,
        additional_info: propertyFee.additional_info || '',
      },
    };
  },

  displayName: 'PropertyFeeForm',
});

export default EnhancedForm(PropertyFeeForm);
