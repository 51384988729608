import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  initialize() {
    this.initPopovers()
  }

  initPopovers() {
    $('[data-toggle="popover"]').popover()
  }
}
