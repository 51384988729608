import * as React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import PaidIcon from '@mui/icons-material/Paid';
import ArticleIcon from '@mui/icons-material/Article';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const PropertyItem = props => {
  const {
    formerlyKnownAs,
    propertyId,
    marketId,
    managementCompanyName,
    marketName,
    propertyCode,
    propertyName,
    rmName,
    rvpName,
    rvmName,
    corporateSalesSpecialistName: ssName,
    regionalLeasingTeamLeadName: rltmName,
    regionalMarketingSpecialistName: rmsName,
    canEditPropertySalesInfo,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card sx={{ minWidth: 275, margin: '16px 0px 16px 0px', borderRadius: '0px' }}>
      <CardContent sx={{ width: 'inherit', backgroundColor: '#f7fafc' }}>
        <Grid container mb={-1}>
          <Grid item xs={12} mb={2}>
            <Typography fontSize={18} component="div" sx={{ fontWeight: 'bold' }}>
              <a href={`/properties/${propertyId}`} title={propertyName}>
                {propertyName}
              </a>{' '}
              &#8212;{' '}
              <a href={`/markets/${marketId}`} title={marketName}>
                {marketName}
              </a>
            </Typography>
          </Grid>
          {managementCompanyName === 'Scion Group' && (
            <Grid item xs={12} sx={{ display: 'flex' }}>
              <Grid
                item
                xs={6}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                }}
              >
                {propertyCode && <Typography variant="body1"> Code: {propertyCode} </Typography>}
                <Stack direction="row" spacing={2}>
                  {/* <IconButton>
                    <InfoIcon />
                  </IconButton> */}
                  <IconButton
                    id="demo-positioned-button"
                    aria-controls={open ? 'demo-positioned-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                  >
                    <PaidIcon />
                  </IconButton>
                  {canEditPropertySalesInfo && (
                    <IconButton href={`/properties/${propertyId}/property_sales_info`}>
                      <ArticleIcon />
                    </IconButton>
                  )}
                </Stack>
                {formerlyKnownAs && (
                  <Typography sx={{ color: '#9f7aea' }} variant="body1">
                    {`FKA: ${formerlyKnownAs}`}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6} variant="body1">
                {rvpName && <Typography>RVP: {rvpName} </Typography>}
                {rmName && <Typography>RM: {rmName} </Typography>}
                {rvmName && <Typography>SM: {rvmName} </Typography>}
                {rltmName && <Typography>SRLTL: {rltmName} </Typography>}
                {rmsName && <Typography>RMS: {rmsName} </Typography>}
                {ssName && <Typography>SS: {ssName} </Typography>}
              </Grid>
            </Grid>
          )}
          {managementCompanyName !== 'Scion Group' && (
            <Grid item xs={12}>
              <Typography variant="body1">{managementCompanyName}</Typography>
            </Grid>
          )}
        </Grid>
      </CardContent>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleClose}>
          <a href={`/properties/${propertyId}/revenue?report_type=summary`}>Summary</a>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <a href={`/properties/${propertyId}/revenue/tiers?report_type=tiers`}>Tiers</a>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <a href={`/properties/${propertyId}/rate_history?report_type=rate_history`}>Rates</a>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <a href={`/properties/${propertyId}/revenue/billing_audit?report_type=billing_audit`}>
            Scheduled Billing Audit
          </a>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <a
            href={`/properties/${propertyId}/revenue/rate_adjustment_requests?report_type=rate_adjustment&status=requested`}
          >
            Rate Target Adjustments
          </a>
        </MenuItem>
      </Menu>
    </Card>
  );
};

PropertyItem.propTypes = {
  formerlyKnownAs: PropTypes.string,
  managementCompanyName: PropTypes.string,
  marketId: PropTypes.number,
  marketName: PropTypes.string,
  propertyId: PropTypes.number,
  propertyCode: PropTypes.string,
  propertyName: PropTypes.string,
  rmName: PropTypes.string,
  rvpName: PropTypes.string,
  rvmName: PropTypes.string,
  corporateSalesSpecialistName: PropTypes.string,
  regionalLeasingTeamLeadName: PropTypes.string,
  regionalMarketingSpecialistName: PropTypes.string,
  canEditPropertySalesInfo: PropTypes.bool,
};

PropertyItem.defaultProps = {
  formerlyKnownAs: null,
  marketId: null,
  marketName: null,
  propertyId: null,
  propertyCode: null,
  propertyName: null,
  managementCompanyName: null,
  rmName: null,
  rvpName: null,
  rvmName: null,
  corporateSalesSpecialistName: null,
  regionalLeasingTeamLeadName: null,
  regionalMarketingSpecialistName: null,
  canEditPropertySalesInfo: false,
};

export default PropertyItem;
