import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

const Index = ({
  data: { title: title, qr_code_url: qrCodeUrl, back_link: backLink, back_link_text: backLinkText },
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh',
        placeContent: 'space-evenly',
      }}
    >
      <Typography variant="h4" sx={{ margin: '30px' }}>
        {title}
      </Typography>
      <Button href={backLink} size="large" variant="contained" backgroundColor="#ffd202">
        {backLinkText}
      </Button>

      <img src={qrCodeUrl} alt="QR Code" />
    </Box>
  );
};

Index.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    qr_code_url: PropTypes.string,
    back_link: PropTypes.string,
    back_link_text: PropTypes.string,
  }).isRequired,
};

export default Index;
