import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Switch } from '@material-ui/core';

import { get as objectGet } from '@utils/lib/object';

const styles = {
  labelBase: `block text-gray-700 text-sm font-bold mb-2 truncate`,
  errorBase: 'text-red-600 p-1 my-1',
  statusBase: 'text-red-600 p-1 my-2',
};

const FormikInputSwitch = ({
  children,
  field,
  form: { setFieldValue, status, touched, errors },
  isDisabled,
  key,
  label,
  onChange,
  value,
  ...props
}) => {
  const labelProp = { inputProps: { 'aria-label': label } };
  return (
    <div key={key}>
      <span className={classNames(styles.labelBase)}>{label}</span>
      <Switch
        checked={field.value}
        color="primary"
        disabled={isDisabled}
        onClick={() => {
          setFieldValue(field.name, !field.value);
        }}
        value={field.value}
        {...labelProp}
        {...props}
      />

      {objectGet(touched, field.name) && objectGet(status, field.name) && (
        <div className={classNames(styles.statusBase)}>{objectGet(status, field.name)}</div>
      )}

      {objectGet(touched, field.name) && objectGet(errors, field.name) && (
        <div className={classNames(styles.errorBase)}>{objectGet(errors, field.name)}</div>
      )}
    </div>
  );
};

FormikInputSwitch.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.bool,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    status: PropTypes.shape(),
    touched: PropTypes.shape(),
    errors: PropTypes.shape(),
  }).isRequired,
  isDisabled: PropTypes.bool,
  key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.bool,
};

FormikInputSwitch.defaultProps = {
  children: null,
  isDisabled: false,
  key: null,
  label: '',
  onChange: undefined,
  value: false,
};

export default FormikInputSwitch;
