import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { DataType, BooleanType, LinkType, CheckType } from './TableCellTypes';
import StandardTable from './StandardTable';

const TableFromServer = ({ table, has_pagination: hasPagination }) => {
  const data = useMemo(() => table.data, []);

  const columns = useMemo(
    () =>
      table.columns.map(headerColumn => {
        return {
          Header: headerColumn.name,
          columns: headerColumn.columns.map(column => {
            return {
              id: column.id,
              Header: column.name,
              sortType: 'basic',
              accessor: column.accessor.path,
              Cell: row => {
                return applyAccessorType({
                  ...column.accessor,
                  value: row.cell.value,
                });
              },
              className: column.styles,
            };
          }),
        };
      }),
    []
  );

  return <StandardTable columns={columns} data={data} hasPagination={hasPagination} />;
};

TableFromServer.propTypes = {
  table: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        columns: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            accessor: PropTypes.shape({
              path: PropTypes.string.isRequired,
              type: PropTypes.oneOf(['data', 'link', 'boolean', 'check']).isRequired,
            }),
            width: PropTypes.number,
            styles: PropTypes.string,
          })
        ),
      })
    ),
  }),
  has_pagination: PropTypes.bool,
};

TableFromServer.defaultProps = {
  table: {
    data: [],
    columns: [],
  },
  has_pagination: true,
};

const applyAccessorType = ({ value, type, ...accessor }) => {
  switch (type) {
    case 'boolean':
      return <BooleanType value={value} isReversed={accessor.reverse} />;
    case 'check':
      return <CheckType value={value} />;
    case 'link':
      return <LinkType value={value && value.link}>{value && value.text}</LinkType>;
    case 'data':
    default:
      return <DataType value={value} format={accessor.format} />;
  }
};

applyAccessorType.propTypes = {
  value: PropTypes.oneOfType([
    function(props, propName, componentName) {
      if (props.type === 'link' && !(props[propName].link && props[propName].text)) {
        return new Error(
          'Invalid prop `' +
            propName +
            '` supplied to' +
            ' `' +
            componentName +
            '`. Validation failed.'
        );
      }
    },
  ]).isRequired,
  type: PropTypes.oneOf(['data', 'link', 'boolean', 'check']).isRequired,
};

export default TableFromServer;
