import _ from 'lodash';
import * as types from './types';

const measuresReducer = (measures = {}, action) => {
  if (action.type === types.TOGGLE) {
    const { measure } = action.payload;
    if (measures[measure.id]) return _.omit(measures, measure.id);
    else {
      return { ...measures, [measure.id]: measure };
    }
  }

  if (action.type === types.DELETE) {
    const { measureId } = action.payload;
    return _.omit(measures, measureId);
  }

  return measures;
};

export default measuresReducer;
