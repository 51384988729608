/* eslint-disable */

import React from 'react';

import BITable from '../../bi/table';

/*
  Props:
    columns: Array<Column>
    data: Array<Cell>

  Components:
    Cell:
      value: string | number | boolean | null | undefined
        - Required
      component: Component
        - Optional

    Component:
      element: String
        - Optional
        - Default = "span"

      display: String | Number
        - Optional
        - Default = Cell[value]
        - Applies value into render component children

      styles: JSS
        - Optional
        - Applied direct to element

    Column:
      Header: String | '_rowspan'
        - Required
        - Display Name of column
        - Table will not render if value = _rowspan

      Footer: String
        - Optional

      footerStyles: JSS
        - Optional

      rowSpan: Number
        - Optional

      canSort: Boolean
        - Optional
        - if rowspan override set canSort=True

      styles: JSS Object
        - Optional
        - Controls styles for header

      cellStyles: JSS Object
        - Optional
        - Controls styles for all data cells in column

     columns: Array<Column>
       - *Optional
       - Required if rowSpan > 1 specified.
       - No limit of nesting

      accessor: String
        - Optional
        - Set accessor on last column in Group or Main rowspan column
*/

const IndexPage = ({ reactTable }) => {
  const data = [
    {
      title: {
        value: 'Some Property',
        component: {
          element: 'a',
          href: '/dlv',
          display: 'KKJAHDKJASHDKAS',
        },
      },
      yesterday_prelease_delta: { value: 3 },
      yesterday_prelease_delta_percent: { value: 10.0 },
      sunday_prelease_delta: { value: 1 },
      sunday_prelease_delta_percent: { value: 10.0 },
      today_prelease_delta: { value: 10 },
      today_prelease_delta_percent: { value: 20.5 },
      today_approved_delta: { value: 10 },
      today_approved_delta_perc: { value: 25.0 },
      deadline: { value: '2020-10-10-' },
      eod_prelease: { value: 1 },
      eod_prelease_perc: { value: 10.0 },
      eod_contract: { value: 1 },
      eom_prelease: { value: 10.0 },
      eom_prelease_perc: { value: 10 },
      eom_contract: { value: 20.5 },
      eos_prelease: { value: 10 },
      market_eod_prelease: { value: 25.0 },
      market_portfolio_prelease: { value: 90.0 },
      market_rank: { value: '10/1' },
      aytd_contracts: { value: 100 },
      bed_total: { value: 100 },
    },
    {
      title: {
        value: 'Some Property 2',
        display: 'Noo 2',
      },
      yesterday_prelease_delta: { value: 1 },
      yesterday_prelease_delta_percent: { value: 10.0 },
      sunday_prelease_delta: { value: 1 },
      sunday_prelease_delta_percent: { value: 10.0 },
      today_prelease_delta: { value: 10 },
      today_prelease_delta_percent: { value: 20.5 },
      today_approved_delta: { value: 10 },
      today_approved_delta_perc: { value: 25.0 },
      deadline: { value: '2020-10-10-' },
      eod_prelease: { value: 1 },
      eod_prelease_perc: { value: 10.0 },
      eod_contract: { value: 1 },
      eom_prelease: { value: 10.0 },
      eom_prelease_perc: { value: 10 },
      eom_contract: { value: 20.5 },
      eos_prelease: { value: 10 },
      market_eod_prelease: { value: 25.0 },
      market_portfolio_prelease: { value: 90.0 },
      market_rank: { value: '10/1' },
      aytd_contracts: { value: 100 },
      bed_total: { value: 100 },
    },
    {
      title: {
        value: 'Some Property 2',
        display: 'Noo 2',
      },
      yesterday_prelease_delta: { value: 2 },
      yesterday_prelease_delta_percent: { value: 10.0 },
      sunday_prelease_delta: { value: 1 },
      sunday_prelease_delta_percent: { value: 10.0 },
      today_prelease_delta: { value: 10 },
      today_prelease_delta_percent: { value: 20.5 },
      today_approved_delta: { value: 10 },
      today_approved_delta_perc: { value: 25.0 },
      deadline: { value: '2020-10-10-' },
      eod_prelease: { value: 1 },
      eod_prelease_perc: { value: 10.0 },
      eod_contract: { value: 1 },
      eom_prelease: { value: 10.0 },
      eom_prelease_perc: { value: 10 },
      eom_contract: { value: 20.5 },
      eos_prelease: { value: 10 },
      market_eod_prelease: { value: 25.0 },
      market_portfolio_prelease: { value: 90.0 },
      market_rank: { value: '10/1' },
      aytd_contracts: { value: 100 },
      bed_total: { value: 100 },
    },
  ];

  const columns = [
    {
      Header: `Title`,
      columnAccessor: 'title',
      rowSpan: 3,
      canSort: true,
      columns: [
        {
          Header: '_rowspan',
          columns: [
            {
              Header: '_rowspan',
              accessor: 'title',
              Footer: 'Title',
              cellStyles: {
                textAlign: 'left',
              },
            },
          ],
        },
      ],
    },
    {
      Header: 'Current',
      columns: [
        {
          Header: 'Day',
          columns: [
            {
              Header: `Δ`,
              accessor: 'yesterday_prelease_delta',
              Footer: '11.01%',
            },
            {
              Header: `% Δ`,
              accessor: 'yesterday_prelease_delta_percent',
            },
          ],
        },
        {
          Header: 'Week',
          columns: [
            {
              Header: `Δ`,
              accessor: 'sunday_prelease_delta',
            },
            {
              Header: `% Δ`,
              accessor: 'sunday_prelease_delta_percent',
            },
          ],
        },
        {
          Header: 'Signed',
          columns: [
            {
              Header: `Δ`,
              accessor: 'today_prelease_delta',
            },
            {
              Header: `% Δ`,
              accessor: 'today_prelease_delta_percent',
            },
          ],
        },
        {
          Header: 'Approved',
          columns: [
            {
              Header: `Δ`,
              accessor: 'today_approved_delta_delta',
            },
            {
              Header: `% Δ`,
              accessor: 'today_approved_deltaa_percent',
            },
          ],
        },
        {
          Header: 'Deadline',
          columnAccessor: 'deadline',
          rowSpan: 2,
          canSort: true,
          columns: [
            {
              Header: '_rowspan',
              accessor: 'deadline',
            },
          ],
        },
      ],
    },
    {
      Header: '2019/2020',
      columns: [
        {
          Header: 'EoD',
          columns: [
            {
              Header: `Signed`,
              accessor: 'eod_prelease',
            },
            {
              Header: `% Δ`,
              accessor: 'eod_prelease_perc',
            },
            {
              Header: `Contr Δ`,
              accessor: 'eod_contract',
            },
          ],
        },
        {
          Header: 'EoM',
          columns: [
            {
              Header: `Δ`,
              accessor: 'eom_prelease',
            },
            {
              Header: `% Δ`,
              accessor: 'eom_prelease_delta',
            },
            {
              Header: `Contr Δ`,
              accessor: 'eom_contract',
            },
          ],
        },
        {
          Header: 'Signed',
          columns: [
            {
              Header: `Δ`,
              accessor: 'xtoday_prelease_delta',
            },
            {
              Header: `% Δ`,
              accessor: 'xtoday_prelease_delta_percent',
            },
          ],
        },
        {
          Header: 'EoS',
          columnAccessor: 'eos_prelease',
          rowSpan: 2,
          columns: [
            {
              Header: `_rowspan`,
              accessor: 'eos_prelease',
            },
          ],
        },
      ],
    },
  ];

  return (
    <div className="mx-8 pt-16">
      <BITable
        table={{
          columns,
          data,
        }}
        hasFooter
      />
    </div>
  );
};

export default IndexPage;
