import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import Divider from '@mui/material/Divider';
import convertToYYYYMMDD from '../helper_functions/convertToYYYYMMDD';
import convertToHHMM from '../helper_functions/convertToHHMM';
import SharedAlerts from '../add_to_calendar_components/SharedAlerts';

const WorkSchedule = ({ title, start, end, details, propertyName, tzValue }) => {
  return (
    <>
      <Typography variant="h6">
        <p className="mt-4">{title}</p>
      </Typography>
      {details['position_name'] && (
        <Typography component="span" variant="body1" color="textSecondary">
          <p className="text-gray-600 mb-2">{details['position_name']}</p>
        </Typography>
      )}
      {start && (
        <Typography component="span" variant="subtitle1">
          <p className="text-gray-600">
            <span className="font-medium">Start:</span> {start}
          </p>
        </Typography>
      )}
      {end && (
        <Typography component="span" variant="subtitle1">
          <p className="text-gray-600 mb-2">
            <span className="font-medium">End:</span> {end}
          </p>
        </Typography>
      )}
      {details && (
        <>
          {details['email'] && (
            <Typography component="span" variant="body2" color="textSecondary">
              <p className="text-gray-600 mb-1">
                <span className="font-medium">Email: </span>
                <a href={`mailto:${details['email']}`}>{details['email']}</a>
              </p>
            </Typography>
          )}
          {details['cell_phone'] && (
            <Typography component="span" variant="body2" color="textSecondary">
              <p className="text-gray-600">
                <span className="font-medium">Cell:</span> {details['cell_phone']}
              </p>
            </Typography>
          )}
          <Divider sx={{ marginBottom: '12px', marginTop: '12px' }} />
          <Typography component="span" variant="subtitle1" color="textPrimary">
            <p className="text-gray-600 mb-1">Add Event to Your Calendar</p>
          </Typography>
          <AddToCalendarButton
            name={`${title} - ${propertyName}`}
            startDate={convertToYYYYMMDD(start)}
            startTime={convertToHHMM(start)}
            endDate={convertToYYYYMMDD(end)}
            endTime={convertToHHMM(end)}
            timeZone={tzValue}
            description={` 
            [p][strong]➤Position:[/strong] ${details['position_name']}[/p]
            [p][strong]➤Start:[/strong] ${start} [/p]
            [p][strong]➤End:[/strong] ${end}[/p]
            [p][strong]➤Email:[/strong] ${details['email']}[/p]
            [p][strong]➤Cell Phone:[/strong] ${details['cell_phone']}[/p]
            [br]
          `}
            buttonStyle="text"
            options={['Microsoft365', 'MicrosoftTeams', 'Apple', 'Google']} 
            hideCheckmark="true"
            buttonsList
            hideTextLabelButton
            size="5"
          />
          <SharedAlerts />
        </>
      )}
    </>
  );
};

WorkSchedule.propTypes = {
  title: PropTypes.string,
  start: PropTypes.string,
  end: PropTypes.string,
  details: PropTypes.shape({
    cell_phone: PropTypes.string,
    email: PropTypes.string,
    position_name: PropTypes.string,
    scheduled_hours: PropTypes.number,
  }),
  propertyName: PropTypes.string,
  tzValue: PropTypes.string,
};

WorkSchedule.defaultProps = {
  title: null,
  start: null,
  end: null,
  details: {},
  propertyName: null,
  tzValue: null,
};

export default WorkSchedule;
