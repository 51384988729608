import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import TableContext from './TableContext';

const styles = {
  base: '',
};

const tableState = {
  variant: 'body',
};

const TableBody = props => {
  const { className, component: Component = 'tbody', ...other } = props;

  return (
    <TableContext.Provider value={tableState}>
      <Component
        className={classNames(styles.base, {
          [className]: className,
        })}
        {...other}
      />
    </TableContext.Provider>
  );
};

TableBody.propTypes = {
  className: PropTypes.string,
  component: PropTypes.string,
};

TableBody.defaultProps = {
  className: '',
  component: 'tbody',
};

export default TableBody;
