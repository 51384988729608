import PropTypes from 'prop-types';
import React from 'react';
import * as Icon from 'react-feather';

import { usePanelDispatch } from '../../panel/PanelContext';
import * as panelTypes from '../../panel/panelTypes';
import ItemFormContainer from './ItemFormContainer';

const ItemEditButton = ({
  formAttributes,
  formObject,
  modelName,
  onUpdate,
  setStateIndexItems,
  stateIndexItems,
}) => {
  const panelDispatch = usePanelDispatch();

  const handleUpdate = () => {
    onUpdate();
    panelDispatch({
      type: panelTypes.CLOSE,
    });
  };

  return (
    <button
      type="button"
      className="inline-block text-center"
      onClick={() => {
        panelDispatch({
          type: panelTypes.OPEN,
          render: (
            <ItemFormContainer
              formAttributes={formAttributes}
              formObject={formObject}
              isEdit
              modelName={modelName}
              onUpdate={handleUpdate}
              setStateIndexItems={setStateIndexItems}
              stateIndexItems={stateIndexItems}
            />
          ),
        });
      }}
    >
      <Icon.Edit size="12" />
    </button>
  );
};

ItemEditButton.propTypes = {
  formAttributes: PropTypes.shape({}).isRequired,
  formObject: PropTypes.shape({}).isRequired,
  modelName: PropTypes.string,
  onUpdate: PropTypes.func,
  stateIndexItems: PropTypes.arrayOf(PropTypes.shape({})),
  setStateIndexItems: PropTypes.func.isRequired,
};

ItemEditButton.defaultProps = {
  modelName: 'Item',
  onUpdate: undefined,
  stateIndexItems: [],
};

export default ItemEditButton;
