import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import Divider from '@mui/material/Divider';
import convertToYYYYMMDD from '../helper_functions/convertToYYYYMMDD';
import SingleDayEventAlerts from '../add_to_calendar_components/SharedAlerts';

const InstagramPost = ({ title, date, details, propertyName, tzValue }) => {
  const calendarDescription = details => {
    let htmlString = '';
    details.map(subEvent => {
      htmlString += `➤[p][strong]Image Url:[/strong] [url]${subEvent['media']}[/url][/p][p][strong]➤Content: [/strong]${subEvent['message']}[/p][br]`;
    });
    return htmlString;
  };

  return (
    <>
      <Typography variant="h6" sx={{ paddingBottom: '12px' }}>
        <p className="mt-4">{title}</p>
      </Typography>
      <Typography component="span" variant="subtitle1">
        <p className="text-gray-600 mb-2">{date}</p>
      </Typography>
      {!details && <></>}
      {details &&
        details.map((subEvent, index) => {
          return (
            // SHOULD ADD RELIABLE ID FOR PROPER KEY
            // eslint-disable-next-line react/no-array-index-key
            <div key={index}>
              <div className="pb-3 max-w-xs">
                <img src={subEvent['media']} alt="Instagram Post" />
              </div>
              <Typography component="span" variant="body2" color="textSecondary">
                <p>{subEvent['message']}</p>
              </Typography>
              <Typography component="span" variant="overline" gutterBottom>
                <p className="text-blue-600 pb-2">
                  {`${subEvent['impressions'] ? subEvent['impressions'] : 0} Impressions`} |{' '}
                  {`${subEvent['likes'] ? subEvent['likes'] : 0} Likes`}
                </p>
              </Typography>
            </div>
          );
        })}
      <Divider sx={{ marginBottom: '12px' }} />
      <Typography component="span" variant="subtitle1" color="textPrimary">
        <p className="text-gray-600 mb-1">Add Event to Your Calendar</p>
      </Typography>
      <AddToCalendarButton
        name={`${title} - ${propertyName}`}
        startDate={convertToYYYYMMDD(date)}
        timeZone={tzValue}
        description={calendarDescription(details)}
        buttonStyle="text"
        options={['Microsoft365', 'MicrosoftTeams', 'Apple', 'Google']}
        hideCheckmark="true"
        buttonsList
        hideTextLabelButton
        size="5"
      />
      <SingleDayEventAlerts />
    </>
  );
};

InstagramPost.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  details: PropTypes.instanceOf(Array),
  propertyName: PropTypes.string,
  tzValue: PropTypes.string,
};

InstagramPost.defaultProps = {
  title: null,
  date: null,
  details: [],
  propertyName: null,
  tzValue: null,
};

export default InstagramPost;
