import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['table']

  connect() {
    if ($(window).width() > 1100) {
      this.originalDimensions = this.tableTarget.getBoundingClientRect();
      this.tableHeader = this.tableTarget.tHead;
      this.tBodyRow = $(this.tableTarget).find('tbody').find('tr').first()
      this.lastHeaderRow = $(this.tableHeader).find('tr').last()
      this.onScrollRunning = true;
      this.resizeHeader();
    }
  }

  onScroll() {
    if (!this.onScrollRunning) {
      this.onScrollRunning = true;
      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(this.scrollTableHeader.bind(this));
      } else {
        setTimeout(this.scrollTableHeader.bind(this), 66);
      }
    }
  }

  scrollTableHeader() {
    const offset = this.offset(this.tableTarget);
    const extra = parseFloat(this.data.get('extra'))
    if (window.scrollY >= (this.originalDimensions.top + $(this.tableTarget).height() + extra)) {
      this.placeholder.setAttribute('style', 'opacity: 1;');
      this.tableHeader.setAttribute('style', `display: none`);
    } else if (window.scrollY >= this.originalDimensions.top + extra) {
      this.placeholder.setAttribute('style', 'opacity: 0;');
      this.width = this.placeholder.getBoundingClientRect().width;
      const topPos = $('.titlebar').height()
      this.tableHeader.setAttribute('style', `top: ${topPos}px; position: fixed; z-index: 99999999999; width: ${this.width}px;`);
    } else {
      // Reset Style
      this.placeholder.setAttribute('style', 'display: none; opacity: 0;');
      this.tableHeader.setAttribute('style', '');
    }
    this.onScrollRunning = false;
  }

  resizeHeader() {
    let dims = {}

    $(this.tBodyRow).children('td').each((i, el)=> {
      const style = window.getComputedStyle(el);
      const buffer = parseFloat(style.paddingRight) + parseFloat(style.paddingLeft) + parseFloat(style.borderRightWidth) + parseFloat(style.borderLeftWidth);
      dims[i] = $(el).width() + buffer
    })

    $(this.lastHeaderRow).children('th').each((i, el) => {
      $(el).css('width',`${dims[i]}px`)
    })

    if (this.placeholder) {
      this.width = this.placeholder.getBoundingClientRect().width;
      this.placeholder.remove();
    } else {
      this.width = this.tableHeader.getBoundingClientRect().width;
    }
    this.placeholder = this.tableHeader.cloneNode(true);
    this.placeholder.setAttribute('data-target', '');
    this.placeholder.setAttribute('style', 'display: none; opacity: 0;');
    this.tableHeader.insertAdjacentElement('afterend', this.placeholder);

    this.scrollTableHeader();
  }

  offset(el) {
    var rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
  }
}
