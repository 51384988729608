import Highcharts from 'highcharts'
import addMore from "highcharts/highcharts-more";
import addDrilldown from "highcharts/modules/drilldown";
import addNoData from "highcharts/modules/no-data-to-display";
import addExporting from "highcharts/modules/exporting";
import {fontFamily, labelStyles, titleStyles} from "../utils";

addMore(Highcharts)
addDrilldown(Highcharts)
addNoData(Highcharts)
addExporting(Highcharts)

document.addEventListener('DOMContentLoaded', function () {
  const data = $('#js-property-market-weights-graph').data('graph-data');

  if(data){
    // Add quadrant labels
    data.push({
      color: '#ffffff',
      data: [
        {x: 7.5, y: 9.75, z: 1, nri_variance: 350000, property_code: "Strong Property, Strong Market"},
        {x: 2.5, y: 9.75, z: 1, nri_variance: 350000, property_code: "Strong Property, Weak Market"},
        {x: 2.5, y: 0.25, z: 1, nri_variance: 350000, property_code: "Weak Property, Weak Market"},
        {x: 7.5, y: 0.25, z: 1, nri_variance: 350000, property_code: "Weak Property, Strong Market"},
      ],
      name: ' '
    })

    Highcharts.setOptions({
      lang: {
        thousandsSep: ','
      }
    });

    const chart = Highcharts.chart('js-property-market-weights-graph', {

      chart: {
          type: 'bubble',
          plotBorderWidth: 1,
          zoomType: 'xy',
      },

      title: {
          text: ''
      },

      xAxis: {
          allowDecimals: false,
          min: 0,
          max: 10,
          gridLineWidth: 0,
          title: {
              text: 'MARKET STRENGTH',
              style: titleStyles
          },
          labels: {
            format: '{value}',
            style: titleStyles
          },
          plotLines: [{
            color: 'rgba(197, 197, 197)',
            value: 5,
            width: 1
          }]

      },

      yAxis: {
        allowDecimals: false,
        min: 0,
        max: 10,
        gridLineWidth: 0,
        title: {
            text: 'PROPERTY STRENGTH',
            style: titleStyles
        },
        labels: {
          format: '{value}',
          style: titleStyles
        },
        maxPadding: 0.2,
        plotLines: [{
          color: 'rgba(197, 197, 197)',
          value: 5,
          width: 1
        }]
      },

      tooltip: {
        backgroundColor: "#ffffff",
        style: {
          fontFamily: fontFamily
        },
        outside: true,
        hideDelay: 0,
        useHTML: true,
        headerFormat: '<table>',
        pointFormat: '<tr><th colspan="2"><h3>{point.property_code}</h3></th></tr>' +
            '<tr><th>NRI Variance:</th><td>{point.nri_variance:,.0f}</td></tr>' +
            '<tr><th>Strategy:</th><td>{point.strategy}</td></tr>' +
            '<tr><th>Property Weight:</th><td>{point.x}</td></tr>' +
            '<tr><th>Market Weight:</th><td>{point.y}</td></tr>',
        footerFormat: '</table>',
        followPointer: true
      },
      legend: {
        itemStyle: titleStyles
      },
      plotOptions: {
        bubble: {
          minSize: 15,
          maxSize: 75
        },
        series: {
          stickyTracking: false,
          dataLabels: {
            enabled: true,
            format: '{point.property_code}',
            style: {
              color: 'black',
              fontFamily: fontFamily
            }
          }
        }
      },

      series: data.map(function(series){
        return {
          name: series.name,
          color: series.color,
          data: series.data,
          enableMouseTracking: series.name === ' ' ? false : true
        }
      })
    });
  }
})