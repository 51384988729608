import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Tab from './Tab';
import TabPanel from './TabPanel';

const DEFAULT_INDEX = 0;

const Tabs = ({ defaultIndex, children, className, onTabClick }) => {
  const [selectedIndex, setSelectedIndex] = useState(defaultIndex);

  const tabList = React.Children.toArray(children).filter(item => item.type.name === Tab.name);
  const tabPabelList = React.Children.toArray(children).filter(
    item => item.type.name === TabPanel.name
  );

  const handleTabClick = index => setSelectedIndex(index);

  return (
    <div className={className}>
      {tabList.map((tab, index) =>
        React.cloneElement(tab, {
          isSelected: index === selectedIndex,
          onClick: () => {
            handleTabClick(index);

            if (onTabClick) {
              onTabClick();
            }
          },
        })
      )}

      {tabPabelList[selectedIndex]}
    </div>
  );
};

Tabs.propTypes = {
  defaultIndex: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string,
  onTabClick: PropTypes.func,
};
Tabs.defaultProps = {
  defaultIndex: DEFAULT_INDEX,
  children: null,
  className: '',
  onTabClick: undefined,
};

export default Tabs;
