import initMap from './address_map';

$(document).ready(function() {
  unwrapSubmitButtons();

  if ($('#map-input').length > 0) {
    initMap({});
  }
});

function unwrapSubmitButtons() {
  var $targets = $('i.btn').children('input[type="submit"]');

  $targets.each(function(i, el) {
    var $wrapper = $(el).closest('i.btn');
    var $classes = $wrapper.attr('class');
    $(el)
      .insertBefore($wrapper)
      .addClass($classes)
      .toggleClass('waves-button-input waves-input-wrapper');
    $wrapper.remove();
  });
}
