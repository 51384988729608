import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import * as Icon from 'react-feather';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import Divider from '@mui/material/Divider';
import StandardTable from '../../table/StandardTable';
import convertToYYYYMMDD from '../helper_functions/convertToYYYYMMDD';
import SingleDayEventAlerts from '../add_to_calendar_components/SharedAlerts';

const MoveIn = ({ title, date, details, propertyName, propertyId, tzValue }) => {
  const calendarDescription = details => {
    let description = '';
    let numberofMoveIns = details.length;
    details.forEach(detail => {
      description += `
      {p}{strong}➤Name:{/strong} ${detail.full_name}{/p}
      {p}{strong}➤User Dashboard Link:{/strong} {url}https://www.scionintelligence.net/residents/${detail.customer_id}{/url}{/p}
      {p}{strong}➤Email:{/strong} ${detail.email}{/p}
      {p}{strong}➤Unit:{/strong} ${detail.unit_number}{/p}
      {p}{strong}➤Entrata Link:{/strong} https://thesciongroup.entrata.com/?module=customers_systemxxx&lease[lease_id]=${detail.lease_id}&lease[customer_id]=${detail.customer_id} {/p}
      {br}
      `;
    });
    if (description.length > 1000) {
      return `${numberofMoveIns} Move-ins, please see SI Calendar for more details: https://www.scionintelligence.net/properties/${propertyId}/calendar`;
    }
    return description;
  };

  const columns = useMemo(() => [
    {
      Header: 'Name',
      // eslint-disable-next-line react/prop-types
      accessor: ({ customer_id, full_name }) => (
        <a href={`/residents/${customer_id}`}>{full_name}</a>
      ),
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Unit',
      accessor: 'unit_number',
      className: 'text-center',
    },
    {
      Header: 'Entrata',
      // eslint-disable-next-line react/prop-types
      accessor: ({ customer_id, lease_id }) => (
        <a
          href={`https://thesciongroup.entrata.com/?module=customers_systemxxx&lease[lease_id]=${lease_id}&lease[customer_id]=${customer_id}`}
        >
          <button type="button">
            <Icon.ExternalLink size="12" />
          </button>
        </a>
      ),
      className: 'text-center',
    },
  ]);

  return (
    <>
      <Typography variant="h6">
        <p className="mt-4">{title}</p>
      </Typography>
      <Typography component="span" variant="subtitle1">
        <p className="text-gray-600 mb-2">{date}</p>
      </Typography>
      {!details && <></>}
      {details && (
        <StandardTable
          columns={columns.filter(col => col.show != false)}
          data={details}
          hasPagination={details.length > 20}
          isSearchable={details.length > 20}
        />
      )}
      <Divider sx={{ marginBottom: '12px' }} />
      <Typography component="span" variant="subtitle1" color="textPrimary">
        <p className="text-gray-600 mb-1">Add Event to Your Calendar</p>
      </Typography>
      <AddToCalendarButton
        name={`${title} - ${propertyName}`}
        startDate={convertToYYYYMMDD(date)}
        description={calendarDescription(details)}
        timeZone={tzValue}
        buttonStyle="text"
        options={['Microsoft 365', 'MicrosoftTeams', 'Apple', 'Google']}
        hideCheckmark="true"
        buttonsList
        hideTextLabelButton
        size="5"
      />
      <SingleDayEventAlerts />
    </>
  );
};

MoveIn.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  details: PropTypes.instanceOf(Array),
  propertyName: PropTypes.string,
  propertyId: PropTypes.string,
  tzValue: PropTypes.string,
};

MoveIn.defaultProps = {
  title: null,
  date: null,
  details: [],
  propertyName: null,
  propertyId: null,
  tzValue: null,
};

export default MoveIn;
