import PropTypes from 'prop-types';
import React, { useMemo, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import DOMPurify from 'dompurify';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CircleIcon from '@mui/icons-material/Circle';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import RemoveIcon from '@mui/icons-material/Remove';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

import ItemEditButton from './ItemEditButton';
import StandardTable from '../../table/StandardTable';

const iconComponentsMapping = {
  access_time: AccessTimeIcon,
  attach_money: AttachMoneyIcon,
  arrow_downward: ArrowDownwardIcon,
  arrow_upward: ArrowUpwardIcon,
  circle: CircleIcon,
  insert_chart: InsertChartIcon,
  remove: RemoveIcon,
  text_snippet: TextSnippetIcon,
  trending_down: TrendingDownIcon,
  trending_flat: TrendingFlatIcon,
  trending_up: TrendingUpIcon,
};

const urlCell = col => cellInfo => {
  let {
    row: { original: rowAttributes },
  } = cellInfo;
  return <a href={rowAttributes[col.url_accessor]}>{rowAttributes[col.accessor]}</a>;
};

const tooltipCell = col => cellInfo => {
  let {
    row: { original: rowAttributes },
  } = cellInfo;
  return <span data-tip={rowAttributes[col.tooltip_accessor]}>{rowAttributes[col.accessor]}</span>;
};

const textColorCell = col => cellInfo => {
  let {
    row: { original: rowAttributes },
  } = cellInfo;
  return (
    <span style={{ color: rowAttributes[col.text_color_accessor] }}>
      {rowAttributes[col.accessor]}
    </span>
  );
};

const imageCell = col => cellInfo => {
  let {
    row: { original: rowAttributes },
  } = cellInfo;

  // thumbnail dimensions
  const thumbnailWidth = 50;  // in pixels
  const thumbnailHeight = 30; // in pixels

  if ((rowAttributes[col.accessor]) && (col.accessor !== 'floorplan_pdf_url')) {
    return (
      <a style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%'
      }}
      href={rowAttributes[col.url_accessor]}
      target="_blank"
      rel="noopener noreferrer">
        <img
          src={rowAttributes[col.accessor]}
          alt={rowAttributes[col.alt_accessor]}
          width={thumbnailWidth}
          height={thumbnailHeight}
          style={{
            objectFit: 'cover',
            maxWidth: '100%',
            maxHeight: '100%'
          }}
        />
      </a>
    );
  }
  else if ((rowAttributes[col.accessor]) && (col.accessor === 'floorplan_pdf_url')) {
    return (
      <a
        href={rowAttributes[col.url_accessor]}
        target="_blank" rel="noopener noreferrer"
        style={{color: 'blue'}}
      >
      Open <i class="fa-solid fa-up-right-from-square"></i></a>
    );
  }
  else {
    return null;
  }
};

const iconCell = col => cellInfo => {
  let {
    row: { original: rowAttributes },
  } = cellInfo;
  const iconAttributes = rowAttributes[col.icon_attributes_accessor];
  const iconName = iconAttributes ? iconAttributes['name'] : '';
  const iconColor = iconAttributes ? iconAttributes['color'] || 'black' : '';
  const iconSize = iconAttributes ? iconAttributes['size'] || '30' : '';
  const IconComp = iconComponentsMapping[iconName];
  const tooltipAttribute = rowAttributes[col.tooltip_accessor]
    ? { 'data-tip': rowAttributes[col.tooltip_accessor] }
    : {};
  const iconContent = (
    <span {...tooltipAttribute}>
      {IconComp ? <IconComp sx={{ color: iconColor, fontSize: iconSize }} /> : <span />}
    </span>
  );
  return rowAttributes[col.url_accessor] ? (
    <a href={rowAttributes[col.url_accessor]} target="_blank" rel="noreferrer">
      {iconContent}
    </a>
  ) : (
    <>{iconContent}</>
  );
};

const dangerouslySetInnerHTMLCell = col => cellInfo => {
  let {
    row: { original: rowAttributes },
  } = cellInfo;

  // Sanitize the HTML content
  const sanitizedHTML = DOMPurify.sanitize(rowAttributes[col.accessor]);

  return <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />; // eslint-disable-line react/no-danger
};

const editCell = (
  formAttributes,
  modelName,
  onUpdate,
  setStateIndexItems,
  stateIndexItems
) => cellInfo => {
  let {
    row: { original: formObject },
  } = cellInfo;
  return (
    <ItemEditButton
      formAttributes={formAttributes}
      formObject={formObject}
      modelName={modelName}
      onUpdate={onUpdate}
      setStateIndexItems={setStateIndexItems}
      stateIndexItems={stateIndexItems}
    />
  );
};

const ItemIndexTable = ({
  formAttributes,
  hideColumns,
  modelName,
  onUpdate,
  setStateIndexItems,
  stateIndexItems,
  tableAttributes,
}) => {
  console.log(formAttributes)

  const defaultPageSize = Math.min(50, stateIndexItems.length);

  const columns = useMemo(() =>
    tableAttributes.attributes.map((col, index) => {
      if (col.hidden) {
        return {
          show: false,
          id: index,
          Header: col.header,
          accessor: col.accessor,
          className: col.class_name,
        };
      } else if (col.boolean_marker) {
        return {
          id: index,
          Header: col.header,
          accessor: col.accessor,
          className: col.class_name,
          Cell: row => (row.row.original[col.accessor] ? `${col.boolean_marker}` : ''),
        };
      } else if (col.image_accessor) {
        return {
          id: index,
          Header: col.header,
          accessor: col.accessor,
          className: col.class_name,
          Cell: imageCell(col),
        };
      }

      else if (col.icon_attributes_accessor) {
        return {
          id: index,
          Header: col.header,
          accessor: col.accessor,
          className: col.class_name,
          Cell: iconCell(col),
        };
      } else if (col.url_accessor) {
        return {
          id: index,
          Header: col.header,
          accessor: col.accessor,
          className: col.class_name,
          Cell: urlCell(col),
        };
      } else if (col.tooltip_accessor) {
        return {
          id: index,
          Header: col.header,
          accessor: col.accessor,
          className: col.class_name,
          Cell: tooltipCell(col),
        };
      } else if (col.text_color_accessor) {
        return {
          id: index,
          Header: col.header,
          accessor: col.accessor,
          Footer: col.footer || '-',
          className: col.class_name,
          Cell: textColorCell(col),
        }
      } else if (col.html) {
        return {
          id: index,
          Header: col.header,
          accessor: col.accessor,
          className: col.class_name,
          Cell: dangerouslySetInnerHTMLCell(col),
        }
      } else {
        return {
          id: index,
          Header: col.header,
          accessor: col.accessor,
          Footer: col.footer || '-',
          className: col.class_name,
        };
      }
    })
  ).concat([
    {
      Header: 'Edit',
      accessor: 'edit',
      Footer: '-',
      show: !hideColumns.includes('edit'),
      className: 'text-center',
      disableSortBy: true,
      Cell: editCell(formAttributes, modelName, onUpdate, setStateIndexItems, stateIndexItems),
    },
  ]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [stateIndexItems]);

  return (
    <>
      <ReactTooltip place="bottom" type="info" effect="solid" />
      <StandardTable
        columns={columns.filter(col => col.show != false)}
        data={stateIndexItems}
        hasPagination={tableAttributes?.features?.pagination}
        isSearchable={tableAttributes?.features?.search}
        hasFooter={tableAttributes?.features?.footer}
        defaultPageSize={defaultPageSize}
      />
    </>
  );
};

ItemIndexTable.propTypes = {
  formAttributes: PropTypes.shape({}).isRequired,
  hideColumns: PropTypes.arrayOf(PropTypes.string),
  modelName: PropTypes.string,
  onUpdate: PropTypes.func,
  setStateIndexItems: PropTypes.func.isRequired,
  stateIndexItems: PropTypes.arrayOf(PropTypes.shape({})),
  tableAttributes: PropTypes.shape({
    attributes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    features: PropTypes.shape({
      footer: PropTypes.bool,
      pagination: PropTypes.bool,
      search: PropTypes.bool,
    }),
  }).isRequired,
};

ItemIndexTable.defaultProps = {
  hideColumns: [],
  modelName: 'Item',
  onUpdate: undefined,
  stateIndexItems: [],
};

export default ItemIndexTable;
