export default {
  buttons: {
    primary: {
      'bg-gray-800': true,
      'hover:bg-gray-700': true,
      'border-2': true,
      'border-gray-800': true,
      'hover:border-gray-700': true,
      'text-white': true,
      'hover:text-white': true,
    },

    primaryOutline: {
      'border-2': true,
      'border-gray-800': true,
      'hover:border-gray-700': true,
      'hover:bg-gray-700': true,
      'text-gray-800': true,
      'hover:text-white': true,
    },

    danager: {
      'bg-red-700': true,
      'hover:bg-red-600': true,
      'border-2': true,
      'border-red-700': true,
      'hover:border-red-600': true,
      'text-white': true,
      'hover:text-white': true,
    },
  },
  buttonSizes: {
    sm: {
      'py-2': true,
      'px-4': true,
    },
    lg: {
      'py-3': true,
      'px-4': true,
    },
  },
};
