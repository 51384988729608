import React from 'react';
import Alert from '@mui/material/Alert';

const SharedAlerts = () => (
  <>
    <Alert severity="info" sx={{ marginTop: '12px' }}>
      Microsoft Teams - Please note that HTML formatting is not supported in Microsoft Teams. When
      creating Teams calendar events, use arrows provided (➤) to indent and organize text.
    </Alert>
  </>
);

export default SharedAlerts;
