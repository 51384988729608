import React, { useState, useEffect } from 'react';
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Button from '@material-ui/core/Button';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DashboardIcon from '@material-ui/icons/Dashboard';
import VideocamIcon from '@material-ui/icons/Videocam';
import TableChartIcon from '@material-ui/icons/TableChart';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ApartmentIcon from '@material-ui/icons/Apartment';
import HotelIcon from '@material-ui/icons/Hotel';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import PeopleIcon from '@material-ui/icons/People';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ForumIcon from '@material-ui/icons/Forum';
import BuildIcon from '@material-ui/icons/Build';
import RefreshIcon from '@material-ui/icons/Refresh';
import TodayIcon from '@material-ui/icons/Today';
import ComputerIcon from '@material-ui/icons/Computer';
import StorefrontIcon from '@material-ui/icons/Storefront';
import SchoolIcon from '@material-ui/icons/School';
import SpeedIcon from '@material-ui/icons/Speed';

import AppHeader from './components/header';
import AppSidebar from './components/sidebar';

import ScionImage from '../../images/scionlogo-white-0250.png';

const drawerWidth = 240;

const iconComponents = {
  ArrowForward: ArrowForwardIcon,
  Dashboard: DashboardIcon,
  Videocam: VideocamIcon,
  TableChart: TableChartIcon,
  AttachMoney: AttachMoneyIcon,
  ThumbUp: ThumbUpIcon,
  Apartment: ApartmentIcon,
  Hotel: HotelIcon,
  People: PeopleIcon,
  CardGiftcard: CardGiftcardIcon,
  TrendingUp: TrendingUpIcon,
  Forum: ForumIcon,
  ContactPhone: ContactPhoneIcon,
  CalendarIcon: TodayIcon,
  Person: PersonIcon,
  Settings: SettingsIcon,
  LocalOffer: LocalOfferIcon,
  Build: BuildIcon,
  Refresh: RefreshIcon,
  Computer: ComputerIcon,
  Storefront: StorefrontIcon,
  School: SchoolIcon,
  Speed: SpeedIcon,
};

const useStyles = makeStyles(theme => ({
  app: {
    [theme.breakpoints.up('md')]: {
      marginTop: '65px',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '65px',
    },
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuListItem: {
    paddingTop: '4px',
    paddingBottom: '4px',
  },
  menuItem: {
    transition: 'background-color 250ms linear',
    paddingTop: '0px',
    paddingBottom: '0px',
    color: 'white',
    textTransform: 'none',
    backgroundColor: 'transparent',
    '&:hover': {
      color: '#F9DB4A',
      backgroundColor: 'inherit',
    },
  },
  activeMenuItem: {
    color: '#F9DB4A !important',
  },
}));

const checkSidebarState = () => {
  return JSON.parse(window.localStorage.getItem('isSidebarOpen')) || false;
};

const setSidebarState = (value, callback) => {
  window.localStorage.setItem('isSidebarOpen', value);

  if (callback && typeof callback === 'function') {
    callback(value);
  }

  return value;
};

const AppLayout = ({
  html_yield,
  page_filters,
  can_edit_representatives,
  can_edit_dictionary_terms,
  can_edit_property_sales_info,
  current,
  user_logo_html,
  sidebar_config,
  search_config,
}) => {
  const [menuOpen, setMenuOpen] = useState(checkSidebarState());
  const classes = useStyles();

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  });

  const renderBody = html => {
    return (
      <div className="min-h-screen">
        <div
          dangerouslySetInnerHTML={{ __html: html }} // eslint-disable-line react/no-danger
        />
      </div>
    );
  };

  const renderFooter = timestamp => {
    return (
      <div className="flex flex-col-reverse md:flex-row lg:justify-between items-center bg-gray-800 lg:mt-16 mt-4 md:mb-0 py-4">
        <div className="flex flex-col justify-start w-full md:w-1/2 px-4 mt-4 md:mt-0">
          <div>
            <img className="h-12" src={ScionImage} alt="Logo" />
          </div>
          <div className="text-gray-300 text-sm pt-2 ml-1">
            Leasing data updated: <span className="font-bold">{timestamp}</span>
          </div>
        </div>
        <div className="flex w-full md:w-1/2 text-white text-gray-300 px-4">
          <div className="w-2/3 md:w-1/2 lg:1/3">
            <div className="inline-block text-lg font-normal mb-2 border-b-2 border-white">
              Resources
            </div>
            <div className="flex justify-between">
              <ul className="flex flex-col flex-wrap md:h-32 gap-x-12 text-base">
                <li className="py-2 md:py-1">
                  <a
                    className="py-2 md:py-1 hover:text-white"
                    href="https://workforcenow.adp.com/workforcenow/login.html"
                    target="noopener noreferrer"
                  >
                    ADP
                  </a>
                </li>
                <li className="py-2 md:py-1">
                  <a
                    className="py-2 md:py-1 hover:text-white"
                    href="https://sciongroupllc.sharepoint.com/sites/BrandHub"
                    target="noopener noreferrer"
                  >
                    Brand Hub
                  </a>
                </li>
                <li className="py-2 md:py-1">
                  <a
                    className="py-2 md:py-1 hover:text-white"
                    href="https://www.concursolutions.com/"
                    target="noopener noreferrer"
                  >
                    Concur
                  </a>
                </li>
                <li className="py-2 md:py-1">
                  <a
                    className="py-2 md:py-1 hover:text-white"
                    href="https://sciongroup.percipio.com/"
                    target="noopener noreferrer"
                  >
                    Sprout
                  </a>
                </li>
                <li className="py-2 md:py-1">
                  <a
                    className="py-2 md:py-1 hover:text-white"
                    href="https://thesciongroup.entrata.com/"
                    target="noopener noreferrer"
                  >
                    Entrata
                  </a>
                </li>
                <li className="py-2 md:py-1">
                  <a
                    className="py-2 md:py-1 hover:text-white"
                    href="https://www.greenhouse.com/"
                    target="noopener noreferrer"
                  >
                    Greenhouse
                  </a>
                </li>
                <li className="py-2 md:py-1">
                  <a
                    className="py-2 md:py-1 hover:text-white"
                    href="https://thesciongroup.latticehq.com/"
                    target="noopener noreferrer"
                  >
                    Lattice
                  </a>
                </li>
                <li className="py-2 md:py-1">
                  <a
                    className="py-2 md:py-1 hover:text-white"
                    href="https://www.qualtrics.com/login"
                    target="noopener noreferrer"
                  >
                    Qualtrics
                  </a>
                </li>
                <li className="py-2 md:py-1">
                  <a
                    className="py-2 md:py-1 hover:text-white"
                    href="https://support.thesciongroup.com/"
                    target="noopener noreferrer"
                  >
                    Scion Help Desk
                  </a>
                </li>
                <li className="py-2 md:py-1">
                  <a
                    className="py-2 md:py-1 hover:text-white"
                    href="https://insurance.thesciongroup.com/app"
                    target="noopener noreferrer"
                  >
                    Scion Insurance Portal
                  </a>
                  <li className="py-2 md:py-1">
                    <a
                      className="py-2 md:py-1 hover:text-white"
                      href="https://app.meetsoci.com/admin/login"
                      target="noopener noreferrer"
                    >
                      SOCi
                    </a>
                  </li>
                </li>
                <li className="py-2 md:py-1">
                  <a
                    className="py-2 md:py-1 hover:text-white"
                    href="https://sciongroupllc.sharepoint.com/sites/BU-PropertyManagementSystem-CorporateProperty"
                    target="noopener noreferrer"
                  >
                    Systems Hub
                  </a>
                </li>
                <li className="py-2 md:py-1">
                  <a
                    className="py-2 md:py-1 hover:text-white"
                    href="https://sciongroupllc.sharepoint.com/sites/TalentAcquisition"
                    target="noopener noreferrer"
                  >
                    Talent Hub
                  </a>
                </li>
                <li className="py-2 md:py-1">
                  <a
                    className="py-2 md:py-1 hover:text-white"
                    href="https://sciongroupllc.sharepoint.com/sites/Intranet/Shared%20Documents/Forms/AllItems.aspx?csf=1&web=1&e=g3z7Rv&RootFolder=%2Fsites%2FIntranet%2FShared%20Documents%2FScion%20Policies&FolderCTID=0x012000E2075C08EB969E4C8BEC3A4BA23A3356&CT=1710947742028&OR=OWA%2DNT%2DMail&CID=a7dd6792%2Dead9%2D8cfb%2D0d3e%2Dc00242118992"
                    target="noopener noreferrer"
                  >
                    Scion Policies
                  </a>
                </li>
                <li className="py-2 md:py-1">
                  <a
                    className="py-2 md:py-1 hover:text-white"
                    href="https://sciongroupllc.sharepoint.com/sites/TurnHub"
                    target="noopener noreferrer"
                  >
                    Turn Hub
                  </a>
                </li>
                <li className="py-2 md:py-1">
                  <a
                    className="py-2 md:py-1 hover:text-white"
                    href="https://sciongroupllc.sharepoint.com/sites/Intranet?CT=1710948348510&OR=OWA-NT-Mail&CID=1ffc518d-1c61-d459-d014-1df60d9e3a09"
                    target="noopener noreferrer"
                  >
                    The Hub
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleMenuOpen = () => {
    const currentValue = menuOpen;
    setSidebarState(!currentValue, value => setMenuOpen(value));
  };

  return (
    <>
      <div className={classNames(classes.app, { [classes.appShift]: menuOpen })}>
        <AppHeader
          menuOpen={menuOpen}
          handleMenuOpen={handleMenuOpen}
          page_filters={page_filters}
          current={current}
          can_edit_representatives={can_edit_representatives}
          can_edit_dictionary_terms={can_edit_dictionary_terms}
          user_logo_html={user_logo_html}
          sidebar_config={sidebar_config}
          search_config={search_config}
          can_edit_property_sales_info={can_edit_property_sales_info}
        />
        {renderBody(html_yield)}
        {renderFooter(current.fact_property_updated_at)}
      </div>

      <AppSidebar open={menuOpen} toggleOpen={handleMenuOpen}>
        <List component="nav">
          {sidebar_config.map(item => {
            const iconComponent = iconComponents[item.icon] || DashboardIcon;
            return (
              <ListItem key={item.path} className={classes.menuListItem}>
                <Button
                  href={item.path}
                  className={classNames(classes.menuItem, {
                    [classes.activeMenuItem]: item.path === window.location.pathname,
                  })}
                >
                  <ListItemIcon>
                    {React.createElement(iconComponent, {
                      fontSize: 'small',
                      style: { color: 'white' },
                    })}
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                </Button>
              </ListItem>
            );
          })}
        </List>
      </AppSidebar>
    </>
  );
};

AppLayout.propTypes = {
  html_yield: PropTypes.string,
  can_edit_representatives: PropTypes.bool,
  can_edit_dictionary_terms: PropTypes.bool,
  can_edit_property_sales_info: PropTypes.bool,
  current: PropTypes.shape({
    fact_property_updated_at: PropTypes.string,
  }).isRequired,
  page_filters: PropTypes.shape({}).isRequired,
  sidebar_config: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      isPermitted: PropTypes.bool.isRequired,
      path: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  search_config: PropTypes.shape({
    options: PropTypes.shape({
      property: PropTypes.bool,
      directory: PropTypes.bool,
      resident: PropTypes.bool,
    }),
  }).isRequired,
  user_logo_html: PropTypes.string,
};

AppLayout.defaultProps = {
  html_yield: '',
  can_edit_representatives: false,
  can_edit_dictionary_terms: false,
  can_edit_property_sales_info: false,
  user_logo_html: null,
};

export default AppLayout;
