export function hexToLuminance(hex) {
  const value = hex.replace(/[#]/g, "");
  let match = null;

  if (value.length == 6) {
    match = value.match(/.{2}/g);
  }
  if (value.length == 3) {
    match = value.match(/.{1}/g);
  }
  if (match == null) {
    return nil;
  }

  return (
    parseInt(match[0], 16) * 0.2126 +
    parseInt(match[1], 16) * 0.587 +
    parseInt(match[2], 16) * 0.114
  );
}

export function rgbToLumance(red, green, blue) {
  return red * 0.2126 + green * 0.587 + blue * 0.114;
}

// https://stackoverflow.com/questions/1740700/how-to-get-hex-color-value-rather-than-rgb-value
// JQuery background-color always returns rgb(#,#,#) - use this for cases needing hex.
export function rgbToHex(rgb) {
  if (rgb.search("rgb") == -1) {
    return rgb;
  }
  rgb = rgb.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+))?\)$/);
  function hex(x) {
    return `0${parseInt(x).toString(16)}`.slice(-2);
  }
  return `#${hex(rgb[1])}${hex(rgb[2])}${hex(rgb[3])}`;
}

export function statToColor(a, reversed = false) {
  if (a == 0) {
    return "black";
  }

  if (reversed) {
    return a >= 0 ? "red" : "green";
  }

  return a >= 0 ? "green" : "red";
}
