import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import TableContext from './TableContext';

const styles = {
  base: '',
};

const TableCell = props => {
  const { className, sortDirection, children, ...other } = props;

  const tableContext = React.useContext(TableContext);

  let Component;
  if (tableContext && tableContext.variant === 'body') {
    Component = 'td';
  } else {
    Component = 'th';
  }

  return (
    <Component
      className={classNames(styles.base, {
        [className]: className,
      })}
      {...other}
    >
      {children}

      {sortDirection && (
        <span className="inline-block float-right text-sm">
          <i
            className={classNames('align-middle', {
              'fa fa-caret-down': sortDirection === 'asc',
              'fa fa-caret-up': sortDirection === 'desc',
            })}
            aria-hidden="true"
          />
        </span>
      )}
    </Component>
  );
};

TableCell.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  sortDirection: PropTypes.string,
};

TableCell.defaultProps = {
  className: null,
  children: null,
  sortDirection: null,
};

export default TableCell;
