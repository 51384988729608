import React from 'react';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown';

const CountdownTimer = ({ url }) => {
  function refreshPage() {
    url.searchParams.set('auto-refresh', true);
    window.location.href = url.href;
  }

  const renderer = ({ minutes, seconds }) => {
    return (
      <span className="text-sm -ml-3">
        {minutes}:{seconds < 10 ? '0' + seconds : seconds}
      </span>
    );
  };

  renderer.propTypes = {
    minutes: PropTypes.string.isRequired,
    seconds: PropTypes.string.isRequired,
  };

  return (
    <>
      <Countdown
        date={Date.now() + 300000} // 10 minutes
        renderer={renderer}
        // eslint-disable-next-line react/jsx-no-bind
        onComplete={refreshPage}
      />
    </>
  );
};

CountdownTimer.propTypes = {
  url: PropTypes.shape({
    searchParams: PropTypes.shape({
      set: PropTypes.func.isRequired,
    }),
    href: PropTypes.string.isRequired,
  }).isRequired,
};

export default CountdownTimer;
