import React from 'react';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import PropTypes from 'prop-types';

const AutoRefresh = ({ toggled }) => {
  return <AutorenewIcon style={{ fill: toggled ? '#4db6ac' : 'inherit' }} />;
};

AutoRefresh.propTypes = {
  toggled: PropTypes.bool.isRequired,
};

export default AutoRefresh;
