import * as types from './types';

const initialState = {
  isTransposed: false,
  isRunning: false,
  isError: false,
};

const queryReducer = (query = initialState, action) => {
  if (action.type === types.RUN_REQUESTED) {
    const { queryParams } = action.payload;
    return {
      ...query,
      params: queryParams,
      isRunning: true,
      isError: false,
    };
  }

  if (action.type === types.RUN_SUCCESS) {
    const { response } = action.payload;
    return {
      ...query,
      ...response,
      isRunning: false,
    };
  }

  if (action.type === types.RUN_FAILURE) {
    // const { error } = action.payload;
    return {
      ...query,
      isRunning: false,
      isError: true,
    };
  }

  if (action.type === types.TOGGLE_TRANSPOSE) {
    return {
      ...query,
      isTransposed: action.payload.isTransposed,
    };
  }

  return query;
};

export default queryReducer;
