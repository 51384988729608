import { connect } from 'react-redux';
import { addFilter } from '../../state/filters';
import FilterList from './FilterList';

const mapStateToProps = state => ({
  filters: state.filters,
  dimensions: state.source.dimensions,
});

export default connect(mapStateToProps, { addFilter })(FilterList);
