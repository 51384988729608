import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

// State
import { StoreContext, actions, createAction } from '../reducer';

const TierHeader = ({ tierStatus, tier, lastTier, tierKey, isEditing }) => {
  const store = useContext(StoreContext);
  const dispatch = store.dispatch;

  const getTitle = () => {
    if (tierStatus == 'current_to_date') {
      return `Tier ${tier} (to date)`;
    } else if (tierStatus == 'current_remaining') {
      return `Tier ${tier} (remaining)`;
    } else if (tierStatus == 'past_demo') {
      return `Past Tier`;
    } else if (tierStatus == 'future_demo') {
      return `Future Tier`;
    } else {
      return `Tier ${tier}`;
    }
  };

  const deleteTier = () => {
    dispatch(createAction(actions.DELETE_TIER, { tierKey }));
    dispatch(createAction(actions.UPDATE_CALCULATIONS));
  };

  return (
    <>
      <Grid item xs={12} className="text-center pt-1">
        {!lastTier && (
          <Typography style={{ fontWeight: 600 }} variant="subtitle1">
            {getTitle()}
          </Typography>
        )}
        {lastTier && (
          <div className="flex flex-row">
            <div
              className={`flex flex-grow w-full justify-center ${
                tierStatus === 'future' && isEditing ? 'pl-5' : ''
              }`}
            >
              <Typography style={{ fontWeight: 600 }} variant="subtitle1">
                {getTitle()}
              </Typography>
            </div>
            {tierStatus === 'future' && isEditing && (
              <div className="flex flex-shrink pr-1 text-gray-600 hover:text-red-700">
                <button type="button" onClick={deleteTier}>
                  <DeleteForeverIcon fontSize="small" />
                </button>
              </div>
            )}
          </div>
        )}
      </Grid>
      <Grid item xs={6} className="text-center">
        <Typography style={{ fontWeight: 500 }} variant="overline">
          Contracts
        </Typography>
      </Grid>
      <Grid item xs={6} className="text-center">
        <Typography style={{ fontWeight: 500 }} variant="overline">
          Rate
        </Typography>
      </Grid>
    </>
  );
};

TierHeader.propTypes = {
  tierStatus: PropTypes.string,
  tier: PropTypes.number,
  lastTier: PropTypes.bool,
  tierKey: PropTypes.string,
  isEditing: PropTypes.bool,
};

TierHeader.defaultProps = {
  tierStatus: '',
  tier: null,
  lastTier: false,
  tierKey: '',
  isEditing: false,
};

export default TierHeader;
