// Code source: https://codesandbox.io/s/react-select-v2-required-input-3xvvb?file=/src/FixRequiredSelect.js

import React from 'react';
import PropTypes from 'prop-types';

const noop = () => {
  // no operation (do nothing real quick)
};

class FixRequiredSelect extends React.Component {
  state = {
    // eslint-disable-next-line
    value: this.props.value || '',
  };

  selectRef = null;
  setSelectRef = ref => {
    this.selectRef = ref;
  };

  onChange = (value, actionMeta) => {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.onChange(value, actionMeta);
    this.setState({ value });
  };

  getValue = () => {
    // eslint-disable-next-line
    if (this.props.value != undefined) return this.props.value;
    // eslint-disable-next-line react/destructuring-assignment
    return this.state.value || '';
  };

  render() {
    const { SelectComponent, required, ...props } = this.props;
    const { isDisabled } = this.props;
    const enableRequired = !isDisabled;

    return (
      <div>
        <SelectComponent {...props} ref={this.setSelectRef} onChange={this.onChange} />
        {enableRequired && (
          <input
            tabIndex={-1}
            autoComplete="off"
            style={{
              opacity: 0,
              width: '100%',
              height: 1,
            }}
            value={this.getValue()}
            onChange={noop}
            onFocus={() => this.selectRef.focus()}
            required={required}
          />
        )}
      </div>
    );
  }
}

FixRequiredSelect.propTypes = {
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  SelectComponent: PropTypes.func.isRequired,
};

FixRequiredSelect.defaultProps = {
  isDisabled: false,
  onChange: noop,
  required: false,
};

export default FixRequiredSelect;
