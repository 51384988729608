/* eslint react/prop-types: 0 */
import React from 'react';
import { Box, Typography } from '@material-ui/core';
import _ from 'lodash';
import Filter from '../filter';

const FilterList = ({ filters, dimensions }) => {
  const filtersGroupedByDimensionId = _.groupBy(Object.values(filters), 'dimensionId');
  return (
    <Box my="1rem" p="1rem" bgcolor="white">
      <Typography variant="h5">Filters</Typography>
      {Object.keys(filtersGroupedByDimensionId).map(dimensionId => {
        const dimension = dimensions[dimensionId];
        return (
          <Box key={dimensionId} my="1rem">
            <Box key={dimensionId} my="1rem">
              {filtersGroupedByDimensionId[dimensionId].map(filter => (
                <Filter key={filter.id} filter={filter} dimension={dimension} />
              ))}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default FilterList;
