import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import api from 'frontend/comps/api';

const Form = ({ data: { id: id, case_id: caseId, status: status, comment: comment } }) => {
  const [caseStatus, setCaseStatus] = useState(status);
  const [caseComment, setCaseComment] = useState(comment);
  const [showConfirm, setShowConfirm] = useState(false);

  const handleChange = event => {
    setCaseComment(event.target.value);
  };

  const handleClick = event => {
    setCaseStatus(event.target.value);
  };
  const data = {
    record_id: id,
    case_id: caseId,
    status: caseStatus,
    comment: caseComment,
  };

  const updateCaseStatus = () => {
    api.realPageCaseStatuses.update(data).then(e => {
      if (e.status === 200) {
        setShowConfirm(true);
      }
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f7fafc',
        marginTop: '-36px',
        height: '100vh',
      }}
    >
      {showConfirm && (
        <Box
          sx={{
            backgroundColor: '#a0aec0',
            width: '-webkit-fill-available',
            margin: '0px 12px 30px 12px',
            color: 'white',
            borderRadius: '5px',
            display: 'flex',
            padding: '4px',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5" gutterBottom>
            {' '}
            Case Status successfully Updated!
          </Typography>
          <Typography variant="p" gutterBottom>
            {' '}
            Please allow at least 1 hour for any updates to be reflected on the dashboard
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          padding: '24px',
          backgroundColor: 'white',
          borderRadius: '5px',
          boxShadow: '0 2px 7px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            flexWrap: 'wrap',
          }}
        >
          <Typography variant="h2" gutterBottom>
            Update Case {caseId} Status
          </Typography>
        </Box>
        <Box>
          <Box sx={{ marginBottom: '12px' }}>
            <Typography variant="h6" gutterBottom>
              Current Status: {status}
            </Typography>
            <FormControl>
              <FormLabel id="demo-form-control-label-placement">Change Status</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                defaultValue={status}
              >
                <FormControlLabel
                  value="pending"
                  control={<Radio onClick={handleClick} />}
                  label="Pending"
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="closed"
                  control={<Radio onClick={handleClick} />}
                  label="Closed"
                  labelPlacement="top"
                />
              </RadioGroup>
            </FormControl>
            <Typography>Comments:</Typography>
            <TextField
              value={caseComment}
              multiline
              onChange={handleChange}
              sx={{ width: '100%', marginBotton: '12px' }}
            />
            <Box
              sx={{
                marginTop: '12px',
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Button
                variant="contained"
                sx={{ backgroundColor: 'green' }}
                href="/real_page_case_statuses/"
              >
                View Case Statuses
              </Button>
              <Button variant="contained" color="primary" onClick={updateCaseStatus}>
                Update Case Status
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

Form.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    case_id: PropTypes.string,
    status: PropTypes.string,
    comment: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
  }).isRequired,
};

export default Form;
