import React from 'react';
import PropTypes from 'prop-types';
import Avatars from '@dicebear/avatars';
import sprites from '@dicebear/avatars-initials-sprites';
import microsoftTeamsIcon from '../../../../images/microsoftTeamsIcon.svg';

const generateAvatarSvg = name => {
  const options = { backgroundColors: ['blueGrey'] };
  const avatars = new Avatars(sprites(options));
  const buff = Buffer.from(avatars.create(name));
  return buff.toString('base64');
};

const DirectoryItem = props => {
  const {
    id,
    fullName,
    propertyName,
    positionName,
    officePhone,
    cellPhone,
    email,
    logo,
    hasWhitelistedEmail,
    userId,
    isCurrentUserAdmin,
    adpAssociateOid,
    propertyId,
  } = props;
  const logoImage = logo ? logo : `data:image/svg+xml;base64,${generateAvatarSvg(fullName)}`;

  return (
    <div key={id} className="flex bg-white rounded-lg p-6 w-full">
      <img className="h-16 w-16 rounded-full mx-0 mr-6" src={logoImage} alt="avatar" />
      <div className="text-left leading-tight">
        {fullName && <h2 className="text-lg font-normal float-left">{fullName}</h2>}
        {hasWhitelistedEmail && (
          <a
            href={`https://teams.microsoft.com/l/chat/0/0?users=${email}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="float-left ml-1 transition transform hover:scale-110 duration-75 ease-in-out"
              style={{ marginTop: -1.5 }}
              src={microsoftTeamsIcon}
              alt="Microsoft Teams logo"
            />
          </a>
        )}
        {propertyName && <h2 className="text-gray-700 clear-both">{propertyName}</h2>}
        {positionName && <div className="text-gray-700 clear-both">{positionName}</div>}
        {email && (
          <div className="text-gray-600">
            <a href={`mailto:${email}`}>{email}</a>
          </div>
        )}
        {officePhone && (
          <div className="text-gray-600">
            Office: <a href={`tel:${officePhone}`}>{officePhone}</a>
          </div>
        )}
        {cellPhone && (
          <div className="text-gray-600">
            Cell: <a href={`tel:${cellPhone}`}>{cellPhone}</a>
          </div>
        )}
        {!userId && isCurrentUserAdmin && (
          <div>
            <button
              className="text-indigo-500 background-transparent font-bold uppercase px-0 py-0 text-xs outline-none focus:outline-none mr-1 mb-1"
              type="button"
              style={{ transition: 'all .15s ease' }}
            >
              <a
                href={`/admin/users/new?adp_associate_oid=${adpAssociateOid}&property_id=${propertyId}`}
              >
                Add User
              </a>
            </button>
          </div>
        )}
        {userId && isCurrentUserAdmin && (
          <div>
            <button
              className="text-gray-600 background-transparent font-bold uppercase px-0 py-0 text-xs outline-none focus:outline-none mr-1 mb-1"
              type="button"
              style={{ transition: 'all .15s ease' }}
            >
              <a href={`/admin/users/${userId}/edit`}>Edit User</a>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

DirectoryItem.propTypes = {
  id: PropTypes.string.isRequired,
  fullName: PropTypes.string,
  propertyName: PropTypes.string,
  positionName: PropTypes.string,
  officePhone: PropTypes.string,
  cellPhone: PropTypes.string,
  email: PropTypes.string,
  logo: PropTypes.string,
  hasWhitelistedEmail: PropTypes.bool,
  userId: PropTypes.number,
  isCurrentUserAdmin: PropTypes.bool,
  adpAssociateOid: PropTypes.string,
  propertyId: PropTypes.number,
};

DirectoryItem.defaultProps = {
  fullName: null,
  propertyName: null,
  positionName: null,
  officePhone: null,
  cellPhone: null,
  email: null,
  logo: null,
  hasWhitelistedEmail: false,
  userId: null,
  isCurrentUserAdmin: false,
  adpAssociateOid: null,
  propertyId: null,
};

export default DirectoryItem;
