import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const styles = {
  base: 'table',
  baseDefault: 'table-bordered table-hover table-sm shadow',
};

const Table = props => {
  const { className, component: Component = 'table', ...other } = props;

  return (
    <Component
      className={classNames(styles.base, {
        [styles.baseDefault]: !className,
        [className]: className,
      })}
      {...other}
    />
  );
};

Table.propTypes = {
  className: PropTypes.string,
  component: PropTypes.string,
};

Table.defaultProps = {
  className: '',
  component: 'table',
};

export default Table;
