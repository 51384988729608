import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import api from '../../api';
import Loader from '../../shared/Loader';
import ItemForm from './ItemForm';

const ItemFormContainer = props => {
  const {
    formAttributes,
    formObject,
    isEdit,
    modelName,
    onUpdate,
    setStateIndexItems,
    stateIndexItems,
  } = props;

  const [state, setState] = useState({
    itemToEdit: null,
    error: [],
    isLoading: true,
  });

  const [formErrors, setFormErrors] = useState();

  useEffect(() => {
    const params = { ...formAttributes.params, formObject: formObject };
    axios
      .all([api.crudItems.editOrNew(params)])
      .then(
        axios.spread(response => {
          setState({
            ...state,

            itemToEdit: response.data.form_object,
            isLoading: false,
          });
        })
      )
      .catch(() => {
        setState({
          ...state,
          error: 'Something went wrong.',
        });
      });
  }, []);

  const { itemToEdit, error, isLoading } = state;
  return (
    <div>
      {isLoading && (
        <div className="mt-6">
          <Loader />
        </div>
      )}
      {error && error}
      {itemToEdit && (
        <ItemForm
          formAttributes={formAttributes}
          isEdit={isEdit}
          itemToEdit={itemToEdit}
          modelName={modelName}
          onUpdate={onUpdate}
          setFormErrors={setFormErrors}
          setStateIndexItems={setStateIndexItems}
          stateIndexItems={stateIndexItems}
        />
      )}
      {formErrors && (
        <ul className="list-inside list-disc mt-6">
          {formErrors.map(error => (
            <li key={error} className="text-red-600 text-base mb-3">
              {typeof error === 'object' ? `${error.field} ${error.code}` : error}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

ItemFormContainer.propTypes = {
  formAttributes: PropTypes.shape({
    params: PropTypes.shape({}).isRequired,
  }).isRequired,
  formObject: PropTypes.shape({}),
  isEdit: PropTypes.bool,
  modelName: PropTypes.string,
  onUpdate: PropTypes.func,
  stateIndexItems: PropTypes.arrayOf(PropTypes.shape({})),
  setStateIndexItems: PropTypes.func,
};

ItemFormContainer.defaultProps = {
  formObject: {},
  isEdit: false,
  modelName: 'Item',
  onUpdate: undefined,
  setStateIndexItems: () => {},
  stateIndexItems: [],
};

export default ItemFormContainer;
