import areaChart from "./area";
import themeSelector from "../themes/theme_selector";
import { capitalize } from "@utils/lib/string";

const Highcharts = require("highcharts");
require("highcharts/modules/exporting")(Highcharts);

if ($(".basic-area-graph").length) {
  $(".basic-area-graph").each(function() {
    const _this = $(this);
    const dataObj = _this.data("chart");
    const theme = themeSelector(_this.data("theme"));
    const { data, overrideOptions } = dataObj;

    let options = {
      chart: {
        renderTo: _this[0],
        type: "area"
      },
      series: data.map(el => ({
        name: capitalize(el.name),
        data: el.data.map(_el => parseInt(_el))
      }))
    };

    options = Highcharts.merge(options, theme);
    options = Highcharts.merge(options, overrideOptions);

    areaChart(options);
  });
}
