import { Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import IncentiveFormContainer from '../incentive/IncentiveFormContainer';
import IncentiveTable from '../incentive/IncentiveTable';
import FormikInputCheckbox from '../shared/formik_inputs/FormikInputCheckbox';
import { usePanelDispatch } from '../panel/PanelContext';
import * as panelTypes from '../panel/panelTypes';
import Button from '../shared/Button';

const PropertySurveyFormIncentivesFields = ({
  values,
  incentives,
  unitMixes,
  propertyId,
  onIncentiveUpdate,
}) => {
  const findUnitMix = id => {
    return unitMixes.find(mix => mix.id === id);
  };

  return (
    <div className="mb-12 px-4">
      <div className="mb-6">
        <h3 className="inline-block text-2xl font-light pr-3">Incentives</h3>
        <NewIncentiveButton propertyId={propertyId} onUpdate={onIncentiveUpdate} />
      </div>

      <div className="mb-4">
        <IncentiveTable
          showColumns={{ unitMixes: true }}
          data={incentives.map(incentive => ({
            ...incentive,
            unit_mixes: incentive.unit_mix_ids.map(id => findUnitMix(id)),
          }))}
          onUpdate={onIncentiveUpdate}
        />
      </div>

      <div className="flex justify-center">
        <Field
          label="Confirm Incentives"
          name="incentive.is_verified"
          value={values.incentive.is_verified}
          component={FormikInputCheckbox}
          labelClassName={{ 'inline-block': true, 'mb-2': false, 'mb-1': true }}
          checkboxClassName="mt-2 ml-2"
        />
      </div>
    </div>
  );
};

PropertySurveyFormIncentivesFields.propTypes = {
  values: PropTypes.shape({
    incentive: PropTypes.shape({
      is_verified: PropTypes.bool.isRequired,
    }),
  }).isRequired,
  incentives: PropTypes.arrayOf(PropTypes.shape({})),
  unitMixes: PropTypes.arrayOf(PropTypes.shape({})),
  propertyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onIncentiveUpdate: PropTypes.func,
};

PropertySurveyFormIncentivesFields.defaultProps = {
  onIncentiveUpdate: undefined,
  incentives: [],
  unitMixes: [],
};

const NewIncentiveButton = ({ propertyId, onUpdate }) => {
  const panelDispatch = usePanelDispatch();

  const handleUpdate = () => {
    onUpdate();
    panelDispatch({
      type: panelTypes.CLOSE,
    });
  };

  return (
    <Button
      type="button"
      variant="primary"
      size="sm"
      className="float-right"
      onClick={() => {
        panelDispatch({
          type: panelTypes.OPEN,
          render: <IncentiveFormContainer propertyId={propertyId} onUpdate={handleUpdate} />,
        });
      }}
    >
      + New Incentive
    </Button>
  );
};

NewIncentiveButton.propTypes = {
  propertyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onUpdate: PropTypes.func,
};

NewIncentiveButton.defaultProps = {
  onUpdate: undefined,
};

export default PropertySurveyFormIncentivesFields;
