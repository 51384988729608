import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import { Box } from '@material-ui/core';

const VirtualOfficePage = ({ htmlYield }) => {
  const sanitizedHtmlYield = DOMPurify.sanitize(htmlYield);
  return (
    <>
      <Box justifyContent="center" className="relative bg-white overflow-hidden mb-4">
        <div className="w-full md:w-3/4 mx-auto">
          <div className="relative z-40">
            <img
              src="https://res.cloudinary.com/the-scion-group/image/upload/v1590792609/scion_intelligence/production/misc/scion_solistice_banner.jpg"
              useMap="#solsitce-map"
              alt="Scion Solsitce"
              className="w-full"
            />

            <map name="solsitce-map">
              <area
                target=""
                alt="Mercury SS"
                title="Mercury SS"
                href="https://zoom.us/j/829714477"
                coords="441,208,34"
                shape="circle"
              />
              <area
                target=""
                alt="Venus SS"
                title="Venus SS"
                href="https://zoom.us/j/829714477"
                coords="534,210,48"
                shape="circle"
              />
              <area
                target=""
                alt="Earth SS"
                title="Earth SS"
                href="https://zoom.us/j/829714477"
                coords="647,209,50"
                shape="circle"
              />
              <area
                target=""
                alt="Mars SS"
                title="Mars SS"
                href="https://zoom.us/j/829714477"
                coords="748,207,38"
                shape="circle"
              />
              <area
                target=""
                alt="Jupiter SS"
                title="Jupiter SS"
                href="https://zoom.us/j/829714477"
                coords="866,207,65"
                shape="circle"
              />
              <area
                target=""
                alt="Saturn SS"
                title="Saturn SS"
                href="https://zoom.us/j/829714477"
                coords="1031,208,62"
                shape="circle"
              />
              <area
                target=""
                alt="Uranus SS"
                title="Uranus SS"
                href="https://zoom.us/j/829714477"
                coords="1187,206,48"
                shape="circle"
              />
              <area
                target=""
                alt="Neptune SS"
                title="Neptune SS"
                href="https://zoom.us/j/829714477"
                coords="1293,209,42"
                shape="circle"
              />
              <area
                target=""
                alt="Pluto SS"
                title="Pluto SS"
                href="https://zoom.us/j/829714477"
                coords="1371,207,32"
                shape="circle"
              />
            </map>
            <span className="absolute right-0 bottom-0 p-2 text-xs" style={{ color: '#fcfdbd' }}>
              Click the planets to view the sprints in action!
            </span>
          </div>
        </div>
        <div
          className="absolute w-full h-full bg-black bottom-0 z-0"
          style={{ left: '50%', backgroundColor: '#180D1F' }}
        />
      </Box>
      <div className="text-black mx-4">
        <div
          dangerouslySetInnerHTML={{ __html: sanitizedHtmlYield }} // eslint-disable-line react/no-danger
        />
      </div>
    </>
  );
};

VirtualOfficePage.propTypes = {
  htmlYield: PropTypes.string,
};

VirtualOfficePage.defaultProps = {
  htmlYield: '',
};

export default VirtualOfficePage;
