/* eslint react/prop-types: 0 */
import React from 'react';
import mousetrap from 'mousetrap';
import 'mousetrap-global-bind';
import {
  Box,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
  LinearProgress,
} from '@material-ui/core';
import Results from '../results';

const DatasetList = ({ query, runQuery, toggleTranspose }) => {
  mousetrap.bindGlobal('command+shift', () => {
    runQuery();
  });
  return (
    <Box my="1rem" p="1rem" bgcolor="white">
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              label="Transpose"
              checked={query.isTransposed}
              onChange={event => toggleTranspose(event.target.checked)}
              color="default"
            />
          }
          label="Transpose"
        />
      </FormGroup>
      <Button
        variant="outlined"
        onClick={() => {
          runQuery();
        }}
      >
        RUN
      </Button>
      <Box my="1rem">
        {query.isRunning ? (
          <LinearProgress />
        ) : query.isError ? (
          'There was an error running this query.'
        ) : (
          <Results />
        )}
      </Box>
    </Box>
  );
};

export default DatasetList;
