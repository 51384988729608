import { withFormik } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import * as Icon from 'react-feather';
import * as Yup from 'yup';

import { decimalToPercent } from '@utils/lib/number';
import { pluralizeWord } from '@utils/lib/string';
import { HeaderStatsContainer, HeaderStat } from '../shared/header_stats';
import PropertySurveyFormFields from './PropertySurveyFormFields';
import PropertySurveyFormIncentivesFields from './PropertySurveyFormIncentivesFields';
import PropertySurveyFormPropertyFeesFields from './PropertySurveyFormPropertyFeesFields';
import PropertySurveyUnitMixRateFields from './PropertySurveyUnitMixRateFields';
import Button from '../shared/Button';
import api from '../api';
import ErrorBlock from '../shared/ErrorBlockMessages';

const PropertySurveyForm = props => {
  const {
    fees,
    values,
    errors,
    initialValues,
    handleSubmit,
    unitMixes,
    setFieldValue,
    previousPropertySurvey,
    incentives,
    propertyFees,
    onIncentiveUpdate,
    onPropertyFeeUpdate,
    isSubmitting,
    status,
  } = props;

  const bed_count = unitMixes.reduce((acc, unit) => acc + parseInt(unit.beds), 0);
  const prelease = values.property_survey.prelease / 100;
  const previous_prelease = previousPropertySurvey.prelease;
  const occupants = (values.property_survey.occupancy / 100) * parseFloat(bed_count);
  const previous_occupants = (previousPropertySurvey.occupancy / 100) * parseFloat(bed_count);

  const memoizedErrors = useMemo(() => {
    const list = [];

    if (errors.property_survey && Object.keys(errors.property_survey).length) {
      list.push({ field: 'Leasing Details', code: 'have not been validated properly' });
    }

    if (errors.unit_mix_rates && errors.unit_mix_rates.filter(el => el && el.is_verified).length) {
      list.push({
        field: 'Unit Mix Rates',
        code: 'have not been confirmed or validated properly',
      });
    }

    if (errors.incentive) {
      list.push({ field: 'Incentives', code: 'have not been confirmed' });
    }

    if (errors.property_fee) {
      list.push({ field: 'Property Fees', code: 'have not been confirmed' });
    }

    if (status && status.errors) {
      return [...list, ...status.errors];
    }

    return list;
  }, [errors, status && status.errors]);

  return (
    <form onSubmit={handleSubmit}>
      <HeaderStatsContainer>
        <HeaderStat
          title="Submission Dates"
          value={
            <React.Fragment>
              <span className="text-sm inline-block">
                {moment(previousPropertySurvey.date).format('MM-DD-YY')}
              </span>
              <span className="inline-block align-middle px-2">
                <Icon.ArrowRight size="26" />
              </span>
              <span className="text-sm inline-block font-normal">
                {moment(values.property_survey.date).format('MM-DD-YY')}
              </span>
            </React.Fragment>
          }
        />

        <HeaderStat
          title="Leasing"
          value={
            <React.Fragment>
              <div className="pb-1">
                <span className="inline-block">{decimalToPercent(previous_prelease)}%</span>
                <span className="inline-block align-middle px-2">
                  <Icon.ArrowRight size="26" />
                </span>
                <span className="inline-block font-normal">{decimalToPercent(prelease)}%</span>
              </div>

              <div className="text-xs leading-snug font-normal text-center">
                {parseFloat(
                  (decimalToPercent(prelease) - decimalToPercent(previous_prelease)).toFixed(4)
                )}
                %{` `}
                {decimalToPercent(prelease) - decimalToPercent(previous_prelease) >= 0
                  ? 'Gained'
                  : 'Lost'}
              </div>

              <div className="text-xs leading-snug font-normal text-center">
                {Math.round(
                  ((decimalToPercent(prelease) - decimalToPercent(previous_prelease)) / 100) *
                    bed_count
                )}{' '}
                {pluralizeWord(
                  Math.round(
                    ((decimalToPercent(prelease) - decimalToPercent(previous_prelease)) / 100) *
                      bed_count
                  ),
                  'contract'
                )}
                {` `}
                {decimalToPercent(prelease) - decimalToPercent(previous_prelease) >= 0
                  ? 'Gained'
                  : 'Lost'}
              </div>
            </React.Fragment>
          }
        />

        <HeaderStat
          title="Occupancy"
          value={
            <React.Fragment>
              <div>
                <span className="inline-block">{previousPropertySurvey.occupancy}%</span>
                <span className="inline-block align-middle px-2">
                  <Icon.ArrowRight size="26" />
                </span>
                <span className="inline-block font-normal">
                  {decimalToPercent(values.property_survey.occupancy / 100)}%
                </span>
              </div>

              <div className="text-xs leading-snug font-normal text-center">
                {parseFloat(
                  (
                    decimalToPercent(values.property_survey.occupancy / 100) -
                    previousPropertySurvey.occupancy
                  ).toFixed(4)
                )}
                %{` `}
                {values.property_survey.occupancy - previousPropertySurvey.occupancy >= 0
                  ? 'Gained'
                  : 'Lost'}
              </div>

              <div className="text-xs leading-snug font-normal text-center">
                {Math.round(occupants - previous_occupants)}{' '}
                {pluralizeWord(Math.round(occupants - previous_occupants), 'occupant')}
                {` `}
                {occupants - previous_occupants >= 0 ? 'Gained' : 'Lost'}
              </div>
            </React.Fragment>
          }
        />

        <HeaderStat
          title="Bed Count"
          value={unitMixes.map(u => u.beds).reduce((a, c) => a + c, 0)}
        />
      </HeaderStatsContainer>

      <div className="mb-12 px-4">
        <PropertySurveyFormFields
          values={values}
          errors={errors}
          setFieldValue={setFieldValue}
          initialValues={initialValues}
        />
      </div>

      <div className="mb-12 px-4">
        <PropertySurveyUnitMixRateFields
          values={values}
          errors={errors}
          unitMixes={unitMixes}
          setFieldValue={setFieldValue}
          initialValues={initialValues}
        />
      </div>

      <div>
        <PropertySurveyFormIncentivesFields
          values={values}
          errors={errors}
          unitMixes={unitMixes}
          incentives={incentives}
          setFieldValue={setFieldValue}
          propertyId={initialValues.property_survey.property_id}
          onIncentiveUpdate={onIncentiveUpdate}
        />
      </div>

      <div>
        <PropertySurveyFormPropertyFeesFields
          fees={fees}
          propertyFees={propertyFees}
          values={values}
          onPropertyFeeUpdate={onPropertyFeeUpdate}
          propertyId={initialValues.property_survey.property_id}
        />
      </div>

      {memoizedErrors.length > 0 && (
        <ErrorBlock
          message={`We are having trouble validating your property survey submission. To fix these errors,
        please review the errors displayed, apply the necessary changes, and re-submit. If there
        are no errors displayed on the page visually, please re-submit again.`}
          errors={memoizedErrors}
        />
      )}

      <Button type="submit" variant="primary" size="lg" block isDisabled={isSubmitting}>
        Submit
      </Button>
    </form>
  );
};

PropertySurveyForm.propTypes = {
  values: PropTypes.shape({
    property_survey: PropTypes.shape({
      prelease: PropTypes.number,
      date: PropTypes.string,
      occupancy: PropTypes.number,
    }),
  }).isRequired,
  incentives: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fees: PropTypes.instanceOf(Array).isRequired,
  onIncentiveUpdate: PropTypes.func,
  propertyFees: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onPropertyFeeUpdate: PropTypes.func,
  previousPropertySurvey: PropTypes.shape({
    prelease: PropTypes.number,
    date: PropTypes.string,
    occupancy: PropTypes.number,
  }),
  errors: PropTypes.shape({
    property_survey: PropTypes.shape({}),
    unit_mix_rates: PropTypes.arrayOf(PropTypes.shape({})),
    incentive: PropTypes.shape({}),
    property_fee: PropTypes.shape({}),
  }).isRequired,
  initialValues: PropTypes.shape({
    property_survey: PropTypes.shape({
      property_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  unitMixes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  status: PropTypes.shape({
    message: PropTypes.string,
    code: PropTypes.string,
    errors: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

PropertySurveyForm.defaultProps = {
  onIncentiveUpdate: undefined,
  onPropertyFeeUpdate: undefined,
  previousPropertySurvey: {},
  status: undefined,
};

const ValidationSchema = Yup.object().shape({
  property_survey: Yup.object().shape({
    prelease: Yup.number()
      .required()
      .typeError('Invalid number'),
    occupancy: Yup.number()
      .required()
      .typeError('Invalid number'),
    dnr: Yup.boolean().required(),
    date: Yup.date().required(),
  }),
  unit_mix_rates: Yup.array().of(
    Yup.object().shape({
      rate: Yup.number()
        .required()
        .typeError('Invalid number'),
      is_verified: Yup.boolean().oneOf([true], 'Please Verify'),
    })
  ),
  incentive: Yup.object().shape({
    is_verified: Yup.boolean().oneOf([true], 'Please Verify'),
  }),
  property_fee: Yup.object().shape({
    is_verified: Yup.boolean().oneOf([true], 'Please Verify'),
  }),
});

const EnhancedForm = withFormik({
  validationSchema: ValidationSchema,
  enableReinitialize: true,

  handleSubmit: (values, { setSubmitting, setStatus, props }) => {
    setSubmitting(true);

    const {
      property: { id: propertyId, market_id: marketId },
    } = props;

    const {
      property_survey: { id: surveyId },
    } = values;

    const data = {
      ...values,
      property_survey: {
        ...values.property_survey,
        prelease: values.property_survey.prelease / 100.0,
      },
    };

    return api.propertySurvey
      .createOrUpdate({ propertyId, surveyId }, data)
      .then(() => {
        window.location.assign(`/markets/${marketId}/submissions`);
      })
      .catch(error => {
        setSubmitting(false);

        if (error.response) {
          const { message, code, errors } = error.response.data;
          setStatus({ message, code, errors });
        } else {
          setStatus({ message: 'An error occurred.', code: 'client_error' });
        }
      });
  },

  mapPropsToValues: ({ propertySurvey, unitMixRates }) => {
    return {
      property_survey: {
        id: propertySurvey.id,
        property_id: propertySurvey.property_id,
        date: propertySurvey.date,
        prelease: propertySurvey.prelease * 100 || 0.0,
        occupancy: propertySurvey.occupancy || 0.0,
        dnr: propertySurvey.dnr || false,
      },
      unit_mix_rates: unitMixRates.map(rate => ({ is_verified: false, ...rate })),
      property_fee: { is_verified: false },
      incentive: { is_verified: false },
    };
  },
  displayName: 'PropertySurveyForm',
});

export default EnhancedForm(PropertySurveyForm);
