import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Divider from '@mui/material/Divider';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import { Alert } from '@mui/material';
import { Box } from '@material-ui/core';
import SharedAlerts from '../../propertyCalendar/add_to_calendar_components/SharedAlerts';
import convertToYYYYMMDD from '../../propertyCalendar/helper_functions/convertToYYYYMMDD';
import convertToHHMM from '../../propertyCalendar/helper_functions/convertToHHMM';
import doesStringContainTime from '../../propertyCalendar/helper_functions/doesStringContainTime';

const OperationEvent = props => {
  return (
    <>
      <Typography variant="h6">
        <p className="mt-4 mb-2">
          {props.department} | {props.title}
        </p>
      </Typography>

      <Typography variant="body1" component="span">
        <p className="text-gray-600">
          <span className="font-semibold">Start:</span> {props.start}
        </p>
      </Typography>

      <Typography variant="body1" component="span">
        <p className="text-gray-600">
          <span className="font-semibold">End:</span> {props.end}
        </p>
      </Typography>
      <Typography variant="body1" component="span">
        <p className="text-gray-600">
          <span className="font-semibold">Visibility:</span> {props.visibility}
        </p>
      </Typography>
      <Typography variant="body1" component="span">
        <p className="text-gray-600">
          <span className="font-semibold">Event Type: </span>
          {props.eventType}
        </p>
      </Typography>
      {props.targetAudience && (
        <Typography variant="body1" component="span">
          <p className="text-gray-600">
            <span className="font-semibold">Target Audience: </span> {props.targetAudience}
          </p>
        </Typography>
      )}
      {props.trainingMaterial && (
        <Typography variant="body1" component="span">
          <p className="text-gray-600">
            <span className="font-semibold">Training Material: </span> {props.trainingMaterial}
          </p>
        </Typography>
      )}
      {props.communicationType && (
        <Typography variant="body1" component="span">
          <p className="text-gray-600">
            <span className="font-semibold">Communication Type: </span> {props.communicationType}
          </p>
        </Typography>
      )}
      {props.engagementDetails && (
        <Typography variant="body1" component="span">
          <p className="text-gray-600">
            <span className="font-semibold">Engagement Strategy: </span> {props.engagementStrategy}
          </p>
        </Typography>
      )}
      <Divider sx={{ marginBottom: '12px' }} />
      <Typography component="span" color="textPrimary" variant="body1">
        <p
          className="text-gray-600 mb-1"
          style={{ fontSize: '18px', textDecoration: 'underline', color: 'gray' }}
        >
          Event Details
        </p>
      </Typography>
      <Typography variant="body1" gutterBottom>
        <p className="text-gray-600">
          <span className="font-semibold">Approval Notes:</span> {props.approvalNotes}
        </p>
      </Typography>
      <Typography variant="body1" gutterBottom>
        <p className="text-gray-600">
          <span className="font-semibold">Description:</span> {props.description}
        </p>
      </Typography>
      <Divider sx={{ marginBottom: '12px' }} />
      {props.associatedDates && (
        <>
          <Typography
            component="p"
            variant="body1"
            color="textPrimary"
            className="text-gray-600 mb-1"
            style={{ fontSize: '18px', textDecoration: 'underline', color: 'gray' }}
          >
            Associated Dates
          </Typography>
          {Object.keys(props.associatedDates).map(
            dateName =>
              props.associatedDates[dateName] && (
                <Typography variant="body1" component="span" gutterBottom>
                  <p className="text-gray-600">
                    <span className="font-semibold"> {formatString(dateName)}: </span>
                    {changeDateFormat(props.associatedDates[dateName])}
                  </p>
                </Typography>
              )
          )}
        </>
      )}
    </>
  );
};

function formatString(str) {
  return str
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

function changeDateFormat(dateStr) {
  const [year, month, day] = dateStr.split('-');
  return `${month}/${day}/${year}`;
}

OperationEvent.propTypes = {
  department: PropTypes.string,
  title: PropTypes.string,
  start: PropTypes.string,
  end: PropTypes.string,
  visibility: PropTypes.string,
  eventType: PropTypes.string,
  targetAudience: PropTypes.string,
  trainingMaterial: PropTypes.string,
  communicationType: PropTypes.string,
  engagementDetails: PropTypes.string,
  engagementStrategy: PropTypes.string,
  approvalNotes: PropTypes.string,
  description: PropTypes.string,
  associatedDates: PropTypes.object,
};

export default OperationEvent;
