/* eslint-disable */

const MAP_STYLES = [
  {
    featureType: 'poi.school',
    elementType: 'geometry',
    stylers: [{ color: '#FFDA00' }, { lightness: 65 }],
  },
];

const MAP_ICON_PURPLE = {
  path:
    'M256,0C114.561,0,0,114.623,0,256c0,57.431,18.909,110.456,50.854,153.167l164.208,284.421   c0.953,1.835,1.906,3.661,3.076,5.337l0.364,0.63l0.097-0.054c8.391,11.596,21.888,19.157,37.539,19.157   c14.117,0,26.439-6.427,34.896-16.329l0.435,0.239l1.622-2.811c2.394-3.235,4.521-6.675,6.055-10.486l160.871-278.659   C492.621,367.645,512,314.096,512,256C512,114.623,397.439,0,256,0z M253.469,385.782c-69.691,0-126.134-56.474-126.134-126.135   c0-69.651,56.442-126.134,126.134-126.134s126.134,56.482,126.134,126.134S323.16,385.782,253.469,385.782z',
  fillColor: '#A150EC',
  fillOpacity: 1,
  strokeWeight: 1,
  strokeColor: '#888888',
  scale: 0.035,
};

const MAP_ICON_YELLOW = {
  path:
    'M256,0C114.561,0,0,114.623,0,256c0,57.431,18.909,110.456,50.854,153.167l164.208,284.421   c0.953,1.835,1.906,3.661,3.076,5.337l0.364,0.63l0.097-0.054c8.391,11.596,21.888,19.157,37.539,19.157   c14.117,0,26.439-6.427,34.896-16.329l0.435,0.239l1.622-2.811c2.394-3.235,4.521-6.675,6.055-10.486l160.871-278.659   C492.621,367.645,512,314.096,512,256C512,114.623,397.439,0,256,0z M253.469,385.782c-69.691,0-126.134-56.474-126.134-126.135   c0-69.651,56.442-126.134,126.134-126.134s126.134,56.482,126.134,126.134S323.16,385.782,253.469,385.782z',
  fillColor: '#fcc133',
  fillOpacity: 1,
  strokeWeight: 1,
  strokeColor: 'goldenrod',
  scale: 0.035,
};

const MAP_ICON_RED = {
  path:
    'M256,0C114.561,0,0,114.623,0,256c0,57.431,18.909,110.456,50.854,153.167l164.208,284.421   c0.953,1.835,1.906,3.661,3.076,5.337l0.364,0.63l0.097-0.054c8.391,11.596,21.888,19.157,37.539,19.157   c14.117,0,26.439-6.427,34.896-16.329l0.435,0.239l1.622-2.811c2.394-3.235,4.521-6.675,6.055-10.486l160.871-278.659   C492.621,367.645,512,314.096,512,256C512,114.623,397.439,0,256,0z M253.469,385.782c-69.691,0-126.134-56.474-126.134-126.135   c0-69.651,56.442-126.134,126.134-126.134s126.134,56.482,126.134,126.134S323.16,385.782,253.469,385.782z',
  fillColor: '#e74c3c',
  fillOpacity: 1,
  strokeWeight: 1,
  strokeColor: '#c0392b',
  scale: 0.035,
};

const DTC_RANGES = {
  '1': {
    radius: 0,
    text: 'Center of Campus',
  },
  '2': {
    radius: 804.672,
    text: '0.5 Miles',
  },
  '3': {
    radius: 1609.344,
    text: '1 Mile',
  },
  '4': {
    radius: 2414.016,
    text: '1.5 Miles',
  },
  '5': {
    radius: 3218.688,
    text: '2 Miles',
  },
};

$(document).ready(function() {
  // If there's a map on the page
  if ($('#js-map').length) {
    const $id = $('#js-map').data('property-id');
    const iconColors = { yellow: MAP_ICON_YELLOW, red: MAP_ICON_RED, purple: MAP_ICON_PURPLE };
    const $mapEl = document.getElementById('js-map');
    let map, $preleaseData, $locations, $mainProperty;
    initMap();

    /**
     * initMap
     * Gets json object with property and prelease data
     */
    function initMap() {
      $.get(`/map_json/${$id}`, function(data) {
        $locations = data.locations;
        $preleaseData = data.preleaseData;
        $mainProperty = data.property;
        $market = data.market;
        buildMap();
      });
    }
    /**
     * buildMap
     * Creates map markers and popup windows
     */
    function buildMap() {
      map = new google.maps.Map($mapEl, {
        center: {
          lat: parseFloat($mainProperty.address.latitude),
          lng: parseFloat($mainProperty.address.longitude),
        },
        zoom: 13,
        scrollwheel: false,
        styles: MAP_STYLES,
      });

      if ($market.address.latitude && $market.address.longitude) {
        Object.keys(DTC_RANGES).forEach(dtc => {
          const dtcRange = DTC_RANGES[dtc];
          const circle = new google.maps.Circle({
            center: {
              lat: parseFloat($market.address.latitude),
              lng: parseFloat($market.address.longitude),
            },
            strokeColor: '#808080',
            strokeOpacity: 0.35,
            strokeWeight: 1,
            fillOpacity: 0.0,
            map: map,
            radius: dtcRange.radius,
          });

          const radiusOffset = new google.maps.geometry.spherical.computeOffset(
            circle.getCenter(),
            dtcRange.radius,
            0
          );

          const radiusMarker = new google.maps.Marker({
            position: {
              lat: radiusOffset.lat(),
              lng: radiusOffset.lng(),
            },
            icon: {
              path: google.maps.SymbolPath.CIRCLE,
              strokeColor: '#808080',
              fillColor: '#808080',
              fillOpacity: 1,
              scale: 2.25,
              labelOrigin: new google.maps.Point(0, -8),
            },
            type: 'info',
            zIndex: -9999,
            map: map,
          });

          

          const infowindow = new google.maps.InfoWindow({ content: dtcRange.text });

          radiusMarker.addListener('click', function() {
            infowindow.open(map, radiusMarker);
          });
        });
        const legend = document.getElementById("legend");

          const iconInfo = [
            {
              color: MAP_ICON_YELLOW.fillColor,
              description: 'Scion Property',
            },
            {
              color: MAP_ICON_PURPLE.fillColor,
              description: 'Competitor - Below Scion Prelease %',
            },
            {
              color: MAP_ICON_RED.fillColor,
              description: 'Competitor - Above Scion Prelease %',
            },
          ]
          iconInfo.forEach((icon, index) => {
            const div = document.createElement("div");
            div.innerHTML = `<i class="material-icons" style="width: 20px; height: 20px; margin-right: 5px; color: ${icon.color}"/>place</i> ${icon.description}`;
            legend.appendChild(div);
          });
          map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(legend);
      }

      var markers = $locations.map(function(location, i) {
        let prelease = !$preleaseData[parseInt(location.id)]
          ? 0.0
          : (100 * $preleaseData[parseInt(location.id)]).toFixed(2);
        let contentString = buildContentString(location, prelease);
        let infowindow = new google.maps.InfoWindow({ content: contentString });
        let color = determineColor(location, prelease);

        let newMarker = new google.maps.Marker({
          position: {
            lat: parseFloat(location.address.latitude),
            lng: parseFloat(location.address.longitude),
          },
          map: map,
          icon: iconColors[color],
        });

        newMarker.addListener('click', function() {
          infowindow.open(map, newMarker);
        });

        return newMarker;
      });
    }

    /**
     * buildContentString
     * Builds the content for the marker's popup window.
     */
    function buildContentString(location, prelease) {
      const roundedDtc = location.distance_to_campus
        ? parseFloat(location.distance_to_campus).toFixed(2)
        : 0;
      return (
        '<div id="content"><h5><a class="blue-text" target="_blank" href="/properties/' +
        location.id +
        '">' +
        location.name +
        '</a></h5><p><b>Prelease: </b> ' +
        prelease +
        '%</p><p><b>Distance to Campus:</b> ' +
        roundedDtc +
        ' mi.</p><p>' +
        location.address.street +
        '<br>' +
        location.address.city +
        ', ' +
        location.address.state +
        ', ' +
        location.address.postal_code +
        '</p></div>'
      );
    }

    /**
     * determineColor
     * Sets the color of the map marker based on prelease.
     */
    function determineColor(property, prelease) {
      const mainPrelease = 100 * $preleaseData[$mainProperty.id].toFixed(4);
      if (property.id === $mainProperty.id) {
        return 'yellow';
      } else {
        return prelease < mainPrelease ? 'purple' : 'red';
      }
    }
  }
});
