import * as types from './types';

const pivotDimensionsReducer = (pivotDimensions = {}, action) => {
  if (action.type === types.SELECT) {
    const { dimension } = action.payload;
    if (pivotDimensions[dimension.id]) {
      return {};
    } else {
      return { [dimension.id]: dimension };
    }
  }

  return pivotDimensions;
};

export default pivotDimensionsReducer;
