import React from 'react';
import { FormControl, Grid, TextField, Typography } from '@material-ui/core';

// Components
import TierHeader from './TierHeader';

const TierDemo = () => {
  const cellStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const trueValue = true;

  return (
    <div className="overflow-x-auto inline-grid xs:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-7 gap-3 mt-2 mb-2">
      <Grid container className="grid grid-cols-1 border rounded bg-gray-200">
        <TierHeader tierStatus="past_demo" isEditing={trueValue} />
        <Grid item xs={12} className="text-center p-1 bg-purple-300" style={cellStyle}>
          <Typography style={{ fontWeight: 500 }} variant="subtitle1">
            Prior Approved Target
          </Typography>
        </Grid>
        <Grid item xs={12} className="text-center p-1 bg-gray-300" style={cellStyle}>
          <Typography style={{ fontWeight: 600 }} variant="subtitle1">
            Signed Actuals
          </Typography>
        </Grid>
        <Grid item xs={12} className="text-center text-gray-600 bg-gray-100 p-1" style={cellStyle}>
          <Typography style={{ fontWeight: 400 }} variant="caption">
            Cumulative Signed Rate
          </Typography>
        </Grid>
      </Grid>

      <Grid container className="grid grid-cols-1 border rounded bg-blue-200">
        <TierHeader tierStatus="future_demo" isEditing={trueValue} />
        <Grid item xs={12} className="text-center p-1 bg-purple-300" style={cellStyle}>
          <Typography style={{ fontWeight: 500 }} variant="subtitle1">
            Prior Approved Target
          </Typography>
        </Grid>
        <Grid item xs={12} className="text-center p-1 bg-yellow-300" style={cellStyle}>
          <FormControl width="90%">
            <TextField
              name="tierRate"
              type="text"
              required
              InputProps={{
                inputProps: {
                  style: { backgroundColor: 'white' },
                },
              }}
              size="small"
              variant="outlined"
              defaultValue="Adjusted Target"
              disabled={trueValue}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} className="text-center text-gray-600 bg-gray-100 p-1" style={cellStyle}>
          <Typography style={{ fontWeight: 400 }} variant="caption">
            Cumulative Signed + Target Rates
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default TierDemo;
