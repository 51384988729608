/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

const TableGlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter, onChange }) => {
  const count = preGlobalFilteredRows.length;

  return (
    <span className="flex text-gray-700 items-center">
      <span className="px-2 text-gray-800">Search:</span>
      <input
        className="p-1 flex-grow outline-none"
        value={globalFilter || ''}
        onChange={e => {
          setGlobalFilter(e.target.value || undefined);
          onChange && onChange(e);
        }}
        placeholder={`${count} records...`}
      />
    </span>
  );
};

TableGlobalFilter.propTypes = {
  preGlobalFilteredRows: PropTypes.arrayOf(PropTypes.any).isRequired,
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func.isRequired,
  onChange: PropTypes.func,
};

TableGlobalFilter.defaultProps = {
  globalFilter: undefined,
  onChange: undefined,
};

export default TableGlobalFilter;
