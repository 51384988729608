import React from 'react';
import PropTypes from 'prop-types';

const ErrorBlock = ({ errors }) => {
  return (
    <div className="mt-8 p-4 bg-red-100 border border-red-600 rounded mb-6 leading-normal">
      <ul className="w-full px-8 text-red-600 list-disc">
        {errors &&
          errors.map(error => (
            <li key={error} className="p-1">
              {error}
            </li>
          ))}
      </ul>
    </div>
  );
};

ErrorBlock.propTypes = {
  errors: PropTypes.instanceOf(Array),
};

ErrorBlock.defaultProps = {
  errors: [],
};

export default ErrorBlock;
