import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container", "spinner", "nav"]

  initialize() {
    this.fetchInitialContent()
    this.loading = false
  }

  handleTabClick(event) {
    event.preventDefault();
    let $target = $(event.target)

    this.setActiveNav($target)

    if ($target.attr('data-content')) {
      this.content = JSON.parse($target.data('content'))
    } else {
      this.loading = true
      fetch($target.attr('href'), {credentials: 'same-origin'})
        .then(response => response.text())
        .then(html => {
          $target.attr('data-content', JSON.stringify(html))
          this.loading = false
          this.content = html
        })
        .catch(error => {
          this.loading = false
          console.error(error)
        })
    }
  }

  fetchInitialContent() {
    let $target = $(this.navTarget).find('.active')
    fetch($target.attr('href'), {credentials: 'same-origin'})
      .then(response => response.text())
      .then(html => {
        $target.attr('data-content', JSON.stringify(html))
        this.content = html
      })
      .catch(error => {
        console.error(error)
      })
  }

  setActiveNav($element) {
    $(this.navTarget).find('.active').removeClass('active')
    $element.addClass('active')
  }

  get loading() {
    return this.data.get('loading')
  }

  set loading(value) {
    this.data.set('loading', value)
    $(this.spinnerTarget).toggle(this.loading)
  }

  get content() {
    return $(this.containerTarget).html()
  }

  set content(value) {
    $(this.containerTarget).html(value)
  }
}
