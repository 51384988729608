import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

const AppAlert = ({ title, message, severity }) => {
  const [open, setOpen] = useState(true);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Snackbar
      className="w-full sm:w-1/2 mb-12 md:mb-4"
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <Alert className="w-full mx-2" onClose={handleClose} severity={severity}>
        {title && <AlertTitle>{title}</AlertTitle>}
        {message}
      </Alert>
    </Snackbar>
  );
};

AppAlert.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  severity: PropTypes.oneOf(['info', 'warning', 'error', 'success']).isRequired,
};

AppAlert.defaultProps = {
  title: undefined,
};

export default AppAlert;
