import React from 'react';
import PropTypes from 'prop-types';

import Button from '../shared/Button';

const Tab = ({ isSelected, onClick, children }) => {
  const buttonVariant = isSelected ? 'primary' : 'primaryOutline';

  return (
    <Button
      className={{
        'rounded-sm': false,
        'mr-2': true,
      }}
      onClick={onClick}
      variant={buttonVariant}
    >
      {children}
    </Button>
  );
};

Tab.displayName = 'Tab';

Tab.propTypes = {
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

Tab.defaultProps = {
  isSelected: false,
  onClick: () => undefined,
  children: null,
};

export default Tab;
