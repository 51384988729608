import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Avatar,
  Drawer,
  Badge,
  Chip,
  Divider,
} from '@material-ui/core';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import moment from 'moment';

import DateChip from './DateChip';
import DictionaryTerms from './DictionaryTerms';
import Search from '../search';
import Filter from '../filter';
import AutoRefresh from '../autoRefresh';
import BrokenImage from './BrokenImage';
import ProfileImage from './ProfileImage';
import AccountMenu from './AccountMenu';
import CountdownTimer from '../autoRefresh/CountdownTimer';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'white',
    boxShadow: 'none',
    borderBottom: '1px solid #e2e8f0',
    [theme.breakpoints.down('sm')]: {
      top: 'auto',
      bottom: '0',
    },
  },
  rootOpen: {
    marginLeft: '240px',
    width: 'calc(100% - 240px)',
  },
  sidebarButton: {
    marginRight: theme.spacing(2),
  },
  menuItems: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-evenly',
    },
  },
  menuButton: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2),
    },
  },
  userButtons: {},
  userButton: {
    fontWeight: 300,
    'text-transform': 'capitalize',
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  userAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: 10,
    marginRight: theme.spacing(1),
  },
  drawer: {
    width: 480,
    maxWidth: '100%',
    flexShrink: 0,
  },
  drawerPaper: {
    width: 480,
    maxWidth: '100%',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  drawerBody: {
    padding: theme.spacing(0, 1),
  },
  filterBadge: {
    '& .MuiBadge-colorPrimary': {
      backgroundColor: 'red',
    },
  },
  filterItem: {
    textTransform: 'capitalize',
    marginRight: theme.spacing(1),
  },
  filterItemChanged: {
    color: 'red',
  },
  menuDivider: {
    alignSelf: 'stretch',
    height: 'auto',
  },
}));

const PanelComponent = ({ component, ...props }) => {
  switch (component) {
    case 'search':
      return <Search {...props} />;
    case 'filter':
      return <Filter {...props} />;
    default:
      return null;
  }
};

PanelComponent.propTypes = {
  component: PropTypes.string,
};

PanelComponent.defaultProps = {
  component: null,
};

const AppHeader = ({
  menuOpen,
  handleMenuOpen,
  page_filters,
  current,
  can_edit_representatives: canEditRepresentatives,
  can_edit_dictionary_terms: canEditDictionaryTerms,
  user_logo_html,
  ...props
}) => {
  const classes = useStyles();
  const [panel, setPanel] = useState(null);
  const [autoRefreshToggled, setAutoRefreshToggled] = useState(false);
  const theme = useTheme();
  const mdBreakpointMatch = useMediaQuery(theme.breakpoints.up('md'));

  const url = new URL(window.location.href);

  const [accountMenuAnchorEl, setAccountMenuAnchorEl] = useState(null);
  const accountMenuOpen = Boolean(accountMenuAnchorEl);
  const handleAccountMenuClick = event => {
    setAccountMenuAnchorEl(event.currentTarget);
  };
  const handleAccountMenuClose = () => {
    setAccountMenuAnchorEl(null);
  };

  useEffect(() => {
    setAutoRefreshToggled(url.searchParams.has('auto-refresh'));
  }, []);

  const toggleRefresh = () => {
    setAutoRefreshToggled(!autoRefreshToggled);
    if (url.searchParams.has('auto-refresh')) {
      url.searchParams.delete('auto-refresh');
      window.location.href = url.href;
    }
  };

  const renderPanel = () => {
    return (
      <PanelComponent component={panel} page_filters={page_filters} current={current} {...props} />
    );
  };

  const hasNonDefaultFilters = () => {
    if (page_filters.date.is_permitted && current.date !== page_filters.date.max) {
      return true;
    }

    if (
      page_filters.investment_portfolio_scope.is_permitted &&
      page_filters.investment_portfolio_scope.value !== 'all'
    ) {
      return true;
    }

    if (
      page_filters.rvp_scope.is_permitted && 
      page_filters.rvp_scope.value !== 'all'
    ) {
      return true;
    }

    if (
      page_filters.rm_scope.is_permitted &&
      page_filters.rm_scope.value !== 'all'
    ) {
      return true;
    }

    if (
      page_filters.sm_scope.is_permitted &&
      page_filters.sm_scope.value !== 'all'
    ) {
      return true;
    }

    if (
      page_filters.property_type_scope.is_permitted &&
      page_filters.property_type_scope.value !== 'all'
    ) {
      return true;
    }

    if (
      page_filters.active_user.is_permitted &&
      current.active_user.id !== current.logged_in_user.id
    ) {
      return true;
    }

    if (
      page_filters.property_filter_scope.is_permitted &&
      page_filters.property_filter_scope.value !== 'current'
    ) {
      return true;
    }

    return false;
  };

  const renderChipLabel = (label, value, isChanged = false) => {
    return (
      <>
        <strong>{label}: </strong>
        <span
          className={classNames({
            [classes.filterItemChanged]: isChanged,
          })}
        >
          {value}
        </span>
      </>
    );
  };

  return (
    <>
      <AppBar
        position="fixed"
        color="default"
        className={classNames(classes.root, { [classes.rootOpen]: menuOpen })}
      >
        <Toolbar>
          {!menuOpen && (
            <>
              <IconButton
                edge="start"
                color="default"
                aria-label="menu"
                onClick={handleMenuOpen}
                className={classes.sidebarButton}
              >
                <MenuIcon />
              </IconButton>
              {!mdBreakpointMatch && (
                <Divider orientation="vertical" className={classes.menuDivider} />
              )}
            </>
          )}

          <div className={classes.menuItems}>
            <IconButton
              color="inherit"
              className={classes.menuButton}
              onClick={() => setPanel('search')}
            >
              <SearchIcon />
            </IconButton>

            {!mdBreakpointMatch && (
              <Divider orientation="vertical" className={classes.menuDivider} />
            )}

            <IconButton
              color="inherit"
              className={classes.menuButton}
              onClick={() => setPanel('filter')}
            >
              <Badge
                className={classes.filterBadge}
                color="primary"
                overlap="circular"
                variant="dot"
                invisible={!hasNonDefaultFilters()}
              >
                <FilterListIcon />
              </Badge>
            </IconButton>

            {!mdBreakpointMatch && (
              <Divider orientation="vertical" className={classes.menuDivider} />
            )}

            <>
              {page_filters.date.is_permitted && (
                <DateChip
                  date={page_filters.date.value}
                  minDate={page_filters.date.min}
                  label={renderChipLabel(
                    'Date',
                    moment(page_filters.date.value).format('MM/DD/YYYY'),
                    moment().diff(page_filters.date.value, 'days') !== 0
                  )}
                  size="small"
                  variant="outlined"
                  className={classes.filterItem}
                  onChange={date => window.location.assign(`/filter/date_scope?date=${date}`)}
                />
              )}

              {page_filters.property_scope.is_permitted && (
                <Chip
                  label={renderChipLabel('Properties', page_filters.property_scope.value)}
                  size="small"
                  variant="outlined"
                  className={classes.filterItem}
                />
              )}
            </>

            {!mdBreakpointMatch && (
              <Divider orientation="vertical" className={classes.menuDivider} />
            )}

            {mdBreakpointMatch && (
              <IconButton
                color="inherit"
                className={classes.menuButton}
                onClick={() => toggleRefresh()}
              >
                <AutoRefresh toggled={autoRefreshToggled} />
              </IconButton>
            )}
            {mdBreakpointMatch && autoRefreshToggled && <CountdownTimer url={url} />}

            {mdBreakpointMatch && (
              <DictionaryTerms canEditDictionaryTerms={canEditDictionaryTerms} />
            )}

            {!mdBreakpointMatch && (
              <Button href="/users/settings" className={classes.userButton}>
                <Avatar className={classes.userAvatar}>
                  {user_logo_html && (
                    /* eslint-disable */
                    <ProfileImage
                      logoUrl={user_logo_html.substring(
                        user_logo_html.indexOf('src="') + 5,
                        user_logo_html.indexOf('" />')
                      )}
                    />
                    /* eslint-enable */
                  )}
                  {!user_logo_html && <BrokenImage />}
                </Avatar>
              </Button>
            )}
          </div>

          {mdBreakpointMatch && (
            <div className={classes.userButtons}>
              {/* <Button href="/users/settings" className={classes.userButton}> */}
              <Button onClick={handleAccountMenuClick} className={classes.userButton}>
                <Avatar className={classes.userAvatar}>
                  {user_logo_html && (
                    /* eslint-disable */
                    <ProfileImage
                      logoUrl={user_logo_html.substring(
                        user_logo_html.indexOf('src="') + 5,
                        user_logo_html.indexOf('" />')
                      )}
                    />
                    /* eslint-enable */
                  )}
                  {!user_logo_html && <BrokenImage />}
                </Avatar>

                <span className="">
                  {current.active_user.first_name} {current.active_user.last_name}
                </span>
              </Button>
              <AccountMenu
                accountMenuAnchorEl={accountMenuAnchorEl}
                accountMenuOpen={accountMenuOpen}
                current={current}
                handleAccountMenuClose={handleAccountMenuClose}
                canEditRepresentatives={canEditRepresentatives}
              />
            </div>
          )}
        </Toolbar>

        <Drawer
          anchor="left"
          open={!!panel}
          className={classes.drawer}
          onClose={() => setPanel(null)}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={() => setPanel(null)} color="inherit">
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <div className={classes.drawerBody}>{renderPanel()}</div>
        </Drawer>
      </AppBar>
    </>
  );
};

AppHeader.propTypes = {
  menuOpen: PropTypes.bool.isRequired,
  handleMenuOpen: PropTypes.func.isRequired,
  user_logo_html: PropTypes.string,
  can_edit_representatives: PropTypes.bool,
  can_edit_dictionary_terms: PropTypes.bool,
  current: PropTypes.shape({
    date: PropTypes.string.isRequired,
    active_user: PropTypes.shape({
      id: PropTypes.number,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }).isRequired,
    logged_in_user: PropTypes.shape({
      id: PropTypes.number,
    }),
  }).isRequired,
  page_filters: PropTypes.shape({
    date: PropTypes.shape({
      max: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      is_permitted: PropTypes.bool.isRequired,
      min: PropTypes.string.isRequired,
    }).isRequired,
    active_user: PropTypes.shape({
      is_permitted: PropTypes.bool.isRequired,
    }),
    property_scope: PropTypes.shape({
      value: PropTypes.string.isRequired,
      is_permitted: PropTypes.bool.isRequired,
    }).isRequired,
    property_filter_scope: PropTypes.shape({
      value: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string,
        })
      ),
      is_permitted: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
};

AppHeader.defaultProps = {
  can_edit_representatives: false,
  can_edit_dictionary_terms: false,
  user_logo_html: null,
};

export default AppHeader;
