/* eslint react/prop-types: 0 */
import React from 'react';
import {
  Box,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import FilterIcon from '@material-ui/icons/FilterList';

const Item = ({ attribute, selectedAttributes, addAttribute, addFilter }) => (
  <Box pl={2}>
    <ListItem
      role={undefined}
      selected={!!Object.keys(selectedAttributes).find(attributeId => attributeId === attribute.id)}
      dense
      button
      onClick={() => addAttribute(attribute)}
    >
      <ListItemText id={attribute.name} primary={attribute.label} />
      {addFilter && (
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="add-filter"
            onClick={() => addFilter(attribute.id)}
            size="small"
          >
            <FilterIcon fontSize="inherit" />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  </Box>
);

export default Item;
