import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import humps from 'humps';
import api from '../../api';
import ErrorBlock from '../../shared/ErrorBlock';

const ScheduledBillingConfirmationSchema = yup.object().shape({});

const ScheduledBillingConfirmation = ({
  confirmed_by: confirmedBy,
  property_name: propertyName,
  is_confirming_init: isConfirmingInit,
  sbc: {
    id: id,
    created_at: createdAt,
    concession_rate: concessionRate,
    customer_id: customerId,
    customer_name: customerName,
    lease_id: leaseId,
    lease_interval_id: leaseIntervalId,
    market_rate: marketRate,
    property_id: propertyId,
  },
}) => {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      acknowledge: false,
      concessionRate: concessionRate,
      customerId: customerId,
      customerName: customerName,
      leaseId: leaseId,
      leaseIntervalId: leaseIntervalId,
      marketRate: marketRate,
      propertyId: propertyId,
    },
    validationSchema: ScheduledBillingConfirmationSchema,
  });

  const [submitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState({});
  const [checked, setChecked] = useState(false);
  const [isConfirming, setIsConfirming] = useState(isConfirmingInit);

  const onSubmit = data => {
    setSubmitting(true);
    setStatus({});

    const serializedData = humps.decamelizeKeys({ sbc: data });

    api.scheduledBillingConfirmations
      .create(serializedData)
      .then(() => {
        setSubmitting(false);
        setIsConfirming(false);
        window.location.assign(
          `/contracts/scheduled_billing_confirmations/new?lease_interval_id=${leaseIntervalId}&property_id=${propertyId}&lease_id=${leaseId}&customer_id=${customerId}&customer_name=${customerName}`
        );
      })
      .catch(e => {
        setStatus({ errors: e.response.data.errors });
        setSubmitting(false);
        throw new Error(e);
      });
  };

  const handleDelete = () => {
    setSubmitting(true);
    setStatus({});

    if (
      window.confirm(
        "Are you sure you want to delete this scheduled billing confirmation record?\nIf yes, click 'OK'."
      )
    ) {
      return api.scheduledBillingConfirmations
        .destroy({ sbcId: id })
        .then(() => window.location.assign(`/properties/${propertyId}/revenue/billing_audit`))
        .catch(error => {
          setSubmitting(false);

          if (error.response) {
            const { message, code, errors } = error.response.data;
            setStatus({ message, code, errors });
          } else {
            setStatus({ message: 'An error occurred.', code: 'client_error' });
          }
        });
    }
  };

  return (
    <Container maxWidth="md">
      <Box mb={1} mt={2}>
        <Typography
          style={{ fontWeight: 300 }}
          className="text-center text-purple-600"
          variant="h6"
        >
          {customerName} – {propertyName}
        </Typography>
      </Box>
      <Box mb={1} mt={0}>
        <Typography className="text-center" variant="h4">
          {id ? '' : 'Create'} Scheduled Billing Confirmation
        </Typography>
      </Box>
      <Box mb={3} mt={0}>
        <Typography
          style={{ fontWeight: 300 }}
          className="text-center text-yellow-700"
          variant="body1"
        >
          NOTICE: It may take up to an hour for new scheduled billing confirmations to be reflected
          in revenue dashboards/reports.
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={0} md={3} />
        <Grid item xs={12} md={3}>
          <Box>
            <Typography className="text-center" variant="subtitle2">
              Market Rate
            </Typography>
          </Box>
          <Box>
            <Typography className="text-center" variant="h2">
              ${marketRate}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box>
            <Typography className="text-center" variant="subtitle2">
              Concession Rate
            </Typography>
          </Box>
          <Box>
            <Typography className="text-center" variant="h2">
              {concessionRate < 0 && '-'}${Math.abs(concessionRate)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={0} md={3} />
      </Grid>
      {isConfirming && (
        <>
          <Divider orientation="vertical" style={{ minHeight: '20px', width: '0px' }} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="acknowledge"
                      color="primary"
                      inputRef={register}
                      size="medium"
                      checked={checked}
                      onChange={() => setChecked(!checked)}
                    />
                  }
                  label={
                    <Typography variant="body2" color="textSecondary">
                      By submitting this form, I am confirming that the current scheduled billing
                      reflects the resident&apos;s executed housing agreement and any associated
                      amendments. If the scheduled billing is incorrect, I should make the necessary
                      updates in Entrata instead.
                    </Typography>
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <TextField name="id" inputRef={register} type="hidden" value={id} />
          <TextField name="customerId" inputRef={register} type="hidden" value={customerId} />
          <TextField name="customerName" inputRef={register} type="hidden" value={customerName} />
          <TextField name="leaseId" inputRef={register} type="hidden" value={leaseId} />
          <TextField name="marketRate" inputRef={register} type="hidden" value={marketRate} />
          <TextField
            name="concessionRate"
            inputRef={register}
            type="hidden"
            value={concessionRate}
          />
          <TextField
            name="leaseIntervalId"
            inputRef={register}
            type="hidden"
            value={leaseIntervalId}
          />
          <TextField name="propertyId" inputRef={register} type="hidden" value={propertyId} />
        </Grid>

        {!isConfirming && (
          <Box mb={-1} mt={3}>
            <Typography className="text-center text-gray-500 font-thin" variant="subtitle1">
              Confirmed by {confirmedBy} on{' '}
              {new Date(Date.parse(createdAt)).toLocaleDateString('en-US', {
                month: 'numeric',
                day: 'numeric',
                year: 'numeric',
                hour12: true,
                hour: 'numeric',
                minute: '2-digit',
                timeZoneName: 'short',
              })}
            </Typography>
          </Box>
        )}

        <Box mt={2}>
          <Grid container spacing={2} className="justify-center">
            {isConfirming && (
              <Grid item>
                <Button
                  color="primary"
                  disabled={!checked || submitting}
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Confirm Scheduled Billing
                </Button>
              </Grid>
            )}

            {!isConfirming && (
              <Grid item>
                <Button
                  color="secondary"
                  disabled={submitting}
                  onClick={() => {
                    handleDelete();
                  }}
                  size="large"
                  type="button"
                  variant="contained"
                >
                  Delete Confirmation
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
        {status.constructor === Object && Object.keys(status).length !== 0 && (
          <ErrorBlock errors={status.errors} />
        )}
      </form>
    </Container>
  );
};

ScheduledBillingConfirmation.propTypes = {
  confirmed_by: PropTypes.string,
  property_name: PropTypes.string.isRequired,
  is_confirming_init: PropTypes.bool.isRequired,
  sbc: PropTypes.shape({
    concession_rate: PropTypes.number,
    created_at: PropTypes.string,
    customer_id: PropTypes.number,
    customer_name: PropTypes.string,
    id: PropTypes.number,
    lease_id: PropTypes.number,
    lease_interval_id: PropTypes.number.isRequired,
    market_rate: PropTypes.number,
    property_id: PropTypes.number,
  }).isRequired,
};

ScheduledBillingConfirmation.defaultProps = {
  confirmed_by: '',
};

export default ScheduledBillingConfirmation;
