import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const LookerEmbed = ({ embedSrc, height }) => {
  const [iframeHeight, setIframeHeight] = useState(
    height ? height : window.innerHeight - document.querySelector('.MuiToolbar-root').offsetHeight
  );

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
  }, []);

  const receiveMessage = event => {
    var data;

    if (event.origin === 'https://scion.cloud.looker.com') {
      if (typeof event.data === 'string') {
        data = JSON.parse(event.data);
      } else {
        data = event.data;
      }

      if (data.type == 'page:properties:changed') {
        setIframeHeight(data.height + 50 + 'px');
      }
    }
  };

  return (
    <div id="looker-iframe-embed">
      <iframe
        frameBorder="0"
        height={iframeHeight}
        overflow="hidden"
        scrolling="no"
        src={embedSrc}
        style={{ width: '1px', minWidth: '100%', overflowY: 'hidden' }}
        title="Looker dashboard embed"
      />
    </div>
  );
};

LookerEmbed.propTypes = {
  embedSrc: PropTypes.string.isRequired,
  height: PropTypes.number,
};

LookerEmbed.defaultProps = {
  height: null,
};

export default LookerEmbed;
