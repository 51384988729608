import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './styles';

const variantStyles = variant => {
  return styles.buttons[variant] || styles.buttons.primary;
};

const sizeStyles = size => {
  return styles.buttonSizes[size] || styles.buttonSizes.sm;
};

const Button = ({
  component,
  type,
  variant,
  size,
  className,
  block,
  isDisabled,
  href,
  ...props
}) => {
  const classes = classNames(
    { 'rounded-sm': true, 'cursor-pointer': true, 'font-light': true, ...className },
    variantStyles(variant),
    sizeStyles(size),
    block && 'w-full',
    isDisabled && 'opacity-10 cursor-not-allowed'
  );

  let ComponentProp = component;
  if (ComponentProp === 'button' && href) {
    ComponentProp = 'a';
  }

  let buttonProps = {};
  if (ComponentProp === 'button') {
    buttonProps.type = type;
    buttonProps.disabled = isDisabled;
  } else if (ComponentProp === 'a') {
    buttonProps.href = href;
  }

  // eslint-disable-next-line react/button-has-type
  return <ComponentProp className={classes} {...buttonProps} {...props} />;
};

Button.propTypes = {
  component: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  type: PropTypes.string,
  isDisabled: PropTypes.bool,
  block: PropTypes.bool,
  href: PropTypes.string,
};

Button.defaultProps = {
  component: 'button',
  variant: 'primary',
  size: 'sm',
  className: {},
  type: 'button',
  isDisabled: false,
  block: false,
  href: null,
};

export default Button;
