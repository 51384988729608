import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactSelect from 'react-select';

import { get as objectGet } from '@utils/lib/object';
import selectStyles from '../inputs/selectStyles';
import FixRequiredSelect from './FixRequiredSelect';

const styles = {
  errorBase: 'text-red-600 p-1 my-1',
  labelBase: `text-gray-700 text-sm font-bold truncate`,
  selectAllBase: `text-gray-600 text-sm font-then ml-2 cursor-pointer border-dashed hover:text-gray-700 hover:border-b`,
  selectBase: selectStyles,
  statusBase: 'text-red-600 p-1 my-2',
};

const FormikInputSelect = ({
  allowSelectAll,
  field,
  form: { setFieldValue, status, touched, errors },
  isClearable,
  isdisabled,
  isMulti,
  label,
  labelClassName,
  options,
  required,
}) => {
  const Select = ({ ...props }) => (
    <FixRequiredSelect {...props} SelectComponent={ReactSelect} options={options} />
  );

  const onChange = (option, { action }) => {
    if (action === 'clear') {
      setFieldValue(field.name, isMulti ? [] : null);
    } else {
      setFieldValue(
        field.name,
        isMulti ? (option ? option.map(item => item.value) : []) : option.value
      );
    }
  };

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter(option => field.value.indexOf(option.value) >= 0)
        : options.find(option => option.value === field.value);
    } else {
      return isMulti ? [] : '';
    }
  };

  const handleSelectAll = () => {
    setFieldValue(
      field.name,
      options.map(item => item.value)
    );
  };

  return (
    <div>
      <div className="mb-2">
        <span className={classNames(styles.labelBase, labelClassName)}>{label}</span>

        {allowSelectAll && (
          <span
            role="button"
            tabIndex={0}
            className={classNames(styles.selectAllBase)}
            onClick={handleSelectAll}
          >
            Select All
          </span>
        )}
      </div>
      <div>
        <Select
          isClearable={isClearable}
          isDisabled={isdisabled}
          isMulti={isMulti}
          name={field.name}
          onChange={onChange}
          options={options}
          required={required}
          styles={styles.selectBase}
          value={getValue()}
        />
      </div>

      {objectGet(touched, field.name) && objectGet(status, field.name) && (
        <div className={classNames(styles.statusBase)}>{objectGet(status, field.name)}</div>
      )}

      {objectGet(touched, field.name) && objectGet(errors, field.name) && (
        <div className={classNames(styles.errorBase)}>{objectGet(errors, field.name)}</div>
      )}
    </div>
  );
};

FormikInputSelect.propTypes = {
  allowSelectAll: PropTypes.bool,
  field: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
      PropTypes.bool,
    ]),
    name: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    status: PropTypes.shape({}),
    touched: PropTypes.shape({}),
    errors: PropTypes.shape({}),
  }).isRequired,
  isClearable: PropTypes.bool,
  isdisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  label: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).required,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).required,
    })
  ),
  required: PropTypes.bool,
};

FormikInputSelect.defaultProps = {
  allowSelectAll: false,
  isClearable: false,
  isdisabled: false,
  isMulti: false,
  labelClassName: '',
  options: [],
  required: false,
};

export default FormikInputSelect;
