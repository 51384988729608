import axios from 'axios';
import { serialize } from 'object-to-formdata';

const csrfToken = document.querySelector('[name=csrf-token]').content;

axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

const API = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

API.interceptors.request.use(
  config => {
    config.params = { format: 'json', ...config.params };
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

const adminUser = {
  createOrUpdate: ({ userId }, data) => {
    const routeAction = userId ? `/${userId}` : '';
    const url = `/admin/users${routeAction}`;
    return userId ? API.put(url, data) : API.post(url, data);
  },
};

const calendar = {
  getByDate: ({ propertyId, monthStart }) => {
    if (propertyId) {
      return API.get(`/properties/${propertyId}/calendar`, {
        params: { month_start: monthStart },
      });
    } else {
      return API.get(`/calendar`, {
        params: { month_start: monthStart },
      });
    }
  },
};

const competitor_incentives = {
  getActiveByDate: ({ propertyId, date }) =>
    API.get(`/properties/${propertyId}/competitor_incentives`, {
      params: { incentive_date: date },
    }),

  editOrNew: ({ propertyId, incentiveId = null }) => {
    const routeAction = incentiveId ? `${incentiveId}/edit` : 'new';
    return API.get(`/properties/${propertyId}/competitor_incentives/${routeAction}`);
  },

  createOrUpdate: ({ propertyId, incentiveId = null }, data) => {
    const routeAction = incentiveId ? `/${incentiveId}` : '';
    const url = `/properties/${propertyId}/competitor_incentives${routeAction}`;
    return incentiveId ? API.put(url, data) : API.post(url, data);
  },
};

const crudItems = {
  getAll: params => {
    return API.get('/crud', { params: params });
  },

  editOrNew: params => {
    let requestParams = { ...params, formObject: {} }; // Set form_object to null to avoid large query string error
    const routeAction =
      Object.entries(params.formObject).length > 0 ? `${params.formObject.id}/edit` : 'new';
    return API.get(`/crud/${routeAction}`, { params: requestParams });
  },

  updateOrCreate: params => {
    let requestParams = { ...params, form_object: {} }; // Set form_object to null to avoid large query string error
    const routeAction = params.isEdit ? `/${params.form_object.id}` : '';
    const url = `/crud${routeAction}`;
    const data = serialize(params.form_object, null, null, 'crud');
    return params.isEdit
      ? API.put(url, data, { params: requestParams })
      : API.post(url, data, { params: requestParams });
  },

  destroy: params => {
    const config = {};
    config.data = params;
    return API.delete(`/crud/${params.form_object.id}`, config);
  },
};

const rateAdjustmentRequests = {
  createOrUpdate: ({ isEdit, data }) => {
    const routeAction = isEdit ? `/${data.version.id}` : '';
    const url = `/rate_adjustment_requests${routeAction}`;
    return isEdit ? API.put(url, data) : API.post(url, data);
  },
  approve: ({ data }) => {
    const url = `/rate_adjustment_requests/${data.version.id}/approve`;
    return API.put(url, data);
  },
  deny: ({ data }) => {
    const url = `/rate_adjustment_requests/${data.version.id}/deny`;
    return API.put(url, data);
  },
  destroy: ({ data }) => {
    return API.delete(`/rate_adjustment_requests/${data.version.id}`);
  },
};

const scheduledBillingConfirmations = {
  create: data => {
    return API.post('/contracts/scheduled_billing_confirmations', data);
  },
  destroy: ({ sbcId }) => {
    return API.delete(`/contracts/scheduled_billing_confirmations/${sbcId}`);
  },
};

const employmentPositionDepartments = {
  createOrUpdate: ({ id }, data) => {
    const routeAction = id ? `/${id}` : '';
    const url = `/employment_position_departments/${routeAction}`;

    return id ? API.put(url, data) : API.post(url, data);
  },
};

const employmentPositions = {
  createOrUpdate: ({ id }, data) => {
    const routeAction = id ? `/${id}` : '';
    const url = `/employment_positions/${routeAction}`;

    return id ? API.put(url, data) : API.post(url, data);
  },
};

const propertyFloorplanSeasons = {
  update: ({ propertyId, id }, data) => {
    const url = `/properties/${propertyId}/floorplan_seasons/${id}`;

    return API.put(url, data);
  },
};

const propertySurvey = {
  createOrUpdate: ({ propertyId, surveyId }, data) => {
    const routeAction = surveyId ? `/${surveyId}` : '';
    const url = `/properties/${propertyId}/survey_form${routeAction}`;

    return surveyId ? API.put(url, data) : API.post(url, data);
  },
};

const search = {
  getResults: ({ query, type }) => API.get(`/search`, { params: { query: query, type: type } }),
};

const user = {
  update: ({ userId }, data) => {
    return axios.put(`/users/${userId}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};

const realPageCaseStatuses = {
  update: data => {
    return axios.put('/real_page_case_statuses/update', data);
  },
};

const session = {
  destroy: () => {
    return API.delete('/sign_out');
  },
};

const propertySalesImagesSelector = {
  update: data => {
    const url = '/open_asset_media_requests/select_images';
    return API.put(url, data);
  },
};

const propertySalesImagesOrganizer = {
  update: data => {
    const url = '/open_asset_media_requests/order_images';
    return API.put(url, data);
  },
};

const propertyUrlLinksOrganizer = {
  update: data => {
    // eslint-disable-next-line no-console
    const propertyId = (data.data.property_id);
    const url = `/properties/${propertyId}/url_links/order_urls`;
    return API.put(url, data);
  },
};

export default {
  axios: API,
  adminUser,
  calendar,
  competitor_incentives,
  crudItems,
  employmentPositionDepartments,
  employmentPositions,
  propertyFloorplanSeasons,
  propertySurvey,
  rateAdjustmentRequests,
  search,
  scheduledBillingConfirmations,
  session,
  user,
  propertySalesImagesSelector,
  propertySalesImagesOrganizer,
  realPageCaseStatuses,
  propertyUrlLinksOrganizer,
};
