import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import TableContext from './TableContext';

const styles = {
  base: '',
  baseDefault: 'bg-scion-blue text-white',
};

const tableState = {
  variant: 'head',
};

const TableHead = props => {
  const { className, component: Component = 'thead', ...other } = props;

  return (
    <TableContext.Provider value={tableState}>
      <Component
        className={classNames(styles.base, {
          [styles.baseDefault]: !className,
          [className]: className,
        })}
        {...other}
      />
    </TableContext.Provider>
  );
};

TableHead.propTypes = {
  className: PropTypes.string,
  component: PropTypes.string,
};

TableHead.defaultProps = {
  className: '',
  component: 'thead',
};

export default TableHead;
