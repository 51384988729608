import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import api from '../../api';
import PhotoGrid from './PhotoGrid';

const App = ({ media, property }) => {
  const [photos, setPhotos] = useState(media);
  const toggleLike = id => {
    const newPhotos = photos.map(photo => {
      if (photo.id === id) {
        return { ...photo, is_property_info_page: !photo.is_property_info_page };
      }
      return photo;
    });
    setPhotos(newPhotos);
  };

  const returnUrl = `/properties/${property.id}/property_sales_info`;
  return (
    <div
      style={{
        margin: '0px 12px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <div>
          <Typography variant="h4" component="div" sx={{ marginBottom: '12px' }}>
            Property Photos
          </Typography>
          <Typography variant="caption1" gutterBottom component="div">
            (Select photos to display on property info page)
          </Typography>
        </div>
        <div>
          <Button variant="contained" size="large" href={returnUrl} sx={{ marginRight: '12px' }}>
            <NavigateBeforeIcon /> Return to Info Sheet
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              api.propertySalesImagesSelector
                .update({ photos: photos, property: property })
                .then(response => {
                  window.location.assign(response.data.redirect_url);
                })
                .catch(e => {
                  alert(
                    'There was an error saving your changes. Please create a help desk ticket if this continues to occur.'
                  );
                  throw new Error(e);
                });
            }}
            size="large"
          >
            Select Photo Order <NavigateNextOutlinedIcon />
          </Button>
        </div>
      </div>
      <PhotoGrid toggleLike={toggleLike} photos={photos} direction="column" />
    </div>
  );
};

App.propTypes = {
  media: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.string,
      property_sales_info_order: PropTypes.number,
      is_property_info_page: PropTypes.bool,
    })
  ).isRequired,
  property: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};

export default App;
