import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';

const FormHeader = ({ title, is_html = false }) => {
  return (
    <div>
      {' '}
      {/* Added border for visual debugging */}
      {is_html ? (
        <h2 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title) }} /> // eslint-disable-line react/no-danger
      ) : (
        <h2>{title}</h2>
      )}{' '}
    </div>
  );
};

FormHeader.propTypes = {
  title: PropTypes.string.isRequired,
  is_html: PropTypes.bool, // Indicates if the title is HTML
};

FormHeader.defaultProps = {
  is_html: false,
};

export default FormHeader;
