import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

const Image = ({ image }) => {
  return (
    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ backgroundColor: 'white', position: 'relative' }}>
      <img
        alt="img"
        src={image}
        loading="lazy"
        style={{
          width: '100%',
          height: '45vh',
          objectFit: 'cover',
          borderRadius: '5px',
          boxShadow: 1,
        }}
      />
    </Grid>
  );
};

Image.propTypes = {
  image: PropTypes.string.isRequired,
};

export default Image;
