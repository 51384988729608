import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["table"]

  connect() {
    this.tableId = this.data.get("tableId")
    this.searchInputId = this.data.get("searchInputId")
    this.sorting = JSON.parse(this.data.get("sorting"))
    this.paging = JSON.parse(this.data.get("paging"))
    this.initDataTable()
    this.bindSortingClickListener()
    this.initSearch()
  }

  initDataTable() {
   this.table = $(`#${this.tableId}`).DataTable({
      order: [],
      responsive: true,
      retrieve: true, // allow reinitialization
      searching: this.isSearchable(),
      paging: this.paging,
      ordering: this.sorting,
      aaSorting: [] // disable initial sorting
    })
  }

  bindSortingClickListener() {
    this.table.on('click', 'thead', (e) => {
      this.table.rows().invalidate().draw( false )
    })
  }

  initSearch() {
    const table = this.table
    if(this.isSearchable()){
      $(`#${this.searchInputId}`).on('keyup', function() {
        table.search(this.value).draw();
      })
    }
  }

  isSearchable() {
    return !!this.searchInputId
  }
}
