import React from 'react';
import OpenAndDownloadImageCard from "../../cards/OpenAndDownloadImageCard";
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";

const DeliverablesGrid = ({ deliverables }) => {
  return (
    <Grid container spacing={2} mt={2}>
      {deliverables.map((deliverable) => (
        <Grid item xs={4} key={deliverable.id}>
          <OpenAndDownloadImageCard
            file={deliverable}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default DeliverablesGrid;