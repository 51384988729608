import PropTypes from 'prop-types';
import React from 'react';

import PanelContainer from './PanelContainer';
import { PanelProvider } from './PanelContext';

const Panel = ({ children }) => <PanelProvider Panel={PanelContainer}>{children}</PanelProvider>;

Panel.propTypes = {
  children: PropTypes.node,
};

Panel.defaultProps = {
  children: null,
};

export default Panel;
