import { hexToLuminance, rgbToHex } from "@utils/lib/color";

const Highcharts = require("highcharts");
require("highcharts/modules/exporting")(Highcharts);

Highcharts.SparkLine = function(a, b, c) {
  const hasRenderToArg = typeof a === "string" || a.nodename;
  let options = arguments[hasRenderToArg ? 1 : 0];
  const defaultOptions = {
    chart: {
      renderTo: (options.chart && options.chart.renderTo) || this, // TODO: Don't use $
      backgroundColor: null,
      borderWidth: 0,
      type: "area",
      margin: [2, 0, 2, 0],
      height: 75,
      style: {
        overflow: "visible"
      },
      skipClone: true
    },
    title: {
      text: ""
    },
    credits: {
      enabled: false
    },
    xAxis: {
      labels: {
        enabled: false
      },
      title: {
        text: null
      },
      startOnTick: false,
      endOnTick: false,
      tickPositions: []
    },
    yAxis: {
      endOnTick: false,
      startOnTick: false,
      labels: {
        enabled: false
      },
      title: {
        text: null
      },
      tickPositions: [0]
    },
    legend: {
      enabled: false
    },
    tooltip: {
      backgroundColor: null,
      borderWidth: 0,
      shadow: false,
      useHTML: true,
      hideDelay: 0,
      shared: true,
      padding: 0,
      positioner(w, h, point) {
        return { x: point.plotX - w / 2, y: point.plotY - h };
      }
    },
    plotOptions: {
      series: {
        animation: false,
        lineWidth: 1,
        shadow: false,
        states: {
          hover: {
            lineWidth: 1
          }
        },
        marker: {
          radius: 0,
          states: {
            hover: {
              radius: 2
            }
          }
        },
        fillOpacity: 0.25
      },
      exporting: { enabled: false },
      column: {
        negativeColor: "#910000",
        borderColor: "silver"
      }
    }
  };
  options = Highcharts.merge(defaultOptions, options);

  return hasRenderToArg
    ? new Highcharts.Chart(a, options, c)
    : new Highcharts.chart(options, b);
};

if ($(".js-sparkline-graph").length) {
  $(".js-sparkline-graph").each(function() {
    const _this = $(this);
    const dataObj = _this.data("sparkline").data;
    const theme = _this.data("color");
    let chartColor = '#000000'

    if(theme){
      chartColor = rgbToHex(_this.closest(theme).css("background-color"));
      const luminance = hexToLuminance(chartColor)
      chartColor = luminance > 155 ? '#000000' : '#ffffff';
    }

    const chart = { renderTo: _this[0] };
    const chartType = _this.data("chart");

    if(chartType){
      chart.type = chartType;
    }

    Highcharts.SparkLine({
      series: [
        {
          data: dataObj.map( el => [el.label, parseFloat(el.y)] )
        }
      ],
      tooltip: {
        style: {
          color: chartColor
        },
        headerFormat: "<span>{point.label}</label>",
        pointFormat: "<b>{point.y}</b>"
      },
      plotOptions: {
          area: {
              fillOpacity: 0.10
          },
          series: {
              color: chartColor
          }
      },
      exporting: { enabled: false },
      chart: chart
    });
  });
}
