import PropTypes from 'prop-types';
import React, { useState } from 'react';

import api from '../../api';
import Card from '../../shared/Card';
import Panel from '../../panel';
import PropertySurveyForm from '../../property_survey/PropertySurveyForm';

const ShowPage = props => {
  const {
    property,
    survey: {
      previous_property_survey: previousPropertySurvey,
      property_survey: propertySurvey,
      unit_mix_rates: unitMixRates,
      unit_mixes: unitMixes,
      property_fees: propertyFees,
      fees: fees,
      incentives,
    },
  } = props;

  const [stateIncentives, setStateIncentives] = useState(incentives);
  const [statePropertyFees, setStatePropertyFees] = useState(propertyFees);

  const getIncentives = () => {
    api.competitor_incentives
      .getActiveByDate({ propertyId: propertySurvey.property_id, date: propertySurvey.date })
      .then(({ data }) => {
        setStateIncentives(data.incentives);
      })
      .catch(() => {});
  };

  const getPropertyFees = () => {
    api.crudItems
      .getAll({
        property_id: propertySurvey.property_id,
        form_class: 'PropertyFee',
        fee_ids: [1, 2, 3],
      })
      .then(({ data }) => {
        setStatePropertyFees([...data.index_items]);
      })
      .catch(() => {});
  };

  return (
    <Panel>
      <div className="container mx-auto">
        <Card>
          <PropertySurveyForm
            fees={fees}
            inEditMode
            property={property}
            previousPropertySurvey={previousPropertySurvey}
            propertySurvey={propertySurvey}
            unitMixes={unitMixes}
            unitMixRates={unitMixRates}
            incentives={stateIncentives}
            onIncentiveUpdate={getIncentives}
            propertyFees={statePropertyFees}
            onPropertyFeeUpdate={getPropertyFees}
          />
        </Card>
      </div>
    </Panel>
  );
};

ShowPage.propTypes = {
  property: PropTypes.shape({}).isRequired,
  survey: PropTypes.shape({
    fees: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    previous_property_survey: PropTypes.shape({}),
    property_survey: PropTypes.shape({
      date: PropTypes.string,
      property_id: PropTypes.number,
    }).isRequired,
    unit_mix_rates: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    unit_mixes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    incentives: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    property_fees: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
};

export default ShowPage;
