import { shallow } from 'enzyme';
import makeColumns from './MakeColumns';

describe('makeColumns function', () => {
  test('it should return empty when no columns', () => {
    expect(makeColumns([])).toEqual([]);
    expect(makeColumns(null)).toEqual([]);
  });

  test('it should return column accessor schema', () => {
    const columns = [
      {
        Header: 'testing',
        accessor: 'someAccessor',
      },
    ];

    const actual = makeColumns(columns);
    expect(actual.length).toEqual(1);
    expect(actual[0].Header).toEqual('testing');
    expect(actual[0].id).toEqual('someAccessor');
    expect(actual[0].accessor({ someAccessor: { value: 1 } })).toEqual(1);

    const cell = actual[0].Cell({
      row: { original: { someAccessor: { value: 1 } } },
      cell: { value: 1 },
    });

    expect(cell).toEqual('1');
  });

  test('it should override Cell when component display is given', () => {
    const columns = [
      {
        Header: 'testing',
        accessor: 'someAccessor',
      },
    ];

    const actual = makeColumns(columns);

    const cell = actual[0].Cell({
      row: { original: { someAccessor: { value: 1, component: { display: 'Override' } } } },
      cell: { value: 1 },
    });

    const cellComponent = shallow(cell);

    expect(cellComponent.type()).toEqual('span');
    expect(cellComponent.text()).toEqual('Override');
  });

  test('it should override Cell when component element is given', () => {
    const columns = [
      {
        Header: 'testing',
        accessor: 'someAccessor',
      },
    ];

    const actual = makeColumns(columns);

    const cell = actual[0].Cell({
      row: {
        original: {
          someAccessor: {
            value: 1,
            component: { element: 'a', href: 'www.example.com', display: 'Override' },
          },
        },
      },
      cell: { value: 1 },
    });

    const cellComponent = shallow(cell);

    expect(cellComponent.type()).toEqual('a');
    expect(cellComponent.props().href).toEqual('www.example.com');
    expect(cellComponent.text()).toEqual('Override');
  });
});
