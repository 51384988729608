import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';

import {
  Avatar,
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import api from '../../api';
import ErrorBlock from '../../shared/ErrorBlock';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const UserEditFormSchema = yup.object().shape({
  firstName: yup.string(),
  lastName: yup.string(),
  email: yup.string(),
  timeZone: yup.string(),
  cellPhone: yup.lazy(value =>
    !value ? yup.string() : yup.string().matches(phoneRegExp, 'Phone number is not valid')
  ),
  officePhone: yup.lazy(value =>
    !value ? yup.string() : yup.string().matches(phoneRegExp, 'Phone number is not valid')
  ),
  password: yup.string(),
  passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const UserEditForm = ({
  user_logo_html: userLogoHtml,
  time_zone_options: timeZoneOptions,
  user: {
    id: id,
    first_name: firstName,
    last_name: lastName,
    email: email,
    cell_phone: cellPhone,
    office_phone: officePhone,
    time_zone: timeZone,
  },
}) => {
  const { register, handleSubmit, control, errors, getValues } = useForm({
    defaultValues: {
      firstName: firstName || '',
      lastName: lastName || '',
      email: email || '',
      cellPhone: cellPhone || '',
      logo: userLogoFile || null,
      officePhone: officePhone || '',
      timeZone: timeZone || 'Central Time (US & Canada)',
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: UserEditFormSchema,
  });

  const [submitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState({});
  const [userLogoUrl, setUserLogoUrl] = useState(userLogoHtml);
  const [userLogoFile, setUserLogoFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(null);

  const handlePhotoChange = e => {
    const file = e.target.files[0];
    const reader = new FileReader();
    setUserLogoFile(file);
    setSelectedFileName(file.name);
    reader.onload = e => {
      setUserLogoUrl(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const onSubmit = () => {
    setSubmitting(true);
    setStatus({});

    const {
      firstName,
      lastName,
      email,
      cellPhone,
      officePhone,
      timeZone,
      password,
      passwordConfirmation,
    } = getValues();

    const formData = new FormData();
    formData.append('first_name', firstName);
    formData.append('last_name', lastName);
    formData.append('email', email);
    formData.append('cell_phone', cellPhone);
    formData.append('office_phone', officePhone);
    formData.append('time_zone', timeZone);
    if (userLogoFile) {
      formData.append('logo', userLogoFile);
    }
    if (password && passwordConfirmation) {
      formData.append('password', password);
      formData.append('passwordConfirmation', passwordConfirmation);
    }

    return api.user
      .update({ userId: id }, formData)
      .then(() => window.location.assign(`/users/${id}/edit`))
      .catch(error => {
        setSubmitting(false);
        if (error.response) {
          const { message, code, errors } = error.response.data;
          setStatus({ message, code, errors });
        } else {
          setStatus({ message: 'An error occurred.', code: 'client_error' });
        }
      });
  };

  return (
    <Container maxWidth="md">
      {userLogoUrl && (
        <Grid container spacing={2} className="justify-center">
          <Grid item>
            <Avatar
              alt={firstName + lastName}
              src={userLogoUrl}
              style={{ alignSelf: 'center', height: 200, width: 200 }}
            />
          </Grid>
        </Grid>
      )}
      <Box mt={2}>
        <Typography
          style={{ fontWeight: 300 }}
          className="text-center text-purple-600"
          variant="h6"
        >
          {firstName} {lastName}
        </Typography>
      </Box>
      <Box mb={2} mt={0}>
        <Typography className="text-center" variant="h4">
          Edit Profile
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mb={3} mt={0}>
          <Grid container spacing={2} className="justify-center">
            <Grid item>
              <Button variant="contained" disabled={submitting} component="label" size="medium">
                {selectedFileName ? 'Change Photo' : 'Upload Photo'}
                <input
                  type="file"
                  name="logo"
                  accept="image/*"
                  hidden
                  onChange={handlePhotoChange}
                />
              </Button>
            </Grid>
          </Grid>
        </Box>
        {selectedFileName && (
          <Box mb={3} mt={-2}>
            <Typography style={{ fontWeight: 300 }} className="text-center" variant="subtitle2">
              Selected Image: {selectedFileName}
            </Typography>
          </Box>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                label="First Name"
                name="firstName"
                type="text"
                required
                inputRef={register}
                error={errors.firstName}
                helperText={errors.firstName && errors.firstName.message}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                label="Last Name"
                name="lastName"
                type="text"
                required
                inputRef={register}
                error={errors.lastName}
                helperText={errors.lastName && errors.lastName.message}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                label="Email"
                name="email"
                type="email"
                required
                inputRef={register}
                error={errors.email}
                helperText={errors.email && errors.email.message}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl required fullWidth>
              <InputLabel id="time-zone-label">Time Zone</InputLabel>
              <Controller
                as={
                  <Select
                    labelId="time-zone-label"
                    id="time-zone"
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                    required
                  >
                    {timeZoneOptions.map(tz => (
                      <MenuItem key={tz.label} value={tz.value}>
                        {tz.label}
                      </MenuItem>
                    ))}
                  </Select>
                }
                name="timeZone"
                control={control}
                onChange={([selected]) => selected.target.value}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                label="Cell Phone"
                name="cellPhone"
                type="text"
                inputRef={register}
                error={errors.cellPhone}
                helperText={errors.cellPhone && errors.cellPhone.message}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                label="Office Phone"
                name="officePhone"
                type="text"
                inputRef={register}
                error={errors.officePhone}
                helperText={errors.officePhone && errors.officePhone.message}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                label="New Password"
                name="password"
                type="password"
                inputRef={register}
                error={errors.password}
                helperText={errors.password && errors.password.message}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                label="New Password Confirmation"
                name="passwordConfirmation"
                type="password"
                inputRef={register}
                error={errors.passwordConfirmation}
                helperText={errors.passwordConfirmation && errors.passwordConfirmation.message}
              />
            </FormControl>
          </Grid>
        </Grid>

        {status.constructor === Object && Object.keys(status).length !== 0 && (
          <ErrorBlock errors={status.errors} />
        )}

        <Box mt={4}>
          <Grid container spacing={2} className="justify-center">
            <Grid item>
              <Button
                color="primary"
                disabled={submitting}
                size="large"
                type="submit"
                variant="contained"
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
      <Box mb={2} mt={3}>
        <Typography
          style={{ fontWeight: 300 }}
          className="text-center text-yellow-700"
          variant="body2"
        >
          NOTE: Changes will be reflected sitewide and in the directory within 12 hours.
        </Typography>
      </Box>
    </Container>
  );
};

UserEditForm.propTypes = {
  time_zone_options: PropTypes.arrayOf(PropTypes.shape).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    cell_phone: PropTypes.string,
    office_phone: PropTypes.string,
    time_zone: PropTypes.string,
  }).isRequired,
  user_logo_html: PropTypes.string,
};

UserEditForm.defaultProps = {
  user_logo_html: null,
};

export default UserEditForm;
