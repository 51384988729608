import Highcharts from "highcharts";
import addMore from "highcharts/highcharts-more";
import addDrilldown from "highcharts/modules/drilldown";
import addNoData from "highcharts/modules/no-data-to-display";

addMore(Highcharts);
addDrilldown(Highcharts);
addNoData(Highcharts);

document.addEventListener('DOMContentLoaded', function () {
  const data = $("#js-enrollments-state").data("graph-data");
  if (data) {
    Highcharts.setOptions({
      colors: ["#454A5E", "#3BCEBF"],
      lang: {
        thousandsSep: ","
      }
    });

    const chart = Highcharts.chart("js-enrollments-state", {
      chart: {
        type: "pie"
      },

      exporting: { enabled: false },

      title: {
        text: ""
      },

      tooltip: {
        formatter() {
          return `<b>${this.point.name}</b>: ${this.point.percentage.toFixed(
            2
          )} %`;
        }
      },

      plotOptions: {
        pie: {
          shadow: false
        }
      },

      legend: {
        enabled: false
      },

      series: data.map(series => ({
        name: series.name,
        color: series.color,
        data: series.data.map(d => (d[1] == 0 ? ["", null] : d)),
        size: "100%",
        innerSize: "50%",
        dataLabels: {
          distance: -20
        }
      }))
    });
  }
})