import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Drawer, IconButton, Divider } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import ScionImage from '../../../../images/scionlogo-white-0250.png';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    color: 'white',
    backgroundColor: '#1a202c',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  divider: {
    backgroundColor: 'rgb(255, 255, 255, .8)',
  },
  drawerBody: {
    overflow: 'auto',
  },
}));

const AppSidebar = ({ open, toggleOpen, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        anchor="left"
        variant="persistent"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div>
          <div className={classes.drawerHeader}>
            <div>
              <img src={ScionImage} alt="Scion Intelligence" />
            </div>
            <IconButton onClick={toggleOpen} color="inherit">
              <ChevronLeftIcon />
            </IconButton>
          </div>

          <Divider className={classes.divider} />
        </div>

        <div className={classes.drawerBody}>{children}</div>
      </Drawer>
    </div>
  );
};

AppSidebar.propTypes = {
  open: PropTypes.bool,
  toggleOpen: PropTypes.func,
  children: PropTypes.element,
};

AppSidebar.defaultProps = {
  open: false,
  toggleOpen: () => undefined,
  children: null,
};

export default AppSidebar;
