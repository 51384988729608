import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';

import {
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  Button,
  FormGroup,
  Switch,
  FormHelperText,
} from '@material-ui/core';
import humps from 'humps';
import api from '../api';
import ErrorBlock from '../shared/ErrorBlock';

const UserFormSchema = yup.object().shape({
  active: yup.boolean(),
  associateId: yup.string(),
  firstName: yup.string().required('First Name is a required field'),
  lastName: yup.string().required('Last Name is a required field'),
  email: yup.string().required('Email is a required field'),
  type: yup.string().required('Type is a required field'),
  jobTitleId: yup.string(),
  password: yup.string(),
  passwordConfirmation: yup.string(),
  skipPasswordEmail: yup.boolean(),
  //eslint-disable-next-line react/forbid-prop-types
  assignedPropertyIds: yup.array(),
  //eslint-disable-next-line react/forbid-prop-types
  assignedPermissionGroupIds: yup.array(),
  assignedAccountIds: yup.array().required('Accounts is a required field'),
  occupancyEmailFlag: yup.boolean(),
  dlvFlag: yup.boolean(),
  dnsEmail: yup.boolean(),
});

const AdminUserForm = ({ types, jobs, properties, accounts, groups, user }) => {
  const { register, handleSubmit, control, errors } = useForm({
    defaultValues: {
      active: user.active,
      associateId: user.associateId,
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      email: user.email || '',
      type: user.type || 'standard',
      jobTitleId: user.jobTitleId || '',
      password: user.password || '',
      passwordConfirmation: user.passwordConfirmation || '',
      skipPasswordEmail: false,
      assignedPropertyIds: user.assignedPropertyIds || [],
      assignedPermissionGroupIds: user.assignedPermissionGroupIds || [],
      assignedAccountIds: user.assignedAccountIds || [],
      occupancyEmailFlag: user.occupancyEmailFlag,
      dlvFlag: user.dlvFlag,
      dnsEmail: user.dnsEmail,
    },
    validationSchema: UserFormSchema,
  });

  const [submitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState({});

  const onSubmit = data => {
    setSubmitting(true);
    setStatus({});

    const serializedData = humps.decamelizeKeys({ user: data });

    return api.adminUser
      .createOrUpdate({ userId: user.id }, serializedData)
      .then(() => window.location.assign(`/admin/users`))
      .catch(error => {
        setSubmitting(false);

        if (error.response) {
          const { message, code, errors } = error.response.data;
          setStatus({ message, code, errors });
        } else {
          setStatus({ message: 'An error occurred.', code: 'client_error' });
        }
      });
  };

  return (
    <Container maxWidth="md">
      <Box mb={4} mt={2}>
        <Typography variant="h4">{user.id ? 'Edit' : 'Create'} User</Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mb={1}>
          <Typography variant="h5">Details</Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormControlLabel
                control={
                  <Controller
                    as={<Switch name="active" color="primary" />}
                    name="active"
                    control={control}
                  />
                }
                label="Active?"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="user-type-select-label" error={!!errors.type}>
                Account Type *
              </InputLabel>
              <Controller
                as={
                  <Select
                    labelId="user-type-select-label"
                    id="user-type-select"
                    error={errors.type}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {types.map(type => (
                      <MenuItem key={type.id} value={type.value}>
                        {type.value}
                      </MenuItem>
                    ))}
                  </Select>
                }
                name="type"
                control={control}
                onChange={([selected]) => selected.target.value}
                required
              />
              {!!errors.type && <FormHelperText error>{errors.type.message}</FormHelperText>}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <TextField
                label="Email *"
                type="email"
                name="email"
                inputRef={register}
                error={errors.email}
                helperText={errors.email && errors.email.message}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <TextField
                label="Associate ID"
                name="associateId"
                inputRef={register}
                error={errors.associateId}
                helperText={errors.associateId && errors.associateId.message}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <TextField
                label="First Name *"
                name="firstName"
                inputRef={register}
                error={errors.firstName}
                helperText={errors.firstName && errors.firstName.message}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <TextField
                label="Last Name *"
                name="lastName"
                inputRef={register}
                error={errors.lastName}
                helperText={errors.lastName && errors.lastName.message}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="user-job-select-label">Job Title</InputLabel>
              <Controller
                as={
                  <Select
                    labelId="user-job-select-label"
                    id="user-job-select"
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {jobs
                      .sort((a, b) => a.title.localeCompare(b.title))
                      .map(type => (
                        <MenuItem key={type.id} value={type.id}>
                          {type.title}
                        </MenuItem>
                      ))}
                  </Select>
                }
                name="jobTitleId"
                control={control}
                onChange={([selected]) => selected.target.value}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Box mt={6} mb={1}>
          <Typography variant="h5" display="inline">
            Credentials
          </Typography>
        </Box>

        <Grid container spacing={4} alignItems="baseline">
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <TextField label="Password" type="password" name="password" inputRef={register} />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <TextField
                label="Confirm Password"
                type="password"
                name="passwordConfirmation"
                inputRef={register}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControl>
              <FormControlLabel
                control={<Checkbox name="skipPasswordEmail" color="primary" inputRef={register} />}
                label="Skip Password Email"
              />
            </FormControl>
          </Grid>
        </Grid>

        <Box mt={6} mb={1}>
          <Typography variant="h5">Permissions</Typography>
        </Box>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <FormControl required fullWidth>
              <InputLabel id="user-accounts-select-label" error={!!errors.assignedAccountIds}>
                Accounts
              </InputLabel>
              <Controller
                as={
                  <Select
                    labelId="user-accounts-select-label"
                    id="user-accounts-select"
                    required
                    multiple
                    fullWidth
                    error={!!errors.assignedAccountIds}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {accounts.map(account => (
                      <MenuItem key={account.id} value={account.id}>
                        {account.name}
                      </MenuItem>
                    ))}
                  </Select>
                }
                name="assignedAccountIds"
                control={control}
                onChange={([selected]) => selected.target.value}
              />
            </FormControl>
            {!!errors.assignedAccountIds && (
              <FormHelperText error>{errors.assignedAccountIds.message}</FormHelperText>
            )}
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="user-permissions-select-label">Permissions</InputLabel>
              <Controller
                as={
                  <Select
                    labelId="user-permissions-select-label"
                    id="user-permissions-select"
                    multiple
                    fullWidth
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {groups
                      .sort((a, b) => a.title.localeCompare(b.title))
                      .map(group => (
                        <MenuItem key={group.id} value={group.id}>
                          {group.title}
                        </MenuItem>
                      ))}
                  </Select>
                }
                name="assignedPermissionGroupIds"
                control={control}
                onChange={([selected]) => selected.target.value}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="user-properties-select-label">Properties</InputLabel>
              <Controller
                as={
                  <Select
                    label="Properties"
                    id="user-properties-select"
                    multiple
                    fullWidth
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {properties.map(property => (
                      <MenuItem key={property.id} value={property.id}>
                        {property.name}
                      </MenuItem>
                    ))}
                  </Select>
                }
                name="assignedPropertyIds"
                control={control}
                onChange={([selected]) => selected.target.value}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Box mt={6} mb={1}>
          <Typography variant="h5">Subscriptions</Typography>
        </Box>

        <FormControl component="fieldset">
          <FormGroup>
            <FormControlLabel
              control={
                <Controller
                  as={<Checkbox color="primary" inputRef={register} />}
                  name="dlvFlag"
                  control={control}
                />
              }
              label="DLV Email"
            />
            <FormControlLabel
              control={
                <Controller
                  as={<Checkbox color="primary" inputRef={register} />}
                  name="dnsEmail"
                  control={control}
                />
              }
              label="DNS Email"
            />
            <FormControlLabel
              control={
                <Controller
                  as={<Checkbox color="primary" inputRef={register} />}
                  name="occupancyEmailFlag"
                  control={control}
                />
              }
              label="Occupancy Email"
            />
          </FormGroup>
        </FormControl>

        {status.constructor === Object && Object.keys(status).length !== 0 && (
          <ErrorBlock errors={status.errors} />
        )}

        <Box mt={6}>
          <Grid container justify="flex-end">
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                disabled={submitting}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Container>
  );
};

AdminUserForm.propTypes = {
  types: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  jobs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    })
  ),
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  user: PropTypes.shape({
    id: PropTypes.number,
    associateId: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    type: PropTypes.string,
    password: PropTypes.string,
    passwordConfirmation: PropTypes.string,
    jobTitleId: PropTypes.number,
    dlvFlag: PropTypes.bool,
    dnsEmail: PropTypes.bool,
    active: PropTypes.bool,
    occupancyEmailFlag: PropTypes.bool,
    assignedPropertyIds: PropTypes.arrayOf(PropTypes.number),
    assignedPermissionGroupIds: PropTypes.arrayOf(PropTypes.number),
    assignedAccountIds: PropTypes.arrayOf(PropTypes.number),
  }),
};

AdminUserForm.defaultProps = {
  types: [],
  jobs: [],
  properties: [],
  accounts: [],
  groups: [],
  user: {
    id: null,
    associateId: null,
    firstName: null,
    lastName: null,
    email: null,
    type: null,
    password: null,
    passwordConfirmation: null,
    jobTitleId: null,
    dlvFlag: false,
    dnsEmail: false,
    active: false,
    occupancyEmailFlag: false,
    assignedPropertyIds: [],
    assignedPermissionGroupIds: [],
    assignedAccountIds: [],
  },
};

export default AdminUserForm;
