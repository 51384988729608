import React from 'react';
import _ from 'lodash';

// Contexts
export const StoreContext = React.createContext(null);

// Action constants
export const actions = {
  UPDATE_TIER_CONTRACTS_ACTUAL: 'update tier contracts actual',
  UPDATE_TIER_RATE_ACTUAL: 'update tier rate actual',
  UPDATE_VERSION_REQUEST_NOTES: 'update version request notes',
  UPDATE_VERSION_LEVEL_1_NOTES: 'update version level 1 notes',
  UPDATE_VERSION_LEVEL_2_NOTES: 'update version level 2 notes',
  UPDATE_VERSION_LEVEL_3_NOTES: 'update version level 3 notes',
  ADD_TIER: 'add tier',
  DELETE_TIER: 'delete tier',
  UPDATE_CALCULATIONS: 'update calculations',
};

export const createAction = (type, payload) => {
  return {
    type,
    payload,
  };
};

const getTiersByFloorplan = state => {
  return _.groupBy(state.tiers, 'floorplan_spacetype_key');
};

const getTargetContractsByFloorplan = state => {
  return _.keyBy(
    state.floorplans.map(floorplan => {
      return {
        floorplan_spacetype_key: floorplan.floorplan_spacetype_key,
        bed_count: floorplan.bed_count,
      };
    }),
    'floorplan_spacetype_key'
  );
};

// Reducer
export const reducer = (state, action) => {
  switch (action.type) {
    case actions.UPDATE_TIER_CONTRACTS_ACTUAL: {
      const { value, tierKey } = action.payload;
      return {
        ...state,
        tiers: {
          ...state.tiers,
          [tierKey]: {
            ...state.tiers[tierKey],
            tier_contracts: value,
          },
        },
      };
    }
    case actions.UPDATE_TIER_RATE_ACTUAL: {
      const { value, tierKey } = action.payload;
      return {
        ...state,
        tiers: {
          ...state.tiers,
          [tierKey]: {
            ...state.tiers[tierKey],
            tier_rate: value,
          },
        },
      };
    }
    case actions.UPDATE_VERSION_REQUEST_NOTES: {
      const { value } = action.payload;
      return {
        ...state,
        version: {
          ...state.version,
          request_notes: value,
        },
      };
    }
    case actions.UPDATE_VERSION_LEVEL_1_NOTES: {
      const { value } = action.payload;
      return {
        ...state,
        version: {
          ...state.version,
          level_1_notes: value,
        },
      };
    }
    case actions.UPDATE_VERSION_LEVEL_2_NOTES: {
      const { value } = action.payload;
      return {
        ...state,
        version: {
          ...state.version,
          level_2_notes: value,
        },
      };
    }

    case actions.UPDATE_VERSION_LEVEL_3_NOTES: {
      const { value } = action.payload;
      return {
        ...state,
        version: {
          ...state.version,
          level_3_notes: value,
        },
      };
    }
    case actions.ADD_TIER: {
      const { floorplanId, spaceType, floorplanSpacetypeKey } = action.payload;
      let floorplanTiers = getTiersByFloorplan(state)[floorplanSpacetypeKey];
      let lastTier = floorplanTiers ? floorplanTiers[Object.keys(floorplanTiers).length - 1] : null;
      let tier = lastTier?.tier + 1 || 1;
      let tierSequence = lastTier?.tier_sequence + 1 || 1;
      let tierKey = `${floorplanSpacetypeKey}_${tierSequence}`;
      return {
        ...state,
        tiers: {
          ...state.tiers,
          [tierKey]: {
            tier_key: tierKey,
            tier: tier,
            tier_sequence: tierSequence,
            floorplan_id: floorplanId,
            space_type: spaceType,
            floorplan_spacetype_key: floorplanSpacetypeKey,
            previous_version_tier_contracts: null,
            previous_version_tier_rate: null,
            tier_contracts: 0,
            tier_rate: 0,
            tier_status: 'future',
          },
        },
      };
    }
    case actions.DELETE_TIER: {
      const { tierKey } = action.payload;
      return {
        ...state,
        tiers: _.omit(state.tiers, tierKey),
      };
    }
    case actions.UPDATE_CALCULATIONS: {
      let tiersByFloorplan = getTiersByFloorplan(state);
      let targetContractsByFloorplan = getTargetContractsByFloorplan(state);

      // Calculate cumulative sum of contracts * rate by tier by floorplan
      var cumuProductSumBuilder = function(acc, obj) {
        var lastObj = acc.length > 0 ? acc[acc.length - 1] : 0;
        var rate = ['past', 'current_to_date'].includes(obj.tier_status) ? obj.signed_rate : obj.tier_rate; // eslint-disable-line prettier/prettier
        acc.push(lastObj + obj.tier_contracts * rate);
        return acc;
      };

      const unkeyedCumuProductSumsByTierByFloorplan = _.map(tiersByFloorplan, function(arr) {
        return {
          floorplan_spacetype_key: arr[0]['floorplan_spacetype_key'],
          cumu_product_sums: _.reduce(arr, cumuProductSumBuilder, []),
        };
      });

      const cumuProductSumsByTierByFloorplan = _.keyBy(
        unkeyedCumuProductSumsByTierByFloorplan,
        'floorplan_spacetype_key'
      );

      // Calculate cumulative sum of contracts by floorplan
      var cumuSumBuilder = function(acc, obj) {
        var lastObj = acc.length > 0 ? acc[acc.length - 1] : 0;
        acc.push(lastObj + obj.tier_contracts);
        return acc;
      };

      const unkeyedCumuSumsByTierByFloorplan = _.map(tiersByFloorplan, function(arr) {
        return {
          floorplan_spacetype_key: arr[0]['floorplan_spacetype_key'],
          cumu_contract_sums: _.reduce(arr, cumuSumBuilder, []),
        };
      });

      const cumuContractsByTierByFloorplan = _.keyBy(
        unkeyedCumuSumsByTierByFloorplan,
        'floorplan_spacetype_key'
      );

      let floorplanCalculations = _.keyBy(
        _.map(tiersByFloorplan, (tiersArray, floorplanSpacetypeId) => {
          return {
            floorplan_spacetype_key: floorplanSpacetypeId,
            cumu_avg_rates: cumuProductSumsByTierByFloorplan[floorplanSpacetypeId][
              'cumu_product_sums'
            ].map((productSum, i) => {
              return (
                productSum /
                cumuContractsByTierByFloorplan[floorplanSpacetypeId]['cumu_contract_sums'][i]
              );
            }),
            total_contracts: _.sumBy(tiersArray, 'tier_contracts'),
          };
        }),
        'floorplan_spacetype_key'
      );

      let totalContractsByFloorplan = _.mapValues(floorplanCalculations, floorplan => {
        return floorplan.total_contracts;
      });

      let avgRateByFloorplan = _.mapValues(floorplanCalculations, floorplan => {
        return floorplan.cumu_avg_rates.slice(-1)[0];
      });

      let sumProductForProperty = _.sum(
        _.keys(avgRateByFloorplan).map(floorplanSpacetypeKey => {
          return (
            totalContractsByFloorplan[floorplanSpacetypeKey] *
            avgRateByFloorplan[floorplanSpacetypeKey]
          );
        })
      );

      let sumContractsForProperty = _.sum(_.values(totalContractsByFloorplan));

      let floorplanContractsOff = _.some(targetContractsByFloorplan, floorplan => {
        return totalContractsByFloorplan[floorplan.floorplan_spacetype_key] !== floorplan.bed_count;
      });

      let allValidRates = _.every(state.tiers, tier => {
        return tier.tier_rate > 0;
      });

      let propertyCalculations = {
        total_contracts: sumContractsForProperty, // not used at the moment
        total_avg_rate: sumProductForProperty / sumContractsForProperty,
        floorplan_contracts_off: floorplanContractsOff,
        all_valid_rates: allValidRates,
      };

      return {
        ...state,
        floorplan_calculations: floorplanCalculations,
        property_calculations: propertyCalculations,
      };
    }
    default:
      return state;
  }
};
