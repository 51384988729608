import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { Paper, Grid } from '@material-ui/core';

import { DashboardPanel, ResourcePanel, UserPanel } from './Panels';
import theme from '../../../theme';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: theme.spacing(3),
  },
  card: {
    padding: theme.spacing(3),
  },
}));

const UserProfilePage = ({ user, properties, activities, markets }) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <div className={classNames(classes.root)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={5} lg={4}>
            <Paper className={classNames(classes.card)}>
              <UserPanel user={user} />
            </Paper>

            {properties.length > 0 && (
              <Paper className={classNames(classes.card, 'mt-6')}>
                <ResourcePanel properties={properties} markets={markets} />
              </Paper>
            )}
          </Grid>

          <Grid item xs={12} md={7} lg={8}>
            <Paper className={classNames(classes.card)}>
              <DashboardPanel activities={activities} />
            </Paper>
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  );
};

UserProfilePage.propTypes = {
  user: PropTypes.shape({}).isRequired,
  properties: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  activities: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  markets: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default UserProfilePage;
