import { connect } from 'react-redux';
import Results from './Results';

const mapStateToProps = state => ({
  sql: state.query.sql,
  headers: state.query.headers,
  data: state.query.data,
});

export default connect(mapStateToProps, {})(Results);
