import LeadChart from "./lead_chart";
import BreakdownLeadChart from "./breakdown_lead_chart";
import LeadEngine from "./lead_engine";

document.addEventListener("prelease-delta-loaded", function() {
  const preleaseLeadChart = new LeadChart("#js-lead-chart");
  const  breakdownChart = new BreakdownLeadChart("#js-lead-breakdown-chart");
  const leadEngine = new LeadEngine("#js-lead-engine", preleaseLeadChart, breakdownChart);
  leadEngine.createCharts();
});
