import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginTop: theme.spacing(-0.2),
    marginLeft: theme.spacing(1.5),
  },
  input: {
    borderRadius: '15px',
    fontWeight: 400,
    padding: '0px 0px',
    paddingTop: '2px',
    margin: '0px 0px',
    fontSize: '0.5em',
    color: '#666666',
    height: 26,
  },
  icon: {
    position: 'absolute',
    top: '0',
    right: '0',
    marginTop: '4.9px',
    marginRight: '5.5px',
    display: 'inline-block',
    color: '#777777',
    width: '16px',
    height: '16px',
    pointerEvents: 'none',
  },
}));

const ChipFilter = ({ options, currentSelection, ...props }) => {
  const [selected, setSelected] = useState(currentSelection);
  const classes = useStyles();

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    const path = `/brand/role_scope?role=${selected}`;
    window.location.assign(path);
  }, [selected]);

  const handleChange = event => {
    setSelected(event.target.value);
  };

  const MoreIcon = () => {
    return <MoreVertIcon className={classes.icon} />;
  };

  return (
    <FormControl
      variant="outlined"
      classnames="inline"
      size="small"
      className={classes.formControl}
    >
      <Select
        className={classes.input}
        IconComponent={MoreIcon}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selected}
        onChange={handleChange}
        {...props}
      >
        {options.map(option => (
          <MenuItem className={classes.menuItem} key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

ChipFilter.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentSelection: PropTypes.string,
};

ChipFilter.defaultProps = {
  currentSelection: 'Default',
};

export default ChipFilter;
