import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import FolderIcon from '@mui/icons-material/Folder';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import DownloadIcon from '@mui/icons-material/Download';

const FileList = ({ files: files, list_title: listTitle, file_action: fileAction }) => {
  const selectIcon = (fileAction, fileUrl) => {
    if (fileAction == 'view') {
      return (
        <IconButton href={fileUrl}>
          <VisibilityIcon href={fileUrl} />
        </IconButton>
      );
    } else if (fileAction == 'download') {
      return (
        <IconButton href={fileUrl}>
          <DownloadIcon />
        </IconButton>
      );
    }
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ margin: '28px' }}>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <Typography
              variant="h6"
              component="div"
              id="nested-list-subheader"
              sx={{ marginLeft: '16px' }}
            >
              {listTitle}
            </Typography>
          }
          sx={{ bgcolor: 'background.paper' }}
        >
          {files.map(file => (
            <ListItem key={file.id} secondaryAction={selectIcon(fileAction, file.file_url)}>
              <ListItemAvatar>
                <Avatar>
                  <FolderIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={file.file_name} secondary={file.secondary_file_name} />
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

FileList.propTypes = {
  files: PropTypes.arrayOf({}).isRequired,
  list_title: PropTypes.string.isRequired,
  file_action: PropTypes.string.isRequired,
};
export default FileList;
