import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["fee", "cost"];

  handleAllowanceIncludedChange(event) {
    this.toggleInputs();
  }

  toggleInputs() {
    $(this.feeTarget).toggleClass('invisible');
    $(this.costTarget).toggleClass('invisible');
  }
}
