import PropTypes from 'prop-types';
import React from 'react';

import api from '../../api';
import Button from '../../shared/Button';
import { usePanelDispatch } from '../../panel/PanelContext';
import * as panelTypes from '../../panel/panelTypes';

const ItemCustomButton = ({ buttonMessage, props, setFormErrors, text, values }) => {
  const panelDispatch = usePanelDispatch();

  const handleButtonClick = () => {
    const { formAttributes, isEdit, onUpdate } = props;
    const params = {
      ...formAttributes.params,
      form_object: Object.assign(values.itemToEdit, { button_message: buttonMessage }),
      isEdit: isEdit,
    };

    api.crudItems
      .updateOrCreate(params)
      .then(() => {
        onUpdate();
      })
      .catch(error => {
        setFormErrors(error.response.data.errors);
      });
  };

  return (
    <Button
      className="mt-2"
      type="button"
      variant="secondary"
      size="lg"
      block
      onClick={() => {
        panelDispatch({
          type: panelTypes.CLOSE,
        });
        handleButtonClick();
      }}
    >
      {text}
    </Button>
  );
};

ItemCustomButton.propTypes = {
  buttonMessage: PropTypes.string.isRequired,
  formAttributes: PropTypes.shape({
    params: PropTypes.shape({}),
  }).isRequired,
  isEdit: PropTypes.bool,
  onUpdate: PropTypes.bool.isRequired,
  props: PropTypes.shape({}).isRequired,
  setFormErrors: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  values: PropTypes.shape({
    itemToEdit: PropTypes.shape({
      update_scope_form: PropTypes.string,
    }),
  }).isRequired,
};

ItemCustomButton.defaultProps = {
  isEdit: false,
};

export default ItemCustomButton;
