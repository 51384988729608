import queryString from "query-string";
import { Controller } from "stimulus";

export default class extends Controller {
  initialize() {}

  handleClick(e) {
    const url = new URL(location);
    url.searchParams.set(this.key, this.value);
    window.location = url;
  }

  get key() {
    return this.data.get("key");
  }

  get value() {
    return this.data.get("value");
  }
}
