import { connect } from 'react-redux';
import { deleteDimension } from '../../state/dimensions';
import { deleteMeasure } from '../../state/measures';
import { deleteCalculation } from '../../state/calculations';
import { selectPivotDimension } from '../../state/pivotDimensions';
import queryAttributeList from './QueryAttributeList';

const mapStateToProps = state => ({
  dimensions: state.dimensions,
  measures: state.measures,
  calculations: state.calculations,
  pivotDimensions: state.pivotDimensions,
});

export default connect(mapStateToProps, {
  deleteDimension,
  deleteMeasure,
  deleteCalculation,
  selectPivotDimension,
})(queryAttributeList);
