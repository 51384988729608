import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input', 'image'];

  change_preview() {
    const image = this.imageTarget;
    const file = this.inputTarget.files[0];
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      function() {
        image.src = reader.result;
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }

    window.URL.revokeObjectURL(reader);
  }
}
