import React from 'react';
import PropTypes from 'prop-types';

const ProfileImage = ({ logoUrl }) => {
  return <img src={logoUrl} alt="user profile pic" />;
};

ProfileImage.propTypes = {
  logoUrl: PropTypes.string.isRequired,
};

export default ProfileImage;
