import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Panel from '../panel';
import EventFilter from './EventFilter';
import EventDetailPanel from './EventDetailPanel';

const ShowPage = props => {
  const {
    calendar_events: calendarEvents,
    property_id: propertyId,
    property_name: propertyName,
    edit_privilages: editPrivilages,
  } = props;

  const defaultFilters = {
    checkedInstagramPost: false,
    checkedResidentEvent: false,
    checkedTour: false,
    checkedWorkSchedule: false,
    checkedTravelEvent: false,
    checkedEndOfSeason: true,
    checkedVIP: true,
    checkedRenewal: true,
    checkedLeadGeneration: true,
    checkedBrandAwareness: true,
    checkedUniversityDate: true,
  };

  const arraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }
    return true;
  };
  
  const [filters, setFilters] = useState(() => {
    const defaultFiltersArray = Object.keys(defaultFilters);
    const storedFilters = JSON.parse(localStorage.getItem('PropertyCalendarFilters'));
    
    if (storedFilters) {
      const storedFiltersKeys = Object.keys(storedFilters);
  
      if (arraysEqual(defaultFiltersArray, storedFiltersKeys)) {
        return storedFilters;
      } else {
        localStorage.removeItem('PropertyCalendarFilters');
      }
    }
  
    return defaultFilters;
  });
  useEffect(() => {
    localStorage.setItem(`PropertyCalendarFilters`, JSON.stringify(filters));
  }, [filters]);

  const handleCheckboxChange = event => {
    setFilters({ ...filters, [event.target.name]: event.target.checked });
  };
  return (
    <>
      <img
        src="https://res.cloudinary.com/the-scion-group/image/upload/v1693406500/m9kyblqfghx1oicgs3ky.jpg"
        alt="property calendar key"
        className="w-full"
      />
      <EventFilter handleCheckboxChange={handleCheckboxChange} filters={filters} />
      <Panel>
        <EventDetailPanel
          calendarEvents={calendarEvents}
          propertyId={propertyId}
          propertyName={propertyName}
          filters={filters}
          editPrivilages={editPrivilages}
        />
      </Panel>
    </>
  );
};

ShowPage.propTypes = {
  calendar_events: PropTypes.instanceOf(Array),
  property_id: PropTypes.number,
  property_name: PropTypes.string.isRequired,
  edit_privilages: PropTypes.bool,
};

ShowPage.defaultProps = {
  calendar_events: [],
  property_id: null,
  edit_privilages: false,
};

export default ShowPage;
