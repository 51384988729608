let Highcharts = require('highcharts');
require('highcharts/modules/exporting')(Highcharts);
Highcharts.setOptions({lang: { decimalPoint: '.', thousandsSep: ',' }});

import columnrangeChart from "../common/columnrange";
import themeSelector from "../themes/theme_selector";
import {capitalize} from "@utils/lib/string";
import {statToColor} from "@utils/lib/color";
import {decimalToPercent} from "@utils/lib/number";
import {focusZoom} from "../utils";
const moment = require('moment');


class LeadChart{
  constructor(selectorName){
    if(selectorName === undefined && !$(`${selectorName}`).length){
      throw "Chart Error: Not a valid highchart";
    }
    this.state = {
      className: selectorName,
      domElement: $(selectorName),
      theme: null,
      title: null,
      data: {},
      chart: null,
      columns: null,
      options: {exporting: { buttons: { }}},
    }
  }

  createChart(data){
    this.state = {...this.state, ...data }
    let {options} = this.state;
    options = Highcharts.merge(options, this._createChartOptions());
    options = Highcharts.merge(options, themeSelector(data.theme));
    this.state.chart = columnrangeChart(options);
    this._resetToDataZoom();
  }

  updateChart(data){
    this.state.chart.showLoading();
    this.state = {...this.state, ...data }
    let {options} = this.state;
    options = Highcharts.merge(options, this._createChartOptions());
    options = Highcharts.merge(options, themeSelector(data.theme));
    this.state.chart.update(options);
    this.state.chart.redraw();
    this.state.chart.hideLoading();
    this._resetToDataZoom();
  }

  addButtonOptions(data){
    this.state.options.exporting.buttons[data.text] = data;
  }

  _createChartOptions(){
    const _this = this;
    return {
      chart: {
        renderTo: _this.state.domElement[0],
        zoomType: 'x',
        resetZoomButton: {
          theme: {
              display: 'none'
          }
        }
      },
      title: {
        text: _this.state.title
      },
      tooltip: {
        useHTML: true,
        formatter: function () {
          let formattedTooltip = "";
          this.points.forEach(point => {
            const { base, difference, comparer, series } = point.point;

            formattedTooltip += `<div style='min-width: 350px;'>`;
            formattedTooltip += `<table class="highcharts-table">`;
            formattedTooltip += `<tr>
                                  <th style="width: 140px">
                                    ${base.date}
                                  </th>
                                  <th style="width: 70px" class="text-center">${_this.state.columns[0]}</th>
                                  <th style="width: 70px" class="text-center">${_this.state.columns[1]}</th>
                                  <th style="width: 70px" class="text-center">${_this.state.columns[2]}</th>
                                 </tr>`;
            if(_this.state.domElement.data('type') !== 'properties_vs_market'){
              formattedTooltip += `<tr>
                                    <td>Signed Contracts</th>
                                    <td class="text-center"><b>${base.prelease_capped_delta}</b></td>
                                    <td class="text-center"><b>${comparer.prelease_capped_delta}</b></td>
                                    <td class="text-center" style="color: ${statToColor(difference.prelease_capped_delta)}"><b>${difference.prelease_capped_delta}</b></td>
                                  </tr>`;
            }
            formattedTooltip += `<tr>
                                  <td>Signed Prelease</th>
                                  <td class="text-center"><b>${decimalToPercent(base.prelease_capped_delta_perc)}%</b></td>
                                  <td class="text-center"><b>${decimalToPercent(comparer.prelease_capped_delta_perc)}%</b></td>
                                  <td class="text-center" style="color: ${statToColor(difference.prelease_capped_delta_perc)}"><b>${decimalToPercent(difference.prelease_capped_delta_perc)}%</b></td>
                                </tr>`;
            if(_this.state.domElement.data('type') !== 'properties_vs_market'){
              formattedTooltip += `<tr>
                                    <td>Total Contracts</th>
                                    <td class="text-center"><b>${Highcharts.numberFormat(base.prelease_capped_total,-1,'.',',')}</b></td>
                                    <td class="text-center"><b>${Highcharts.numberFormat(comparer.prelease_capped_total,-1,'.',',')}</b></td>
                                    <td class="text-center" style="color: ${statToColor(difference.prelease_capped_total)}"><b>${Highcharts.numberFormat(difference.prelease_capped_total,-1,'.',',')}</b></td>
                                  </tr>`;
            }
            formattedTooltip += `<tr>
                                  <td>Total Prelease</th>
                                  <td class="text-center"><b>${decimalToPercent(base.prelease_capped_total_perc)}%</b></td>
                                  <td class="text-center"><b>${decimalToPercent(comparer.prelease_capped_total_perc)}%</b></td>
                                  <td class="text-center" style="color: ${statToColor(difference.prelease_capped_total_perc)}"><b>${decimalToPercent(difference.prelease_capped_total_perc)}%</b></td>
                                </tr>`;
            formattedTooltip += `</table>`;
            formattedTooltip += `</div>`;

          });
          return formattedTooltip;
        }
      },
      yAxis: {
        labels: {
          formatter: function() {
             return this.value+"%";
          }
        },
        plotLines: [{
          color: '#bfc1c2',
          width: 1,
          value: 0
        }]
      },
      xAxis: {
        type: "category",
        categories: _this.state.data.map(content => moment(content.base.date).format("MMM-DD")),
        plotBands: [{
            from: (_this.state.data.findIndex(content => content.id == _this.state.index) + .5),
            to: (_this.state.data.length),
            color: '#F7F7F7'
        }]
      },
      legend: {
          enabled: false
      },
      series: [
        {
          name: "prelease_lead",
          data: _this.state.data.map((content, index, array) => {
            let low = 0, high = 0;
            const prelease_delta = decimalToPercent(content.difference.prelease_capped_total_perc);
            const previous_prelease_delta = decimalToPercent((array[index-1] && array[index-1].difference.prelease_capped_total_perc) || 0.0);

            if(previous_prelease_delta <= prelease_delta){
              low = previous_prelease_delta;
              high = prelease_delta;
            } else {
              low = prelease_delta;
              high = previous_prelease_delta;
            }

            const color = (prelease_delta - previous_prelease_delta) >= 0 ? '#606988' : '#D65757';

            return {
                     ...content,
                     index: index,
                     low,
                     high,
                     borderColor: color,
                     color: color,
                     name: moment(content.base.date).format("MMM-DD")
                   }
          }),
          point: {
            events: {
              click: function() {
                if(_this.state.engine !== null || _this.state.engine !== 'undefined'){
                  const date = this.base.date;
                  const segment = _this.state.segment;
                  _this.state.engine._getSegmentedData(segment, date)
                }
              }
            }
          }
        },
      ],
      exporting: {
        enabled: true,
        buttons: {
          defaultZoomButton: {
            text: 'Default',
            x: -185,
            theme: {
              padding: 10,
              stroke: '#a8a8a8',
              fill: '#F0F0F0',
              states: {
                hover: {
                    fill: '#d8d8d8'
                }
              }
            },
            onclick: function(){
              _this._resetToDataZoom();
            }
          },
          currentZoomButton: {
            text: 'Season',
            x: -115,
            theme: {
              padding: 10,
              stroke: '#a8a8a8',
              fill: '#F0F0F0',
              states: {
                hover: {
                    fill: '#d8d8d8'
                }
              }
            },
            onclick: function(){
              const focalPoint = _this.state.chart.series[0].data.findIndex(content => content.id == _this.state.index );
              const extremes = { min: 0, max: (_this.state.chart.series[0].data.length - 1), offsetLeft: (_this.state.chart.series[0].data.length - 1), offsetRight: 0}
              focusZoom(_this.state.chart, focalPoint, extremes);
            }
          },
          futureZoomButton: {
            text: 'Future',
            x: -50,
            theme: {
              padding: 10,
              stroke: '#a8a8a8',
              fill: '#F0F0F0',
              states: {
                hover: {
                    fill: '#d8d8d8'
                }
              }
            },
            onclick: function(){
              const focalPoint = _this.state.chart.series[0].data.findIndex(content => content.id == _this.state.index );
              const extremes = { min: 0, max: (_this.state.chart.series[0].data.length - 1), offsetLeft: 7, offsetRight: 14}
              focusZoom(_this.state.chart, focalPoint, extremes);
            }
          },
          allZoomButton: {
            text: 'Full',
            x: 0,
            theme: {
              padding: 10,
              stroke: '#a8a8a8',
              fill: '#F0F0F0',
              states: {
                hover: {
                    fill: '#d8d8d8'
                }
              }
            },
            onclick: function(){
              _this.state.chart.xAxis[0].setExtremes(null,null);
            }
          },
          contextButton: {
            enabled: false,
          }
        }
      },
      plotOptions: { series: { cropThreshold: 100, turboThreshold: 2000 } },
      navigation: {
        buttonOptions: {
            theme: {
                states: {
                    hover: {
                        fill: '#ededed'
                    }
                }
            }
        }
    }
    }
  }

  chart(){
    return this.state.chart;
  }

  _resetToDataZoom(){
    const focalPoint = this.state.chart.series[0].data.findIndex(content => content.id == this.state.index );
    const extremes = { min: 0, max: (this.state.chart.series[0].data.length - 1), offsetLeft: 14, offsetRight: 0}
    focusZoom(this.state.chart, focalPoint, extremes);
  }

}

export default LeadChart;
