import { withFormik, Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import * as Yup from 'yup';

import api from '../api';
import FormikInputBasic from '../shared/formik_inputs/FormikInputBasic';
import FormikInputDatePicker from '../shared/formik_inputs/FormikInputDatePicker';
import FormikInputSelect from '../shared/formik_inputs/FormikInputSelect';
import FormikInputCheckbox from '../shared/formik_inputs/FormikInputCheckbox';
import Button from '../shared/Button';

const incentive_types = [
  { value: 'Fee Concession', label: 'Fee Concession' },
  { value: 'Gift', label: 'Gift' },
  { value: 'Miscellaneous', label: 'Miscellaneous' },
  { value: 'Rate Concession', label: 'Rate Concession' },
  { value: 'Reward Card', label: 'Reward Card' },
];

const methods = [
  { value: 'one_time', label: 'One Time' },
  { value: 'monthly', label: 'Monthly' },
];

const scopes = [
  { value: 'both', label: 'Both' },
  { value: 'new', label: 'New' },
  { value: 'renewal', label: 'Renewal' },
];

const IncentiveForm = props => {
  const { values, handleSubmit, isEdit, unitMixes, isSubmitting } = props;

  return (
    <form onSubmit={handleSubmit}>
      <h3 className="text-2xl font-light mb-6">{isEdit ? 'Update' : 'New'} Incentive</h3>

      {isEdit && (
        <p className="mb-6 text-red-600">
          {`If the value or method of concession has changed, please enter an end date below to close
        the current special. A separate entry should then be submitted for the new concession with
        the updated value/method. Only use the edit function to change the value or method fields if
        there was an error upon original entry.`}
        </p>
      )}

      <div className="flex flex-wrap -mx-3">
        <div className="w-full md:w-1/3 px-3 mb-6">
          <Field
            label="Method"
            value={values.incentive.method}
            name="incentive.method"
            options={methods}
            component={FormikInputSelect}
          />
        </div>

        <div className="w-full md:w-1/3 px-3 mb-6">
          <Field
            label="Incentive Type"
            value={values.incentive.incentive_type}
            name="incentive.incentive_type"
            options={incentive_types}
            component={FormikInputSelect}
          />
        </div>

        <div className="w-full md:w-1/3 px-3 mb-6">
          <Field
            label="Eligibility"
            value={values.incentive.scope}
            name="incentive.scope"
            options={scopes}
            component={FormikInputSelect}
          />
        </div>
      </div>

      <div className="flex flex-wrap -mx-3">
        <div className="w-full px-3 mb-6">
          <Field
            label="Unit Mixes"
            value={values.incentive.unit_mix_ids}
            name="incentive.unit_mix_ids"
            isMulti
            menuIsOpen
            allowSelectAll
            options={unitMixes.map(unitMix => ({
              value: unitMix.id,
              label: [
                unitMix.unit_type,
                unitMix.unit_style,
                `${unitMix.beds_unit}x${unitMix.baths_unit}`,
              ]
                .filter(i => !!i)
                .join(' - '),
            }))}
            component={FormikInputSelect}
          />
        </div>
      </div>

      <div className="flex flex-wrap -mx-3">
        <div className="w-full px-3 mb-6">
          <Field
            type="number"
            label={
              <span>
                Total Value{' '}
                <small className="align-middle">(Ex $10/mo equals $120 total value)</small>
              </span>
            }
            name="incentive.value"
            component={FormikInputBasic}
          />
        </div>
      </div>

      <div className="flex flex-wrap -mx-3">
        <div className="w-full px-3 mb-6">
          <Field
            type="text"
            label="Description"
            name="incentive.description"
            component={FormikInputBasic}
          />
        </div>
      </div>

      <div className="flex -mx-3 mb-10">
        <div className="w-full md:w-2/5 px-3">
          <Field
            uid="incentive-start-date"
            value={values.incentive.start_date}
            label="Start Date"
            name="incentive.start_date"
            numberOfMonths={1}
            isOutsideRange={() => false}
            hideKeyboardShortcutsPanel
            component={FormikInputDatePicker}
            block
            required
          />
        </div>

        <div className="w-full md:w-2/5 px-3">
          <Field
            uid="incentive-end-date"
            value={values.incentive.end_date}
            label="End Date"
            name="incentive.end_date"
            numberOfMonths={1}
            isOutsideRange={() => false}
            hideKeyboardShortcutsPanel
            component={FormikInputDatePicker}
            block
          />
        </div>

        <div className="w-full md:w-1/5 px-3">
          <Field
            label="Advertised"
            value={values.incentive.advertised}
            name="incentive.advertised"
            component={FormikInputCheckbox}
          />
        </div>
      </div>

      {status.error && <div className="text-red-600 p-1 my-1">{status.error}</div>}

      <Button type="submit" variant="primary" size="lg" block isDisabled={isSubmitting}>
        Submit
      </Button>
    </form>
  );
};

IncentiveForm.propTypes = {
  values: PropTypes.shape({
    incentive: PropTypes.shape({
      method: PropTypes.string,
      value: PropTypes.number,
      start_date: PropTypes.string,
      end_date: PropTypes.string,
      description: PropTypes.string,
      incentive_type: PropTypes.string,
      scope: PropTypes.string,
      advertised: PropTypes.bool,
      unit_mix_ids: PropTypes.arrayOf(PropTypes.number),
    }),
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool,
  unitMixes: PropTypes.arrayOf(PropTypes.shape({})),
};

IncentiveForm.defaultProps = {
  isEdit: false,
  unitMixes: [],
};

const ValidationSchema = Yup.object().shape({
  incentive: Yup.object().shape({
    method: Yup.string().required('Please choose a valid option'),
    value: Yup.number()
      .moreThan(-1, 'Value must be 0 or more')
      .required('Please enter a positive number.'),
    unit_mix_ids: Yup.array()
      .ensure()
      .min(1, 'Please choose at least 1 Unit Mix.'),
    start_date: Yup.date().required('Please select a valid date'),
    end_date: Yup.date().nullable(),
    incentive_type: Yup.string().required('Please choose a valid option'),
    scope: Yup.string().required('Please choose a valid option'),
    advertised: Yup.bool(),
    description: Yup.string().when('incentive_type', {
      is: incentive_type => incentive_type === 'Gift',
      then: Yup.string().required('Please enter a valid description'),
      otherwise: Yup.string(),
    }),
  }),
});

const EnhancedForm = withFormik({
  enableReinitialize: false,

  validationSchema: ValidationSchema,

  handleSubmit: (values, { setSubmitting, setStatus, props }) => {
    const { incentive, isEdit } = props;
    const params = { propertyId: incentive.property_id, incentiveId: isEdit && incentive.id };

    return api.competitor_incentives
      .createOrUpdate(params, values)
      .then(() => {
        props.onUpdate();
      })
      .catch(() => {
        setSubmitting(false);
        setStatus({ error: 'An error has occurred. Please try again or contact support.' });
      });
  },

  mapPropsToValues: ({ incentive }) => {
    return {
      incentive: {
        ...incentive,
        method: incentive.method || '',
        value: incentive.value || 0,
        unit_mix_ids: incentive.unit_mix_ids || [],
        start_date: incentive.start_date || undefined,
        end_date: incentive.end_date || undefined,
        incentive_type: incentive.incentive_type || '',
        scope: incentive.scope || '',
        advertised: incentive.advertised || false,
        description: incentive.description || '',
      },
    };
  },

  displayName: 'IncentiveForm',
});

export default EnhancedForm(IncentiveForm);
