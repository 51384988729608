import React from 'react';
import PropTypes from 'prop-types';

import { Box, Popover, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';

const InfoPopover = ({ text }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openPopOver = event => {
    setAnchorEl(event.currentTarget);
  };

  const closePopOver = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const makeIconActive = event => {
    event.target.style.cursor = 'pointer';
  };

  const textArray = text.constructor === Array ? text : [text];

  return (
    <>
      <InfoIcon
        style={{ fontSize: 'small' }}
        className="align-text-center"
        variant="contained"
        onClick={openPopOver}
        onMouseOver={makeIconActive}
        onFocus={makeIconActive}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={closePopOver}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2 }}>
          {textArray.map(t => (
            <Typography key={t}>{t}</Typography>
          ))}
        </Box>
      </Popover>
    </>
  );
};

InfoPopover.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export default InfoPopover;
