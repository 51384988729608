import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const Card = ({ children }) => <div className={classNames('p-3', 'bg-white')}>{children}</div>;

Card.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Card;
