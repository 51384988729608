import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Image from './Image';

const ImageGrid = ({ images }) => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        overflowY: 'scroll',
        height: '100vh',
        padding: '12px',
      }}
    >
      <Grid container spacing={2}>
        {images.map(image => (
          <Image key={image.id} image={image} />
        ))}
      </Grid>
    </Box>
  );
};
ImageGrid.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
export default ImageGrid;
