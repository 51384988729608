import React from 'react';

const ItemImageOrPdf = ({ field, value }) => {
  const isPDF = value && value.toLowerCase().endsWith('.pdf');

  return (
    <div className="item-pdf-container">
      <h3>{field.label}</h3>
      <div className="pdf-wrapper" style={{
        width: '100%',
        height: '500px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden'
      }}>
        {value ? (
          isPDF ? (
            <iframe
              src={value}
              title={field.label || "Current PDF"}
              width="100%"
              height="100%"
              style={{ border: 'none' }}
            />
          ) : (
            <img
              src={value}
              alt={field.label || "Current image"}
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'cover'
              }}
            />
          )
        ) : (
          <p>No file available</p>
        )}
      </div>
    </div>
  );
};

export default ItemImageOrPdf;