import PropTypes from 'prop-types';
import React, { useState, useEffect, useMemo } from 'react';
import * as Icon from 'react-feather';

import PropertyFeeFormContainer from './PropertyFeeFormContainer';
import { usePanelDispatch } from '../panel/PanelContext';
import * as panelTypes from '../panel/panelTypes';
import StandardTable from '../table/StandardTable';

const editCell = (fees, onUpdate) => cellInfo => {
  let {
    row: { original: rowAttributes },
  } = cellInfo;
  return (
    <EditPropertyFeeButton
      fees={fees}
      formObject={rowAttributes}
      propertyId={rowAttributes.property_id}
      propertyFeeId={rowAttributes.id}
      onUpdate={onUpdate}
    />
  );
};

const PropertyFeeTable = ({ data: propertyFees, fees, onUpdate, hideColumns }) => {
  const [data, setData] = useState(propertyFees);

  useEffect(() => {
    setData(propertyFees);
  });

  const columns = useMemo(() => [
    {
      Header: 'Fee',
      accessor: 'name',
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      className: 'text-center',
    },
    {
      Header: 'Waived?',
      accessor: 'is_waived',
      className: 'text-center',
      Cell: row => (row.row.original.is_waived ? 'x' : ''),
    },
    {
      Header: 'Additional Information',
      accessor: 'additional_info',
    },
    {
      Header: 'Edit',
      accessor: 'edit',
      show: !hideColumns.includes('edit'),
      className: 'text-center',
      disableSortBy: true,
      Cell: editCell(fees, onUpdate),
    },
  ]);

  return <StandardTable data={data} columns={columns} hasPagination={false} />;
};

PropertyFeeTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      amount: PropTypes.number,
      edit: PropTypes.string,
    })
  ).isRequired,
  fees: PropTypes.instanceOf(Array).isRequired,
  hideColumns: PropTypes.arrayOf(PropTypes.string),
  onUpdate: PropTypes.func,
  formObject: PropTypes.shape({}),
};

PropertyFeeTable.defaultProps = {
  onUpdate: undefined,
  hideColumns: [],
  formObject: {},
};

const EditPropertyFeeButton = ({ fees, formObject, propertyId, propertyFeeId, onUpdate }) => {
  const panelDispatch = usePanelDispatch();

  const handleUpdate = () => {
    onUpdate();
    panelDispatch({
      type: panelTypes.CLOSE,
    });
  };

  return (
    <button
      type="button"
      className="inline-block text-center"
      onClick={() => {
        panelDispatch({
          type: panelTypes.OPEN,
          render: (
            <PropertyFeeFormContainer
              fees={fees}
              formObject={formObject}
              isEdit
              propertyId={propertyId}
              propertyFeeId={propertyFeeId}
              onUpdate={handleUpdate}
            />
          ),
        });
      }}
    >
      <Icon.Edit size="12" />
    </button>
  );
};

EditPropertyFeeButton.propTypes = {
  fees: PropTypes.instanceOf(Array).isRequired,
  formObject: PropTypes.shape({}),
  propertyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  propertyFeeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onUpdate: PropTypes.func,
};

EditPropertyFeeButton.defaultProps = {
  formObject: {},
  propertyFeeId: null,
  onUpdate: undefined,
};

export default PropertyFeeTable;
