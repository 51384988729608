import PropTypes from 'prop-types';
import React from 'react';

import api from '../../api';
import Button from '../../shared/Button';
import { usePanelDispatch } from '../../panel/PanelContext';
import * as panelTypes from '../../panel/panelTypes';

const ItemDeleteButton = ({
  formAttributes,
  itemToEdit,
  modelName,
  setFormErrors,
  setStateIndexItems,
  stateIndexItems,
  values,
}) => {
  const panelDispatch = usePanelDispatch();

  const windowConfirm = () => {
    if (values.itemToEdit?.update_scope_form === 'this_and_following_events') {
      return window.confirm(
        "Are you sure you want to delete this and all following calendar events in this series?\nIf yes, click 'OK'"
      );
    } else {
      return window.confirm(
        `Are you sure you want to delete this ${
          formAttributes.title ? formAttributes.title : modelName
        } record?\nIf yes, click 'OK'.`
      );
    }
  };

  const handleDelete = () => {
    if (windowConfirm()) {
      const params = { ...formAttributes.params, form_object: itemToEdit, values: values };

      api.crudItems
        .destroy(params)
        .then(res => {
          let newData = stateIndexItems.filter(function(obj) {
            return !res.data.map(item => item.id).includes(obj.id);
          });
          setStateIndexItems(newData);
        })
        .catch(error => {
          setFormErrors(error.response.data.errors);
        });
    }
  };

  return (
    <Button
      className="mt-2"
      type="button"
      variant="secondary"
      size="lg"
      block
      onClick={() => {
        panelDispatch({
          type: panelTypes.CLOSE,
        });
        handleDelete();
      }}
    >
      {formAttributes.delete_button_text || `Delete ${modelName}`}
    </Button>
  );
};

ItemDeleteButton.propTypes = {
  formAttributes: PropTypes.shape({
    delete_button_text: PropTypes.string,
    params: PropTypes.shape({}).isRequired,
    title: PropTypes.string,
  }).isRequired,
  itemToEdit: PropTypes.shape({}).isRequired,
  modelName: PropTypes.string,
  setFormErrors: PropTypes.func.isRequired,
  setStateIndexItems: PropTypes.func,
  stateIndexItems: PropTypes.arrayOf(PropTypes.shape({})),
  values: PropTypes.shape({
    itemToEdit: PropTypes.shape({
      update_scope_form: PropTypes.string,
    }),
  }).isRequired,
};

ItemDeleteButton.defaultProps = {
  modelName: 'Item',
  stateIndexItems: [],
  setStateIndexItems: () => {},
};

export default ItemDeleteButton;
