import { withFormik, Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import api from '../../api';
import Button from '../../shared/Button';
import FormikInputBasic from '../../shared/formik_inputs/FormikInputBasic';
import FormikInputCheckbox from '../../shared/formik_inputs/FormikInputCheckbox';
import FormikInputDatePicker from '../../shared/formik_inputs/FormikInputDatePicker';
import FormikInputFile from '../../shared/formik_inputs/FormikInputFile';
import FormikInputNoEdit from '../../shared/formik_inputs/FormikInputNoEdit';
import FormikInputSelect from '../../shared/formik_inputs/FormikInputSelect';
import FormikInputSwitch from '../../shared/formik_inputs/FormikInputSwitch';
import FormikInputTextArea from '../../shared/formik_inputs/FormikInputTextArea';
import FormikInputTimePicker from '../../shared/formik_inputs/FormikInputTimePicker';
import ItemCustomButton from './ItemCustomButton';
import ItemDeleteButton from './ItemDeleteButton';
import ItemImageOrPdf from './ItemImageOrPdf';
import FormHeader from '../../shared/FormHeader';
import FormInstructions from '../../shared/FormInstructions';

const fieldComponentType = {
  checkbox: FormikInputCheckbox,
  datepicker: FormikInputDatePicker,
  file: FormikInputFile,
  noedit: FormikInputNoEdit,
  number: FormikInputBasic,
  select: FormikInputSelect,
  switch: FormikInputSwitch,
  text: FormikInputBasic,
  textarea: FormikInputTextArea,
  timepicker: FormikInputTimePicker,
  image: ItemImageOrPdf,
};

const ItemForm = props => {
  const {
    formAttributes, // header attribute can be passed as a form attribute
    handleSubmit,
    setFieldValue,
    isEdit,
    isSubmitting,
    itemToEdit,
    modelName,
    setFormErrors,
    setStateIndexItems,
    stateIndexItems,
    values,
  } = props;
  const isFieldDisabled = field => {
    return (
      field.enabled_conditions &&
      !Object.keys(field.enabled_conditions).every(key => {
        return (
          field.enabled_conditions[key].includes(values.itemToEdit[key]) ||
          (!isEdit &&
            formAttributes.fields.find(field => field.name === key && field.edit_only === true))
        );
      })
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <h3 className="text-2xl font-light my-6">
        {isEdit
          ? `Update ${formAttributes.title ? formAttributes.title : modelName}`
          : `New ${formAttributes.title ? formAttributes.title : modelName}`}
      </h3>
      {isEdit && values.itemToEdit.image && (
        <ItemImageOrPdf
          field={{ label: 'Current Image' }}
          value={values.itemToEdit.image}
        />
      )}
      {formAttributes.header_notice && (
        <p className="text-sm font-light leading-normal text-yellow-700 -mt-1 mb-6">
          {formAttributes.header_notice}
        </p>
      )}

      <div className={formAttributes.style}>
        {formAttributes.fields.map(field => {
          if (
            field.visible_conditions &&
            !Object.keys(field.visible_conditions).every(key => {
              return (
                field.visible_conditions[key].includes(values.itemToEdit[key]) ||
                (!isEdit &&
                  formAttributes.fields.find(
                    field => field.name === key && field.edit_only === true
                  ))
              );
            })
          ) {
            return null;
          } else if (field.hidden) {
            return null;
          } else if (field.edit_only && !isEdit) {
            return null;
          } else if (field.new_only && isEdit) {
            return null;
          } else if (field.type === 'select') {
            return (
              <div key={field.name} className={field.style}>
                <Field
                  allowSelectAll={field.select_all}
                  component={fieldComponentType[field.type]}
                  isClearable={field.clearable}
                  isdisabled={isFieldDisabled(field) || field.disabled}
                  isMulti={field.multi_select}
                  label={field.label + (field.required ? ' *' : '')}
                  name={`itemToEdit.${field.name}`}
                  options={field.options}
                  required={field.required}
                  value={field.value || values.itemToEdit[field.name]}
                />
              </div>
            );
          } else if (field.type === 'number') {
            return (
              <div key={field.name} className={field.style}>
                <Field
                  component={fieldComponentType[field.type]}
                  label={field.label + (field.required ? ' *' : '')}
                  minimumValue={field.minimum_value}
                  maximumValue={field.maximum_value}
                  name={`itemToEdit.${field.name}`}
                  required={field.required}
                  type="number"
                  value={field.value || values.itemToEdit[field.name]}
                />
              </div>
            );
          } else if (field.type === 'datepicker') {
            return (
              <div key={field.name} className={field.style}>
                <Field
                  component={fieldComponentType[field.type]}
                  isDisabled={isFieldDisabled(field) || field.disabled}
                  label={field.label + (field.required ? ' *' : '')}
                  minDate={field.min_date}
                  maxDate={field.max_date}
                  name={`itemToEdit.${field.name}`}
                  required={field.required}
                  value={values.itemToEdit[field.name]}
                  businessDaysBlocked={field.business_days_blocked}
                />
              </div>
            );
          } else if (field.type === 'groupheader') {
            return (
              // eslint-disable-next-line react/jsx-key, react/style-prop-object
              <div className={field.style} key={field.label}>
                <FormHeader title={field.label} is_html={field.is_html} />
              </div>
            );
          } else if (field.type === 'forminstructions') {
            return (
              <div className={field.style} key={field.text}>
                <FormInstructions formInstructions={field.text} textStyle={field.text_style} />
              </div>
            );
          } else if (field.type === 'file') {
            return (
              <div key={field.name} className={field.style}>
                <FormikInputFile
                  field={field}
                  label={field.label + (field.required ? ' *' : '')}
                  name={`itemToEdit.${field.name}`}
                  isDisabled={isFieldDisabled(field) || field.disabled}
                  multiple={field.multiple}
                  required={field.required}
                  setFieldValue={setFieldValue}
                />
              </div>
            );
          } else if (field.type === 'image') {
            return (
              <div key={field.name} className={field.style}>
                <ItemImageOrPdf
                  field={field}
                  label={field.label}
                  name={`itemToEdit.${field.name}`}
                  required={field.required}
                  value={values.itemToEdit[field.name]}
                />
              </div>
            );
          }
          else {
            return (
              <div key={field.name} className={field.style}>
                <Field
                  component={fieldComponentType[field.type]}
                  isdisabled={isFieldDisabled(field) || field.disabled}
                  label={field.label + (field.required ? ' *' : '')}
                  name={`itemToEdit.${field.name}`}
                  required={field.required}
                  value={values.itemToEdit[field.name] || ''}
                />
              </div>
            );
          }
        })}
      </div>

      {status.error && <div className="text-red-600 p-1 my-1">{status.error}</div>}
      {formAttributes.submit_button != false && (
        <Button type="submit" variant="primary" size="lg" block isDisabled={isSubmitting}>
          {formAttributes.submit_button_text || 'Submit'}
        </Button>
      )}
      {formAttributes.custom_buttons && (
        <div>
          {formAttributes.custom_buttons.map(button => {
            if (button.show) {
              return (
                <div className="flex-1 mt-3" key={button.text}>
                  <ItemCustomButton
                    buttonMessage={button.button_message}
                    props={props}
                    setFormErrors={setFormErrors}
                    setStateIndexItems={setStateIndexItems}
                    stateIndexItems={stateIndexItems}
                    text={button.text}
                    values={values}
                  />
                </div>
              );
            }
          })}
        </div>
      )}
      {isEdit && formAttributes.delete_button && (
        <div className="flex-1 mt-3">
          <ItemDeleteButton
            formAttributes={formAttributes}
            itemToEdit={itemToEdit}
            modelName={modelName}
            setFormErrors={setFormErrors}
            setStateIndexItems={setStateIndexItems}
            stateIndexItems={stateIndexItems}
            values={values}
          />
        </div>
      )}
    </form>
  );
};

ItemForm.propTypes = {
  values: PropTypes.shape({
    itemToEdit: PropTypes.shape({}),
  }).isRequired,
  formAttributes: PropTypes.shape({
    custom_buttons: PropTypes.arrayOf(PropTypes.shape({})),
    delete_button: PropTypes.bool,
    fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    header_notice: PropTypes.string,
    style: PropTypes.string.isRequired,
    submit_button: PropTypes.bool,
    submit_button_text: PropTypes.string,
    title: PropTypes.string,
    form_instructions: PropTypes.string,
  }).isRequired,
  itemToEdit: PropTypes.shape({}),
  handleSubmit: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  isSubmitting: PropTypes.bool.isRequired,
  modelName: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  setFormErrors: PropTypes.func.isRequired,
  setStateIndexItems: PropTypes.func,
  stateIndexItems: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string,
};

ItemForm.defaultProps = {
  isEdit: false,
  itemToEdit: {},
  modelName: 'Item',
  stateIndexItems: [],
  setStateIndexItems: () => {},
  title: null,
};

const EnhancedForm = withFormik({
  mapPropsToValues: ({ itemToEdit }) => {
    let newObj = { itemToEdit: { ...itemToEdit } };
    Object.keys(itemToEdit).forEach(key => {
      newObj.itemToEdit[key] = itemToEdit[key];
    });
    return newObj;
  },

  enableReinitialize: false,

  handleSubmit: (values, { setSubmitting, props }) => {
    setSubmitting(true);
    const { formAttributes, isEdit } = props;
    const params = {
      ...formAttributes.params,
      form_object: values.itemToEdit,
      isEdit: isEdit,
    };

    api.crudItems
      .updateOrCreate(params)
      .then(response => {
        if (response.data.redirect_path) {
          window.location.href = response.data.redirect_path;
        } else {
          props.onUpdate();
        }
      })
      .catch(error => {
        props.setFormErrors(error.response.data.errors);
        setSubmitting(false);
      });
  },

  displayName: 'ItemForm',
});

export default EnhancedForm(ItemForm);
