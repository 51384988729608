import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Icon from '@material-ui/core/Icon';
import { Button, Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

import api from '../api';

const DownloadButton = ({ url, icon, data }) => {
  const [isRequested, setIsRequested] = useState(false);

  const [notification, setNotification] = useState({
    open: false,
    title: '',
    content: '',
    url: '',
    severity: 'info',
  });

  const onClick = () => {
    setIsRequested(true);
    api.axios
      .post(url, data)
      .then(() => {
        setNotification({
          open: true,
          title: 'Generating Report',
          content: `The report has been placed into queue.`,
          url: '/reports',
          severity: 'info',
        });
      })
      .catch(() => {
        setNotification({
          open: true,
          title: 'Report Error',
          content: 'An error has occurred with generating the report. Please try again.',
          severity: 'error',
        });
      })
      .then(() => {
        setIsRequested(false);
      });
  };

  const onClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const buttonStyle = {
    color: 'grey',
    borderColor: 'grey',
  };

  return (
    <>
      <Button style={buttonStyle} variant="outlined" onClick={onClick} disabled={isRequested}>
        {isRequested && 'Download Requested'}
        {!isRequested && <Icon>{icon}</Icon>}
      </Button>
      <Snackbar
        open={notification.open}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        autoHideDuration={6000}
        onClose={onClose}
      >
        <Alert className="text-left" severity={notification.severity} onClose={onClose}>
          <AlertTitle>{notification.title}</AlertTitle>
          {notification.content}
          {notification.url && (
            <a href={notification.url} className="font-bold underline inline">
              Click to View
            </a>
          )}
        </Alert>
      </Snackbar>
    </>
  );
};

DownloadButton.propTypes = {
  url: PropTypes.string.isRequired,
  data: PropTypes.shape({}).isRequired,
  icon: PropTypes.string,
};

DownloadButton.defaultProps = {
  icon: 'get_app',
};

export default DownloadButton;
