import _ from 'lodash';
import * as types from './types';

const filtersReducer = (filters = {}, action) => {
  if (action.type === types.ADD) {
    const { filter } = action.payload;
    return { ...filters, [filter.id]: filter };
  }

  if (action.type === types.UPDATE) {
    const { filter } = action.payload;
    return { ...filters, [filter.id]: filter };
  }

  if (action.type === types.DELETE) {
    const { filterId } = action.payload;
    return _.omit(filters, filterId);
  }

  return filters;
};

export default filtersReducer;
