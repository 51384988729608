/* eslint react/prop-types: 0 */

import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import StandardTable from '../../../../table/StandardTable';

const DEFAULT_PAGE_SIZE = 25;

const BITable = ({ headers, data }) => {
  const columns = () =>
    headers.map(h => ({
      Header: h.label,
      accessor: h.id,
    }));

  return (
    <>
      {data.length > 0 ? (
        <Box style={{ overflowX: 'scroll', overflowY: 'visible' }}>
          <StandardTable columns={columns()} data={data} defaultPageSize={DEFAULT_PAGE_SIZE} />
        </Box>
      ) : (
        <div>No results found</div>
      )}
    </>
  );
};

BITable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

BITable.defaultProps = {
  data: [],
};

export default BITable;
