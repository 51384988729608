/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import 'images';
import '../styles/app.scss';

import 'pickadate/lib/picker';
import 'pickadate/lib/picker.date';
import 'bootstrap/dist/js/bootstrap';

import '../components/components';
import '../modules/modules';

import '../styles/tailwind.css';
import "trix/dist/trix";

require.context('../images', true);

// Support component names relative to this directory:
var componentRequireContext = require.context('comps', true);
var ReactRailsUJS = require('react_ujs');

ReactRailsUJS.useContext(componentRequireContext);
ReactRailsUJS.mountComponents();
