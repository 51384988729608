/* eslint react/prop-types: 0 */

import React from 'react';
import { Provider } from 'react-redux';
import { Grid } from '@material-ui/core';
import store from './store';
import getInitialState from './state/initialState';
import QueryControl from './components/queryControl';
import FilterList from './components/filterList';
import DatasetSelectorList from './components/datasetSelectorList';
import QueryAttributeList from './components/queryAttributeList';

const QueryContainer = ({ data }) => {
  const initialState = getInitialState(data);
  return (
    <Provider store={store(initialState)}>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <DatasetSelectorList />
        </Grid>
        <Grid item xs={9}>
          <FilterList />
          <QueryAttributeList />
          <QueryControl />
        </Grid>
      </Grid>
    </Provider>
  );
};

export default QueryContainer;

/* <Typography variant="h1">Filters</Typography>
<Typography variant="h2">Filters</Typography>
<Typography variant="h3">Filters</Typography>
<Typography variant="h4">Filters</Typography>
<Typography variant="h5">Filters</Typography>
<Typography variant="h6">Filters</Typography>
<Typography variant="subtitle1">Filters</Typography>
<Typography variant="subtitle2">Filters</Typography>
<Typography variant="body1">Filters</Typography>
<Typography variant="body2">Filters</Typography>
<Typography variant="caption">Filters</Typography> */
