import PropTypes from 'prop-types';
import React from 'react';

const Loader = ({ children }) => (
  <span className="block text-center">{children ? children : 'Loading...'}</span>
);

Loader.propTypes = {
  children: PropTypes.node,
};

Loader.defaultProps = {
  children: null,
};

export default Loader;
