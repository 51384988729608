/* eslint-disable react/prop-types */
import React from 'react';

const createColumn = column => {
  const tableColumn = { ...column, columns: undefined };

  if (column.accessor) {
    tableColumn.accessor = row => {
      return row[column.accessor] && row[column.accessor].value;
    };

    tableColumn.id = `${column.accessor}`;

    tableColumn.Cell = ({ cell, row }) => {
      const rowAccessor = row.original[column.accessor];
      let value = String(cell.value);

      if (typeof rowAccessor === 'undefined') {
        return null;
      }

      if (rowAccessor.component) {
        const { element, display, ...props } = rowAccessor.component;
        const Component = element || 'span';
        value = <Component {...props}>{display || value}</Component>;
      }

      // eslint-disable-next-line no-console
      return value;
    };
  }

  if (column.columns && column.columns.length) {
    // eslint-disable-next-line no-use-before-define
    tableColumn.columns = makeColumns(column.columns);
  }

  return tableColumn;
};

const makeColumns = columns => {
  if (!Array.isArray(columns) || !columns.length) {
    return [];
  }

  const tableColumns = [];

  columns.forEach(column => {
    tableColumns.push(createColumn(column));
  });
  return tableColumns;
};

export default makeColumns;
