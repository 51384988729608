import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormControl, Grid, TextField } from '@material-ui/core';

// State
import { StoreContext, actions, createAction } from '../reducer';

// Components
import TierHeader from './TierHeader';
import TierContractsRateRow from './TierContractsRateRow';
import TierFooter from './TierFooter';

const Tier = ({
  tier: {
    tier_key: tierKey,
    tier: tier,
    tier_sequence: tierSequence,
    floorplan_spacetype_key: floorplanSpacetypeKey,
    previous_version_tier_contracts: previousVersionTierContracts,
    previous_version_tier_rate: previousVersionTierRate,
    tier_contracts: tierContracts,
    tier_rate: tierRate,
    tier_status: tierStatus,
    signed_rate: signedRate,
  },
  previousTargetRate,
  lastTier,
  isEditing,
}) => {
  const store = useContext(StoreContext);
  const state = store.state;
  const dispatch = store.dispatch;

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    dispatch(createAction(actions.UPDATE_CALCULATIONS));
  }, [tierContracts, tierRate]);

  const floorplanCalculations = state.floorplan_calculations?.[floorplanSpacetypeKey];

  const cellStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const contractsChangeHandler = e => {
    let payload = { value: parseInt(e.target.value), tierKey: tierKey };
    dispatch(createAction(actions.UPDATE_TIER_CONTRACTS_ACTUAL, payload));
  };

  const rateChangeHandler = e => {
    let payload = { value: parseInt(e.target.value), tierKey: tierKey };
    dispatch(createAction(actions.UPDATE_TIER_RATE_ACTUAL, payload));
  };

  return (
    <Grid
      container
      className={`grid grid-cols-1 border rounded ${
        tierStatus === 'past' || tierStatus === 'current_to_date' ? 'bg-gray-200' : 'bg-blue-200'
      }`}
    >
      <TierHeader
        tierStatus={tierStatus}
        tier={tier}
        lastTier={lastTier}
        tierKey={tierKey}
        isEditing={isEditing}
      />
      <TierContractsRateRow
        contracts={previousVersionTierContracts}
        rate={previousVersionTierRate ? previousVersionTierRate.toFixed(0) : previousVersionTierRate} // eslint-disable-line prettier/prettier
        cellStyle={cellStyle}
        type="planned"
      />
      {(tierStatus == 'past' || tierStatus == 'current_to_date') && (
        <TierContractsRateRow
          contracts={tierContracts}
          rate={signedRate ? signedRate.toFixed(2) : signedRate}
          cellStyle={cellStyle}
          type="actual"
        />
      )}
      {(tierStatus == 'current_remaining' || tierStatus == 'future') && (
        <>
          <Grid item xs={6} className="text-center py-2 bg-yellow-300">
            <FormControl width="90%">
              <TextField
                name="tierContracts"
                type="number"
                required
                InputProps={{
                  inputProps: {
                    min: 0,
                    max: 1000,
                    style: { backgroundColor: 'white' },
                  },
                }}
                onChange={contractsChangeHandler}
                size="small"
                variant="outlined"
                disabled={!isEditing}
                defaultValue={tierContracts}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} className="text-center py-2 bg-yellow-300">
            <FormControl width="90%">
              <TextField
                name="tierRate"
                type="number"
                required
                InputProps={{
                  inputProps: {
                    min: 1,
                    max: 2000,
                    style: { backgroundColor: 'white' },
                  },
                }}
                onChange={rateChangeHandler}
                size="small"
                variant="outlined"
                defaultValue={tierRate}
                disabled={tierStatus === 'current_remaining' || !isEditing}
              />
            </FormControl>
          </Grid>
        </>
      )}
      <TierFooter
        cumuRateAvg={
          floorplanCalculations ? floorplanCalculations?.cumu_avg_rates[tierSequence - 1] : null
        }
        cellStyle={cellStyle}
        previousTargetRate={previousTargetRate}
      />
    </Grid>
  );
};

Tier.propTypes = {
  tier: PropTypes.shape({
    tier_key: PropTypes.string,
    tier: PropTypes.number,
    tier_sequence: PropTypes.number,
    floorplan_spacetype_key: PropTypes.string,
    previous_version_tier_contracts: PropTypes.number,
    previous_version_tier_rate: PropTypes.number,
    tier_contracts: PropTypes.number,
    tier_rate: PropTypes.number,
    tier_status: PropTypes.string,
    signed_rate: PropTypes.number,
  }),
  previousTargetRate: PropTypes.number,
  lastTier: PropTypes.bool,
  isEditing: PropTypes.bool,
};

Tier.defaultProps = {
  tier: {},
  previousTargetRate: null,
  lastTier: false,
  isEditing: false,
};

export default Tier;
