import Highcharts from 'highcharts'
import addMore from "highcharts/highcharts-more";
import addDrilldown from "highcharts/modules/drilldown";
import addNoData from "highcharts/modules/no-data-to-display";
import addExporting from "highcharts/modules/exporting";
import {fontFamily, labelStyles, titleStyles} from "../utils";

addMore(Highcharts)
addDrilldown(Highcharts)
addNoData(Highcharts)
addExporting(Highcharts)


document.addEventListener('DOMContentLoaded', function () {
  const data = $('#js-competitor-rates-graph').data('graph-data');

  if(data){

    Highcharts.setOptions({
      lang: {
          thousandsSep: ','
      }
    });

    Highcharts.chart('js-competitor-rates-graph', {
      chart: {
        type: 'bubble',
        plotBorderWidth: 1,
        zoomType: 'xy',
        fontFamily: fontFamily
      },
      title: {
        text: ''
      },
      xAxis: {
        gridLineWidth: 1,
        title: {
          text: 'PROPERTY PRELEASE',
          style: titleStyles
        },
        labels: {
          format: '{value} %',
          style: labelStyles
        },
      },
      yAxis: {
        title: {
          text: 'AVG BED RENT',
          style: titleStyles
        },
        labels: {
          format: '${value}',
          style: labelStyles
        },
        maxPadding: 0.2,
      },
      legend: {
        itemStyle: titleStyles
      },
      tooltip: {
        style: {
          fontFamily: fontFamily
        },
        backgroundColor: '#ffffff',
        padding: 10,
        outside: true,
        hideDelay: 0,
        useHTML: true,
        headerFormat: '<table>',
        pointFormat: '<tr><th colspan="2"><h3>{point.name}</h3></th></tr>' +
            '<tr><th>{point.comp_type}</th><td></td></tr>' +
            '<tr><th>Prelease:</th><td>{point.x}%</td></tr>' +
            '<tr><th>Ave Bed Rent:</th><td>${point.y:,.0f}</td></tr>' +
            '<tr><th>Bed Count:</th><td>{point.z:,.0f}</td></tr>' +
            '<tr><th>Type:</th><td>{point.prop_type}</td></tr>' +
            '<tr><th>DTC:</th><td>{point.dtc}</td></tr>',
        footerFormat: '</table>',
        followPointer: true
      },

      plotOptions: {
        bubble: {
          minSize: 15,
          maxSize: 60
        },
        series: {
          stickyTracking: false,
          dataLabels: {
            enabled: true,
            format: ''
          },
          marker: {
            radius: 6
          }
        }
      },
      series: data.map(function(series){
        return {
          name: series.name,
          color: series.color,
          data: series.data
        }
      })
    });
  }
})