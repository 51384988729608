import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Typography } from '@material-ui/core';

const TierContractsRateRow = ({ contracts, rate, cellStyle, type }) => {
  return (
    <>
      <Grid
        item
        xs={6}
        className={`text-center p-1 ${type == 'planned' ? 'bg-purple-300' : 'bg-gray-300'}`}
        style={cellStyle}
      >
        <Typography style={{ fontWeight: type == 'planned' ? 500 : 600 }} variant="subtitle1">
          {contracts || '-'}
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        className={`text-center p-1 ${type == 'planned' ? 'bg-purple-300' : 'bg-gray-300'}`}
        style={cellStyle}
      >
        <Typography style={{ fontWeight: type == 'planned' ? 500 : 600 }} variant="subtitle1">
          {rate || '-'}
        </Typography>
      </Grid>
    </>
  );
};

TierContractsRateRow.propTypes = {
  contracts: PropTypes.number,
  rate: PropTypes.number,
  cellStyle: PropTypes.shape({}),
  type: PropTypes.string,
};

TierContractsRateRow.defaultProps = {
  contracts: null,
  rate: null,
  cellStyle: {},
  type: '',
};

export default TierContractsRateRow;
