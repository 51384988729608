import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { CircularProgress, TextField } from '@material-ui/core';
import humps from 'humps';
import api from '../../../api';

import DirectoryItem from './DirectoryItem';
import PropertyItem from './PropertyItem';
import ResidentItem from './ResidentItem';
import { Tab, Tabs, TabPanel } from '../../../tabs';

const AppSearchContainer = ({
  search_config: config,
  can_edit_property_sales_info: canEditPropertySalesInfo,
}) => {
  const [search, setSearch] = useState({ type: '', query: '' });
  const [searchType, setSearchType] = useState('property');
  const [data, setData] = useState({
    results: [],
    isCurrentUserAdmin: false,
    loading: false,
    error: null,
  });

  useEffect(() => {
    if (sessionStorage.getItem(`${searchType}Query`)) {
      fetchStoredSearchResults(`${searchType}Query`);
    }
  }, [searchType]);

  const searchHandler = useCallback(
    debounce((query, type) => fetchSearchResults(query, type), 1000),
    []
  );

  const onSearchChange = event => {
    const query = event.target.value;
    const type = event.target.attributes.name.value;

    setSearch(state => ({
      ...state,
      type,
      query,
    }));

    setData(state => ({
      ...state,
      loading: true,
    }));

    searchHandler(query, type);
  };

  const handleReset = () => {
    setTimeout(function() {
      setSearchType(document.querySelector('#input-search').name);
    }, 20); // delay necessary for state to set synchronously

    setSearch({
      type: '',
      query: '',
    });

    setData({ results: [], isCurrentUserAdmin: false, loading: false, error: null });
  };

  const fetchSearchResults = (query, type) => {
    if (query.length == 0) {
      setData({
        results: [],
        isCurrentUserAdmin: false,
        loading: false,
        error: null,
      });
    } else {
      api.search
        .getResults({ query: query, type: type })
        .then(res => {
          const noResults = 'No results found.';

          setData({
            results: JSON.parse(res.data.records),
            isCurrentUserAdmin: JSON.parse(res.data.is_current_user_admin),
            loading: false,
            error: JSON.parse(res.data.records).length == 0 ? noResults : null,
          });
          sessionStorage.setItem(
            `${type}Query`,
            JSON.stringify({
              results: `${res.data.records}`,
              isCurrentUserAdmin: `${res.data.is_current_user_admin}`,
              loading: false,
              error: null,
            })
          );
        })
        .catch(() => {
          setData({
            results: [],
            isCurrentUserAdmin: false,
            loading: false,
            error: 'An error has occurred.',
          });
        });
    }
  };

  const fetchStoredSearchResults = query => {
    const sessionData = JSON.parse(sessionStorage.getItem(query));
    setData({
      results: JSON.parse(sessionData.results),
      isCurrentUserAdmin: JSON.parse(sessionData.isCurrentUserAdmin),
      loading: sessionData.loading,
      error: JSON.parse(sessionData.error),
    });
  };

  const renderSearchInput = (placeholder, searchType) => {
    return (
      <div className="flex-row w-full mt-4">
        <div className="flex-1">
          <TextField
            autoFocus
            type="text"
            id="input-search"
            variant="outlined"
            value={search.query}
            onChange={onSearchChange}
            label={placeholder}
            name={searchType}
            fullWidth
          />
        </div>

        <div className="flex content-center flex-1 mt-5 justify-center h-4">
          {data.loading && <CircularProgress size={16} />}
          {data.error && !data.loading && <span>{data.error}</span>}
        </div>
      </div>
    );
  };

  return (
    <Tabs onTabClick={handleReset} classNames="h-full">
      {config.options.property && <Tab>Property</Tab>}
      {config.options.property && (
        <TabPanel className="h-full">
          {renderSearchInput('Search for a Property...', 'property')}
          <div className="overflow-auto h-full" style={{ margin: '-34px 2px 0px 2px' }}>
            {data.results.map(result => (
              <PropertyItem
                {...humps.camelizeKeys(result)}
                key={result.id}
                canEditPropertySalesInfo={canEditPropertySalesInfo}
              />
            ))}
          </div>
        </TabPanel>
      )}

      {config.options.directory && <Tab>Directory</Tab>}
      {config.options.directory && (
        <TabPanel className="flex-row h-full">
          {renderSearchInput('Search the Directory...', 'directory')}
          <div className="flex flex-wrap justify-start overflow-auto h-full content-start">
            {data.results.map(result => (
              <DirectoryItem
                {...humps.camelizeKeys(result)}
                key={result.id}
                isCurrentUserAdmin={data.isCurrentUserAdmin}
              />
            ))}
          </div>
        </TabPanel>
      )}

      {config.options.resident && <Tab>Resident</Tab>}
      {config.options.resident && (
        <TabPanel className="flex-row h-full">
          {renderSearchInput('Search for a Resident...', 'resident')}
          <div className="flex flex-wrap justify-start overflow-auto h-full content-start">
            {data.results.map(result => (
              <ResidentItem {...humps.camelizeKeys(result)} key={result.id} />
            ))}
          </div>
        </TabPanel>
      )}
    </Tabs>
  );
};

AppSearchContainer.propTypes = {
  search_config: PropTypes.shape({
    options: PropTypes.shape({
      property: PropTypes.bool,
      directory: PropTypes.bool,
      resident: PropTypes.bool,
    }),
  }).isRequired,
  can_edit_property_sales_info: PropTypes.bool.isRequired,
};

export default AppSearchContainer;
