// eslint-disable-next-line no-dupe-else-if
import * as React from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import BasicTable from '../../table/BasicTable';

const FloorplanRates = ({ tableHeaders, tableData }) => {
  return (
    <>
      <Card sx={{ padding: '16px' }}>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          sx={{ paddingBottom: '16px' }}
        >
          <Typography variant="h5" component="div">
            Floorplan Rates
          </Typography>
        </Box>
        <BasicTable headers={tableHeaders} data={tableData} />
      </Card>
    </>
  );
};

FloorplanRates.propTypes = {
  tableHeaders: PropTypes.arrayOf(PropTypes.shape).isRequired,
  tableData: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default FloorplanRates;
