import { connect } from 'react-redux';
import { toggleDimension } from '../../state/dimensions';
import { toggleMeasure } from '../../state/measures';
import { toggleCalculation } from '../../state/calculations';
import { addFilter } from '../../state/filters';
import DatasetSelectorList from './DatasetSelectorList';

const mapStateToProps = state => ({
  datasets: state.source.datasets,
  dimensions: state.source.dimensions,
  measures: state.source.measures,
  calculations: state.source.calculations,
  selectedDimensions: state.dimensions,
  selectedMeasures: state.measures,
  selectedCalculations: state.calculations,
});

export default connect(mapStateToProps, {
  toggleDimension,
  toggleMeasure,
  toggleCalculation,
  addFilter,
})(DatasetSelectorList);
