import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["floorPlans", "description", "groups", "category", "renewalOptions", "renewalTier", "existingDescription"];

  initialize() {
    if (this.currentItem.length > 0) {
      this.setDescription()
      this.setCategory()
    }
    this.toggleRenewalOptions()
  }

  handleTypeChange(event) {
    this.setCurrentItem(event.target.value)
    this.setDescription()
    this.setCategory()
  }

  handleScopeChange(event) {
    this.currentScope = event.target.value
  }

  toggleRenewalOptions() {
    if (this.currentScope != 'renewal' && this.currentScope != 'both') {
      $(this.renewalOptionsTarget).find('select').each(function(i,el) {
        el.value = ''
      })
      $(this.renewalOptionsTarget).hide();
    } else {
      // Default to Market
      $(this.renewalTierTarget).find('select')[0].value = 'Market'
      $(this.renewalOptionsTarget).show();
    }
  }

  setCurrentItem(value) {
    this.data.set('current-item', value)
  }

  setDescription() {
    if (this.existingDescriptionTarget.innerHTML.length === 0) {
      const regex = /\sPROPERTY\s/
      const yearRegex = /\sTERM YEARS \(ie 2018-19\)\s/
      this.description = this.placeholderText.replace(regex, ` ${this.property} `).replace(yearRegex, ' 2021-22 ')
    }
  }

  handleFloorPlansChange() {
    this.floorPlans = $(this.floorPlansTarget).val()
  }

  setCategory() {
    $(this.categoryTarget).val(this.categoryText)
  }

  selectAllFloorPlans() {
    this.floorPlans = this.allFloorPlans
  }

  validateRequiredFields(event) {
    $('[required=required]').each(function(i, el) {
      if ($(el).val() === '') {
        event.preventDefault()
        $(el).addClass('border border-danger')
      } else {
        $(el).removeClass('border border-danger')
      }
    });
  }

  get allFloorPlans() {
    return JSON.parse(this.data.get('allFloorPlans'))
  }

  get floorPlans() {
    return JSON.parse(this.data.get('floorPlans'))
  }

  set floorPlans(value) {
    this.data.set('floorPlans', JSON.stringify(value))

    $(this.floorPlansTarget).val(value)

    const str = this.description.split(' Applies to ')[0]
    this.description = this.floorPlans.length === 1 ? `${str} Applies to ${this.floorPlans[0].toLowerCase()} only.` : str
  }

  get description() {
    return $(this.descriptionTarget).val()
  }

  set description(value) {
    this.data.set('description', value)

    $(this.descriptionTarget).val(value)
  }

  get currentScope() {
    return this.data.get('current-scope').toLowerCase()
  }

  set currentScope(value) {
    this.data.set('current-scope', value)

    this.toggleRenewalOptions()
  }

  get property() {
    return this.data.get('property')
  }

  get currentItem() {
   return this.data.get('current-item')
  }

  get groups() {
    return $(this.groupsTarget).data('incentive-groups')
  }

  get placeholderText() {
    return this.groups[this.currentItem][0]['description']
  }

  get categoryText() {
    return this.groups[this.currentItem][0]['category']
  }
}
