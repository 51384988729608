import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import moment from 'moment-timezone';

import {
  Avatar,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Tab,
  Tabs,
  Typography,
  Box,
  TableHead,
  Button,
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import Skeleton from '@material-ui/lab/Skeleton';
import DashboardIcon from '@material-ui/icons/Dashboard';

const useStyles = makeStyles(() => ({
  avatar: {
    width: 90,
    height: 90,
  },
  cardContainer: {
    maxHeight: 375,
  },
  analyticsTable: {
    maxHeight: 325,
    overflow: 'auto',
  },
}));

const TabPanel = ({ children, value, index, ...props }) => {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...props}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </Typography>
  );
};

TabPanel.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

const UserPanel = ({ user }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classNames('flex flex-col items-center mx-auto mb-4')}>
        <Avatar className={classNames(classes.avatar, 'mb-2')} src={user.logo || undefined} />
        <div className={classNames('flex-1')}>
          <h5 className="font-normal text-xl">{`${user.firstName} ${user.lastName}`}</h5>
          {user.position && <h6 className="font-light">Software Engineer</h6>}
        </div>
      </div>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Email
              </TableCell>
              <TableCell align="right">{user.email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Location
              </TableCell>
              <TableCell align="right">-</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Cell Number
              </TableCell>
              <TableCell align="right">{user.cellPhone || '-'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Office Number
              </TableCell>
              <TableCell align="right">{user.officePhone || '-'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Last Login
              </TableCell>
              <TableCell align="right">
                {user.lastSignInAt &&
                  moment(user.lastSignInAt)
                    .tz('America/Chicago')
                    .format('MMMM Do YYYY')}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

UserPanel.propTypes = {
  user: PropTypes.shape({
    logo: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    cellPhone: PropTypes.string,
    officePhone: PropTypes.string,
    lastSignInAt: PropTypes.string,
    position: PropTypes.string,
  }).isRequired,
};

const ResourcePanel = ({ properties, markets }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="User Analytics"
        indicatorColor="primary"
      >
        <Tab label="Properties" />
        <Tab label="Markets" />
      </Tabs>

      <TabPanel value={value} index={0}>
        <TableContainer className={classes.cardContainer}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Property</TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {properties.map(property => (
                <TableRow key={property.id}>
                  <TableCell component="th" scope="row">
                    {property.name}
                  </TableCell>
                  <TableCell align="right">
                    <Button href={`/properties/${property.id}`} color="primary">
                      show
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <TableContainer className={classes.cardContainer}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Market</TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {markets.map(market => (
                <TableRow key={market.id}>
                  <TableCell component="th" scope="row">
                    {market.name}
                  </TableCell>
                  <TableCell align="right">
                    <Button href={`/market/${market.id}`} color="primary">
                      show
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
    </>
  );
};

ResourcePanel.propTypes = {
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    })
  ).isRequired,
  markets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    })
  ).isRequired,
};

const DashboardPanel = ({ activities }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="User Dashboard"
        indicatorColor="primary"
      >
        <Tab label={<DashboardIcon />} />
        <Tab label={<AccessTimeIcon />} />
      </Tabs>

      <TabPanel value={value} index={0}>
        <div className={classNames('pb-8')}>
          <Skeleton variant="rect" height={350} />
        </div>

        <div className={classNames(classes.analyticsTable)}>
          {Array.apply(null, Array(10)).map((_, i) => (
            <div
              className="pb-2"
              // eslint-disable-next-line react/no-array-index-key
              key={`Skeleton-${i}`}
            >
              <Skeleton variant="rect" height={30} />
            </div>
          ))}
        </div>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Action</TableCell>
                <TableCell>Method</TableCell>
                <TableCell>Route</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activities.map(activity => (
                <TableRow key={activity.id}>
                  <TableCell component="th" scope="row">
                    {activity.time &&
                      moment(activity.time)
                        .tz('America/Chicago')
                        .format('YYYY-MM-DD hh:mm a')}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {activity.name}
                  </TableCell>
                  <TableCell align="right">{activity.properties.action}</TableCell>
                  <TableCell align="right">{activity.properties.controller}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
    </>
  );
};

DashboardPanel.propTypes = {
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    })
  ).isRequired,
};

export { DashboardPanel, ResourcePanel, UserPanel };
