import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

const MarketingCampaignInitiative = ({ details, tzValue }) => {
  if (details.category === 'Guerilla Marketing') {
    return (
      <>
        <Typography variant="h6">
          <p className="mt-4 mb-2">{details.title}</p>
        </Typography>
        <Typography component="span" variant="subtitle1">
          <p className="text-gray-600">
            <span className="font-semibold">Guerilla Tactic: </span> {details.guerilla_tactics}
          </p>
        </Typography>
        <Typography component="span" variant="subtitle1">
          <p className="text-gray-600">
            <span className="font-semibold"> What are you doing?: </span>
            {details.guerilla_tactics_details}
          </p>
        </Typography>
        <Typography component="span" variant="subtitle1">
          <p className="text-gray-600">
            <span className="font-semibold">Target Audience: </span> {details.target_audience}
          </p>
        </Typography>
        <Typography component="span" variant="subtitle1">
          <p className="text-gray-600">
            <span className="font-semibold">Location: </span> {details.location}
          </p>
        </Typography>
        <Typography component="span" variant="subtitle1">
          <p className="text-gray-600">
            <span className="font-semibold">Start: </span> {details.date}
          </p>
        </Typography>
        <Typography component="span" variant="subtitle1">
          <p className="text-gray-600">
            <span className="font-semibold">Created By: </span> {details.created_by_info}
          </p>
        </Typography>
        <Typography component="span" variant="subtitle1">
          <p className="text-gray-600">
            <span className="font-semibold">Update Info: </span> {details.updated_by_info}
          </p>
        </Typography>
        <Divider className="mt-4 mb-4" sx={{ marginTop: '12px' }} />
        <Box sx={{ width: '100%', marginTop: '12px' }}>
          <Typography variant="h6">
            <p className="mt-4 mb-2">Metrics</p>
          </Typography>
          <Typography component="span" variant="subtitle1">
            <p className="text-gray-600">
              <span className="font-semibold">Prospects Received: </span>{' '}
              {details.prospects_received}
            </p>
          </Typography>
          <Typography component="span" variant="subtitle1">
            <p className="text-gray-600">
              <span className="font-semibold">Social Media Follows: </span>{' '}
              {details.social_media_follows}
            </p>
          </Typography>
          <Typography component="span" variant="subtitle1">
            <p className="text-gray-600">
              <span className="font-semibold">Number of QR Code Scans: </span>{' '}
              {details.number_of_qr_code_scans}
            </p>
          </Typography>
          <Typography component="span" variant="subtitle1">
            <p className="text-gray-600">
              <span className="font-semibold">Call to Action: </span> {details.call_to_action}
            </p>
          </Typography>
          <Typography component="span" variant="subtitle1">
            <p className="text-gray-600">
              <span className="font-semibold">Comments: </span> {details.comments}
            </p>
          </Typography>
        </Box>
      </>
    );
  } else if (details.category === 'Email Marketing') {
    return (
      <>
        <Typography variant="h6">
          <p className="mt-4 mb-2">{details.title}</p>
        </Typography>
        <Typography component="span" variant="h6">
          <p className="text-gray-600">
            <span className="font-semibold">Communication Platform: </span>{' '}
            {details.communication_platform}
          </p>
        </Typography>
        <Typography component="span" variant="subtitle1">
          <p className="text-gray-600">
            <span className="font-semibold">Date: </span> {details.date}
          </p>
        </Typography>
        <Typography component="span" variant="subtitle1">
          <p className="text-gray-600">
            <span className="font-semibold">Target Audience: </span> {details.target_audience}
          </p>
        </Typography>
        <Typography component="span" variant="subtitle1">
          <p className="text-gray-600">
            <span className="font-semibold">Created By: </span> {details.created_by_info}
          </p>
        </Typography>
        <Typography component="span" variant="subtitle1">
          <p className="text-gray-600">
            <span className="font-semibold">Update Info: </span> {details.updated_by_info}
          </p>
        </Typography>
        <Typography component="span" variant="subtitle1">
          <p className="text-gray-600">
            <span className="font-semibold">Graphic/Image:</span>
            <br />
            {details.file !== null && (
              <img
                src={details.file}
                alt="graphic"
                className="w-1/2"
                style={{ width: '50%', marginTop: '12px' }}
              />
            )}
          </p>
        </Typography>
        <Divider className="mt-4 mb-4" sx={{ marginTop: '12px' }} />
        <Box sx={{ width: '100%', marginTop: '12px' }}>
          <Typography variant="h6">
            <p className="mt-4 mb-2">Metrics</p>
          </Typography>
          <Typography component="span" variant="subtitle1">
            <p className="text-gray-600">
              <span className="font-semibold">Subject Line: </span> {details.subject_line}
            </p>
          </Typography>
          <Typography component="span" variant="subtitle1">
            <p className="text-gray-600">
              <span className="font-semibold">Message: </span> {details.message}
            </p>
          </Typography>
          <Typography component="span" variant="subtitle1">
            <p className="text-gray-600">
              <span className="font-semibold">Call to Action: </span> {details.call_to_action}
            </p>
          </Typography>
          <Typography component="span" variant="subtitle1">
            <p className="text-gray-600">
              <span className="font-semibold">Open Rate: </span> {details.open_rate}
            </p>
          </Typography>
          <Typography component="span" variant="subtitle1">
            <p className="text-gray-600">
              <span className="font-semibold">Click Through Rate: </span>{' '}
              {details.click_through_rate}
            </p>
          </Typography>
        </Box>
      </>
    );
  } else if (details.category === 'Promotional Event') {
    return (
      <>
        <Typography variant="h6">
          <p className="mt-4 mb-2">{details.title}</p>
        </Typography>
        <Typography component="span" variant="subtitle1">
          <p className="text-gray-600">
            <span className="font-semibold">Date: </span> {details.date}
          </p>
        </Typography>
        <Typography component="span" variant="subtitle1">
          <p className="text-gray-600">
            <span className="font-semibold">Location: </span> {details.location}
          </p>
        </Typography>
        <Typography component="span" variant="subtitle1">
          <p className="text-gray-600">
            <span className="font-semibold">Target Audience: </span> {details.target_audience}
          </p>
        </Typography>
        <Typography component="span" variant="subtitle1">
          <p className="text-gray-600">
            <span className="font-semibold">Created By: </span> {details.created_by_info}
          </p>
        </Typography>
        <Typography component="span" variant="subtitle1">
          <p className="text-gray-600">
            <span className="font-semibold">Update Info: </span> {details.updated_by_info}
          </p>
        </Typography>
        <Divider className="mt-4 mb-4" sx={{ marginTop: '12px' }} />
        <Box sx={{ width: '100%', marginTop: '12px' }}>
          <Typography variant="h6">
            <p className="mt-4 mb-2">Metrics</p>
          </Typography>
          <Typography component="span" variant="subtitle1">
            <p className="text-gray-600">
              <span className="font-semibold">Subject Line: </span> {details.subject_line}
            </p>
          </Typography>
          <Typography component="span" variant="subtitle1">
            <p className="text-gray-600">
              <span className="font-semibold">Call to Action: </span> {details.call_to_action}
            </p>
          </Typography>
          <Typography component="span" variant="subtitle1">
            <p className="text-gray-600">
              <span className="font-semibold">Number of QR Code Scans: </span>
              {details.qr_code_scans}
            </p>
          </Typography>
          <Typography component="span" variant="subtitle1">
            <p className="text-gray-600">
              <span className="font-semibold">Number of Social Media Follows: </span>
              {details.social_media_follows}
            </p>
          </Typography>
          <Typography component="span" variant="subtitle1">
            <p className="text-gray-600">
              <span className="font-semibold">Number of Prospects Received: </span>
              {details.prospects_received}
            </p>
          </Typography>
          <Typography component="span" variant="subtitle1">
            <p className="text-gray-600">
              <span className="font-semibold">Number of Prospects Converted: </span>
              {details.comments}
            </p>
          </Typography>
        </Box>
      </>
    );
  }
};

MarketingCampaignInitiative.propTypes = {
  title: PropTypes.string.isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  details: PropTypes.objectOf({}).isRequired,
  propertyName: PropTypes.string.isRequired,
};

export default MarketingCampaignInitiative;
