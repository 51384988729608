const Highcharts = require('highcharts');
import columnChart from '../common/column';
import themeSelector from "../themes/theme_selector";
import { capitalize } from "@utils/lib/string";
import { statToColor } from "@utils/lib/color";
import { decimalToPercent } from "@utils/lib/number";

document.addEventListener('DOMContentLoaded', function () {
  if($('#js-occupancy-by-month-chart').length){
    const data = $('#js-occupancy-by-month-chart').data('chart');

    let options = {
      chart: {
        zoomType: 'y',
        type: 'column',
        renderTo: $('#js-occupancy-by-month-chart')[0],
        resetZoomButton: {
          position: {
            y: -40
          }
        }
      },
      title: {
        text: `${data.title} Physical Occupancy`
      },
      tooltip: {
        useHTML: true,
        formatter: function () {
          const point = this.points[0];
          let { season, final_prelease_perc, season_start_perc, occupancy_perc,
                movein_perc, moveout_perc, final_prelease_totals, season_start_totals,
                occupancy_totals, movein_totals, moveout_totals } = data;
          const season_years = [season, season+1].map(s => s.toString().slice(-2))
          occupancy_perc = occupancy_perc[point.point.index];
          movein_perc = movein_perc[point.point.index];
          moveout_perc = moveout_perc[point.point.index];
          occupancy_totals = occupancy_totals[point.point.index];
          movein_totals = movein_totals[point.point.index];
          moveout_totals = moveout_totals[point.point.index];

          let tooltip = "<div style='min-width: 350px;'>";
          tooltip += `<table class="highcharts-table">`;
          tooltip += `<tr>
                        <th style="width: 150px">Final AY ${season_years.join("/")}</th>
                        <th style="width: 100px" class="text-center">%</th>
                        <th style="width: 100px" class="text-center">#</th>
                      <tr>`;
          tooltip += `<tr>
                        <td>Prelease</td>
                        <td class="text-center"><b>${decimalToPercent(final_prelease_perc)}%</b></td>
                        <td class="text-center"><b>${final_prelease_totals}</b></td>
                      <tr>`;
          tooltip += `<tr>
                        <td>Move-In Occupancy</td>
                        <td class="text-center"><b>${decimalToPercent(season_start_perc)}%</b></td>
                        <td class="text-center"><b>${season_start_totals}</b></td>
                      <tr>`;
          tooltip += `<tr>
                        <td>No-show</td>
                        <td class="text-center" style="color:${statToColor(season_start_perc - final_prelease_perc)}"><b>${decimalToPercent(season_start_perc - final_prelease_perc)}%</b></td>
                        <td class="text-center" style="color:${statToColor(season_start_totals - final_prelease_totals)}"><b>${season_start_totals - final_prelease_totals}<b></td>
                      <tr>`;
          tooltip += `<tr>
                        <th>${capitalize(point.x)} Comparison</th>
                        <th></th>
                        <th></th>
                      <tr>`
          tooltip += `<tr>
                        <td>EOM Occ</td>
                        <td class="text-center"><b>${decimalToPercent(occupancy_perc)}%</b></td>
                        <td class="text-center"><b>${occupancy_totals}</b></td>
                      <tr>`
          tooltip += `<tr>
                        <td>Final Prelease &Delta;</td>
                        <td class="text-center" style="color:${statToColor(occupancy_perc - final_prelease_perc)}"><b>${decimalToPercent(occupancy_perc - final_prelease_perc)}%</b></td>
                        <td class="text-center" style="color:${statToColor(occupancy_totals - final_prelease_totals)}"><b>${occupancy_totals - final_prelease_totals}</b></td>
                      <tr>`
          tooltip += `<tr>
                        <td>Move-In &Delta;</td>
                        <td class="text-center" style="color:${statToColor(occupancy_perc - season_start_perc)}"><b>${decimalToPercent(occupancy_perc - season_start_perc)}%</b></td>
                        <td class="text-center" style="color:${statToColor(occupancy_totals - season_start_totals)}"><b>${occupancy_totals - season_start_totals}</b></td>
                      <tr>`
          tooltip += `<tr>
                        <th>${capitalize(point.x)} Movement</th>
                        <th></th>
                        <th></th>
                      <tr>`
          tooltip += `<tr>
                        <td>Move-ins</td>
                        <td class="text-center" style="color:${statToColor(movein_perc)}"><b>${decimalToPercent(movein_perc)}%</b></td>
                        <td class="text-center" style="color:${statToColor(movein_totals)}"><b>${movein_totals}</b></td>
                      <tr>`
          tooltip += `<tr>
                        <td>Move-outs</td>
                        <td class="text-center" style="color:${statToColor(moveout_perc)}"><b>${decimalToPercent(moveout_perc)}%</b></td>
                        <td class="text-center" style="color:${statToColor(moveout_totals)}"><b>${moveout_totals}</b></td>
                      <tr>`
          tooltip += `<tr>
                        <td>Net</td>
                        <td class="text-center" style="color:${statToColor(movein_perc + moveout_perc)}"><b>${decimalToPercent(movein_perc + moveout_perc)}%</b></td>
                        <td class="text-center" style="color:${statToColor(movein_totals + moveout_totals)}"><b>${movein_totals + moveout_totals}</b></td>
                      <tr>`
          tooltip += `</table>`;
          tooltip += "</div>";

          return tooltip
        }
      },
      plotOptions: {
          column: {
              stacking: 'percent'
          },
          series: {
              events: {
                  legendItemClick: function () { return false; }
              }
          }
      },
      xAxis: {
        categories: data.months.map(month => capitalize(month)),
        labels: {
          x: 15
        }
      },
      yAxis: [
        {
          ceiling: 100,
          zoomEnabled: true,
          title:{
            text: 'Occupancy',
          },
          plotLines: [
            {
              color: '#6F6C99',
              label: {
                text: "Prelease",
                style: {
                  color: '#6F6C99',
                  fontWeight: 'bold',
                  padding: 2
                },
                x: -1
              },
              dashStyle: 'LongDash',
              width: 2,
              value: Number((data.final_prelease_perc * 100).toFixed(2)),
              zIndex: 5
          },
          {
            color: '#606988',
            label: {
              text: "Move-In",
              style: {
                color: '#606988',
                fontWeight: 'bold',
                padding: 2
              },
              x: -1
            },
            dashStyle: 'LongDash',
            width: 2,
            value: Number((data.season_start_perc * 100).toFixed(2)),
            zIndex: 5
          }
          ],
          labels: {
            formatter: function () {
                  return this.value+"%";
              }
          }
        }
      ],
      series: [
      {
          type: 'column',
          name: 'Vacancies',
          yAxis: 0,
          color: '#bfc3cf',
          pointPlacement: 0.15,
          data: data.vacancy_totals.map((vac, index) => ({
            vacancy_perc: data.vacancy_perc[index] * 100,
            y: vac,
            index: index
          })),
      },
      {
          type: 'column',
          name: 'Occupancies',
          yAxis: 0,
          color: themeSelector('light').colors[7],
          pointPlacement: 0.15,
          data: data.occupancy_totals.map((occ, index) => ({
            occupancy_perc: data.occupancy_perc[index] * 100,
            y: occ,
            index: index
          })),
          dataLabels: {
              enabled: true,
              color: '#FFFFFF',
              format: '{point.occupancy_perc:.2f}%',
              verticalAlign: 'top',
              y: 10
          },
      }

    ]
    }

    options = Highcharts.merge(options, themeSelector('light'))
    const occChart = columnChart(options);
    const extremeLookUpValues = [...data.occupancy_perc, data.final_prelease_perc];

    occChart.yAxis[0].setExtremes(
      Math.min(...(extremeLookUpValues.map(val => Math.floor(val * 100) - .5))),
      Math.max(...(extremeLookUpValues.map(val => Math.ceil(val * 100) + .5)))
    );

    if( !occChart.resetZoomButton ) {
      occChart.showResetZoom();
    }

  }
})