import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import convertToYYYYMMDD from '../../helper_functions/convertToYYYYMMDD';
import convertToHHMM from '../../helper_functions/convertToHHMM';

const ResidentExperience = ({ title, start, end, description, details, propertyName, tzValue }) => {
  return (
    <>
      <Typography variant="h6">
        <p className="mt-4 mb-2">{title}</p>
      </Typography>
      {start && (
        <Typography component="span" variant="subtitle1">
          <p className="text-gray-600">
            <span className="font-semibold">Start:</span> {start}
          </p>
        </Typography>
      )}
      {end && (
        <Typography component="span" variant="subtitle1">
          <p className="text-gray-600 mb-3">
            <span className="font-semibold">End:</span> {end}
          </p>
        </Typography>
      )}
      <Typography component="span" variant="body2">
        <p className="text-gray-600 mb-2">{description}</p>
      </Typography>
      <Divider sx={{ marginTop: '12px', marginBottom: '12px' }} />
      <Typography variant="caption" display="block" gutterBottom>
        <p className="text-black-600 mb-2">{`Creation Info: ${details.created_by}`}</p>
      </Typography>
      {details.updated_by && (
        <Typography variant="caption" display="block" gutterBottom>
          <p className="text-black-600 mb-2">{`Update Info: ${details.updated_by}`}</p>
        </Typography>
      )}
      <Divider sx={{ marginBottom: '12px' }} />
      <Typography component="span" variant="subtitle1" color="textPrimary">
        <p className="text-gray-600 mb-1">Add Event to Your Calendar</p>
      </Typography>
      <AddToCalendarButton
        name={`${title} - ${propertyName}`}
        startDate={convertToYYYYMMDD(start)}
        startTime={convertToHHMM(start)}
        endDate={convertToYYYYMMDD(end)}
        endTime={convertToHHMM(end)}
        timeZone={tzValue}
        description={description}
        buttonStyle="text"
        options={['Microsoft365', 'MicrosoftTeams', 'Apple', 'Google']}
        hideCheckmark="true"
        buttonsList
        hideTextLabelButton
        size="5"
      />
    </>
  );
};

ResidentExperience.propTypes = {
  title: PropTypes.string,
  start: PropTypes.string,
  end: PropTypes.string,
  description: PropTypes.string,
  details: PropTypes.shape({
    created_by: PropTypes.string,
    updated_by: PropTypes.string,
  }),
  propertyName: PropTypes.string,
  tzValue: PropTypes.string,
};

ResidentExperience.defaultProps = {
  title: null,
  start: null,
  end: null,
  description: null,
  details: {},
  propertyName: null,
  tzValue: null,
};

export default ResidentExperience;
