import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import api from '../../api';

const Index = ({ data: { url_links: urlLinks, property: property } }) => {
  const [urls, updateUrls] = useState(urlLinks);
  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(urls);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    updateUrls(items);
  }
  const data = {
    property_id: property.id,
    urls: urls,
  };
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        width: 'min-content',
        margin: 'auto',
      }}
    >
      <Box sx={{ flexGrow: 1, margin: '12px', paddingTop: '16px', paddingBottom: '16px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: 'max-content',
            paddingBottom: '24px',
          }}
        >
          <Typography variant="h4" noWrap component="div" sx={{ paddingRight: '48px' }}>
            {property.name} Url Organizer
          </Typography>
          <Button
            variant="contained"
            size="small"
            sx={{ backgroundColor: '#ffd202' }}
            onClick={() => {
              api.propertyUrlLinksOrganizer
                .update({ data })
                .then(response => {
                  window.location.assign(response.data.redirect_url);
                })
                .catch(e => {
                  alert(
                    'There was an error saving your changes. Please create a help desk ticket if this continues to occur.'
                  );
                  throw new Error(e);
                });
            }}
          >
            Save Order
          </Button>
        </Box>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="urls">
            {provided => (
              <Stack
                className="urls"
                {...provided.droppableProps}
                ref={provided.innerRef}
                spacing={2}
              >
                {urls.map(({ id, name, url }, index) => {
                  return (
                    <Draggable key={id} draggableId={id.toString()} index={index}>
                      {provided => (
                        <Card
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <CardContent>
                            <Typography variant="h6" noWrap component="div">
                              {name}
                            </Typography>
                            <Typography variant="p" noWrap component="div">
                              {url}
                            </Typography>
                          </CardContent>
                          <DragHandleIcon sizes="large" sx={{ marginRight: '36px' }} />
                        </Card>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </Stack>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    </Box>
  );
};

Index.propTypes = {
  data: PropTypes.shape({
    property: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    url_links: PropTypes.arrayOf({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      notes: PropTypes.string.isRequired,
      order: PropTypes.number.isRequired,
    }),
  }).isRequired,
};

export default Index;
