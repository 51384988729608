import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
// import ImageGrid from './ImageGrid';
import Typography from '@mui/material/Typography';
import PropertyInfoCardGrid from './PropertyInfoCardGrid';
import FloorplanRates from './FloorplanRates';
import GeneralPropertyInfo from './GeneralPropertyInfo';

const Index = ({
  images,
  propertyInfoCards,
  tableInfo: { tableHeaders, tableData },
  permissionToEdit,
  property,
  propertyAddress,
  propertyMarket,
}) => {
  // eslint-disable-next-line no-console
  return (
    <Box
      sx={{
        marginLeft: '12px',
        marginRight: '12px',
        marginBottom: '-61px',
        paddingBottom: '100px',
      }}
    >
      {/* <Box sx={{ paddingTop: '20px' }}>
        <ImageGrid images={images} />
      </Box> */}
      <Box sx={{ paddingTop: '20px' }}>
        <GeneralPropertyInfo
          property={property}
          propertyAddress={propertyAddress}
          propertyMarket={propertyMarket}
        />
      </Box>
      <Box sx={{ paddingTop: '20px' }}>
        <FloorplanRates tableHeaders={tableHeaders} tableData={tableData} />
      </Box>
      <Box sx={{ paddingTop: '20px' }}>
        <PropertyInfoCardGrid cards={propertyInfoCards} permissionToEdit={permissionToEdit} />
      </Box>
      <Box
        sx={{
          paddingTop: '0px',
          backgroundColor: 'white',
          padding: '16px',
          marginTop: '16px',
          boxShadow: '1px 1px 7px #00000057',
          borderRadius: '6px',
        }}
      >
        <Typography
          variant="h5"
          component="div"
          sx={{
            marginTop: '12px',
            marginBottom: '5px',
            backgroundColor: 'white',
            height: '52px',
            paddingTop: '0px',
            paddingBottom: '-5px',
          }}
        >
          Property Market Map
        </Typography>
        <div id="js-map" data-property-id={property.id} />
        <div id="legend">
          <h3>Legend</h3>
        </div>
      </Box>
    </Box>
  );
};

Index.propTypes = {
  images: PropTypes.arrayOf(String).isRequired,
  tableInfo: PropTypes.shape({
    tableHeaders: PropTypes.arrayOf(PropTypes.shape),
    tableData: PropTypes.arrayOf(PropTypes.shape),
  }).isRequired,
  propertyInfoCards: PropTypes.arrayOf(PropTypes.shape),
  permissionToEdit: PropTypes.bool.isRequired,
  property: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  propertyAddress: PropTypes.shape({}).isRequired,
  propertyMarket: PropTypes.string.isRequired,
};

Index.defaultProps = {
  propertyInfoCards: PropTypes.arrayOf({}),
};
export default Index;
