/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

const Photo = ({ photo, toggleLike }) => {
  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      lg={3}
      sx={{ backgroundColor: '#f7fafc', position: 'relative' }}
      onClick={() => toggleLike(photo.id)}
    >
      <FavoriteIcon
        style={{
          color: photo.is_property_info_page ? 'red' : 'white',
          position: 'absolute',
          top: '24px',
          right: '6px',
        }}
      />
      <img
        alt="img"
        src={photo.url}
        loading="lazy"
        style={{
          width: '100%',
          height: '45vh',
          objectFit: 'cover',
          borderRadius: '5px',
          boxShadow: 1,
        }}
      />
    </Grid>
  );
};

Photo.propTypes = {
  photo: PropTypes.shape({
    id: PropTypes.number,
    url: PropTypes.string,
    property_sales_info_order: PropTypes.number,
    is_property_info_page: PropTypes.bool,
  }).isRequired,
  toggleLike: PropTypes.func.isRequired,
};

export default Photo;
