import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@material-ui/core';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import HotelIcon from '@mui/icons-material/Hotel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CalculateIcon from '@mui/icons-material/Calculate';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';

// State
import { StoreContext, actions, createAction } from '../reducer';

// Components
import Tier from './Tier';
import InfoPopover from './InfoPopover';

const Floorplan = ({
  floorplan: {
    floorplan_id: floorplanId,
    space_type: spaceType,
    floorplan_spacetype_key: floorplanSpacetypeKey,
    floorplan_name: floorplanName,
    bed_count: bedCount,
    approved_rate: approvedRate,
    previous_eos_hit_rate: previousEosHitRate,
    adjusted_eos_hit_rate: adjustedEosHitRate,
  },
  isEditing: isEditing,
}) => {
  const store = useContext(StoreContext);
  const state = store.state;
  const dispatch = store.dispatch;
  const {
    version: {
      description_investor_approved_rate: descriptionInvestorApprovedRate,
      description_adjusted_eos_hit_rate: descriptionAdjustedEosHitRate,
      description_previous_eos_hit_rate: descriptionPreviousEosHitRate,
    },
  } = store.state;

  const tiers = Object.values(state.tiers).filter(
    tier => tier.floorplan_spacetype_key === floorplanSpacetypeKey
  );

  const floorplanCalculations = store.state?.floorplan_calculations?.[floorplanSpacetypeKey];
  const lastTier = tiers[tiers.length - 1];

  const addTier = () => {
    dispatch(createAction(actions.ADD_TIER, { floorplanId, spaceType, floorplanSpacetypeKey }));
  };

  return (
    <>
      <Box mt={4} mb={1}>
        <Typography style={{ fontWeight: 600 }} variant="h5">
          {floorplanName}
        </Typography>
      </Box>
      <Box mb={1}>
        <Grid container className="grid grid-cols-3 gap-x-8 mt-1">
          <Grid item>
            <Typography
              style={{ fontWeight: 400, lineHeight: 1.2 }}
              variant="h6"
              className={
                floorplanCalculations?.total_contracts &&
                floorplanCalculations?.total_contracts != bedCount
                  ? 'text-orange-700'
                  : 'text-gray-700'
              }
            >
              <HotelIcon className="align-text-bottom" />{' '}
              {floorplanCalculations?.total_contracts &&
              floorplanCalculations?.total_contracts != bedCount
                ? `${floorplanCalculations?.total_contracts} / ${bedCount}`
                : bedCount}
            </Typography>
            <Typography
              style={{ fontWeight: 200, fontSize: '10px' }}
              variant="caption"
              className="text-gray-600"
            >
              <span>BED COUNT</span>
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              style={{ fontWeight: 400, lineHeight: 1.2 }}
              variant="h6"
              className="text-gray-700"
            >
              <CheckCircleIcon className="align-text-bottom" /> ${approvedRate?.toFixed(2)}
            </Typography>
            <Typography
              style={{ fontWeight: 200, fontSize: '10px' }}
              variant="caption"
              className="text-gray-600"
            >
              <InfoPopover text={descriptionInvestorApprovedRate} />
              <span style={{ marginLeft: 6 }}>INVESTOR APPROVED RATE</span>
            </Typography>
          </Grid>
          {previousEosHitRate !== null && (
            <Grid item>
              <Typography
                style={{ fontWeight: 400, lineHeight: 1.2 }}
                variant="h6"
                className="text-gray-700"
              >
                <TrackChangesIcon className="align-text-bottom" /> ${previousEosHitRate?.toFixed(2)}
              </Typography>
              <Typography
                style={{ fontWeight: 200, fontSize: '10px' }}
                variant="caption"
                className="text-gray-600"
              >
                <InfoPopover text={descriptionPreviousEosHitRate} />
                <span style={{ marginLeft: 6 }}>PRIOR APPROVED EOS HIT RATE</span>
              </Typography>
            </Grid>
          )}
          <Grid item>
            <Typography
              style={{ fontWeight: 400, lineHeight: 1.2 }}
              variant="h6"
              className={
                floorplanCalculations?.cumu_avg_rates &&
                floorplanCalculations.cumu_avg_rates.slice(-1)[0] < previousEosHitRate
                  ? 'text-orange-700'
                  : 'text-teal-600'
              }
            >
              <CalculateIcon className="align-text-bottom" /> $
              {floorplanCalculations?.cumu_avg_rates
                ? floorplanCalculations.cumu_avg_rates.slice(-1)[0].toFixed(2)
                : null}{' '}
            </Typography>
            <Typography
              style={{ fontWeight: 200, fontSize: '10px' }}
              variant="caption"
              className="text-gray-600"
            >
              <InfoPopover text={descriptionAdjustedEosHitRate} />
              <span style={{ marginLeft: 6 }}>ADJUSTED EOS HIT RATE</span>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <div className="overflow-x-auto inline-grid xs:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-7 gap-3 mb-6">
        {tiers &&
          tiers.map(tier => (
            <Tier
              key={tier.tier_key}
              tier={tier}
              asjustedEosHitRate={adjustedEosHitRate}
              lastTier={tier.tier_key === lastTier.tier_key}
              isEditing={isEditing}
            />
          ))}
        {isEditing && (
          <Grid
            container
            className="grid grid-cols-1 justify-center border rounded border-dashed text-gray-400 hover:text-blue-400 hover:border-blue-400"
          >
            <button type="button" onClick={addTier} className="w-full">
              <AddCircleIcon style={{ fontSize: 70 }} />
            </button>
          </Grid>
        )}
      </div>
    </>
  );
};

Floorplan.propTypes = {
  floorplan: PropTypes.shape({
    floorplan_id: PropTypes.number,
    space_type: PropTypes.number,
    floorplan_spacetype_key: PropTypes.string,
    floorplan_name: PropTypes.string,
    bed_count: PropTypes.number,
    approved_rate: PropTypes.number,
    previous_eos_hit_rate: PropTypes.number,
    adjusted_eos_hit_rate: PropTypes.number,
  }),
  isEditing: PropTypes.bool,
};

Floorplan.defaultProps = {
  floorplan: {},
  isEditing: false,
};

export default Floorplan;
