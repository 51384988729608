import React from 'react';
import PropTypes from 'prop-types';

const ErrorBlock = ({ code, message, errors }) => {
  return (
    <div className="p-4 bg-red-100 border border-red-600 rounded mb-6 leading-normal">
      <p className="w-full text-red-600 p-1 my-1 font-normal">
        {message} {code && <span className="text-xs">[code: {code}]</span>}
      </p>
      <ul className="w-full px-8 text-red-600 list-disc">
        {errors &&
          errors.map(({ field, code }) => (
            <li key={code + field} className="p-1 my-1">
              {field} {code}
            </li>
          ))}
      </ul>
    </div>
  );
};

ErrorBlock.propTypes = {
  code: PropTypes.string,
  message: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      resource: PropTypes.string,
    })
  ),
};

ErrorBlock.defaultProps = {
  code: null,
  errors: [],
};

export default ErrorBlock;
