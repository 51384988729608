import React from 'react';
import PropTypes from 'prop-types';

import { Menu, MenuItem, MenuList, ListItemIcon } from '@material-ui/core';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import MessageIcon from '@mui/icons-material/Message';
import LogoutIcon from '@mui/icons-material/Logout';
import api from '../../../api';

const AccountMenu = ({
  accountMenuAnchorEl,
  accountMenuOpen,
  canEditRepresentatives,
  current,
  handleAccountMenuClose,
}) => {
  return (
    <Menu
      anchorEl={accountMenuAnchorEl}
      open={accountMenuOpen}
      onClose={handleAccountMenuClose}
      onClick={handleAccountMenuClose}
      PaperProps={{
        elevation: 0,
        style: {
          opacity: 0.98,
          filter: 'drop-shadow(0px 2px 6px rgba(0,0,0,0.32))',
        },
      }}
      getContentAnchorEl={null}
      anchorReference="anchorEl"
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    >
      <MenuList dense>
        <MenuItem onClick={() => window.location.assign(`/users/${current.active_user.id}/edit`)}>
          <ListItemIcon style={{ minWidth: 32 }}>
            <AccountCircleOutlinedIcon fontSize="small" />
          </ListItemIcon>
          Edit Profile
        </MenuItem>
        {canEditRepresentatives && (
          <MenuItem onClick={() => window.location.assign('/representatives')}>
            <ListItemIcon style={{ minWidth: 32 }}>
              <SupervisorAccountIcon fontSize="small" />
            </ListItemIcon>
            Edit Representatives
          </MenuItem>
        )}
        <MenuItem onClick={() => window.location.assign('/feedback')}>
          <ListItemIcon style={{ minWidth: 32 }}>
            <MessageIcon fontSize="small" />
          </ListItemIcon>
          Submit Feedback
        </MenuItem>
        <MenuItem
          onClick={() => {
            api.session.destroy().then(() => window.location.assign('/sign_in'));
          }}
        >
          <ListItemIcon style={{ minWidth: 32 }}>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

AccountMenu.propTypes = {
  accountMenuAnchorEl: PropTypes.shape({}),
  accountMenuOpen: PropTypes.bool.isRequired,
  canEditRepresentatives: PropTypes.bool,
  current: PropTypes.shape({
    active_user: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  }).isRequired,
  handleAccountMenuClose: PropTypes.func.isRequired,
};

AccountMenu.defaultProps = {
  accountMenuAnchorEl: null,
  canEditRepresentatives: false,
};

export default AccountMenu;
