import * as types from './types';

export const toggleDimension = dimension => ({
  type: types.TOGGLE,
  payload: { dimension },
});

export const deleteDimension = dimensionId => ({
  type: types.DELETE,
  payload: { dimensionId },
});
