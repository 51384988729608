import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const styles = {
  formControlLabel: {
    fontSize: 12,
  },
};

const CheckboxGeneralCommunication = withStyles({
  root: {
    color: '#add8e6',
    '&$checked': {
      color: '#add8e6',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const CheckboxRolloutOrInitiative = withStyles({
  root: {
    color: '#ffa500',
    '&$checked': {
      color: '#ffa500',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const CheckboxTraining = withStyles({
  root: {
    color: '#008000',
    '&$checked': {
      color: '#008000',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const CheckboxWebinar = withStyles({
  root: {
    color: '#008080',
    '&$checked': {
      color: '#008080',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const CheckboxMeeting = withStyles({
  root: {
    color: '#00008b',
    '&$checked': {
      color: '#00008b',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const CheckboxHoliday = withStyles({
  root: {
    color: '#ff0000',
    '&$checked': {
      color: '#ff0000',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const CheckboxEvent = withStyles({
  root: {
    color: '#d27d50',
    '&$checked': {
      color: '#d27d50',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const CheckboxOptionalOfficeHour = withStyles({
  root: {
    color: '#ff69b4',
    '&$checked': {
      color: '#ff69b4',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const CheckboxMisc = withStyles({
  root: {
    color: '#800080',
    '&$checked': {
      color: '#800080',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const CheckboxLegacyEvent = withStyles({
  root: {
    color: '#000000',
    '&$checked': {
      color: '#000000',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const CheckboxTravelEvent = withStyles({
  root: {
    color: '#892dd9', // BlueViolet color
    '&$checked': {
      color: '#892dd9',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const EventFilter = props => {
  const { filters: filters, handleCheckboxChange: handleCheckboxChange } = props;

  return (
    <Box display="flex" justifyContent="flex-start" alignItems="center">
      <FormGroup row>
       <FormControlLabel
          control={
            <CheckboxGeneralCommunication
              checked={filters.checkedGeneralCommunication}
              onChange={handleCheckboxChange}
              name="checkedGeneralCommunication"
            />
          }
          label={<Typography style={styles.formControlLabel}>General Communication</Typography>}
        />
        <FormControlLabel
          control={
            <CheckboxRolloutOrInitiative
              checked={filters.checkedRolloutOrInitiative}
              onChange={handleCheckboxChange}
              name="checkedRolloutOrInitiative"
            />
          }
          label={<Typography style={styles.formControlLabel}>Rollout or Initiative</Typography>}
        />
        <FormControlLabel
          control={
            <CheckboxTraining
              checked={filters.checkedTraining}
              onChange={handleCheckboxChange}
              name="checkedTraining"
            />
          }
          label={<Typography style={styles.formControlLabel}>Training</Typography>}
        />
        <FormControlLabel
          control={
            <CheckboxWebinar
              checked={filters.checkedWebinar}
              onChange={handleCheckboxChange}
              name="checkedWebinar"
            />
          }
          label={<Typography style={styles.formControlLabel}>Webinar</Typography>}
        />
        <FormControlLabel
          control={
            <CheckboxMeeting
              checked={filters.checkedMeeting}
              onChange={handleCheckboxChange}
              name="checkedMeeting"
            />
          }
          label={<Typography style={styles.formControlLabel}>Meeting</Typography>}
        />
        <FormControlLabel
          control={
            <CheckboxHoliday
              checked={filters.checkedHoliday}
              onChange={handleCheckboxChange}
              name="checkedHoliday"
            />
          }
          label={<Typography style={styles.formControlLabel}>Holiday</Typography>}
        />
        <FormControlLabel
          control={
            <CheckboxEvent
              checked={filters.checkedEvent}
              onChange={handleCheckboxChange}
              name="checkedEvent"
            />
          }
          label={<Typography style={styles.formControlLabel}>Event</Typography>}
        />
        <FormControlLabel
          control={
            <CheckboxOptionalOfficeHour
              checked={filters.checkedOptionalOfficeHour}
              onChange={handleCheckboxChange}
              name="checkedOptionalOfficeHour"
            />
          }
          label={<Typography style={styles.formControlLabel}>Optional Office Hour</Typography>}
        />
        <FormControlLabel
          control={
            <CheckboxMisc
              checked={filters.checkedMisc}
              onChange={handleCheckboxChange}
              name="checkedMisc"
            />
          }
          label={<Typography style={styles.formControlLabel}>Misc</Typography>}
        />
        <FormControlLabel
          control={
            <CheckboxLegacyEvent
              checked={filters.checkedLegacyEvent}
              onChange={handleCheckboxChange}
              name="checkedLegacyEvent"
            />
          }
          label={<Typography style={styles.formControlLabel}>Legacy Event</Typography>}
        />
        <FormControlLabel
          control={
            <CheckboxTravelEvent
              checked={filters.checkedTravelEvent}
              onChange={handleCheckboxChange}
              name="checkedTravelEvent"
            />
          }
          label={<Typography style={styles.formControlLabel}>Travel Event</Typography>}
        />
      </FormGroup>
    </Box>
  );
};

EventFilter.propTypes = {
  filters: PropTypes.shape({
    checkedGeneralCommunication: PropTypes.bool.isRequired,
    checkedRolloutOrInitiative: PropTypes.bool.isRequired,
    checkedTraining: PropTypes.bool.isRequired,
    checkedMeeting: PropTypes.bool.isRequired,
    checkedWebinar: PropTypes.bool.isRequired,
    checkedHoliday: PropTypes.bool.isRequired,
    checkedEvent: PropTypes.bool.isRequired,
    checkedOptionalOfficeHour: PropTypes.bool.isRequired,
    checkedMisc: PropTypes.bool.isRequired,
    checkedLegacyEvent: PropTypes.bool.isRequired,
    checkedTravelEvent: PropTypes.bool.isRequired,
  }).isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
};

export default EventFilter;
