import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import PropertyInfoCard from './PropertyInfoCard';

const PropertyInfoCardGrid = ({ cards, permissionToEdit }) => (
  <Box sx={{ flexGrow: 1 }}>
    <Grid container spacing={2}>
      {cards.map(card => (
        <Grid item xs={12} sm={6} md={4} key={card.id}>
          <PropertyInfoCard cardInfo={card} permissionToEdit={permissionToEdit} />
        </Grid>
      ))}
    </Grid>
  </Box>
);

PropertyInfoCardGrid.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.shape),
  permissionToEdit: PropTypes.bool.isRequired,
};

PropertyInfoCardGrid.defaultProps = {
  cards: PropTypes.arrayOf({
    content: null,
  }),
};
export default PropertyInfoCardGrid;
