/* eslint react/prop-types: 0 */
import React from 'react';
import {
  Box,
  Button,
  Chip,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Cancel';

export default ({
  dimensions,
  measures,
  calculations,
  pivotDimensions,
  deleteDimension,
  deleteMeasure,
  deleteCalculation,
  selectPivotDimension,
}) => {
  return (
    <Box p="1rem" bgcolor="white">
      <Typography variant="h5">Dimensions</Typography>
      <List dense>
        {Object.values(dimensions).map((dimension, index) => (
          <AttributeItem
            key={dimension.id}
            listIndex={index}
            item={dimension}
            deleteItem={deleteDimension}
            selectPivotDimension={selectPivotDimension}
            pivotDimensions={pivotDimensions}
          />
        ))}
      </List>
      <Typography variant="h5">Measures</Typography>
      <List dense>
        {Object.values(measures).map((measure, index) => (
          <AttributeItem
            key={measure.id}
            listIndex={index}
            item={measure}
            deleteItem={deleteMeasure}
          />
        ))}
      </List>
      <Typography variant="h5">Calculations</Typography>
      <List dense>
        {Object.values(calculations).map((calculation, index) => (
          <AttributeItem
            key={calculation.id}
            listIndex={index}
            item={calculation}
            deleteItem={deleteCalculation}
          />
        ))}
      </List>
    </Box>
  );
};

const AttributeItem = ({ listIndex, item, deleteItem, pivotDimensions, selectPivotDimension }) => (
  <Box key={item.id} borderTop={listIndex === 0 ? 0 : 1} borderColor="grey.300">
    <ListItem>
      <ListItemText primary={item.label} secondary={item.description} />
      <ListItemSecondaryAction>
        {selectPivotDimension ? (
          <PivotAction
            item={item}
            pivotDimensions={pivotDimensions}
            selectPivotDimension={selectPivotDimension}
          />
        ) : (
          <></>
        )}
        <IconButton
          edge="end"
          aria-label="add-filter"
          onClick={() => deleteItem(item.id)}
          size="medium"
        >
          <DeleteIcon fontSize="inherit" />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  </Box>
);

const PivotAction = ({ item, pivotDimensions, selectPivotDimension }) => {
  if (pivotDimensions[item.id]) {
    return (
      <Chip
        label="PIVOTED"
        size="small"
        variant="default"
        onClick={() => selectPivotDimension(item)}
      />
    );
  } else {
    return (
      <Button size="small" onClick={() => selectPivotDimension(item)}>
        Pivot
      </Button>
    );
  }
};
