import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import TextField from '@material-ui/core/TextField';

import { get as objectGet } from '@utils/lib/object';

const styles = {
  inputBase: `appearance-none font-light block w-full text-gray-700 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`,
  labelBase: `block text-gray-700 text-sm font-bold mb-2 truncate`,
  errorBase: 'text-red-600 p-1 my-1',
  statusBase: 'text-red-600 p-1 my-2',
};

const FormikInputTimePicker = ({
  children,
  field,
  form: { setFieldValue, status, touched, errors },
  isDisabled,
  label,
  onChange,
  uid,
  value,
  ...props
}) => {
  return (
    <div>
      <span className={classNames(styles.labelBase)}>{label}</span>
      <div className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
        <TextField
          id={uid}
          type="time"
          disabled={isDisabled}
          color="primary"
          style={{
            marginLeft: 0,
            marginRight: 0,
            width: '100%',
          }}
          placeholder="08:30"
          value={
            field.value && field.value.length === 5
              ? field.value
              : new Date(Date.parse(field.value)).toLocaleTimeString('en-US', {
                  hour12: false,
                  hour: '2-digit',
                  minute: '2-digit',
                })
          }
          className={styles.inputBase}
          inputProps={{
            step: 900, // 5 min
          }}
          onChange={e => {
            if (e.target.value != null) {
              let timeString = e.target.value;
              setFieldValue(field.name, timeString);
            }
          }}
          {...props}
        />
      </div>

      {objectGet(touched, field.name) && objectGet(status, field.name) && (
        <div className={classNames(styles.statusBase)}>{objectGet(status, field.name)}</div>
      )}

      {objectGet(touched, field.name) && objectGet(errors, field.name) && (
        <div className={classNames(styles.errorBase)}>{objectGet(errors, field.name)}</div>
      )}
    </div>
  );
};

FormikInputTimePicker.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    status: PropTypes.shape(),
    touched: PropTypes.shape(),
    errors: PropTypes.shape(),
  }).isRequired,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  uid: PropTypes.string,
  value: PropTypes.string,
};

FormikInputTimePicker.defaultProps = {
  children: null,
  isDisabled: false,
  label: '',
  onChange: undefined,
  uid: 'react_time_picker_id',
  value: undefined,
};

export default FormikInputTimePicker;
