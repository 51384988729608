import PropTypes from 'prop-types';
import React from 'react';
import * as Icon from 'react-feather';

import { decimalToPercent, numberWithCommas } from '@utils/lib/number';

const DataType = ({ value, format }) => {
  return value == null ? '-' : formatValue(value, format);
};

DataType.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  format: PropTypes.string,
};

DataType.defaultProps = {
  format: null,
  value: null,
};

const CheckType = ({ value }) => {
  return <span className="inline-block">{value && <Icon.Check size={12} color="green" />}</span>;
};

CheckType.propTypes = {
  value: PropTypes.bool,
};

CheckType.defaultProps = {
  value: null,
};

const BooleanType = ({ value, isReversed }) => {
  const colors = isReversed ? ['red', 'green'] : ['green', 'red'];
  const color = value ? colors[0] : colors[1];

  return value == null ? (
    '-'
  ) : (
    <span className="inline-block">
      {value ? <Icon.Check size={12} color={color} /> : <Icon.X size={12} color={color} />}
    </span>
  );
};

BooleanType.propTypes = {
  value: PropTypes.bool,
  isReversed: PropTypes.bool,
};

BooleanType.defaultProps = {
  isReversed: false,
  value: null,
};

const LinkType = ({ value, children }) => {
  return value == null ? '-' : <a href={value}>{children}</a>;
};

LinkType.propTypes = {
  value: PropTypes.string,
  children: PropTypes.string,
};

LinkType.defaultProps = {
  children: null,
  value: null,
};

const formatValue = (value, format) => {
  switch (format) {
    case 'percent':
      return `${decimalToPercent(value)}%`;
    case 'number_with_delimiter':
      return numberWithCommas(value);
    default:
      return value;
  }
};

export { DataType, BooleanType, LinkType, CheckType };
