import classNames from 'classnames/dedupe';
import PropTypes from 'prop-types';
import React from 'react';
import { Check } from 'react-feather';

import { get as objectGet } from '@utils/lib/object';

const styles = {
  labelBase: {
    block: true,
    'text-gray-700': true,
    'text-sm': true,
    'font-bold': true,
    'mb-2': true,
    truncate: true,
  },
  checkboxBase: 'inline-block p-1 mr-4 rounded-sm trans cursor-pointer',
  errorBase: 'text-red-600 p-1 my-1',
  statusBase: 'text-red-600 p-1 my-2',
};

const FormikInputCheckbox = ({
  field,
  form: { errors, touched, status, setFieldValue },
  key = null,
  label = null,
  boxIcon = null,
  checkboxClassName = {},
  labelClassName = null,
  ...props
}) => (
  <div key={key}>
    {label && (
      <label htmlFor={field.name} className={classNames(styles.labelBase, labelClassName)}>
        {label}
      </label>
    )}

    <input
      type="checkbox"
      className={classNames(
        'border-0',
        'clip-react-0',
        'h-px',
        '-m-px',
        'overflow-hidden',
        'p-0',
        'absolute',
        'whitespace-nowrap',
        'w-px',
        'invisible'
      )}
      checked={field.value}
      {...field}
      {...props}
    />

    <div
      role="checkbox"
      aria-checked={field.value}
      tabIndex={0}
      className={classNames(
        styles.checkboxBase,
        {
          'bg-gray-400': !field.value,
          'bg-gray-500': field.value,
        },
        checkboxClassName
      )}
      checked={field.value}
      onClick={() => setFieldValue(field.name, !field.value)}
      onKeyDown={event => {
        const isEnterOrSpace = event.keyCode === 32 || event.keyCode === 13;
        if (isEnterOrSpace) {
          event.preventDefault();
          setFieldValue(field.name, !field.value);
        }
      }}
      {...props}
    >
      {boxIcon instanceof Function ? (
        boxIcon()
      ) : (
        <Check
          className={classNames({
            invisible: !field.value,
          })}
          size="16"
          color="white"
        />
      )}
    </div>

    {objectGet(touched, field.name) && objectGet(status, field.name) && (
      <div className={classNames(styles.statusBase)}>{objectGet(status, field.name)}</div>
    )}

    {objectGet(touched, field.name) && objectGet(errors, field.name) && (
      <div className={classNames(styles.errorBase)}>{objectGet(errors, field.name)}</div>
    )}
  </div>
);

FormikInputCheckbox.propTypes = {
  boxIcon: PropTypes.node,
  checkboxClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  field: PropTypes.shape({
    value: PropTypes.bool,
    name: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    status: PropTypes.shape({}),
    touched: PropTypes.shape({}),
    errors: PropTypes.shape({}),
  }).isRequired,
  key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  labelClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
};

FormikInputCheckbox.defaultProps = {
  boxIcon: undefined,
  checkboxClassName: {},
  key: null,
  label: PropTypes.string,
  labelClassName: {},
};

export default FormikInputCheckbox;
