import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  inputBase: `appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`,
  labelBase: 'block text-gray-700 text-sm font-bold mb-2',
  errorBase: 'text-red-600 p-1 my-1',
  statusBase: 'text-red-600 p-1 my-2',
  disabledBase: 'bg-disabled font-light',
};

const FormikInputFile = ({
  field,
  setFieldValue,
  inputClassName,
  isDisabled,
  key,
  label,
  labelClassName,
  multiple,
  required,
  type,
  ...props
}) => (
  <div key={key}>
    <label htmlFor={field.name} className={classNames(styles.labelBase, labelClassName)}>
      {label}
    </label>

    <input
      className={classNames(
        styles.inputBase,
        { [`${styles.disabledBase}`]: isDisabled },
        inputClassName
      )}
      disabled={isDisabled}
      multiple={multiple}
      required={required}
      type={type}
      onChange={event => {
        if (multiple) {
          const files = event.target.files;
          for (let i = 0; i < files.length; i++) {
            setFieldValue(`itemToEdit.${field.name}[${i}]`, files[i]);
          }
        } else {
          setFieldValue(`itemToEdit.${field.name}`, event.currentTarget.files[0]);
        }
      }}
      {...props}
    />
  </div>
);

FormikInputFile.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  inputClassName: PropTypes.string,
  isDisabled: PropTypes.bool,
  key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  type: PropTypes.string,
};

FormikInputFile.defaultProps = {
  isDisabled: false,
  inputClassName: '',
  key: null,
  label: null,
  labelClassName: '',
  multiple: false,
  required: false,
  type: 'file',
};

export default FormikInputFile;
