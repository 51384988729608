import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Typography } from '@material-ui/core';

const TierFooter = ({ cumuRateAvg, cellStyle, previousTargetRate }) => {
  return (
    <Grid item xs={12} className="text-center text-gray-600 bg-gray-100 p-1" style={cellStyle}>
      <Typography
        style={{ fontWeight: 400 }}
        variant="caption"
        className={cumuRateAvg < previousTargetRate ? 'text-orange-700' : 'text-teal-600'}
      >
        ${cumuRateAvg && cumuRateAvg.toFixed(2)}
      </Typography>
    </Grid>
  );
};

TierFooter.propTypes = {
  cumuRateAvg: PropTypes.number,
  cellStyle: PropTypes.shape({}),
  previousTargetRate: PropTypes.number,
};

TierFooter.defaultProps = {
  cumuRateAvg: null,
  cellStyle: {},
  previousTargetRate: null,
};

export default TierFooter;
