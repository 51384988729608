import PropTypes from 'prop-types';
import React from 'react';

const HeaderStatsContainer = ({ children }) => (
  <div className="flex flex-wrap lg:flex-nowrap mb-12">{children}</div>
);

HeaderStatsContainer.propTypes = {
  children: PropTypes.node,
};

HeaderStatsContainer.defaultProps = {
  children: null,
};

const HeaderStat = ({ title, value, subValue }) => (
  <div className="w-full md:w-2/4 lg:w-1/4 lg:border-r last:border-r-0 py-2 sm:py-4 px-2 sm:px-5">
    <h5 className="w-full uppercase text-gray-500 text-sm font-semibold tracking-widest mb-2 truncate">
      {title}
    </h5>
    <div className="uppercase text-2xl text-black text-center">{value}</div>
    {subValue !== null && <div className="mt-2 text-xl">{subValue}</div>}
  </div>
);

HeaderStat.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.node,
  subValue: PropTypes.node,
};

HeaderStat.defaultProps = {
  subValue: null,
  value: null,
};

export { HeaderStatsContainer, HeaderStat };
