import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button"];

  connect() {
    const path = this.data.get('path')
    const min_date = this.data.get('min')
    const max_date = this.data.get('max')
    const date_url_prefix = path.includes("?") ? "&" : "?"
    $(this.buttonTarget).pickadate({
      format: 'yyyy-mm-dd',
      min: new Date(min_date),
      max: new Date(max_date),
      onSet: function(context) {
        // If valid selection
        if (!!context.select) {
          const date = new Date(context.select),
                year = date.getFullYear(),
                month = date.getMonth() + 1,
                day = date.getDate(),
                url = `${window.location.origin}${path}${date_url_prefix}date=${year}-${month}-${day}`
          window.location.replace(url);
        }
      }
    })
  }
}
