import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import api from '../api';
import PropertyFeeForm from './PropertyFeeForm';
import Loader from '../shared/Loader';

const PropertyFeeFormContainer = props => {
  const { fees, isEdit, propertyId, onUpdate, formObject } = props;
  const [state, setState] = useState({
    propertyFee: null,
    error: [],
    isLoading: true,
  });
  const [formErrors, setFormErrors] = useState();

  useEffect(() => {
    const params = { property_id: propertyId, form_class: 'PropertyFee', formObject: formObject };

    axios
      .all([api.crudItems.editOrNew(params)])
      .then(
        axios.spread(response => {
          setState({
            ...state,
            propertyFee: response.data.form_object,
            isLoading: false,
          });
        })
      )
      .catch(() => {
        setState({
          ...state,
          error: 'Something went wrong.',
        });
      });
  }, []);

  const { propertyFee, error, isLoading } = state;

  return (
    <div>
      {isLoading && (
        <div className="mt-6">
          <Loader />
        </div>
      )}
      {error && error}
      {propertyFee && (
        <PropertyFeeForm
          propertyFee={propertyFee}
          propertyId={propertyId}
          fees={fees}
          onUpdate={onUpdate}
          setFormErrors={setFormErrors}
          isEdit={isEdit}
        />
      )}
      {formErrors && (
        <ul className="list-inside list-disc mt-6">
          {formErrors.map(error => (
            <li key={error} className="text-red-600 text-base mb-3">
              {error}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

PropertyFeeFormContainer.propTypes = {
  fees: PropTypes.instanceOf(Array).isRequired,
  formObject: PropTypes.shape({}),
  isEdit: PropTypes.bool,
  propertyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onUpdate: PropTypes.func,
};

PropertyFeeFormContainer.defaultProps = {
  formObject: {},
  onUpdate: undefined,
  isEdit: false,
};

export default PropertyFeeFormContainer;
