import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import SortablePhoto from './SortablePhoto';

const SortablePhotoGrid = SortableContainer(({ photos }) => {
  return (
    <Box sx={{ flexGrow: 1, paddingTop: '24px' }}>
      <Grid container spacing={2}>
        {photos.map((photo, index) => (
          <SortablePhoto key={photo.id} index={index} photo={photo} />
        ))}
      </Grid>
    </Box>
  );
});

export default SortablePhotoGrid;
