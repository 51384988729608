import waterfallChart from "../common/waterfall";

document.addEventListener('DOMContentLoaded', function () {
  const Highcharts = require("highcharts");
  // Load module after Highcharts is loaded
  require("highcharts/modules/exporting")(Highcharts);

  if ($("#js-leasable-student-waterfall").length) {
    const domChart = $("#js-leasable-student-waterfall");
    const data = $("#js-leasable-student-waterfall").data("graph");

    const options = {
      chart: {
        renderTo: domChart[0]
      },
      title: {
        text: null
      },
      yAxis: {
        visible: false,
        stackLabels: {
          enabled: true,
          align: "left"
        }
      },
      tooltip: {
        pointFormatter() {
          return `<span style="color:{point.color}"></span><b>${
            this.y
          }</b> Students<br/>`;
        }
      },
      series: [
        {
          color: "#181D30",
          upColor: "#FFCE00",
          borderWidth: 0,
          showInLegend: false,
          data: [
            {
              name: "Total Enrollment",
              y: data.enrollment_total,
              color: "gray",
              dataLabels: dataLabelConfig("top")
            },
            {
              name: "On-Campus Beds",
              y: data.enrollment_on_campus,
              dataLabels: dataLabelConfig("bottom")
            },
            {
              name: "Graduates",
              y: data.enrollment_graduate,
              dataLabels: dataLabelConfig("bottom")
            },
            {
              name: "Part-Time",
              y: data.enrollment_part_time,
              dataLabels: dataLabelConfig("bottom")
            },
            {
              name: "Young Professional",
              y: data.enrollment_young_professional,
              dataLabels: dataLabelConfig("top")
            },
            {
              name: "Greek",
              y: data.enrollment_greek,
              dataLabels: dataLabelConfig("bottom")
            },
            {
              name: "Leasable Students",
              color: "gray",
              isSum: true,
              y: data.leasable_student_total,
              dataLabels: dataLabelConfig("top")
            }
          ],
          dataLabels: {
            enabled: true
          }
        }
      ]
    };

    const chart = waterfallChart(options);
  }

  function dataLabelConfig(vAlign) {
    const vAlignIsTop = vAlign === "top";
    const yValue = vAlignIsTop ? -25 : 25;
    return {
      inside: !vAlignIsTop,
      verticalAlign: vAlign,
      crop: false,
      color: "#000",
      shadow: undefined,
      y: yValue
    };
  }
})