var Highcharts = require('highcharts');
// Load module after Highcharts is loaded
require('highcharts/modules/exporting')(Highcharts);
import {fontFamily, labelStyles, titleStyles} from "../utils";

document.addEventListener('DOMContentLoaded', function () {
  if ($('#js-floorplan-graph').length) {
    const data = $('#js-floorplan-graph').data('graph-data');
    Highcharts.chart('js-floorplan-graph', {
      chart: {
        type: 'bar',
        fontFamily: fontFamily
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: data["floorplan_names"],
        title: '',
        labels: {
          style: labelStyles
        }
      },
      yAxis: {
        min: 0,
        labels: {
          overflow: 'justify',
          style: labelStyles
        },
        title: {
          text: ''
        }
      },
      tooltip: {
        valueSuffix: ''
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            style: {
              fontFamily: fontFamily,
              fontSize: '10px'
            }
          }
        }
      },
      legend: {
        itemStyle: titleStyles
      },
      credits: {
        enabled: false
      },
      series: [{
        color: "#2BBBAD",
        name: 'Signed',
        data: data["leased_count"]
      },
      {
        color: "#353b50",
        name: 'Beds',
        data: data["bed_count"]
      }]
    });
  }
})