import PropTypes from 'prop-types';
import React, { useState, useMemo } from 'react';
import * as Icon from 'react-feather';
import classNames from 'classnames';

import IncentiveFormContainer from './IncentiveFormContainer';
import { usePanelDispatch } from '../panel/PanelContext';
import * as panelTypes from '../panel/panelTypes';
import UnitMixTable from '../unit_mix/UnitMixTable';
import StandardTable from '../table/StandardTable';

const styles = {
  scopeButtonActive: 'text-white bg-gray-700 hover:bg-gray-800 border-gray-600',
  scopeButton: 'text-gray-700 bg-gray-100 hover:bg-gray-200 border-gray-200',
};

const expanderCell = cellInfo => {
  let {
    row: { getToggleRowExpandedProps, isExpanded },
  } = cellInfo;
  return (
    <span {...getToggleRowExpandedProps()}>
      {isExpanded ? String.fromCharCode(9662) : String.fromCharCode(9656)}
    </span>
  );
};

const editCell = onUpdate => cellInfo => {
  let {
    row: { original: rowAttributes },
  } = cellInfo;
  return (
    <EditIncentiveButton
      propertyId={rowAttributes.property_id}
      incentiveId={rowAttributes.id}
      onUpdate={onUpdate}
    />
  );
};

const IncentiveTable = ({ data: incentives, onUpdate }) => {
  const [scope, setScope] = useState('current');

  const columns = useMemo(() => [
    {
      id: 'expander',
      Header: () => null,
      Cell: expanderCell,
    },
    {
      Header: 'Description',
      accessor: 'description',
      minWidth: 125,
    },
    {
      id: 'method',
      Header: 'Method',
      accessor: 'method',
      className: 'text-center capitalize',
      Cell: row => row.cell.value && row.cell.value.replace('_', ' '),
    },
    {
      Header: 'Incentive Type',
      accessor: 'incentive_type',
      className: 'text-center',
    },
    {
      Header: 'Scope',
      accessor: 'scope',
      className: 'text-center',
    },
    {
      Header: 'Value',
      accessor: 'value',
      className: 'text-center',
    },
    {
      Header: 'Start Date',
      accessor: 'start_date',
      className: 'text-center',
    },
    {
      Header: 'End Date',
      accessor: 'end_date',
      className: 'text-center',
    },
    {
      Header: 'Advertised?',
      accessor: 'advertised',
      className: 'text-center',
      Cell: row => (row.cell.value ? 'x' : ''),
    },
    {
      id: 'unitMixCount',
      Header: '# Unit Mixes',
      className: 'text-center',
      accessor: d => d.unit_mixes && d.unit_mixes.length,
    },
    {
      id: 'editIncentive',
      Header: 'Edit',
      accessor: 'id',
      className: 'text-center',
      Cell: editCell(onUpdate),
    },
  ]);

  const handleScopeChange = value => {
    setScope(value);
  };

  const dataWithScope = data => {
    switch (scope) {
      case 'past':
        return data.filter(d => d.status === 'past');
      case 'future':
        return data.filter(d => d.status === 'future');
      case 'current':
        return data.filter(d => d.status === 'current');
      default:
        return data;
    }
  };

  const data = useMemo(() => dataWithScope(incentives));

  const renderRowSubComponent = row => (
    <div key="associated_unit_mixes" className="p-5">
      <h4 className="text-lg font-medium mb-2">Associated Unit Mixes</h4>
      <UnitMixTable data={row.row.original.unit_mixes} />
    </div>
  );

  return (
    <div className="flex flex-column">
      <div className="flex justify-between text-center mb-3">
        <button
          type="button"
          onClick={() => handleScopeChange('past')}
          className={classNames('w-1/4 p-2 trans-fast border', {
            [styles.scopeButtonActive]: scope === 'past',
            [styles.scopeButton]: scope != 'past',
          })}
        >
          Archive
        </button>
        <button
          type="button"
          onClick={() => handleScopeChange('current')}
          className={classNames('w-1/4 p-2 trans-fast border', {
            [styles.scopeButtonActive]: scope === 'current',
            [styles.scopeButton]: scope != 'current',
          })}
        >
          Current
        </button>
        <button
          type="button"
          onClick={() => handleScopeChange('future')}
          className={classNames('w-1/4 p-2 trans-fast border', {
            [styles.scopeButtonActive]: scope === 'future',
            [styles.scopeButton]: scope != 'future',
          })}
        >
          Future
        </button>
      </div>

      <StandardTable
        columns={columns}
        data={data}
        rowSubComponent={renderRowSubComponent}
        isSearchable
      />
    </div>
  );
};

IncentiveTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  showColumns: PropTypes.shape({
    id: PropTypes.bool,
    edit: PropTypes.bool,
    unitMixes: PropTypes.bool,
  }),
  onUpdate: PropTypes.func,
};

IncentiveTable.defaultProps = {
  data: [],
  showColumns: {},
  onUpdate: undefined,
};

const EditIncentiveButton = ({ propertyId, incentiveId, onUpdate }) => {
  const panelDispatch = usePanelDispatch();

  const handleUpdate = () => {
    onUpdate();
    panelDispatch({
      type: panelTypes.CLOSE,
    });
  };

  return (
    <button
      type="button"
      className="inline-block text-center"
      onClick={() => {
        panelDispatch({
          type: panelTypes.OPEN,
          render: (
            <IncentiveFormContainer
              isEdit
              propertyId={propertyId}
              incentiveId={incentiveId}
              onUpdate={handleUpdate}
            />
          ),
        });
      }}
    >
      <Icon.Edit size="12" />
    </button>
  );
};

EditIncentiveButton.propTypes = {
  propertyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  incentiveId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onUpdate: PropTypes.func,
};

EditIncentiveButton.defaultProps = {
  incentiveId: null,
  onUpdate: undefined,
};

export default IncentiveTable;
