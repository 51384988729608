import * as React from 'react';
import PropTypes from 'prop-types';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const BasicTable = ({ headers, data }) => {
  const snakeToCamel = str =>
    str.toLowerCase().replace(/([-_][a-z])/g, group =>
      group
        .toUpperCase()
        .replace('-', '')
        .replace('_', '')
    );
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow sx={{ backgroundColor: '#f7fafc;' }}>
            {headers.map((header, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableCell key={index}>{header.title}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((datum, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              {headers.map((header, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableCell key={index}>{`${header.prefix ?? ''}${
                  datum[snakeToCamel(header.accessor)]
                }`}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

BasicTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.shape).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default BasicTable;
