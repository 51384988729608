import React from 'react';
import PropTypes from 'prop-types';
import Avatars from '@dicebear/avatars';
import sprites from '@dicebear/avatars-initials-sprites';

const generateAvatarSvg = name => {
  const options = { backgroundColors: ['blueGrey'] };
  const avatars = new Avatars(sprites(options));
  const buff = Buffer.from(avatars.create(name));
  return buff.toString('base64');
};

const renderTag = (currentLeaseStatus, color) =>
  !!currentLeaseStatus && (
    <div className="flex-initial">
      <span
        style={{ backgroundColor: `#${color}` }}
        className="rounded px-3 py-1 text-sm font-medium text-white"
      >
        {currentLeaseStatus}
      </span>
    </div>
  );

const dateOptions = { month: 'numeric', day: 'numeric', year: '2-digit' };

const ResidentItem = props => {
  const {
    id,
    customerId,
    firstName,
    lastName,
    email,
    propertyId,
    propertyName,
    currentLeaseStatus,
    leaseStartDate,
    leaseEndDate,
    color,
    logo,
  } = props;
  const logoImage = logo
    ? logo
    : `data:image/svg+xml;base64,${generateAvatarSvg(firstName + ' ' + lastName)}`;

  return (
    <div key={id} className="flex bg-white rounded-lg p-6 w-full">
      <img className="h-16 w-16 rounded-full mx-0 mr-6" src={logoImage} alt="avatar" />
      <div className="text-left leading-tight">
        {firstName && lastName && (
          <h2>
            <a
              href={`/residents/${customerId}`}
              title={propertyName}
              className="text-lg font-medium text-gray-800 float-left no-underline hover:text-indigo-700"
            >
              {firstName + ' ' + lastName}
            </a>
          </h2>
        )}
        {propertyId && (
          <h2 className="clear-both">
            <a
              href={`/properties/${propertyId}`}
              title={propertyName}
              className="text-gray-700 no-underline hover:text-indigo-700"
            >
              {propertyName}
            </a>
          </h2>
        )}
        {email && (
          <div className="text-gray-600">
            <a href={`mailto:${email}`}>{email}</a>
          </div>
        )}
        {leaseStartDate && (
          <h2 className="text-gray-600 clear-both">
            {new Date(Date.parse(leaseStartDate)).toLocaleDateString('en-US', dateOptions)} -{' '}
            {new Date(Date.parse(leaseEndDate)).toLocaleDateString('en-US', dateOptions)}
          </h2>
        )}
        {currentLeaseStatus && (
          <div className="flex flex-wrap mt-1">{renderTag(currentLeaseStatus, color)}</div>
        )}
      </div>
    </div>
  );
};

ResidentItem.propTypes = {
  id: PropTypes.string.isRequired,
  customerId: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  propertyId: PropTypes.number,
  propertyName: PropTypes.string,
  currentLeaseStatus: PropTypes.string,
  leaseStartDate: PropTypes.string,
  leaseEndDate: PropTypes.string,
  color: PropTypes.string,
  logo: PropTypes.string,
};

ResidentItem.defaultProps = {
  customerId: null,
  firstName: null,
  lastName: null,
  email: null,
  propertyId: null,
  propertyName: null,
  currentLeaseStatus: null,
  leaseStartDate: null,
  leaseEndDate: null,
  color: null,
  logo: null,
};

export default ResidentItem;
