import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

const FormInstructions = ({ formInstructions, textStyle }) => {
  return (
    <Box className={textStyle}>
      <Typography variant="caption" display="block" gutterBottom>
        {formInstructions}
      </Typography>
    </Box>
  );
};

FormInstructions.propTypes = {
  formInstructions: PropTypes.string.isRequired,
  textStyle: PropTypes.string,
};

FormInstructions.defaultProps = {
  textStyle: 'text-gray-700',
};

export default FormInstructions;
