import * as types from './types';

export const addFilter = dimensionId => ({
  type: types.ADD,
  payload: {
    filter: {
      id: Date.now().toString(),
      dimensionId: dimensionId,
    },
  },
});

export const updateFilter = filter => ({
  type: types.UPDATE,
  payload: { filter },
});

export const deleteFilter = filterId => ({
  type: types.DELETE,
  payload: { filterId },
});
