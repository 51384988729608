import PropTypes from 'prop-types';
import React, { useState, useEffect, } from 'react';

import api from '../../api';
import Panel from '../../panel';
import ItemNewButton from './ItemNewButton';
import ItemIndexTable from './ItemIndexTable';

const ItemIndexPage = props => {
  const {
    can_edit: canEdit,
    form_attributes: formAttributes,
    index_items: initialIndexItems,
    model_name: modelName,
    table_attributes: initialTableAttributes,
  } = props;

  const [indexItems, setIndexItems] = useState(initialIndexItems);
  const [tableAttributes, setTableAttributes] = useState(initialTableAttributes);

  const getindexItems = () => {
    api.crudItems
      .getAll(formAttributes.params)
      .then(({ data }) => {
        if (Object.prototype.toString.call(data) === '[object Array]') {
          setIndexItems(data);
        } else {
          setTableAttributes(data.table_attributes);
          setIndexItems(data.index_items);
        }
      })
      .catch(() => {});
  };

  return (
    <Panel>
      <>
        <div className={tableAttributes?.style || 'py-4'}>
          <div className="flex justify-between">
            {
              <h3 className="flex self-center text-2xl font-normal pr-3 py-2">
                {tableAttributes.title || (modelName && `${modelName}s`)}
              </h3>
            }
            {canEdit && formAttributes.new_button && (
              <div className="flex-end pb-4">
                <ItemNewButton
                  formAttributes={formAttributes}
                  getindexItems={getindexItems}
                  modelName={modelName}
                />
              </div>
            )}
          </div>
          {indexItems.length > 0 && (
            <div className="mb-4">
              <ItemIndexTable
                formAttributes={formAttributes}
                hideColumns={canEdit ? [] : ['edit']}
                modelName={modelName}
                onUpdate={getindexItems}
                setStateIndexItems={setIndexItems}
                stateIndexItems={indexItems}
                tableAttributes={tableAttributes}
              />
            </div>
          )}
        </div>
      </>
    </Panel>
  );
};

ItemIndexPage.propTypes = {
  can_edit: PropTypes.bool,
  form_attributes: PropTypes.shape({
    params: PropTypes.shape({}),
    new_button: PropTypes.bool,
  }).isRequired,
  table_attributes: PropTypes.shape({
    title: PropTypes.string,
  }),
  model_name: PropTypes.string,
  index_items: PropTypes.arrayOf(PropTypes.shape({})),
};

ItemIndexPage.defaultProps = {
  can_edit: false,
  model_name: null,
  table_attributes: {},
  index_items: [],
};

export default ItemIndexPage;
