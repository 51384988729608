const Highcharts = require("highcharts");
require("highcharts/modules/exporting")(Highcharts);

export default (a, b, c) => {
  const hasRenderToArg = typeof a === "string";
  let options = a;

  const defaultOptions = {
    chart: {
      zoomType: "x",
      renderTo: (options.chart && options.renderTo) || null,
      backgroundColor: null,
      type: "line"
    },
    title: {},
    credits: { enabled: false },
    xAxis: {},
    yAxis: {},
    legend: {},
    tooltip: {
      shared: true,
      distance: 50,
      followPointer: true,
      padding: 5
    },
    plotOptions: {
      line: {
        marker: {
          symbol: "circle",
          states: {
            hover: {
              radius: 3
            }
          },
          enabled: false
        }
      },
      allowPointSelect: false
    },
    exporting: { enabled: false }
  };

  options = Highcharts.merge(defaultOptions, options);

  Highcharts.setOptions({
    lang: {
      thousandsSep: ","
    }
  });

  return new Highcharts.chart(options, b);
};
