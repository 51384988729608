export const fontFamily = "Roboto, sans-serif";

export const titleStyles = {
  fontSize: "14px",
  fontFamily,
  fontWeight: "bold"
};

export const labelStyles = {
  fontSize: "12px",
  fontFamily
};

export const focusZoom = (chart, focusIndex, extremes = {}) => {
  const { min, max, offsetLeft, offsetRight, xAxis, yAxis } = {
    min: 0,
    max: 0,
    offsetLeft: 0,
    offsetRight: 0,
    xAxis: 0,
    yAxis: 0,
    ...extremes
  };

  if (focusIndex != null || focusIndex !== "undefined") {
    chart.xAxis[xAxis].setExtremes(
      Math.max(focusIndex - offsetLeft, min),
      Math.min(focusIndex + offsetRight, max)
    );

    chart.yAxis[yAxis].setExtremes();

    if (!chart.resetZoomButton) {
      chart.showResetZoom();
    }
  }
};
