import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "buttonA", "buttonB", "valueElement" ]

  selectA() {
    this.showButtonB()
    this.valueElementTargets.forEach((el, i) => {
      el.innerHTML = el.dataset.valueSelectA
    })
  }

  selectB() {
    this.showButtonA()
    this.valueElementTargets.forEach((el, i) => {
      el.innerHTML = el.dataset.valueSelectB
    })
  }

  showButtonA(){
    this.buttonBTarget.style.display = "none";
    this.buttonATarget.style.display = "block";
  }

  showButtonB(){
    this.buttonATarget.style.display = "none";
    this.buttonBTarget.style.display = "block";
  }
}