import * as types from './types';

export const toggleCalculation = calculation => ({
  type: types.TOGGLE,
  payload: { calculation },
});

export const deleteCalculation = calculationId => ({
  type: types.DELETE,
  payload: { calculationId },
});
