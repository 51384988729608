document.addEventListener('DOMContentLoaded', function () {
  $(".datepicker").pickadate({
    format: "yyyy-mm-dd",
    min: new Date("9/16/2016"),
    max: new Date()
  });

  $(".datepicker-no-max").pickadate({
    format: "yyyy-mm-dd"
  });

  // Used for unobtrusive JS calls
  global.pickadate = {
    addToElement: el => {
      $(el).pickadate({
        format: "yyyy-mm-dd",
        min: new Date("9/16/2016"),
        max: new Date()
      });
    }
  };
})