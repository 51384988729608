import { Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import FormikInputDatePicker from '../shared/formik_inputs/FormikInputDatePicker';
import FormikInputBasic from '../shared/formik_inputs/FormikInputBasic';
import FormikInputCheckbox from '../shared/formik_inputs/FormikInputCheckbox';

const PropertySurveyFormFields = ({ values, initialValues, setFieldValue }) => {
  return (
    <React.Fragment>
      <h3 className="text-2xl font-light mb-6">Leasing Details</h3>

      <div className="flex flex-wrap -mx-3">
        <div className="w-full md:w-auto flex-initial px-3 pb-4 md:pb-0">
          <Field
            uid="property-survey-form"
            value={values.property_survey.date}
            label="Report Date"
            name="property_survey.date"
            numberOfMonths={1}
            isOutsideRange={() => false}
            hideKeyboardShortcutsPanel
            component={FormikInputDatePicker}
            onChange={date => {
              date.isValid() &&
                values.unit_mix_rates.forEach((value, index) =>
                  setFieldValue(`unit_mix_rates.${index}.date`, date)
                );
            }}
          />
        </div>

        <div className="flex-1 px-3">
          <Field
            type="number"
            step="any"
            label="Current Prelease %"
            name="property_survey.prelease"
            value={values.property_survey.prelease}
            disabled={values.property_survey.dnr}
            component={FormikInputBasic}
          />
        </div>

        <div className="flex-1 px-3">
          <Field
            type="number"
            step="any"
            label="Current Occupancy %"
            name="property_survey.occupancy"
            disabled={values.property_survey.dnr}
            component={FormikInputBasic}
          />
        </div>

        <div className="flex-initial px-3">
          <Field
            label="IOU"
            name="property_survey.dnr"
            value="values.property_survey.dnr"
            component={FormikInputCheckbox}
            onClick={() => {
              setFieldValue(`property_survey.dnr`, !values.property_survey.dnr);
              setFieldValue(`property_survey.prelease`, initialValues.property_survey.prelease);
              setFieldValue(`property_survey.occupancy`, initialValues.property_survey.occupancy);
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

PropertySurveyFormFields.propTypes = {
  values: PropTypes.shape({
    property_survey: PropTypes.shape({
      date: PropTypes.string,
      prelease: PropTypes.number,
      occupancy: PropTypes.number,
      dnr: PropTypes.bool,
    }),
    unit_mix_rates: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    property_survey: PropTypes.shape({
      prelease: PropTypes.number,
      occupancy: PropTypes.number,
    }),
  }).isRequired,
};

export default PropertySurveyFormFields;
