import { Application } from "stimulus";

import "trix/dist/trix"

import "./datepicker/datepicker";
import "./charts/charts";
import "./tables/tables";
import "./tabs/tabs";
import "./inputs/inputs";
import "./map/map";
import "./filters/property_scope/property_scope";
import './utilities/tooltip';

import StickyTableHeader from "./tables/sticky_header_controller";
import TabsController from "./tabs/tabs_controller";
import IncentivesController from "./forms/incentives/incentives_controller";
import PropertyAddOnController from "./forms/property_add_ons/property_add_on_controller";
import RatesController from "./tabs/rates_controller";
import DataTablesController from "./tables/data_tables_controller";
import AdHocController from "./forms/ad_hoc/ad_hoc_controller";
import IncentivesRowController from "./tables/incentives/incentives_row_controller";
import DatepickerController from "./datepicker/datepicker_controller";
import ValueSelectController from "./utilities/value_select/value_select_controller";
import RenderChartController from "./charts/render_chart_controller";
import UrlParamController from "./url/url_param_controller";
import FilePreviewController from './inputs/file_preview_controller'

const application = Application.start();

application.register("sticky_table_header", StickyTableHeader);
application.register("incentives", IncentivesController);
application.register("property_add_on", PropertyAddOnController);
application.register("tabs", TabsController);
application.register("rates", RatesController);
application.register("data-tables", DataTablesController);
application.register("ad_hoc", AdHocController);
application.register("incentives_row", IncentivesRowController);
application.register("datepicker", DatepickerController);
application.register("value-select", ValueSelectController);
application.register("render-chart", RenderChartController);
application.register("url-param", UrlParamController);
application.register("file-preview", FilePreviewController);
