import React, { useState } from 'react';
import {
  Button,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Checkbox,
  ListItemText,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

function FloorplanSelector({ items, initSelectedItems = [] }) {
  const [selectedItems, setSelectedItems] = useState(initSelectedItems);

  const handleChange = event => {
    setSelectedItems(event.target.value);
  };

  const handleClick = () => {
    // Convert current query string to an object
    const searchParams = new URLSearchParams(window.location.search);
    
    // Remove the previous 'floorplan_name[]' entries
    searchParams.delete('floorplan_name[]');
    
    // Add the new 'floorplan_name[]' entries
    selectedItems.forEach(item => {
      searchParams.append('floorplan_name[]', item);
    });
    
    // Reload the page with the new query string
    window.location.href = `${window.location.pathname}?${searchParams.toString()}`;
  };

  return (
    <Box display="flex" alignItems="center" gap={2}>
      <FormControl variant="outlined" style={{ minWidth: 200 }}>
        <InputLabel id="multi-select-checkbox-label" style={{ color: 'Black' }}>
          Floorplans
        </InputLabel>
        <Select
          labelId="multi-select-checkbox-label"
          id="multi-select-checkbox"
          multiple
          value={selectedItems}
          onChange={handleChange}
          input={<OutlinedInput label="Items" style={{ backgroundColor: 'white' }} />} // set background color to white here
          renderValue={selected => (
            <span style={{ fontSize: '0.8rem' }}>{selected.join(', ')}</span>
          )}
        >
          {items.map(item => (
            <MenuItem key={item} value={item}>
              <Checkbox checked={selectedItems.indexOf(item) > -1} />
              <ListItemText
                primary={item}
                primaryTypographyProps={{ style: { fontSize: '0.8rem' } }}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        style={{
          backgroundColor: '#1A1F2D',
          borderRadius: '50%',
          width: '40px',
          height: '40px',
          minWidth: '40px',
          padding: 0,
        }}
        onClick={handleClick}
      >
        <RefreshIcon style={{ color: 'white' }} />
      </Button>
    </Box>
  );
}

export default FloorplanSelector;
