import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const styles = {
  base: '',
};

const TableRow = props => {
  const { className, component: Component = 'tr', ...other } = props;

  return (
    <Component
      className={classNames(styles.base, {
        [className]: className,
      })}
      {...other}
    />
  );
};

TableRow.propTypes = {
  className: PropTypes.string,
  component: PropTypes.string,
};

TableRow.defaultProps = {
  className: '',
  component: 'tr',
};

export default TableRow;
