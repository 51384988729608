import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  inputBase: `appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`,
  labelBase: 'block text-gray-700 text-sm font-bold mb-2',
  disabledBase: 'bg-disabled font-light',
};

const FormikInputTextArea = ({
  field,
  form,
  inputClassName,
  isdisabled,
  key,
  label,
  labelClassName,
  type,
  ...props
}) => (
  <>
    <label htmlFor={field.name} className={classNames(styles.labelBase, labelClassName)}>
      {label}
    </label>
    <textarea
      className={classNames(
        styles.inputBase,
        { [`${styles.disabledBase}`]: isdisabled },
        inputClassName
      )}
      {...field}
      {...props}
    />
  </>
);

FormikInputTextArea.propTypes = {
  form: PropTypes.shape({}).isRequired,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.node,
  }).isRequired,
  inputClassName: PropTypes.string,
  isdisabled: PropTypes.bool,
  key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  type: PropTypes.string,
};

FormikInputTextArea.defaultProps = {
  inputClassName: '',
  isdisabled: false,
  key: null,
  label: null,
  labelClassName: '',
  type: 'text',
};

export default FormikInputTextArea;
