import lineChart from "../common/line";
import { decimalToPercent } from "@utils/lib/number";

document.addEventListener('DOMContentLoaded', function () {
  const Highcharts = require("highcharts");
  // Load module after Highcharts is loaded
  require("highcharts/modules/exporting")(Highcharts);

  if ($("#js-leasable-student-growth").length) {
    const domChart = $("#js-leasable-student-growth");

    const data = $("#js-leasable-student-growth").data("graph");

    const categories = data.seasons.map(season => season.season[0]);

    const options = {
      chart: {
        renderTo: domChart[0],
        zoomType: {
          enabled: null
        }
      },
      title: {
        text: null
      },
      xAxis: [
        {
          pane: 0,
          margin: 0,
          width: "30%",
          categories,
          title: { text: "POSH" },
          plotBands: [
            {
              from: categories.length - 1.5,
              to: categories.length,
              color: "#F7F7F7"
            }
          ]
        },
        {
          margin: 0,
          offset: 0,
          left: "35%",
          width: "30%",
          categories,
          title: { text: "On-Campus" },
          plotBands: [
            {
              from: categories.length - 1.5,
              to: categories.length,
              color: "#F7F7F7"
            }
          ]
        },
        {
          offset: 0,
          left: "70%",
          width: "30%",
          categories,
          title: { text: "Multi-Family" },
          plotBands: [
            {
              from: categories.length - 1.5,
              to: categories.length,
              color: "#F7F7F7"
            }
          ]
        }
      ],
      yAxis: [
        {
          title: { text: null },
          labels: {
            format: "{value}%"
          },
          plotLines: [
            {
              color: "#bfc1c2",
              width: 2,
              value: 0
            }
          ]
        }
      ],
      legend: {},
      tooltip: {
        useHTML: true,
        formatter() {
          let formatString = `<div style='min-width: 460px;'><table class="highcharts-table">`;
          let count = 0;

          formatString += `
            <tr>
              <th style="width: 160px"><small>${this.points[0].x}</small></td>
              <th style="width: 100px" class="text-center"><small><b>POSH</b></small></th>
              <th style="width: 100px" class="text-center"><small><b>Campus</b></small></th>
              <th style="width: 100px" class="text-center"><small><b>Multi-Family</b></small></th>
            </tr>
          `;

          while (count < this.points.length / 3) {
            formatString += `
              <tr>
                <td style="width: 160px"><b><span style="color:${
                  this.points[count].color
                }">\u25CF</span> ${this.points[count].series.name}</b></td>
                <td style="width: 100px" class="text-center">${
                  this.points[count].y
                }%</td>
                <td style="width: 100px" class="text-center">${
                  this.points[count + 3].y
                }%</td>
                <td style="width: 100px" class="text-center">${
                  this.points[count + 6].y
                }%</td>
              </tr>
            `;

            count += 1;
          }

          formatString += `</table></div>`;
          return formatString;
        }
      },
      plotOptions: {
        series: {
          states: {
            hover: {
              lineWidthPlus: 0
            }
          }
        },
        line: {
          events: {
            legendItemClick() {
              return false;
            }
          }
        },
        allowPointSelect: false
      },
      series: [
        {
          name: "Occupancy Growth",
          data: data.seasons.map(season =>
            decimalToPercent(season.occupancy_growth_posh)
          ),
          yAxis: 0,
          xAxis: 0,
          showInLegend: false,
          color: "#181D30"
        },
        {
          name: "Effective Rent Growth",
          data: data.seasons.map(season =>
            decimalToPercent(season.effective_rent_growth_posh)
          ),
          yAxis: 0,
          xAxis: 0,
          showInLegend: false,
          color: "#FFCE00"
        },
        {
          name: "Revenue Growth",
          data: data.seasons.map(season =>
            decimalToPercent(season.revenue_growth_posh)
          ),
          yAxis: 0,
          xAxis: 0,
          color: "gray",
          showInLegend: false
        },

        {
          name: "Occupancy Growth",
          data: data.seasons.map(season =>
            decimalToPercent(season.occupancy_growth_on_campus)
          ),
          yAxis: 0,
          xAxis: 1,
          color: "#181D30"
        },
        {
          name: "Effective Rent Growth",
          data: data.seasons.map(season =>
            decimalToPercent(season.effective_rent_growth_on_campus)
          ),
          yAxis: 0,
          xAxis: 1,
          color: "#FFCE00"
        },
        {
          name: "Revenue Growth",
          data: data.seasons.map(season =>
            decimalToPercent(season.revenue_growth_on_campus)
          ),
          yAxis: 0,
          xAxis: 1,
          color: "gray"
        },

        {
          name: "Occupancy Growth",
          data: data.seasons.map(season =>
            decimalToPercent(season.mf_occupancy_growth)
          ),
          yAxis: 0,
          xAxis: 2,
          showInLegend: false,
          color: "#181D30"
        },
        {
          name: "Effective Rent Growth",
          data: data.seasons.map(season =>
            decimalToPercent(season.mf_effective_rent_growth)
          ),
          yAxis: 0,
          xAxis: 2,
          showInLegend: false,
          color: "#FFCE00"
        },
        {
          name: "Revenue Growth",
          data: data.seasons.map(season =>
            decimalToPercent(season.mf_revenue_growth)
          ),
          yAxis: 0,
          xAxis: 2,
          color: "gray",
          showInLegend: false
        }
      ]
    };

    const uniChart = lineChart(options);
  }
})