import _ from 'lodash';
import * as types from './types';

const dimensionsReducer = (dimensions = {}, action) => {
  if (action.type === types.TOGGLE) {
    const { dimension } = action.payload;
    if (dimensions[dimension.id]) return _.omit(dimensions, dimension.id);
    else {
      return { ...dimensions, [dimension.id]: dimension };
    }
  }

  if (action.type === types.DELETE) {
    const { dimensionId } = action.payload;
    return _.omit(dimensions, dimensionId);
  }

  return dimensions;
};

export default dimensionsReducer;
