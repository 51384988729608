import * as types from './types';

export const toggleMeasure = measure => ({
  type: types.TOGGLE,
  payload: { measure },
});

export const deleteMeasure = measureId => ({
  type: types.DELETE,
  payload: { measureId },
});
