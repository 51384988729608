/* eslint react/jsx-key: 0 */

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import Paper from '@material-ui/core/Paper';

import { useTable, useSortBy } from 'react-table';

import makeColumns from './MakeColumns';

const useStyles = makeStyles(theme => ({
  root: {},
  tableHead: {
    backgroundColor: '#5e698b',
    color: theme.palette.common.white,
  },
  tableHeadCell: {
    border: `1px solid ${theme.palette.common.white}`,
    textAlign: 'center',
    color: theme.palette.common.white,
  },
  sortLabel: {
    color: '#fff',
    lineHeight: 1.25,
    '&:focus': {
      color: 'inherit',
    },
    '&:hover': {
      color: 'inherit',
    },
    '&.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active': {
      color: '#ffffff',
      '& > .MuiTableSortLabel-icon': {
        color: '#ffffff',
      },
    },
  },
  tableBodyCell: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  tableFooter: {
    backgroundColor: '#fdf4c5',
  },
  tableFooterCell: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
}));

const TableWrapper = ({ table, component, hasFooter, size }) => {
  const classes = useStyles();
  const columns = useMemo(() => makeColumns(table.columns), []);
  const data = useMemo(() => makeColumns(table.data), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    toggleSortBy,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  return (
    <TableContainer component={component}>
      <Table aria-label="caption table" {...getTableProps()} className={classes.root} size={size}>
        <TableHead className={classes.tableHead}>
          {headerGroups.map(headerGroup => {
            // eslint disable react/jsx-key
            return (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => {
                  if (column.Header === '_rowspan') {
                    return null;
                  }

                  let handleClick = column.getHeaderProps(column.getSortByToggleProps({})).onClick;
                  if (column.columnAccessor) {
                    handleClick = () => toggleSortBy(column.columnAccessor, undefined, false);
                  }

                  return (
                    <TableCell
                      {...column.getHeaderProps(
                        column.getSortByToggleProps({
                          onClick: handleClick,
                        })
                      )}
                      className={classes.tableHeadCell}
                      style={{ cursor: column.canSort ? 'pointer' : 'auto', ...column.styles }}
                      rowSpan={column.rowSpan}
                    >
                      {column.canSort ? (
                        <TableSortLabel
                          className={classes.sortLabel}
                          hideSortIcon
                          active={column.isSorted}
                          direction={column.isSortedDesc ? 'desc' : 'asc'}
                        >
                          {column.render('Header')}
                        </TableSortLabel>
                      ) : (
                        column.render('Header')
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableHead>

        <TableBody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);

            return (
              <TableRow {...row.getRowProps()} style={row.original.styles}>
                {row.cells.map(cell => {
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      style={cell.column.cellStyles}
                      className={classes.tableBodyCell}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>

        <TableFooter className={classes.tableFooter}>
          {hasFooter &&
            footerGroups.slice(0, 1).map(footerGroup => {
              return (
                <TableRow {...footerGroup.getFooterGroupProps()}>
                  {footerGroup.headers.map(column => {
                    return (
                      <TableCell
                        {...column.getFooterProps()}
                        style={column.footerStyles}
                        className={classes.footerTableCell}
                      >
                        {column.render('Footer')}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

const columnShape = function() {
  return PropTypes.shape({
    Header: PropTypes.string.isRequired,
    Footer: PropTypes.string,
    accessor: PropTypes.string,
    columnAccessor: function(props, propName, componentName) {
      if (props['rowSpan'] && props['rowSpan'] > 1 && props[propName] == undefined) {
        return new Error(
          `Provide prop '${propName}' '${props[propName]}' supplied to '${componentName}'`
        );
      }
    },
    rowSpan: PropTypes.number,
    canSort: PropTypes.bool,
    styles: PropTypes.shape({}),
    cellStyles: PropTypes.shape({}),
    footerStyles: PropTypes.shape({}),
    columns: PropTypes.arrayOf(columnShape),
  }).apply(this, arguments);
};

const dataShape = PropTypes.objectOf(
  PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
    component: PropTypes.shape({
      element: PropTypes.string,
      display: PropTypes.string,
      styles: PropTypes.shape({}),
    }),
  })
);

TableWrapper.propTypes = {
  table: PropTypes.shape({
    columns: PropTypes.arrayOf(columnShape).isRequired,
    data: PropTypes.arrayOf(dataShape).isRequired,
  }).isRequired,
  component: PropTypes.elementType,
  size: PropTypes.oneOf(['small', 'medium']),
  hasFooter: PropTypes.bool,
};

TableWrapper.defaultProps = {
  size: 'small',
  component: Paper,
  hasFooter: false,
};

export default TableWrapper;
