import addExporting from "highcharts/modules/exporting";
import themeSelector from "../../themes/theme_selector";

import lineChart from "../../common/line";
import columnChart from "../../common/column";
import { focusZoom } from "../../utils";

const Highcharts = require("highcharts");

addExporting(Highcharts);

const moment = require("moment");

document.addEventListener("reputation-lead-chart-loaded", e => {
  if ($("#js-reputation-lead-chart").length) {
    const data = e.detail.data;
    const { meta } = data;
    const leadBreakdownChart = createDefaultLeadBreakdownChart();
    const leadChart = createDefaultLeadChart(leadBreakdownChart, meta);

    const breakdownPromise = callLeadBreakdown(
      meta.date,
      meta.segment,
      meta.data_type
    );

    breakdownPromise.then(data => {
      updateLeadBreakdownChart(leadBreakdownChart, data);
    });

    updateLeadChart(leadChart, leadBreakdownChart, data);
  }
});

function createDefaultLeadChart(leadBreakdownChart, meta) {
  let options = {
    chart: {
      renderTo: $("#js-reputation-lead-chart")[0]
    },
    title: {
      text: null
    },
    plotOptions: {
      series: {
        cursor: "pointer",
        point: {
          events: {
            click() {
              const _meta = meta;
              const breakdownChart = leadBreakdownChart;

              const breakdownPromise = callLeadBreakdown(
                this.date,
                meta.segment,
                meta.data_type
              );

              breakdownPromise.then(data => {
                updateLeadBreakdownChart(breakdownChart, data);
              });
            }
          }
        }
      }
    },
    series: [
      {
        name: "Score",
        data: []
      },
      {
        name: "Seasonal Score",
        data: []
      }
    ]
  };

  options = Highcharts.merge(options, themeSelector("light"));

  return lineChart(options);
}

function createDefaultLeadBreakdownChart() {
  let options = {
    chart: {
      renderTo: $("#js-reputation-lead-breakdown-chart")[0],
      zoomType: "x"
    },
    title: {
      text: null
    },
    legend: { enabled: false},
    series: [
      {
        name: "Score",
        data: []
      }
    ]
  };

  options = Highcharts.merge(options, themeSelector("light"));

  return columnChart(options);
}

function updateLeadChart(chart, subChart, chart_data) {
  const data = chart_data;

  const options = {
    chart: {
      renderTo: $("#js-reputation-lead-chart")[0]
    },
    title: {
      text: null
    },
    xAxis: {
      categories: data.reputations.map(reputation =>
        moment(reputation.date).format("MMM-DD")
      )
    },
    series: [
      {
        name: "Score",
        data: data.reputations.map(reputation => ({
          y: reputation[data.columns[0]]
            ? parseFloat(reputation[data.columns[0]].toFixed(2))
            : null,
          date: reputation.date
        }))
      },
      {
        name: "Seasonal Score",
        data: data.reputations.map(reputation => ({
          y: reputation[data.columns[1]]
            ? parseFloat(reputation[data.columns[1]].toFixed(2))
            : null,
          date: reputation.date
        }))
      }
    ]
  };

  return chart.update(options);
}

function updateLeadBreakdownChart(chart, chart_data) {
  const data = chart_data;
  const reputations = data.reputations.sort((a,b) => a[data.columns[0]] - b[data.columns[0]])

  const options = {
    xAxis: {
      categories: reputations.map(
        reputation =>
          data.properties.find(p => p.id == reputation.property_id).code
      )
    },
    series: [
      {
        name: "Score",
        data: reputations.map(reputation => reputation[data.columns[0]])
      }
    ]
  };

  return chart.update(options);
}

function callLeadBreakdown(date, segment, dataType) {
  return $.ajax({
    url: "/charts/reputations/line_breakdown",
    data: { date, segment, data_type: dataType }
  });
}
