import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import TableContext from './TableContext';

const styles = {
  base: '',
  baseDefault: 'bg-yellow-200 text-gray-600 font-extralight',
};

const tableState = {
  variant: 'foot',
};

const TableFoot = props => {
  const { className, component: Component = 'tfoot', ...other } = props;

  return (
    <TableContext.Provider value={tableState}>
      <Component
        className={classNames(styles.base, {
          [styles.baseDefault]: !className,
          [className]: className,
        })}
        {...other}
      />
    </TableContext.Provider>
  );
};

TableFoot.propTypes = {
  className: PropTypes.string,
  component: PropTypes.string,
};

TableFoot.defaultProps = {
  className: '',
  component: 'tfoot',
};

export default TableFoot;
