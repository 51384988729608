// Use Highcharts.merge() for merging themes
// Known issues:
// -- if using arrays instead of objects, apply each theme by map
const theme = {
  colors: [
    "#5BB8AC",
    "#606988",
    "#B75959",
    "#6F6C99",
    "#66AD8C",
    "#CC86AD",
    "#82E0D3",
    "#3F4763",
    "#D65757",
    "#8985B5",
    "#86BAA1"
  ],
  chart: {
    backgroundColor: null,
    style: {
      fontFamily: "Roboto, Helvetica, sans-serif",
      color: "#FFFFFF",
      fontWeight: "bold"
    }
  },
  title: {
    style: {
      color: "#FFFFFF",
      textTransform: "uppercase",
      fontSize: "18px"
    }
  },
  subtitle: {
    style: {
      color: "#FFFFFF",
      textTransform: "uppercase"
    }
  },
  yAxis: {
    gridLineColor: "#FFFFF",
    labels: {
      style: {
        fontFamily: "Roboto, Helvetica, sans-serif",
        color: "#FFFFFF"
      }
    },
    title: {
      text: "",
      style: {
        fontFamily: "Roboto, Helvetica, sans-serif",
        color: "#FFFFFF"
      }
    },
    minorTickInterval: null,
    gridLineWidth: 0,
    lineColor: "rgba(255,255,255,.5)",
    lineWidth: 1,
    tickColor: "#fff",
    tickWidth: 0
  },
  xAxis: {
    gridLineColor: "#FFFFFF",
    labels: {
      style: {
        fontFamily: "Roboto, Helvetica, sans-serif",
        color: "#FFFFFF"
      }
    },
    title: {
      text: "",
      style: {
        fontFamily: "Roboto, Helvetica, sans-serif",
        color: "#FFFFFF"
      }
    },
    minorTickInterval: null,
    gridLineWidth: 0,
    lineColor: "rgba(255,255,255,.5)",
    lineWidth: 1,
    tickColor: "#fff",
    tickWidth: 0
  },
  tooltip: {
    backgroundColor: "rgba(44,35,58,.75)",
    style: {
      color: "#fffff"
    }
  },
  plotOptions: {
    series: {
      dataLabels: {
        color: "#ffffff"
      },
      marker: {
        lineColor: "#ffffff"
      }
    },
    boxplot: {
      fillColor: "#505053"
    },
    candlestick: {
      lineColor: "white"
    },
    errorbar: {
      color: "white"
    }
  },
  legend: {
    itemStyle: {
      color: "#E0E0E3"
    },
    itemHoverStyle: {
      color: "#FFF"
    },
    itemHiddenStyle: {
      color: "#606063"
    }
  },
  credits: {
    style: {
      color: "#666"
    }
  },
  labels: {
    style: {
      color: "#ffffff"
    }
  },
  drilldown: {
    activeAxisLabelStyle: {
      color: "#F0F0F3"
    },
    activeDataLabelStyle: {
      color: "#F0F0F3"
    }
  },
  navigation: {
    buttonOptions: {
      symbolStroke: "#DDDDDD",
      theme: {
        fill: "#505053"
      }
    }
  }
};

export default theme;
