import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Panel from '../panel';
import EventFilter from './EventFilter';
import EventDetailPanel from './EventDetailPanel';

const ShowPage = props => {
  const {
    calendar_admin: calendarAdmin,
    calendar_events: calendarEvents,
    new_events_permission: newEventsPermission,
  } = props;

  const defaultFilters = {
    checkedGeneralCommunication: true,
    checkedRolloutOrInitiative: true,
    checkedTraining: true,
    checkedWebinar: true,
    checkedMeeting: true,
    checkedHoliday: true,
    checkedEvent: true,
    checkedOptionalOfficeHour: true,
    checkedMisc: true,
    checkedLegacyEvent: true,
    checkedTravelEvent: true,
  };

  const [filters, setFilters] = useState(() => {
    const storedFilters = localStorage.getItem('CalendarFilters');
    return storedFilters !== null &&
      Object.keys(JSON.parse(storedFilters)).length === Object.keys(defaultFilters).length
      ? JSON.parse(storedFilters)
      : defaultFilters;
  });

  useEffect(() => {
    localStorage.setItem(`CalendarFilters`, JSON.stringify(filters));
  }, [filters]);

  const handleCheckboxChange = event => {
    setFilters({ ...filters, [event.target.name]: event.target.checked });
  };
  return (
    <>
      <EventFilter handleCheckboxChange={handleCheckboxChange} filters={filters} />
      <Panel>
        <EventDetailPanel
          calendarAdmin={calendarAdmin}
          calendarEvents={[calendarEvents]}
          newEventsPermission={newEventsPermission}
          filters={filters}
        />
      </Panel>
    </>
  );
};

ShowPage.propTypes = {
  calendar_admin: PropTypes.bool,
  calendar_events: PropTypes.instanceOf(Array),
  new_events_permission: PropTypes.bool,
};

ShowPage.defaultProps = {
  calendar_admin: false,
  calendar_events: [],
  new_events_permission: false,
};

export default ShowPage;
