import { Controller } from "stimulus";

export default class extends Controller {
  static targets = []

  initialize() {
  }

  handleSummaryHeaderClick(event) {
    $(".js-breakdown, .js-bd-header-top, .js-breakdown-headers-bottom th").hide();
    $(".js-summary, .js-summary-header").show();
    const type = $(event.target).data('beds');
    this.toggleCells(type)
  }

  handleBreakdownHeaderClick(event) {
    const type = $(event.target).data('beds');
    this.toggleCells(type);
  }

  toggleCells(type) {
    const headerSelector = `[data-beds="${type}"]`;
    const breakdownSelector = `.js-breakdown[data-beds="${type}"]`;
    const summarySelector = `.js-summary[data-beds="${type}"]`;
    $(".prop-col").toggle();
    $(".js-summary-header" + headerSelector).toggle();
    $(".js-bd-header-top" + headerSelector).toggle();
    $(".js-breakdown-headers-bottom").find(headerSelector).toggle();
    $(breakdownSelector).toggle();
    $(summarySelector).toggle();
  }
}