const Highcharts = require("highcharts");
require("highcharts/modules/exporting")(Highcharts);

export default (a, b, c) => {
  const hasRenderToArg = typeof a === "string";
  let options = a;

  const defaultOptions = {
    chart: {
      zoomType: "x",
      renderTo: (options.chart && options.renderTo) || null,
      backgroundColor: null,
      type: 'waterfall'
    },
    title: {},
    credits: { enabled: false },
    xAxis: {
      type: 'category'
    },
    yAxis: {},
    legend: {
      enabled: false
    },
    tooltip: {},
    exporting: { enabled: false }
  };

  options = Highcharts.merge(defaultOptions, options);

  Highcharts.setOptions({
    lang: {
      thousandsSep: ","
    }
  });

  return new Highcharts.chart(options, b);
};
