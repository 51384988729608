import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  contentList: {
    flexShrink: 0,
    width: '100%',
    height: '200px',
    borderBottom: '1px solid gray',
    overflowX: 'auto',
    [theme.breakpoints.up('md')]: {
      width: '275px',
      height: '400px',
      borderBottom: '0',
      borderRight: '1px solid #DCDCDC',
    },
  },
  contentContainer: {
    flexGrow: 1,
    fontSize: '1.1rem',
    padding: theme.spacing(2),
  },
}));

const SpecialSelector = ({ records }) => {
  const [activeRecord, setActiveRecord] = useState(null);
  const classes = useStyles();
  const enabled = true;

  const renderContentContainer = () => {
    if (activeRecord === null) {
      return 'Please select a template...';
    }

    return (
      <>
        <h5 className="font-bold leading-loose select-none">Title:</h5>
        <p className="text-base">{activeRecord.title}</p>
        <br />
        <h5 className="font-bold leading-loose select-none">Content:</h5>
        <p className="text-base">
          <span className="leading-normal">{activeRecord.content}</span>
        </p>
      </>
    );
  };

  return (
    <div className={classes.root}>
      <List className={classes.contentList} dense={enabled}>
        {records.map(record => (
          <ListItem
            button
            onClick={() => setActiveRecord(record)}
            key={record.id}
            selected={activeRecord && activeRecord.id === record.id}
          >
            <ListItemText>
              {record.title} {record.tag ? `(${record.tag})` : ''}
            </ListItemText>
          </ListItem>
        ))}
      </List>

      <div className={classes.contentContainer}>{renderContentContainer()}</div>
    </div>
  );
};

SpecialSelector.propTypes = {
  records: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
    })
  ).isRequired,
};

export default SpecialSelector;
