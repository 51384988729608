import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import api from '../api';
import IncentiveForm from './IncentiveForm';
import Loader from '../shared/Loader';

const IncentiveFormContainer = props => {
  const { isEdit, incentiveId, propertyId, onUpdate } = props;
  const [state, setState] = useState({
    incentive: null,
    unitMixes: [],
    error: [],
    isLoading: true,
  });

  useEffect(() => {
    const params = { propertyId: propertyId, incentiveId: isEdit && incentiveId };

    api.competitor_incentives
      .editOrNew(params)
      .then(res => {
        const incentive = {
          ...res.data.incentive,
          unit_mix_ids: res.data.unit_mix_incentives.map(el => el.id),
        };

        setState({
          ...state,
          incentive: incentive,
          unitMixes: res.data.unit_mixes,
          isLoading: false,
        });
      })
      .catch(() => {
        setState({
          ...state,
          error: 'Something went wrong.',
        });
      });
  }, []);

  const { incentive, unitMixes, error, isLoading } = state;

  return (
    <div>
      {isLoading && <Loader />}
      {error && error}
      {incentive && (
        <IncentiveForm
          incentive={incentive}
          unitMixes={unitMixes}
          onUpdate={onUpdate}
          isEdit={isEdit}
        />
      )}
    </div>
  );
};

IncentiveFormContainer.propTypes = {
  isEdit: PropTypes.bool,
  incentiveId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  propertyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onUpdate: PropTypes.func,
};

IncentiveFormContainer.defaultProps = {
  incentiveId: null,
  onUpdate: undefined,
  isEdit: false,
};

export default IncentiveFormContainer;
