import React from 'react';
import AppLayout from './appLayout';

class App extends React.PureComponent {
  render() {
    return <AppLayout {...this.props} />;
  }
}

export default App;
