const styles = {
  singleValue: provided => ({
    ...provided,
    fontSize: '1rem',
    padding: 5,
  }),
  singleValueLabel: provided => ({
    ...provided,
    fontSize: '1rem',
    padding: '3px 8px 3px 3px',
  }),
  multiValue: provided => ({
    ...provided,
    backgroundColor: '#edf2f7',
    fontSize: '1rem',
    margin: '4px 2px',
    padding: 5,
  }),
  multiValueLabel: provided => ({
    ...provided,
    fontSize: '1rem',
    padding: '3px 8px 3px 3px',
  }),
  multiValueRemove: provided => ({
    ...provided,
    color: 'black',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#a0aec0',
      color: 'white',
    },
  }),
  menu: provided => ({
    ...provided,
    margin: '1px 0',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#edf2f7' : provided.backgroundColor,
  }),
  control: (provided, state) => ({
    ...provided,
    boxShadow: 0,
    borderColor: state.isFocused ? '#a0aec0' : '#edf2f7',
    '&:hover': {
      borderColor: state.isFocused ? '#a0aec0' : '#edf2f7',
    },
  }),
};

export default styles;
