import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';

import api from '../api';
// import Corporate from '../calendar/eventDetailPanelContent/General';
import travelEvent from './eventDetailPanelContent/travelEvent';
import InstagramPost from './eventDetailPanelContent/InstagramPost';
import MoveIn from './eventDetailPanelContent/MoveIn';
import MoveOut from './eventDetailPanelContent/MoveOut';
import Tour from './eventDetailPanelContent/Tour';
import WorkSchedule from './eventDetailPanelContent/WorkSchedule';
////////////////////Marketing Campaigns////////////////
import MarketingCampaign from './eventDetailPanelContent/marketingCampaigns/MarketingCampaign';
import MarketingCampaignInitiative from './eventDetailPanelContent/marketingCampaigns/MarketingCampaignInitiative';
import UniversityDate from './eventDetailPanelContent/UniversityDate';

import { usePanelDispatch } from '../panel/PanelContext';
import * as panelTypes from '../panel/panelTypes';

const EventDetailPanel = props => {
  const {
    calendarEvents: calendarEvents,
    propertyId: propertyId,
    propertyName: propertyName,
    timeZone: timeZone,
    //  This value is the way rails format of times.
    filters: filters,
    editPrivilages: editPrivilages,
  } = props;

  const tzValue = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const panelDispatch = usePanelDispatch();

  const panelElement = useRef();
  const panelDate = useRef();
  const panelStart = useRef();
  const panelEnd = useRef();
  const panelTitle = useRef();
  const panelDescription = useRef();
  const panelDetails = useRef();

  // Specific to corporate/strategic calendar events
  const panelCategory = useRef();
  const panelCompleted = useRef();
  const panelDepartment = useRef();
  const panelProperty = useRef();
  const panelRecurring = useRef();
  const panelRecurringUntil = useRef();
  const panelVisibility = useRef();

  const handleOpen = info => {
    panelTitle.current = info.event.title;
    panelDescription.current = info.event.extendedProps['description'];
    panelDetails.current = info.event.extendedProps['details'];
    panelDate.current = info.event.start.toLocaleString('en-US', {
      weekday: 'short',
      month: 'numeric',
      day: '2-digit',
      year: '2-digit',
      timeZone: 'UTC',
    });
    panelStart.current = info.event.start.toLocaleString('en-US', {
      weekday: 'short',
      month: 'numeric',
      day: 'numeric',
      year: '2-digit',
      hour: 'numeric',
      minute: '2-digit',
      timeZone: 'UTC',
    });

    if (info.event.end) {
      if (info.event.end.getTime() === new Date(2100, 0, 2).getTime()) {
        panelEnd.current = 'TBD';
      } else if (
        info.event.allDay &&
        info.event.end.getHours() === 0 &&
        info.event.end.getMinutes() === 0
      ) {
        const endDate = info.event.end;
        const endDateDayBefore = new Date(endDate.setDate(endDate.getDate() - 1));
        panelEnd.current = endDateDayBefore.toLocaleString('en-US', {
          weekday: 'short',
          month: 'numeric',
          day: 'numeric',
          year: '2-digit',
          timeZone: 'UTC',
        });
      } else {
        panelEnd.current = info.event.end.toLocaleString('en-US', {
          weekday: 'short',
          month: 'numeric',
          day: 'numeric',
          year: '2-digit',
          hour: 'numeric',
          minute: '2-digit',
          timeZone: 'UTC',
        });
      }
    }
    if (info.event.extendedProps['category'] === 'University Date') {
      panelElement.current = (
        <UniversityDate
          title={panelTitle.current}
          start={panelStart.current}
          end={panelEnd.current}
          university={info.event.extendedProps['university']}
          propertyName={propertyName}
        />
      );
    }
    if (info.event.extendedProps['category'] === 'Marketing Campaign Event') {
      panelElement.current = (
        <MarketingCampaign
          title={panelTitle.current}
          start={panelStart.current}
          end={panelEnd.current}
          // description={panelDescription.current}
          details={panelDetails.current}
          propertyName={propertyName}
          tzValue={tzValue}
        />
      );
    } else if (
      info.event.extendedProps['category'] === 'Email Marketing' ||
      info.event.extendedProps['category'] === 'Guerilla Marketing' ||
      info.event.extendedProps['category'] === 'Promotional Event'
    ) {
      panelElement.current = (
        <MarketingCampaignInitiative
          title={panelTitle.current}
          start={panelStart.current}
          end={panelEnd.current}
          // description={panelDescription.current}
          details={panelDetails.current}
          propertyName={propertyName}
          tzValue={tzValue}
        />
      );
    } else if (info.event.extendedProps['sub_category'] === 'Instagram Post') {
      panelElement.current = (
        <InstagramPost
          title={panelTitle.current}
          date={panelDate.current}
          details={panelDetails.current}
          propertyName={propertyName}
          tzValue={tzValue}
        />
      );
    } else if (info.event.extendedProps['sub_category'] === 'Move Ins') {
      panelElement.current = (
        <MoveIn
          title={panelTitle.current}
          date={panelDate.current}
          details={panelDetails.current}
          propertyName={propertyName}
          tzValue={tzValue}
          propertyId={propertyId}
        />
      );
    } else if (info.event.extendedProps['sub_category'] === 'Move Outs') {
      panelElement.current = (
        <MoveOut
          title={panelTitle.current}
          date={panelDate.current}
          details={panelDetails.current}
          propertyName={propertyName}
          tzValue={tzValue}
        />
      );
    } else if (info.event.extendedProps['sub_category'] === 'Employee Time Off') {
      panelElement.current = (
        <WorkSchedule
          title={panelTitle.current}
          start={panelStart.current}
          end={panelEnd.current}
          details={panelDetails.current}
          propertyName={propertyName}
          tzValue={tzValue}
        />
      );
    } else if (info.event.extendedProps['sub_category'] === 'Tour') {
      panelElement.current = (
        <Tour
          title={panelTitle.current}
          start={panelStart.current}
          end={panelEnd.current}
          description={panelDescription.current}
          details={panelDetails.current}
          propertyName={propertyName}
          tzValue={tzValue}
        />
      );
    } else if (info.event.extendedProps['sub_category'] === 'Work Schedules') {
      panelElement.current = (
        <WorkSchedule
          title={panelTitle.current}
          start={panelStart.current}
          end={panelEnd.current}
          details={panelDetails.current}
          propertyName={propertyName}
          tzValue={tzValue}
        />
      );
    } else if (info.event.extendedProps['sub_category'] === 'Travel Event') {
      panelElement.current = travelEvent({
        title: panelTitle.current,
        start: panelStart.current,
        end: panelEnd.current,
        description: panelDescription.current,
        details: panelDetails.current,
        propertyName: propertyName,
        tzValue: tzValue,
      });
    } else if (info.event.extendedProps['sub_category'] === 'Corporate') {
      if (info.event.extendedProps['is_all_day'] == true) {
        panelStart.current = info.event.start.toLocaleString('en-US', {
          weekday: 'short',
          month: 'numeric',
          day: 'numeric',
          year: '2-digit',
          timeZone: 'UTC',
        });
        if (info.event.end) {
          const endDate = info.event.end;
          const endDateDayBefore = new Date(endDate.setDate(endDate.getDate() - 1));
          panelEnd.current = endDateDayBefore.toLocaleString('en-US', {
            weekday: 'short',
            month: 'numeric',
            day: 'numeric',
            year: '2-digit',
            timeZone: 'UTC',
          });
        } else {
          panelEnd.current = null;
        }
      } else {
        panelStart.current = info.event.start.toLocaleString('en-US', {
          weekday: 'short',
          month: 'numeric',
          day: 'numeric',
          year: '2-digit',
          timeZone: 'UTC',
        });
        if (info.event.end) {
          panelEnd.current = info.event.end.toLocaleString('en-US', {
            weekday: 'short',
            month: 'numeric',
            day: 'numeric',
            timeZone: 'UTC',
          });
        } else {
          panelEnd.current = null;
        }
      }
    }

    panelDispatch({
      type: panelTypes.OPEN,
      render: panelElement.current,
    });
  };

  const [events, setEvents] = useState(calendarEvents);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const checkedToSubCategory = {
    checkedInstagramPost: 'Instagram Post',
    checkedResidentEvent: ['Move Ins', 'Move Outs'],
    checkedTour: ['Tour', 'Virtual Tour'],
    checkedWorkSchedule: ['Work Schedules', 'Employee Time Off'],
    checkedTravelEvent: ['Travel Event'],
    checkedEndOfSeason: ['End of Season'],
    checkedVIP: ['VIP/Hype'],
    checkedRenewal: ['Renewal Leasing'],
    checkedLeadGeneration: ['New Leasing'],
    checkedBrandAwareness: ['Brand Awareness'],
    checkedUnivesityDate: ['University Dates'],
  };

  const checkFilteredEvents = () => {
    const calendarFilters = filters;
    const asArray = Object.entries(calendarFilters);
    const filtered = asArray.filter(([, value]) => value === false);
    const filteredObject = Object.fromEntries(filtered);
    const filteredSubCategories = Object.keys(filteredObject).map(key => checkedToSubCategory[key]);
    return filteredSubCategories.flat();
  };

  useEffect(() => {
    toggleEvents();
  }, [filters]);

  const toggleEvents = () => {
    let allEvents;
    if (events.length > 0 && filteredEvents.length > 0) {
      allEvents = events.concat(filteredEvents);
    } else if (events.length > 0 && filteredEvents.length === 0) {
      allEvents = events;
    } else if (events.length === 0 && filteredEvents.length > 0) {
      allEvents = filteredEvents;
    } else if (events.length === 0 && filteredEvents.length === 0) {
      allEvents = [];
    }
    toggleEventData(allEvents);
  };

  const toggleEventData = data => {
    setEvents(eventDataVisible(data));
    setFilteredEvents(eventDataFiltered(data));
  };

  const eventDataVisible = data => {
    const eventTypesToFilter = checkFilteredEvents();
    const eventsToDisplay = data.filter(event => !eventTypesToFilter.includes(event.sub_category));
    return eventsToDisplay;
  };

  const eventDataFiltered = data => {
    const eventTypesToFilter = checkFilteredEvents();
    const eventsToFilter = data.filter(event => eventTypesToFilter.includes(event.sub_category));
    return eventsToFilter;
  };

  const getMonthlyEvents = start => {
    api.calendar
      .getByDate({ propertyId: propertyId, monthStart: start })
      .then(({ data }) => {
        toggleEventData(data.property_calendar_events);
      })
      .catch(() => {});
  };

  const enabled = true;

  const addCustomButton = () => {
    const editEventsButton = {
      editEvents: {
        text: 'Manage Events',
        click: function() {
          window.location.assign(`/properties/${propertyId}/property_events`);
        },
      },
    };
    if (editPrivilages) {
      return editEventsButton;
    } else {
      return {};
    }
  };

  return (
    <>
      <FullCalendar
        customButtons={addCustomButton()}
        timeZone={timeZone}
        plugins={[dayGridPlugin, timeGridPlugin]}
        initialView="dayGridMonth"
        events={events}
        eventClick={handleOpen}
        showNonCurrentDates={false}
        nowIndicator={enabled}
        dayMaxEventRows={enabled}
        scrollTime="08:00:00"
        datesSet={view => getMonthlyEvents(view.start)}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: `${editPrivilages ? 'editEvents ' : ''}dayGridMonth,timeGridWeek,timeGridDay`,
        }}
      />
    </>
  );
};

EventDetailPanel.propTypes = {
  calendarEvents: PropTypes.instanceOf(Array),
  propertyId: PropTypes.number,
  propertyName: PropTypes.string,
  filters: PropTypes.shape({
    checkedInstagramPost: PropTypes.bool.isRequired,
    checkedTour: PropTypes.bool.isRequired,
    checkedWorkSchedule: PropTypes.bool.isRequired,
    checkedResidentEvent: PropTypes.bool.isRequired,
    // checkedCorporate: PropTypes.bool.isRequired,
    checkedEndOfSeason: PropTypes.bool.isRequired,
    checkedVIP: PropTypes.bool.isRequired,
    checkedRenewal: PropTypes.bool.isRequired,
    checkedLeadGeneration: PropTypes.bool.isRequired,
    checkedBrandAwareness: PropTypes.bool.isRequired,
    checkedUniversityDate: PropTypes.bool.isRequired,
    checkedTravelEvent: PropTypes.bool.isRequired,
  }).isRequired,
  timeZone: PropTypes.string,
  editPrivilages: PropTypes.bool,
};

EventDetailPanel.defaultProps = {
  calendarEvents: [],
  propertyId: null,
  propertyName: null,
  timeZone: 'Central Time (US & Canada)',
  editPrivilages: false,
};

export default EventDetailPanel;


