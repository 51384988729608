/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import { Divider } from '@mui/material';

const PropertyInfoCard = ({ cardInfo: { title, content, id, editPath }, permissionToEdit }) => {
  const sanitizedContent = DOMPurify.sanitize(content);
  const createMarkup = () => ({ __html: sanitizedContent });

  const toTitleCase = str => {
    return str
      .toLowerCase()
      .split('_')
      .map(function(word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  };

  // The above function is used to convert the string to title case.
  // How to avoid using this function?

  return (
    <>
      <Card sx={{ minWidth: 275, backgroundColor: 'white' }} key={id}>
        <CardContent>
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Typography variant="h5" component="div">
              {toTitleCase(title)}
            </Typography>
            {permissionToEdit && title !== 'Floorplans' && (
              <IconButton href={editPath}>
                <EditIcon fontSize="medium" />
              </IconButton>
            )}
          </Box>
          <Divider sx={{ mb: 2 }} />
          <div dangerouslySetInnerHTML={createMarkup()} />
        </CardContent>
      </Card>
    </>
  );
};

PropertyInfoCard.propTypes = {
  cardInfo: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
    editPath: PropTypes.string,
    id: PropTypes.number,
  }),
  permissionToEdit: PropTypes.bool,
};

PropertyInfoCard.defaultProps = {
  cardInfo: {
    title: '',
    content: '',
    editPath: '',
    id: null,
  },
  permissionToEdit: false,
};

export default PropertyInfoCard;
