import _ from 'lodash';
import * as types from './types';

const calculationsReducer = (calculations = {}, action) => {
  if (action.type === types.TOGGLE) {
    const { calculation } = action.payload;
    if (calculations[calculation.id]) return _.omit(calculations, calculation.id);
    else {
      return { ...calculations, [calculation.id]: calculation };
    }
  }

  if (action.type === types.DELETE) {
    const { calculationId } = action.payload;
    return _.omit(calculations, calculationId);
  }

  return calculations;
};

export default calculationsReducer;
