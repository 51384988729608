/* eslint react/prop-types: 0 */
import React, { useState } from 'react';
import {
  Collapse,
  Typography,
  List,
  ListItem,
  ListSubheader,
  ListItemText,
} from '@material-ui/core';
import Item from './Item';

const Dataset = props => {
  const {
    dataset,
    dimensions,
    selectedDimensions,
    toggleDimension,
    measures,
    selectedMeasures,
    toggleMeasure,
    addFilter,
  } = props;
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <ListItem dense button onClick={() => setIsExpanded(!isExpanded)}>
        <ListItemText>
          <Typography variant="h6">{dataset.label}</Typography>
        </ListItemText>
      </ListItem>
      <Collapse in={isExpanded} unmountOnExit>
        <List component="div" disablePadding dense>
          <ListSubheader component="div">DIMENSIONS</ListSubheader>
          {dataset.dimensionIds.map(dimensionId => {
            const dimension = dimensions[dimensionId];
            if (!dimension.hidden) {
              return (
                <Item
                  key={dimension.id}
                  attribute={dimension}
                  selectedAttributes={selectedDimensions}
                  addAttribute={toggleDimension}
                  addFilter={addFilter}
                />
              );
            }
          })}
          {dataset.measureIds.length > 0 && <ListSubheader component="div">MEASURES</ListSubheader>}
          {dataset.measureIds.map(measureId => {
            const measure = measures[measureId];
            if (!measure.hidden) {
              return (
                <Item
                  key={measure.id}
                  attribute={measure}
                  selectedAttributes={selectedMeasures}
                  addAttribute={toggleMeasure}
                />
              );
            }
          })}
        </List>
      </Collapse>
    </>
  );
};

export default Dataset;
