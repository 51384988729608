/* eslint react/prop-types: 0 */
import React, { useState } from 'react';
import sqlFormatter from 'sql-formatter';
import { Button } from '@material-ui/core';
import Table from './Table';

const Results = ({ sql, headers, data }) => {
  const [isShowingSql, setIsShowingSql] = useState(false);
  const [isShowingResultJSON, setIsShowingResultJSON] = useState(false);

  return (
    <>
      {headers && (
        <>
          <Table headers={headers} data={data} />
          <Button
            variant="outlined"
            onClick={() => {
              setIsShowingSql(!isShowingSql);
            }}
          >
            SQL
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setIsShowingResultJSON(!isShowingResultJSON);
            }}
          >
            Results
          </Button>
          {isShowingSql && (
            <pre className="py-4" style={{ overflow: 'visible' }}>
              {sqlFormatter.format(sql)}
            </pre>
          )}
          {isShowingResultJSON && (
            <pre className="py-4" style={{ overflow: 'visible' }}>
              HEADERS:
              <br />
              {JSON.stringify(headers, null, 2)}
              <br />
              RESULTS:
              <br />
              {JSON.stringify(data, null, 2)}
            </pre>
          )}
        </>
      )}
    </>
  );
};

export default Results;
