import PropTypes from 'prop-types';
import React, { createContext, useReducer } from 'react';

import * as types from './panelTypes';

const PanelStateContext = createContext();
const PanelDispatchContext = createContext();

const initialState = { isExpanded: false, render: null };

function PanelProvider({ children, Panel }) {
  const [state, dispatch] = useReducer(panelReducer, initialState);

  return (
    <PanelStateContext.Provider value={state}>
      <PanelDispatchContext.Provider value={dispatch}>
        {state.isExpanded && <Panel render={state.render} />}

        {children}
      </PanelDispatchContext.Provider>
    </PanelStateContext.Provider>
  );
}

PanelProvider.propTypes = {
  Panel: PropTypes.PropTypes.elementType.isRequired,
  children: PropTypes.node,
};

PanelProvider.defaultProps = {
  children: null,
};

const panelReducer = (state, action) => {
  switch (action.type) {
    case types.OPEN: {
      return { ...state, render: action.render, isExpanded: true };
    }
    case types.CLOSE: {
      return { ...state, render: null, isExpanded: false };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const usePanelState = () => {
  const context = React.useContext(PanelStateContext);
  if (context === undefined) {
    throw new Error('usePanelState must be used within a PanelProvider');
  }
  return context;
};

const usePanelDispatch = () => {
  const context = React.useContext(PanelDispatchContext);
  if (context === undefined) {
    throw new Error('usePanelDispatch must be used within a PanelProvider');
  }
  return context;
};

export { PanelProvider, usePanelState, usePanelDispatch };
