import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import '@utils/react_dates/initialize';
import { SingleDatePicker } from 'react-dates';

import { get as objectGet } from '@utils/lib/object';

const styles = {
  labelBase: `block text-gray-700 text-sm font-bold mb-2 truncate`,
  errorBase: 'text-red-600 p-1 my-1',
  statusBase: 'text-red-600 p-1 my-2',
};

const FormikInputDatePicker = ({
  children,
  field,
  form: { setFieldValue, status, touched, errors },
  isDisabled,
  label,
  minDate,
  maxDate,
  onChange,
  required,
  uid,
  value,
  businessDaysBlocked,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const blockStyling = true;

  const evalMinMaxDates = day => {
    let dayIsBlocked = false;
    if (minDate && day < moment(minDate)) {
      dayIsBlocked = true;
    }
    if (maxDate && day > moment(maxDate)) {
      dayIsBlocked = true;
    }
    return dayIsBlocked;
  };

  const isBusinessDay = (day, businessDaysBlocked) => {
    if (businessDaysBlocked) {
      const dayOfWeek = new Date(day).getDay();
      return dayOfWeek === 0 || dayOfWeek === 6;
    }
  };

  return (
    <div>
      <span className={classNames(styles.labelBase)}>{label}</span>
      <div>
        <SingleDatePicker
          readOnly
          block={blockStyling}
          date={field.value && moment(field.value)}
          disabled={isDisabled}
          focused={isFocused}
          id={uid}
          isDayBlocked={value => isBusinessDay(value, businessDaysBlocked)}
          isOutsideRange={evalMinMaxDates}
          numberOfMonths={1}
          onDateChange={date => {
            if (date != null) {
              setFieldValue(field.name, date.format('YYYY-MM-DD'));
              if (typeof onChange === 'function') {
                onChange(date);
              }
            }
          }}
          onFocusChange={({ focused }) => setIsFocused(focused)}
          required={required}
          {...props}
        />
      </div>

      {objectGet(touched, field.name) && objectGet(status, field.name) && (
        <div className={classNames(styles.statusBase)}>{objectGet(status, field.name)}</div>
      )}

      {objectGet(touched, field.name) && objectGet(errors, field.name) && (
        <div className={classNames(styles.errorBase)}>{objectGet(errors, field.name)}</div>
      )}
    </div>
  );
};

FormikInputDatePicker.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    status: PropTypes.shape(),
    touched: PropTypes.shape(),
    errors: PropTypes.shape(),
  }).isRequired,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  onChange: PropTypes.func,
  required: PropTypes.bool,
  uid: PropTypes.string,
  value: PropTypes.string,
  businessDaysBlocked: PropTypes.bool,
};

FormikInputDatePicker.defaultProps = {
  children: null,
  isDisabled: false,
  label: '',
  maxDate: null,
  minDate: null,
  onChange: undefined,
  required: false,
  uid: 'react_date_picker_id',
  value: undefined,
  businessDaysBlocked: false,
};

export default FormikInputDatePicker;
