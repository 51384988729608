import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { Modal } from '@material-ui/core';

import theme from '../../../theme';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 550,
    [theme.breakpoints.down('sm')]: {
      maxWidth: `calc(100% - ${theme.spacing(2)}px)`,
    },
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: 'auto',
    maxHeight: `calc(100vh - ${theme.spacing(2)}px)`,
  },
}));

const UserPasswordExpiredPage = ({ htmlYield }) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const sanitizedHtmlYield = DOMPurify.sanitize(htmlYield);

  const renderBody = (
    <div style={modalStyle} className={classes.paper}>
      <div
        dangerouslySetInnerHTML={{ __html: sanitizedHtmlYield }} // eslint-disable-line react/no-danger
      />
    </div>
  );

  return (
    <ThemeProvider theme={theme}>
      <Modal open>
        <div>{renderBody}</div>
      </Modal>
    </ThemeProvider>
  );
};

UserPasswordExpiredPage.propTypes = {
  htmlYield: PropTypes.string.isRequired,
};

export default UserPasswordExpiredPage;
