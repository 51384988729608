import React, { useReducer } from 'react';
import PropTypes from 'prop-types';

// Data
import { reducer, StoreContext } from './reducer';

// Components
import Version from './components/Version';

const Index = ({
  data,
  permission_to_edit: permissionToEdit,
  permission_to_destroy: permissionToDestroy,
  permission_to_approve_level_1: permissionToApproveLevel1,
  permission_to_approve_level_2: permissionToApproveLevel2,
  permission_to_approve_level_3: permissionToApproveLevel3,
}) => {
  const [state, dispatch] = useReducer(reducer, data);

  return (
    <StoreContext.Provider value={{ dispatch, state }}>
      <Version
        permissionToEdit={permissionToEdit}
        permissionToDestroy={permissionToDestroy}
        permissionToApproveLevel1={permissionToApproveLevel1}
        permissionToApproveLevel2={permissionToApproveLevel2}
        permissionToApproveLevel3={permissionToApproveLevel3}
      />
    </StoreContext.Provider>
  );
};

Index.propTypes = {
  data: PropTypes.shape({}),
  permission_to_edit: PropTypes.bool,
  permission_to_destroy: PropTypes.bool,
  permission_to_approve_level_1: PropTypes.bool,
  permission_to_approve_level_2: PropTypes.bool,
  permission_to_approve_level_3: PropTypes.bool,
};

Index.defaultProps = {
  data: {},
  permission_to_edit: false,
  permission_to_destroy: false,
  permission_to_approve_level_1: false,
  permission_to_approve_level_2: false,
  permission_to_approve_level_3: false,
};

export default Index;
