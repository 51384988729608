import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  inputBase: `appearance-none block w-full text-gray-700 border border-none rounded py-3 px-1 leading-tight truncate`,
  labelBase: 'block text-gray-700 text-sm font-bold mb-2',
};

const FormikInputNoEdit = ({ labelClassName, inputClassName, key, label, value, ...props }) => (
  <div key={key}>
    <span className={classNames(styles.labelBase, labelClassName)}>{label}</span>
    <div className={classNames(styles.inputBase, inputClassName)} {...props}>
      {value}
    </div>
  </div>
);

FormikInputNoEdit.propTypes = {
  inputClassName: PropTypes.string,
  key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  value: PropTypes.node.isRequired,
};

FormikInputNoEdit.defaultProps = {
  inputClassName: '',
  key: null,
  label: '',
  labelClassName: '',
};

export default FormikInputNoEdit;
