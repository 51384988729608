import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';
import { Box } from '@material-ui/core';
import { List, ListItem, ListItemText } from '@mui/material';

const MarketingCampaign = ({ details, propertyName, tzValue }) => {
  return (
    <>
      <Typography variant="h6">
        <p className="mt-4 mb-2">{details.name}</p>
      </Typography>
      <Typography component="span" variant="subtitle1">
        <p className="text-gray-600">
          <span className="font-semibold">Start:</span> {details.start}
        </p>
      </Typography>
      <Typography component="span" variant="subtitle1">
        <p className="text-gray-600">
          <span className="font-semibold">End:</span> {details.end}
        </p>
      </Typography>
      <Typography component="span" variant="subtitle1">
        <p className="text-gray-600">
          <span className="font-semibold">Event Type:</span> {details.leasing_phase}
        </p>
      </Typography>
      <Typography component="span" variant="subtitle1">
        <p className="text-gray-600">
          <span className="font-semibold">New Lead Goal:</span> {details.new_lead_goal}
        </p>
      </Typography>
      <Typography component="span" variant="subtitle1">
        <p className="text-gray-600">
          <span className="font-semibold">Housing Agreement Goal:</span>{' '}
          {details.housing_agreement_goal}
        </p>
      </Typography>
      <Typography component="span" variant="subtitle1">
        <p className="text-gray-600">
          <span className="font-semibold">Campaign In Box:</span> {details.campaign_in_a_box}
        </p>
      </Typography>
      <Typography component="span" variant="subtitle1">
        <p className="text-gray-600">
          <span className="font-semibold">Assigned Party:</span> {details.assigned_party}
        </p>
      </Typography>
      <Typography component="span" variant="subtitle1">
        <p className="text-gray-600">
          <span className="font-semibold">Initiatives:</span>
        </p>
      </Typography>
      <Box sx={{ width: '100%', marginTop: '12px' }}>
        {details.initiatives &&
          details.initiatives.map(initiative => {
            return (
              <List key={initiative.id} dense>
                <ListItem>
                  {initiative.type === 'Email' ? (
                    <div>
                      <ListItemText primary={`Initiative Type: ${initiative.type}`} />
                      <ListItemText secondary={`Date: ${initiative.email_subject}`} />
                      <ListItemText
                        secondary={`Communication Platform: ${initiative.communication_platform}`}
                      />
                      <ListItemText secondary={`Assigned Party: ${initiative.assigned_party}`} />
                      <ListItemText secondary={`Target Audience: ${initiative.target_audience}`} />
                      {initiative.subject_line && (
                        <ListItemText secondary={`Subject Line: ${initiative.subject_line}`} />
                      )}
                      {initiative.message && (
                        <ListItemText secondary={`Message: ${initiative.message}`} />
                      )}
                      {initiative.message_other && (
                        <ListItemText
                          secondary={`Message Description: ${initiative.message_other}`}
                        />
                      )}
                    </div>
                  ) : initiative.type === 'Guerilla Marketing' ? (
                    <div>
                      <ListItemText primary={`Initiative Type: ${initiative.type}`} />
                      <ListItemText secondary={`Date: ${formatDate(initiative.date)}`} />
                      <ListItemText secondary={`Guerilla Tactic: ${initiative.guerilla_tactics}`} />
                      {initiative.guerilla_tactics_details && (
                        <ListItemText
                          secondary={`Guerilla Tactic Other: ${initiative.guerilla_tactics_details}`}
                        />
                      )}
                      <ListItemText secondary={`Assigned Party: ${initiative.assigned_party}`} />
                      <ListItemText secondary={`Location: ${initiative.location}`} />
                      <ListItemText secondary={`Target Audience: ${initiative.target_audience}`} />
                      {initiative.target_audience_other && (
                        <ListItemText
                          secondary={`Target Audience Description: ${initiative.target_audience_other}`}
                        />
                      )}
                      <ListItemText secondary={`Budget: $${initiative.budget}`} />
                    </div>
                  ) : initiative.type === 'Promotional Event' ? (
                    <div>
                      <ListItemText primary={`Initiative Type: ${initiative.type}`} />
                      <ListItemText secondary={`Date: ${formatDate(initiative.date)}`} />
                      <ListItemText
                        secondary={`Promotional Event Type: ${initiative.event_type}`}
                      />
                      <ListItemText secondary={`Assigned Party: ${initiative.assigned_party}`} />
                      <ListItemText secondary={`Location: ${initiative.location}`} />
                      <ListItemText secondary={`Target Audience: ${initiative.target_audience}`} />
                      {initiative.target_audience_other && (
                        <ListItemText
                          secondary={`Target Audience Description: ${initiative.target_audience_other}`}
                        />
                      )}
                      <ListItemText secondary={`Budget: $${initiative.budget}`} />
                      <ListItemText
                        secondary={`Campaign In a Box: ${initiative.campaign_in_a_box}`}
                      />
                      {initiative.ciab_other && (
                        <ListItemText
                          secondary={`Campaign In a Box Description: ${initiative.ciab_other}`}
                        />
                      )}
                    </div>
                  ) : null}
                </ListItem>
              </List>
            );
          })}
      </Box>
      {/* <Typography component="span" variant="subtitle1" color="textPrimary">
        <p className="text-gray-600 mb-1">Add Event to Your Calendar</p>
      </Typography> */}
      {/* <AddToCalendarButton
        name={`${title} - ${propertyName}`}
        startDate={convertToYYYYMMDD(start)}
        startTime={convertToHHMM(start)}
        endDate={convertToYYYYMMDD(end)}
        endTime={convertToHHMM(end)}
        timeZone={tzValue}
        description={description}
        buttonStyle="text"
        options={['Microsoft365', 'MicrosoftTeams', 'Apple', 'Google']}
        hideCheckmark="true"
        buttonsList
        hideTextLabelButton
        size="5"
      /> */}
    </>
  );
};

function formatDate(inputDate) {
  const date = new Date(inputDate);

  const month = date.getMonth() + 1; // Months are zero-based, so we add 1
  const day = date.getDate();
  const year = date
    .getFullYear()
    .toString()
    .slice(-2); // Get last two digits of the year

  return `${month}/${day}/${year}`;
}

MarketingCampaign.propTypes = {
  title: PropTypes.string.isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  propertyName: PropTypes.string.isRequired,
};

export default MarketingCampaign;
