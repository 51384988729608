import { combineReducers } from 'redux';
// import { query } from './reducers';
import dimensions from './state/dimensions';
import measures from './state/measures';
import calculations from './state/calculations';
import filters from './state/filters';
import pivotDimensions from './state/pivotDimensions';
import query from './state/query';

const source = (state = []) => state;

export default combineReducers({
  source,
  dimensions,
  measures,
  calculations,
  filters,
  pivotDimensions,
  query,
});
