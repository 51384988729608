import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import Divider from '@mui/material/Divider';
import convertToYYYYMMDD from '../helper_functions/convertToYYYYMMDD';
import convertToHHMM from '../helper_functions/convertToHHMM';
import SharedAlerts from '../add_to_calendar_components/SharedAlerts';

const travelEvent = props => {
  return (
    <>
      <Typography variant="h6">
        <p className="mt-4 mb-1">{props.title}</p>
      </Typography>
      {props.start && (
        <Typography component="span" variant="subtitle1">
          <p className="text-gray-600">
            <span className="font-semibold">Start:</span> {props.start}
          </p>
        </Typography>
      )}
      {props.end && (
        <Typography component="span" variant="subtitle1">
          <p className="text-gray-600 mb-2">
            <span className="font-semibold">End:</span> {props.end}
          </p>
        </Typography>
      )}
      {props.details && (
        <>
          {props.details['name'] != 'Not Provided' && (
            <Typography component="span" variant="body2" color="textSecondary">
              <p className="text-gray-600 mb-1">Name: {props.details['user']}</p>
            </Typography>
          )}
          {props.details['role'] != 'Not Provided' && (
            <Typography component="span" variant="body2" color="textSecondary">
              <p className="text-gray-600 mb-1">Role: {props.details['role']}</p>
            </Typography>
          )}
          {props.details['vacancy_to_fill'] != 'Not Provided' && (
            <Typography component="span" variant="body2" color="textSecondary">
              <p className="text-gray-600 mb-1">
                Vacancy to Fill: {props.details['vacancy_to_fill']}
              </p>
            </Typography>
          )}
        </> 
      )}
      <div className="my-4"/>
      {props.description && (
        <>
          <Typography component="span" variant="subtitle1" color="textSecondary">
            <p className="text-gray-600 mb-1">Notes:</p>
          </Typography>
          <Typography component="span" variant="body2" color="textSecondary">
            <p className="text-gray-600 mb-1">{props.description}</p>
          </Typography>
        </>
      )}
      {/* <Divider sx={{ marginBottom: '12px' }} />
      <Typography component="span" variant="subtitle1" color="textPrimary">
        <p className="text-gray-600 mb-1">Add Event to Your Calendar</p>
      </Typography>
      <AddToCalendarButton
        name={`${title} - ${propertyName}`}
        startDate={convertToYYYYMMDD(start)}
        startTime={convertToHHMM(start)}
        endDate={convertToYYYYMMDD(end)}
        endTime={convertToHHMM(end)}
        timezone={tzValue}
        description={` 
            [p][strong]➤Event Type Description:[/strong] ${details['event_type_description']}[/p]
            [p][strong]➤Event Category Description:[/strong] ${details['calendar_event_category_description']}[/p]
            [br]
          `}
        buttonStyle="text"
        options={['Microsoft365', 'MicrosoftTeams', 'Apple', 'Google']}
        hideCheckmark="true"
        buttonsList
        hideTextLabelButton
        size="5"
      />
      <SharedAlerts /> */}
    </>
  );
};

// travelEvent.propTypes = {
//   title: PropTypes.string,
//   start: PropTypes.string,
//   end: PropTypes.string,
//   description: PropTypes.string,
//   details: PropTypes.shape({
//     event_type: PropTypes.string,
//     event_description: PropTypes.string,
//     event_type_description: PropTypes.string,
//     calendar_event_category_description: PropTypes.string,
//     calendar_event_code: PropTypes.string,
//   }),
//   propertyName: PropTypes.string,
//   tzValue: PropTypes.string,
// };

// travelEvent.defaultProps = {
//   title: null,
//   start: null,
//   end: null,
//   description: null,
//   details: {},
//   propertyName: null,
//   tzValue: null,
// };

export default travelEvent;
