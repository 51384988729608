import PropTypes from 'prop-types';
import React from 'react';

import Button from '../../shared/Button';
import { usePanelDispatch } from '../../panel/PanelContext';
import * as panelTypes from '../../panel/panelTypes';
import ItemFormContainer from './ItemFormContainer';

const ItemNewButton = ({ formAttributes, modelName, getindexItems }) => {
  const panelDispatch = usePanelDispatch();

  const handleCreate = () => {
    getindexItems();
    panelDispatch({
      type: panelTypes.CLOSE,
    });
  };
  return (
    <Button
      type="button"
      variant="primary"
      size="sm"
      onClick={() => {
        panelDispatch({
          type: panelTypes.OPEN,
          render: (
            <ItemFormContainer
              formAttributes={formAttributes}
              modelName={modelName}
              onUpdate={handleCreate}
            />
          ),
        });
      }}
    >
      + New
    </Button>
  );
};

ItemNewButton.propTypes = {
  formAttributes: PropTypes.shape({}).isRequired,
  getindexItems: PropTypes.func.isRequired,
  modelName: PropTypes.string.isRequired,
};

export default ItemNewButton;
