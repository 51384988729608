const initInputMap = ({ mapId = 'map-input', fields = {}, lat = 37.09024, lng = -95.712891 }) => {
  const id = document.getElementById(mapId);

  const map = new google.maps.Map(id, {
    center: { lat: lat, lng: lng },
    zoom: 4,
    zoomControl: true,
    scaleControl: true,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
  });

  const input = document.getElementById('map-input-search');

  $(input).bind('keypress keydown keyup', function(e) {
    if (e.keyCode == 13) {
      e.preventDefault();
    }
  });

  const autocomplete = new google.maps.places.Autocomplete(input);
  autocomplete.bindTo('bounds', map);
  autocomplete.setFields(['address_components', 'geometry', 'icon', 'name']);

  let markers = [];
  autocomplete.addListener('place_changed', function() {
    var place = autocomplete.getPlace();

    if (!place.geometry) {
      return;
    }

    if (place.geometry.viewport) {
      map.fitBounds(place.geometry.viewport);
    } else {
      map.setCenter(place.geometry.location);
      map.setZoom(15);
    }
  });

  google.maps.event.addListener(map, 'click', function(e) {
    markers.forEach(function(marker) {
      marker.setMap(null);
    });
    markers = [];

    if (!e.placeId) {
      setValueOnInput({
        street: null,
        city: null,
        state: null,
        postalCode: null,
        country: null,
        latitude: e.latLng.lat(),
        longitude: e.latLng.lng(),
      });

      placeMarker(e.latLng, map);

      return;
    }

    const input = document.getElementById('map-input-search');

    const geocoder = new google.maps.Geocoder();

    geocoder.geocode({ placeId: e.placeId }, function(results, status) {
      if (status == 'OK') {
        const address = results[0];

        const streetNumber = address.address_components.find(comp =>
          comp.types.includes('street_number')
        );
        const streetRoute = address.address_components.find(comp => comp.types.includes('route'));
        const city = address.address_components.find(comp => comp.types.includes('locality'));
        const state = address.address_components.find(comp =>
          comp.types.includes('administrative_area_level_1')
        );
        const country = address.address_components.find(comp => comp.types.includes('country'));
        const postalCode = address.address_components.find(comp =>
          comp.types.includes('postal_code')
        );

        setValueOnInput({
          street: streetNumber.long_name + ' ' + streetRoute.long_name,
          city: city.long_name,
          state: state.long_name,
          postalCode: postalCode.long_name,
          country: country.short_name,
          latitude: e.latLng.lat(),
          longitude: e.latLng.lng(),
        });

        placeMarker(e.latLng, map);
      } else {
        input.value = e.latLng;
        setValueOnInput({
          latitude: e.latLng.lat(),
          longitude: e.latLng.lng(),
        });
        placeMarker(e.latLng, map);
      }
    });
  });

  function setValueOnInput({
    street = null,
    city = null,
    state = null,
    postalCode = null,
    latitude = null,
    longitude = null,
  }) {
    const form = $($(`#${mapId}`)[0].closest('form'));

    const streetInput = form && form.find("input[name$='[address][street]']")[0];
    streetInput && $(streetInput).val(street);

    const cityInput = form && form.find("input[name$='[address][city]']")[0];
    cityInput && $(cityInput).val(city);

    const stateInput = form && form.find("select[name$='[address][state]']")[0];
    stateInput && $(stateInput).val(state);

    const postalCodeInput = form && form.find("input[name$='[address][postal_code]']")[0];
    postalCodeInput && $(postalCodeInput).val(postalCode);

    const latitudeInput = form && form.find("input[name$='[address][latitude]']")[0];
    latitudeInput && $(latitudeInput).val(latitude);

    const longitudeInput = form && form.find("input[name$='[address][longitude]']")[0];
    longitudeInput && $(longitudeInput).val(longitude);

    return;
  }

  function placeMarker(position, map) {
    var marker = new google.maps.Marker({
      position: position,
      map: map,
    });
    markers.push(marker);
    map.panTo(position);
  }
};

export default initInputMap;
