/* eslint react/prop-types: 0 */
import React from 'react';
import { Typography, Box, List } from '@material-ui/core';
import Dataset from './Dataset';
import Item from './Item';

const DatasetSelectorList = props => {
  const { datasets, calculations, selectedCalculations, toggleCalculation } = props;
  return (
    <Box m="1rem" p="1rem" bgcolor="white">
      <List dense>
        <Typography variant="h5">Datasets</Typography>
        {Object.values(datasets).map(dataset => (
          <Dataset key={dataset.id} dataset={dataset} {...props} />
        ))}
      </List>
      <List dense>
        <Typography variant="h5">Calculations</Typography>
        {Object.values(calculations).map(calculation => (
          <Item
            key={calculation.id}
            attribute={calculation}
            selectedAttributes={selectedCalculations}
            addAttribute={toggleCalculation}
          />
        ))}
      </List>
    </Box>
  );
};

export default DatasetSelectorList;
