import axios from 'axios';
import humps from 'humps';
import * as types from './types';

export const runRequested = queryParams => ({
  type: types.RUN_REQUESTED,
  payload: { queryParams },
});

export const runSuccess = response => ({
  type: types.RUN_SUCCESS,
  payload: { response },
});

export const runFailure = error => ({
  type: types.RUN_FAILURE,
  payload: { error },
});

export const toggleTranspose = isTransposed => ({
  type: types.TOGGLE_TRANSPOSE,
  payload: { isTransposed },
});

export const run = () => {
  return (dispatch, getState) => {
    const {
      source,
      dimensions,
      measures,
      calculations,
      filters,
      pivotDimensions,
      query,
    } = getState();
    const queryParams = {
      type: source.type,
      name: source.name,
      dimensions: Object.values(dimensions),
      measures: Object.values(measures),
      calculations: Object.values(calculations),
      filters: formatFilters(filters),
      pivotDimensions: Object.values(pivotDimensions),
      isTransposed: query.isTransposed ? 1 : 0,
    };
    dispatch(runRequested(queryParams));
    axios
      .get('/run_query.json', {
        params: { query_params: humps.decamelizeKeys(queryParams) },
      })
      .then(response => {
        dispatch(runSuccess(response.data));
      })
      .catch(error => {
        dispatch(runFailure(error));
      });
  };
};

const formatFilters = filters => {
  return Object.values(filters).map(filter => ({
    dimension: humps.decamelize(filter.dimensionId),
    operation: filter.operation,
    values: filter.values,
  }));
};
