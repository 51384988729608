import PropTypes from 'prop-types';
import React, { useEffect, createRef } from 'react';
import * as Icon from 'react-feather';

import { usePanelDispatch } from './PanelContext';
import * as panelTypes from './panelTypes';

const REACT_PANEL_ID = 'react-panel';

const PanelContainer = ({ render }) => {
  const node = createRef();
  const panelDispatch = usePanelDispatch();

  function attempt(fn) {
    try {
      return fn();
    } catch (e) {
      return false;
    }
  }

  // useEffect(() => {
  //   function handleClick(e) {
  //     if (
  //       node.current.contains(e.target) ||
  //       attempt(() => {
  //         e.path.find(el => el.id === REACT_PANEL_ID);
  //       })
  //     ) {
  //       return;
  //     }

  //     panelDispatch({ type: panelTypes.CLOSE });
  //   }

  //   document.addEventListener('mousedown', handleClick, false);

  //   return function cleanup() {
  //     document.removeEventListener('mousedown', handleClick, false);
  //   };
  // });

  return (
    <div
      id={REACT_PANEL_ID}
      className="h-screen overflow-y-auto max-w-full fixed top-0 right-0 bg-white shadow-2xl z-50 w-5/6 md:w-1/2 max-w-full px-8 pt-16 pb-8"
      ref={node}
    >
      <button
        type="button"
        className="absolute right-0 top-0 mx-6 mb-6 mt-6 md:mt-20 p-2"
        // className="absolute right-0 top-0 m-6 p-2"
        onClick={() => panelDispatch({ type: panelTypes.CLOSE })}
      >
        <Icon.X size={26} color="black" />
      </button>

      <div className="flex-none w-full">{render}</div>
    </div>
  );
};

PanelContainer.propTypes = {
  render: PropTypes.node,
};

PanelContainer.defaultProps = {
  render: null,
};

export default PanelContainer;
