import { connect } from 'react-redux';
import { updateFilter, deleteFilter } from '../../state/filters';
import Filter from './Filter';

const mapStateToProps = (state, ownProps) => ({
  filter: ownProps.filter,
  dimension: ownProps.dimension,
});

export default connect(mapStateToProps, { updateFilter, deleteFilter })(Filter);
