import Highcharts from "highcharts";
import addMore from "highcharts/highcharts-more";
import addDrilldown from "highcharts/modules/drilldown";
import addNoData from "highcharts/modules/no-data-to-display";
import addExporting from "highcharts/modules/exporting";

addMore(Highcharts);
addDrilldown(Highcharts);
addNoData(Highcharts);
addExporting(Highcharts);

document.addEventListener('DOMContentLoaded', function () {
  const data = $("#js-enrollments-class-breakdown-chart").data("graph-data");

  if (data) {
    Highcharts.setOptions({
      lang: {
        thousandsSep: ","
      }
    });

    const chart = Highcharts.chart("js-enrollments-class-breakdown-chart", {
      chart: {
        type: "bar"
      },

      title: {
        text: ""
      },

      xAxis: {
        categories: [
          "Freshman",
          "Sophomore",
          "Junior",
          "Senior",
          "Graduate"
        ],
        title: {
          text: null
        }
      },

      yAxis: {
        min: 0,
        title: {
          text: ""
        }
      },

      // tooltip: {
      //   valueSuffix: ' millions'
      // },

      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true
          }
        }
      },

      legend: {
        enabled: false
      },

      // tooltip: {
      //     outside: true,
      //     hideDelay: 0,
      //     useHTML: true,
      //     headerFormat: '<table>',
      //     pointFormat: '<tr><th colspan="2"><h3>{point.property_code}</h3></th></tr>' +
      //         '<tr><th>NRI Variance:</th><td>{point.nri_variance:,.0f}</td></tr>' +
      //         '<tr><th>Strategy:</th><td>{point.strategy}</td></tr>' +
      //         '<tr><th>Property Weight:</th><td>{point.x}</td></tr>' +
      //         '<tr><th>Market Weight:</th><td>{point.y}</td></tr>',
      //     footerFormat: '</table>',
      //     followPointer: true
      // },

      series: data.map(series => ({
        name: series.name,
        color: series.color,
        data: series.data.map(d => (d == 0 ? null : d))
      }))
    });
  }
})