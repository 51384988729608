import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import StandardTable from '../table/StandardTable';

const UnitMixTable = ({ data }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Unit Type',
        accessor: 'unit_type',
      },
      {
        Header: 'Unit Style',
        accessor: 'unit_style',
        className: 'text-center',
      },
      {
        Header: 'Beds',
        accessor: 'beds_unit',
        className: 'text-center',
      },
      {
        Header: 'Baths',
        accessor: 'baths_unit',
        className: 'text-center',
      },
      {
        Header: 'Total Beds',
        accessor: 'beds',
        className: 'text-center',
      },
      {
        Header: 'Sq Ft',
        accessor: 'avg_sq_ft',
        className: 'text-center',
      },
    ],
    []
  );

  return <StandardTable columns={columns} data={data} />;
};

UnitMixTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  showColumns: PropTypes.shape({
    id: PropTypes.bool,
    edit: PropTypes.bool,
  }),
};

UnitMixTable.defaultProps = {
  data: [],
  showColumns: {},
};

export default UnitMixTable;
