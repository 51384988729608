/* eslint react/prop-types: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import StandardTable from '../../table/StandardTable';

const DEFAULT_PAGE_SIZE = 25;

const BITable = ({ data }) => {
  const columns = () =>
    Object.keys(data[0]).map(r => ({
      Header: r,
      accessor: r,
    }));

  return (
    <>
      {data.length > 0 ? (
        <StandardTable columns={columns()} data={data} defaultPageSize={DEFAULT_PAGE_SIZE} />
      ) : (
        <div>No results found</div>
      )}
    </>
  );
};

BITable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

BITable.defaultProps = {
  data: [],
};

export default BITable;
