import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import Divider from '@mui/material/Divider';
import convertToYYYYMMDD from '../helper_functions/convertToYYYYMMDD';
import convertToHHMM from '../helper_functions/convertToHHMM';
import SharedAlerts from '../add_to_calendar_components/SharedAlerts';

const Tour = ({ title, start, end, description, details, propertyName, tzValue }) => {
  return (
    <>
      <Typography variant="h6">
        <p className="mt-4 mb-1">{title}</p>
      </Typography>
      {start && (
        <Typography component="span" variant="subtitle1">
          <p className="text-gray-600">
            <span className="font-semibold">Start:</span> {start}
          </p>
        </Typography>
      )}
      {end && (
        <Typography component="span" variant="subtitle1">
          <p className="text-gray-600 mb-2">
            <span className="font-semibold">End:</span> {end}
          </p>
        </Typography>
      )}
      {description != 'Not Provided' && (
        <Typography component="span" variant="body2">
          <p className="text-gray-600 mb-2">{description}</p>
        </Typography>
      )}
      {details && (
        <>
          {details['event_type'] != 'Not Provided' && (
            <Typography component="span" variant="caption" color="textSecondary">
              <p className="text-gray-600 mb-1">Event type: {details['event_type']}</p>
            </Typography>
          )}
          {details['event_type_description'] != details['event_type'] && (
            <Typography component="span" variant="caption" color="textSecondary">
              <p className="text-gray-600 mb-1">
                Event type description: {details['event_type_description']}
              </p>
            </Typography>
          )}
          {details['calendar_event_category_description'] != 'Not Provided' && (
            <Typography component="span" variant="caption" color="textSecondary">
              <p className="text-gray-600 mb-1">
                Event category description: {details['calendar_event_category_description']}
              </p>
            </Typography>
          )}
          {details['calendar_event_code'] != 'Not Provided' && (
            <Typography component="span" variant="caption" color="textSecondary">
              <p className="text-gray-600 mb-1">
                Calendar event code: {details['calendar_event_code']}
              </p>
            </Typography>
          )}
        </>
      )}
      <Divider sx={{ marginBottom: '12px' }} />
      <Typography component="span" variant="subtitle1" color="textPrimary">
        <p className="text-gray-600 mb-1">Add Event to Your Calendar</p>
      </Typography>
      <AddToCalendarButton
        name={`${title} - ${propertyName}`}
        startDate={convertToYYYYMMDD(start)}
        startTime={convertToHHMM(start)}
        endDate={convertToYYYYMMDD(end)}
        endTime={convertToHHMM(end)}
        timezone={tzValue}
        description={` 
            [p][strong]➤Event Type Description:[/strong] ${details['event_type_description']}[/p]
            [p][strong]➤Event Category Description:[/strong] ${details['calendar_event_category_description']}[/p]
            [br]
          `}
        buttonStyle="text"
        options={['Microsoft365', 'MicrosoftTeams', 'Apple', 'Google']}
        hideCheckmark="true"
        buttonsList
        hideTextLabelButton
        size="5"
      />
      <SharedAlerts />
    </>
  );
};

Tour.propTypes = {
  title: PropTypes.string,
  start: PropTypes.string,
  end: PropTypes.string,
  description: PropTypes.string,
  details: PropTypes.shape({
    event_type: PropTypes.string,
    event_description: PropTypes.string,
    event_type_description: PropTypes.string,
    calendar_event_category_description: PropTypes.string,
    calendar_event_code: PropTypes.string,
  }),
  propertyName: PropTypes.string,
  tzValue: PropTypes.string,
};

Tour.defaultProps = {
  title: null,
  start: null,
  end: null,
  description: null,
  details: {},
  propertyName: null,
  tzValue: null,
};

export default Tour;
