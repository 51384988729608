import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Divider from '@mui/material/Divider';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import { Alert } from '@mui/material';
import SharedAlerts from '../../propertyCalendar/add_to_calendar_components/SharedAlerts';
import convertToYYYYMMDD from '../../propertyCalendar/helper_functions/convertToYYYYMMDD';
import convertToHHMM from '../../propertyCalendar/helper_functions/convertToHHMM';
import doesStringContainTime from '../../propertyCalendar/helper_functions/doesStringContainTime';

const General = ({
  category,
  completed,
  department,
  description,
  end,
  property,
  recurring,
  recurringUntil,
  start,
  title,
  visibility,
  tzValue,
}) => {
  const calendarDescription = () => {
    let descriptionString = '';
    if (completed) {
      descriptionString += '{p}{strong}➤Completed:{/strong} Yes {/p}';
    }
    if (description) {
      descriptionString += `{p}{strong}➤Description:{/strong} ${description} {/p}`;
    }
    if (category) {
      descriptionString += `{p}{strong}➤Category:{/strong} ${category} {/p}`;
    }
    if (visibility) {
      descriptionString += `{p}{strong} ➤Visibility:{/strong} ${visibility}{/p}`;
    }
    if (property) {
      descriptionString += `{p}{strong} ➤Property:{/strong} ${property} {/p}`;
    }
    if (recurring) {
      descriptionString += `{p}{strong} Recurring:{/strong} ${recurring} until ${recurringUntil} {/p}`;
    }
    return descriptionString;
  };


  const attributes = {
    name: `${department} | ${title}`,
    startDate: convertToYYYYMMDD(start),
    startTime: doesStringContainTime(start) ? convertToHHMM(start) : null,
    endDate: end ? convertToYYYYMMDD(end) : null,
    endTime: doesStringContainTime(end) ? convertToHHMM(end) : null,
    timeZone: tzValue,
    description: calendarDescription(),
    buttonStyle: 'text',
    options: ['Microsoft365', 'MicrosoftTeams', 'Apple', 'Google'],
    hideCheckmark: 'true',
    buttonsList: true,
    hideTextLabelButton: true,
    size: '5',
  };

  const filteredAttributes = Object.entries(attributes).reduce((acc, [key, value]) => {
    if (value !== null) {
      acc[key] = value;
    }
    return acc;
  }, {});

  return (
    <>
      <Typography variant="h6">
        <p className="mt-4 mb-2">
          {department} | {title}
        </p>
      </Typography>
      {completed && (
        <Typography component="span" variant="subtitle1">
          <p className="text-green-600 mt-2 mb-2">
            <span className="font-semibold">Completed:</span> Yes
          </p>
        </Typography>
      )}
      {start && (
        <Typography component="span" variant="subtitle1">
          <p className="text-gray-600">
            <span className="font-semibold">Start:</span> {start}
          </p>
        </Typography>
      )}
      {end && (
        <Typography component="span" variant="subtitle1">
          <p className="text-gray-600">
            <span className="font-semibold">End:</span> {end}
          </p>
        </Typography>
      )}
      {description && (
        <Typography component="span" variant="body2">
          <p className="text-gray-600 mt-3">{description}</p>
        </Typography>
      )}
      {category && (
        <Typography component="span" variant="caption" color="textSecondary">
          <p className="text-gray-600 mt-3 mb-1">
            <span className="font-semibold">Category:</span> {category}
          </p>
        </Typography>
      )}
      {visibility && (
        <Typography component="span" variant="caption" color="textSecondary">
          <p className="text-gray-600 mb-1">
            <span className="font-semibold">Visibility:</span> {visibility}
          </p>
        </Typography>
      )}
      {property && (
        <Typography component="span" variant="caption" color="textSecondary">
          <p className="text-gray-600 mb-1">
            <span className="font-semibold">Property:</span> {property}
          </p>
        </Typography>
      )}
      {recurring && (
        <Typography component="span" variant="caption" color="textSecondary">
          <p className="text-gray-600 mb-1">
            <span className="font-semibold">Recurring:</span> {recurring} until {recurringUntil}
          </p>
        </Typography>
      )}
      <Divider sx={{ marginBottom: '12px' }} />
      <Typography component="span" variant="subtitle1" color="textPrimary">
        <p className="text-gray-600 mb-1">Add Event to Your Calendar</p>
      </Typography>
      <AddToCalendarButton {...filteredAttributes} />
      <Alert severity="warning" sx={{ marginTop: '12px' }}>
        Important! - Add to calendar buttons do not support recurring events, please do so in your
        individual calendars.
      </Alert>
      <SharedAlerts />
    </>
  );
};

General.propTypes = {
  category: PropTypes.string,
  completed: PropTypes.bool,
  department: PropTypes.string,
  description: PropTypes.string,
  end: PropTypes.string,
  property: PropTypes.string,
  recurring: PropTypes.string,
  recurringUntil: PropTypes.string,
  start: PropTypes.string,
  title: PropTypes.string,
  visibility: PropTypes.string,
  tzValue: PropTypes.string,
};

General.defaultProps = {
  category: null,
  completed: false,
  department: null,
  description: null,
  end: null,
  property: null,
  recurring: null,
  recurringUntil: null,
  start: null,
  title: null,
  visibility: null,
  tzValue: null,
};

export default General;
