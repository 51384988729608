let Highcharts = require('highcharts');
import addExporting from "highcharts/modules/exporting";
addExporting(Highcharts);

import {decimalToPercent} from "@utils/lib/number";
import {focusZoom} from "../utils";
const moment = require('moment');

function switchDateFormat(granularity) {
  let format;
  switch (granularity) {
    case 'Day':
        format = "MMM-DD";
        break;
    case 'Week':
        format = "[W]WW";
        break;
    case 'Month':
        format = "MMM";
        break;
  }
  return format;
}

function createDatesArray(data, granularity) {
  const format = switchDateFormat(granularity);
  return Array.from(new Set(data.map((p => moment(p.date).format(format)))));
}

document.addEventListener("prelease-velocity-loaded", function(e) {
  if ($('#js-prelease-velocity-graph').length) {
    const dataObj = e.detail.data;
    const { season, previous_season, segment } = dataObj;

    const categories = (previous_season.data.length > 0 ? previous_season : season)
    const datesArray = createDatesArray(categories.data, segment);

    Highcharts.setOptions({
      lang: {
        thousandsSep: ','
      }
    });

    var myChart = Highcharts.chart('js-prelease-velocity-graph', {
      chart: {
        zoomType: "x",
        fontFamily: 'Roboto, sans-serif',
        backgroundColor: "#353b50",
        style: {
          fontFamily: 'Roboto, Helvetica, sans-serif',
          color: "#ffffff",
          fontWeight: 'bold'
        },
        resetZoomButton: {
          position: {
            align: 'right', // right by default
            verticalAlign: 'top',
            x: -25,
            y: 5
          },
          relativeTo: 'chart'
        },
      },
      legend: {
        itemStyle: {
          color:'#ffffff',
          fontWeight: "normal"
        },
        align: 'center',
        x: 0,
        y: 0
      },
      title: {
        text: `Velocity by ${segment}`,
        style: {
          color: '#ffffff'
        },
      },
      xAxis: {
        title: {
          text: '',
          scrollbar: {
            enabled: true,
            showFull: false
          },
          style: {
            color: '#ffffff',
            fontWeight: 'bold',
            fontSize: '11px',
            fontFamily: 'Roboto, Helvetica, sans-serif'
          }
        },
        categories: categories.data.map((p, index) => {
          if(typeof season[index] === 'undefined'){
            return moment(p.date).format("MMM-DD");
          }else {
            return moment(season[index].date).format("MMM-DD");
          }
        }),
        minorTickInterval: null,
        gridLineWidth: 0,
        lineColor: 'rgba(255,255,255,.5)',
        lineWidth: 1,
        tickColor: '#f00',
        tickWidth: 0,
        labels: {
          style: {
            color: '#ffffff',
            font: '11px Roboto, Helvetica, sans-serif'
          }
        },
      },
      yAxis: [
        {
          title: {
            text: 'CONTRACTS',
            style: {
              color: '#ffffff',
              fontWeight: 'bold',
              fontSize: '11px',
              fontFamily: 'Roboto, Helvetica, sans-serif'
            }
          },
          opposite: true,
          min: 0,
          top: '50%',
          height: '50%',
          gridLineWidth: 0,
          minorTickInterval: null,
          lineColor: 'rgba(255,255,255,.5)',
          lineWidth: 0,
          tickWidth: 0,
          tickColor: '#000',
          labels: {
            style: {
              color: '#ffffff',
              font: '11px Roboto, Helvetica, sans-serif'
            }
          },
        },
        {
          title: {
            text: 'PRELEASE',
            style: {
              color: '#ffffff',
              fontWeight: 'bold',
              fontSize: '11px',
              fontFamily: 'Roboto, Helvetica, sans-serif'
            }
          },
          height: '60%',
          gridLineWidth: 0,
          minorTickInterval: null,
          lineColor: 'rgba(255,255,255,.5)',
          lineWidth: 0,
          tickWidth: 0,
          tickColor: '#000',
          labels: {
            formatter: function() {
               return this.value+"%";
            },
            style: {
              color: '#ffffff',
              font: '11px Roboto, Helvetica, sans-serif'
            }
          },
        },
      ],
      tooltip: {
        headerFormat: '',
        shared: true,
        distance: 50,
        followPointer: true,
        padding: 5
      },
      exporting: { enabled: false },
      series: [
        {
          type: "spline",
          name: `${season.name} Prelease`,
          yAxis: 1,
          color: "#2BBBAD",
          tooltip: {
              valueSuffix: '%',
          },
          data: season.data.map(function(week) {
            const format = switchDateFormat(segment);
            const formattedDate = moment(week.date).format(format);
            const index = datesArray.indexOf(formattedDate);

            if (index != -1) {
              return {
                name: `${decimalToPercent(week.prelease_capped_total_perc)}%`,
                y: decimalToPercent(week.prelease_capped_total_perc)
              }
            }
            return null;
          }).filter(point => point !== null),
          lineWidth: 3,
          zIndex: 1
        },
        {
          type: "spline",
          name: `${previous_season.name} Prelease`,
          yAxis: 1,
          color: "#5e698b",
          tooltip: {
              valueSuffix: '%',
          },
          data: previous_season.data.map(function(week) {
            return {
              name: `${decimalToPercent(week.prelease_capped_total_perc)}%`,
              y: decimalToPercent(week.prelease_capped_total_perc)
            }
          }),
          lineWidth: 3
        },
        {
          type: "column",
          name: `${season.name} Delta`,
          color: "#2BBBAD",
          borderWidth: 0,
          data: season.data.map((week) => {
            const format = switchDateFormat(segment);
            const formattedDate = moment(week.date).format(format);
            const index = datesArray.indexOf(formattedDate);

            if (index != -1) {
              return {
                name: `${week.prelease_capped_delta}`,
                y: week.prelease_capped_delta
              }
            }
            return null;
          }).filter(point => point !== null),
          lineWidth: 2,
        },
        {
          type: "column",
          name: `${previous_season.name} Delta`,
          color: "#5e698b",
          borderWidth: 0,
          data: previous_season.data.map(function(week) {
            return {
              name: `${week.prelease_capped_delta}`,
              y: week.prelease_capped_delta
            }
          }),
          lineWidth: 2,
        }
      ],
      plotOptions: {
        spline: {
          marker: {
            enabled: false
          }
        },
        allowPointSelect: false
      }
    });


    const max = Math.max(myChart.series[1].data.length, myChart.series[0].data.length)
    const focalPoint = myChart.series[0].data.length - 1;
    const extremes = { min: 0, max: max - 1, offsetLeft: 14, offsetRight: 14}
    focusZoom(myChart, focalPoint, extremes);

  }
})
