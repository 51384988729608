let Highcharts = require("highcharts");
Highcharts.setOptions({lang: { decimalPoint: '.', thousandsSep: ',' }});

import barChart from "../common/column";
import themeSelector from "../themes/theme_selector";
import {capitalize} from "@utils/lib/string";
import { statToColor } from "@utils/lib/color";
import {decimalToPercent} from "@utils/lib/number";

class BreakdownLeadChart{
  constructor(selectorName){
    if(selectorName === undefined && !$(`${selectorName}`).length){
      throw "Chart Error: Not a valid highchart";
    }
    this.state = {
      className: selectorName,
      domElement: $(selectorName),
      theme: null,
      title: null,
      columns: null,
      data: {},
      chart: null
    }
  }

  createChart(data){
    this.state = {...this.state, title: data.title, data: data.data, columns: data.columns, theme: data.theme }
    let {options} = this.state;
    options = Highcharts.merge(options, this._createChartOptions());
    options = Highcharts.merge(options, themeSelector(data.theme));
    this.state.chart = barChart(options);
  }

  updateChart(data){
    this.state.chart.showLoading();
    this.state = {...this.state, title: data.title, data: data.data, columns: data.columns, theme: data.theme }
    let {options} = this.state;
    const {series, title} = this._createChartOptions();
    this.state.chart.series[0].remove();
    this.state.chart.setTitle(title);
    this.state.chart.addSeries(series[0]);
    this.state.chart.yAxis[0].update({
        allowDecimals: true,
        labels: {
          formatter: function() {
             return this.value+"%";
          }
        }
    }, true);
    this.state.chart.redraw();
    this.state.chart.hideLoading();
  }

  chart(){
    return this.state.chart;
  }

  updateSeriesData(field = "prelease_capped_delta_perc"){
    if(!this.state.data) { return [] }
    return this.state.data.map((content, index, array) => (
      {
        ...content,
        name: content.base.code,
        code: content.base.name,
        y: (field == "prelease_capped_delta_perc" ? (decimalToPercent(content.difference[field]) || 0) : content.difference[field]),
        color: content.difference[field] >= 0 ? '#606988' : '#D65757'
      }
    ))
  }

  _createChartOptions(){
    const _this = this;
    return {
      chart: {
        renderTo: _this.state.domElement[0],
        zoomType: 'x',
        resetZoomButton: {
          theme: {
              display: 'none'
          }
        }
      },
      title: {
        text: "Prelease " + _this.state.title
      },
      tooltip: {
        useHTML: true,
        formatter: function () {
          let formattedTooltip = "";
          this.points.forEach(point => {
            const { base, difference, comparer } = point.point;

            formattedTooltip += `<div style='min-width: 350px;'>`;
            formattedTooltip += `<table class="highcharts-table">`;
            formattedTooltip += `<tr>
                                  <th style="width: 140px">${base.name}</th>
                                  <th style="width: 70px" class="text-center">${_this.state.columns[0]}</th>
                                  <th style="width: 70px" class="text-center">${_this.state.columns[1]}</th>
                                  <th style="width: 70px" class="text-center">${_this.state.columns[2]}</th>
                                 </tr>`;
            formattedTooltip += `<tr>
                                  <td>Signed Contracts</th>
                                  <td class="text-center"><b>${base.prelease_capped_delta}</b></td>
                                  <td class="text-center"><b>${comparer.prelease_capped_delta}</b></td>
                                  <td class="text-center" style="color: ${statToColor(difference.prelease_capped_delta)}"><b>${difference.prelease_capped_delta}</b></td>
                                </tr>`;
            formattedTooltip += `<tr>
                                  <td>Signed Prelease</th>
                                  <td class="text-center"><b>${decimalToPercent(base.prelease_capped_delta_perc)}%</b></td>
                                  <td class="text-center"><b>${decimalToPercent(comparer.prelease_capped_delta_perc)}%</b></td>
                                  <td class="text-center" style="color: ${statToColor(difference.prelease_capped_delta_perc)}"><b>${decimalToPercent(difference.prelease_capped_delta_perc)}%</b></td>
                                </tr>`;
            formattedTooltip += `<tr>
                                  <td>Total Contracts</th>
                                  <td class="text-center"><b>${Highcharts.numberFormat(base.prelease_capped_total,-1,'.',',')}</b></td>
                                  <td class="text-center"><b>${Highcharts.numberFormat(comparer.prelease_capped_total,-1,'.',',')}</b></td>
                                  <td class="text-center" style="color: ${statToColor(difference.prelease_capped_total)}"><b>${Highcharts.numberFormat(difference.prelease_capped_total,-1,'.',',')}</b></td>
                                </tr>`;
            formattedTooltip += `<tr>
                                  <td>Total Prelease</th>
                                  <td class="text-center"><b>${decimalToPercent(base.prelease_capped_total_perc)}%</b></td>
                                  <td class="text-center"><b>${decimalToPercent(comparer.prelease_capped_total_perc)}%</b></td>
                                  <td class="text-center" style="color: ${statToColor(difference.prelease_capped_total_perc)}"><b>${decimalToPercent(difference.prelease_capped_total_perc)}%</b></td>
                                </tr>`;
            formattedTooltip += `</table>`;
            formattedTooltip += `</div>`;

          });
          return formattedTooltip;
        }
      },
      yAxis: {
        labels: {
          formatter: function() {
             return this.value+"%";
          }
        },
        plotLines: [{
          color: '#bfc1c2',
          width: 1,
          value: 0
        }]
      },
      xAxis: {
        type: "category"
      },
      plotOptions: { series: { cropThreshold: 100, turboThreshold: 2000 } },
      legend: {
          enabled: false
      },
      series: [
        {
          name: "breakdown_lead",
          data: _this.updateSeriesData().sort((a,b) => (b.difference.prelease_capped_delta_perc || 0.0) - (a.difference.prelease_capped_delta_perc || 0.0))
        }
      ],
      exporting: {
        enabled: true,
        buttons: {
          signedButton: {
            text: 'YoY Contracts',
            x: -195,
            onclick: function(){
              _this.state.chart.title.update({
                text: "Contracts " + _this.state.title,
              })
              _this.state.chart.series[0].update({
                  data: _this.updateSeriesData("prelease_capped_delta").sort((a,b) => (b.difference.prelease_capped_delta || 0) - (a.difference.prelease_capped_delta || 0))
              }, true);
              _this.state.chart.yAxis[0].update({
                  allowDecimals: false,
                  labels: {
                    formatter: function() {
                       return this.value;
                    }
                  }
              }, true);
              _this.state.chart.redraw();
            },
            theme: {
              padding: 10,
              stroke: '#a8a8a8',
              fill: '#F0F0F0',
              states: {
                hover: {
                    fill: '#d8d8d8'
                }
              }
            },
          },
          preleaseButton: {
            text: 'YoY Prelease',
            x: -95,
            onclick: function(){
              _this.state.chart.title.update({
                text: "Prelease " + _this.state.title,
              })
              _this.state.chart.series[0].update({
                  data: _this.updateSeriesData().sort((a,b) => (b.difference.prelease_capped_delta_perc || 0.0) - (a.difference.prelease_capped_delta_perc || 0.0))
              }, true);
              _this.state.chart.yAxis[0].update({
                  allowDecimals: true,
                  labels: {
                    formatter: function() {
                       return this.value+"%";
                    }
                  }
              }, true);
              _this.state.chart.redraw();
            },
            theme: {
              padding: 10,
              stroke: '#a8a8a8',
              fill: '#F0F0F0',
              states: {
                hover: {
                    fill: '#d8d8d8'
                }
              }
            },
          },
          allZoomButton: {
            text: 'Reset Zoom',
            x: 0,
            theme: {
              padding: 10,
              stroke: '#a8a8a8',
              fill: '#F0F0F0',
              states: {
                hover: {
                    fill: '#d8d8d8'
                }
              }
            },
            onclick: function(){
              _this.state.chart.xAxis[0].setExtremes(null,null);
            }
          },
          contextButton: {
            enabled: false,
          }
        }
      }
    }
  }

}

export default BreakdownLeadChart;
