import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { parseISO } from 'date-fns';
import { Chip } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

const DateChip = ({ date, minDate, onChange, ...props }) => {
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(date);

  useEffect(() => {
    if (date !== selectedDate) {
      onChange(selectedDate);
    }
  }, [selectedDate]);

  const closeDialog = () => {
    if (open) {
      setOpen(false);
    }
  };

  const renderChip = () => {
    return <Chip {...props} onClick={() => setOpen(true)} />;
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        variant="dialog"
        value={parseISO(selectedDate)}
        onChange={setSelectedDate}
        minDate={parseISO(minDate)}
        allowKeyboardControl
        animateYearScrolling
        disableFuture
        open={open}
        TextFieldComponent={renderChip}
        onClose={closeDialog}
      />
    </MuiPickersUtilsProvider>
  );
};

DateChip.propTypes = {
  minDate: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DateChip;
