import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import PropTypes from 'prop-types';
import { arrayMoveImmutable } from '../../../utils/array-move/index';
import SortablePhotoGrid from './SortablePhotoGrid';
import api from '../../api';

const App = ({ media, property }) => {
  const [photos, setPhotos] = useState(media);
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setPhotos(arrayMoveImmutable(photos, oldIndex, newIndex));
  };
  const returnUrl = `/properties/${property.id}/property_sales_info/select_images`;
  return (
    <div
      style={{
        margin: '0px 12px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <div>
          <Typography variant="h4" component="div" sx={{ marginBottom: '12px' }}>
            {property.name} Property Sales Photo Organizer
          </Typography>
          <Typography variant="caption1" gutterBottom component="div">
            (Drag and drop photos to organize)
          </Typography>
        </div>
        <div>
          <Button variant="contained" size="large" href={returnUrl} sx={{ marginRight: '12px' }}>
            <NavigateBeforeIcon /> Select Photos
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              api.propertySalesImagesOrganizer
                .update({ photos: photos, property: property })
                .then(response => {
                  window.location.assign(response.data.redirect_url);
                })
                .catch(e => {
                  alert(
                    'There was an error saving your changes. Please create a help desk ticket if this continues to occur.'
                  );
                  throw new Error(e);
                });
            }}
          >
            Submit Photo Order <NavigateNextOutlinedIcon />
          </Button>
        </div>
      </div>
      <SortablePhotoGrid photos={photos} onSortEnd={onSortEnd} axis="xy" />
    </div>
  );
};

App.propTypes = {
  media: PropTypes.arrayOf(PropTypes.object).isRequired,
  property: PropTypes.object.isRequired,
}.isRequired;

export default App;
