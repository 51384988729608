import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { Container, Drawer, IconButton, Box } from '@material-ui/core';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import api from '../../../api';
import ItemIndexPage from '../../../pages/crud/ItemIndexPage';

const theme = createTheme({
  overrides: {
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: 'primary',
        },
      },
    },
  },
});

const DictionaryTerms = ({ canEditDictionaryTerms }) => {
  const crudFormClass = 'DictionaryTerm';
  const cacheKey = crudFormClass;
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [indexItems, setIndexItems] = React.useState([]);
  const [tableAttributes, setTableAttributes] = React.useState();

  const getDictionaryTerms = () => {
    api.crudItems
      .getAll({ form_class: crudFormClass })
      .then(({ data }) => {
        setTableAttributes(data.table_attributes);
        setIndexItems(data.index_items);
        sessionStorage.setItem(cacheKey, JSON.stringify(data));
      })
      .catch(() => {});
  };

  React.useEffect(() => {
    if (indexItems.length === 0) {
      if (sessionStorage.getItem(cacheKey)) {
        const sessionData = JSON.parse(sessionStorage.getItem(cacheKey));
        setTableAttributes(sessionData.table_attributes);
        setIndexItems(sessionData.index_items);
      } else {
        getDictionaryTerms();
      }
    }
  }, []);

  const setDrawer = drawerState => () => {
    setIsDrawerOpen(drawerState);
  };

  const refreshDictionaryTerms = () => {
    sessionStorage.removeItem(cacheKey);
    location.reload();
  };

  return (
    <>
      <IconButton type="button" variant="primary" onClick={setDrawer(true)}>
        <MenuBookIcon />
      </IconButton>
      <Drawer anchor="top" open={isDrawerOpen} onClose={setDrawer(false)} sx={{ height: 1 / 2 }}>
        <Container maxWidth="xl">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginBottom: '-48px',
              paddingTop: '4px',
            }}
          >
            <ThemeProvider theme={theme}>
              <IconButton type="button" variant="primary" onClick={refreshDictionaryTerms}>
                <AutorenewIcon />
              </IconButton>
              {canEditDictionaryTerms && (
                <IconButton type="button" variant="primary" href="/dictionary_terms">
                  <EditIcon />
                </IconButton>
              )}
              <IconButton onClick={setDrawer(false)}>
                <CloseIcon />
              </IconButton>
            </ThemeProvider>
          </Box>
          {indexItems && (
            <ItemIndexPage
              form_attributes={{ params: { form_class: 'DictionaryTerm' } }}
              table_attributes={tableAttributes}
              index_items={indexItems}
            />
          )}
        </Container>
      </Drawer>
    </>
  );
};

DictionaryTerms.propTypes = {
  canEditDictionaryTerms: PropTypes.bool,
};
DictionaryTerms.defaultProps = {
  canEditDictionaryTerms: false,
};

export default DictionaryTerms;
