const operationOptionsMapping = {
  string: [
    { label: 'is', value: '=', valueInputType: 'select' },
    { label: 'is not', value: '!=', valueInputType: 'select' },
    { label: 'contains', value: 'contains', valueInputType: 'text' },
    { label: 'starts with', value: 'starts_with', valueInputType: 'text' },
    { label: 'ends with', value: 'ends_with', valueInputType: 'text' },
  ],
  number: [
    { label: 'is', value: '=', valueInputType: 'number' },
    { label: 'is not', value: '!=', valueInputType: 'number' },
    { label: 'less than', value: '<', valueInputType: 'number' },
    { label: 'less than or equal', value: '<=', valueInputType: 'number' },
    { label: 'greater than', value: '>', valueInputType: 'number' },
    { label: 'greater than or equal', value: '>=', valueInputType: 'number' },
    { label: 'between', value: 'between', valueInputType: 'numberRange' },
  ],
  date: [
    { label: 'is', value: '=', valueInputType: 'dateSelect' },
    { label: 'is not', value: '!=', valueInputType: 'dateSelect' },
    { label: 'less than', value: '<', valueInputType: 'dateSelect' },
    { label: 'less than or equal', value: '<=', valueInputType: 'dateSelect' },
    { label: 'greater than', value: '>', valueInputType: 'dateSelect' },
    { label: 'greater than or equal', value: '>=', valueInputType: 'dateSelect' },
    { label: 'between', value: 'between', valueInputType: 'dateRange' },
  ],
};

export default operationOptionsMapping;
