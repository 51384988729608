import { Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import PropertyFeeTable from '../property_fee/PropertyFeeTable';
import FormikInputCheckbox from '../shared/formik_inputs/FormikInputCheckbox';

const FeePropertySurveyFormPropertyFeesFields = ({
  fees,
  propertyFees,
  values,
  onPropertyFeeUpdate,
}) => {
  const tableFees = fees => {
    return fees.map(fee => ({
      edit: `/property/${fee.property_id}/property_fee/${fee.id}/edit`,
      delete: ``,
      ...fee,
    }));
  };

  return (
    <div className="mb-12 px-4">
      <div className="mb-6">
        <h3 className="inline-block text-2xl font-light pr-3">Property Fees</h3>
      </div>

      <div className="mb-4">
        <PropertyFeeTable
          fees={fees}
          data={tableFees(propertyFees)}
          onUpdate={onPropertyFeeUpdate}
        />
        <div className="mt-2">
          <p className="text-left text-md-center mx-auto">
            * {` `}
            <small>This table only includes admin, application, security deposit fees.</small>
          </p>
        </div>
      </div>

      <div className="flex justify-center">
        <Field
          label="Confirm Property Fees"
          name="property_fee.is_verified"
          value={values.property_fee.is_verified}
          component={FormikInputCheckbox}
          labelClassName={{ 'inline-block': true, 'mb-2': false, 'mb-1': true }}
          checkboxClassName="mt-2 ml-2"
        />
      </div>
    </div>
  );
};

FeePropertySurveyFormPropertyFeesFields.propTypes = {
  values: PropTypes.shape({
    property_fee: PropTypes.shape({
      is_verified: PropTypes.bool.isRequired,
    }),
  }).isRequired,
  fees: PropTypes.instanceOf(Array).isRequired,
  propertyFees: PropTypes.arrayOf(PropTypes.shape({})),
  onPropertyFeeUpdate: PropTypes.func,
};

FeePropertySurveyFormPropertyFeesFields.defaultProps = {
  onPropertyFeeUpdate: undefined,
  propertyFees: [],
};

export default FeePropertySurveyFormPropertyFeesFields;
