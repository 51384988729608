import darkTheme from "./dark-dashboard";
import lightTheme from "./light-dashboard";

export default theme => {
  switch (theme) {
    case "dark":
      return darkTheme;
      break;
    case "light":
      return lightTheme;
    default:
      return {};
  }
};
