import columnChart from "../common/column";
import { decimalToPercent } from "@utils/lib/number";

document.addEventListener('DOMContentLoaded', function () {
  const Highcharts = require("highcharts");
  // Load module after Highcharts is loaded
  require("highcharts/modules/exporting")(Highcharts);

  if ($("#js-leasable-student-occupancy").length) {
    const domChart = $("#js-leasable-student-occupancy");
    const data = $("#js-leasable-student-occupancy").data("graph");
    const categories = data.seasons.map(season => season.season);
    const options = {
      chart: {
        renderTo: domChart[0]
      },
      title: {
        text: null
      },
      xAxis: {
        categories,
        plotBands: [
          {
            from: categories.length - 1.5,
            to: categories.length,
            color: "#F7F7F7"
          }
        ]
      },
      yAxis: [
        {
          title: {
            text: "Students"
          }
        },
        {
          opposite: true,
          title: {
            text: "Occupancy"
          },
          labels: {
            format: "{value}%"
          },
          max: 100
        }
      ],
      series: [
        {
          name: "Leasable Students",
          data: data.seasons.map(d => d.leasable_student_total),
          color: "gray",
          stack: "single",
          yAxis: 0
        },
        {
          name: "Total POSH Supply",
          data: data.seasons.map(d => d.supply_posh),
          color: "#FFCE00",
          stack: "single",
          visible: false,
          yAxis: 0
        },
        {
          name: "# of students in POSH",
          data: data.seasons.map(d => d.students_in_posh),
          color: "#181D30",
          stack: "single",
          visible: false,
          yAxis: 0
        },
        {
          type: "line",
          name: "Occupancy",
          data: data.seasons.map(d => decimalToPercent(d.occupancy_posh)),
          yAxis: 1,
          visible: false,
          color: "#419495",
          tooltip: {
            valueSuffix: "%"
          },
          marker: {
            enabled: false
          }
        }
      ]
    };

    const chart = columnChart(options);
  }
})